import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import AccessLogModule from "./AccessLogModule";
import DeadlinesModule from "./DeadlinesModule";
import TasksModule from "./TasksModule";
import PropTypes from "prop-types";

// gets the width of the window and extracts a calculated portion from it
const heightListContainer = window.innerHeight - window.innerHeight / 4;
const customStyles = {
  chevrolColor: {
    background: "#e5e5e5",
    borderRadius: "4px",
    cursor: "pointer",
  },
  disabledChevrol: {
    opacity: "0.4",
  },
};

const TodayModule = (props) => {
  const [t] = useTranslation(["dashboard", "reports"]);
  const [module, setModule] = useState("expiration");
  const [deadLinesLoadindData, setDeadLinesLoadindData] = useState(true);

  /**
   * Section to move from right to left with arrows
   * @param {String} direction
   */
  const changeModule = (direction) => {
    let newModule = module;
    switch (newModule) {
      case "expiration":
        newModule = "accessLog";
        setDeadLinesLoadindData(true);
        break;
      case "accessLog":
        newModule = direction == "left" ? "expiration" : "task";
        props.setAccessLogLoadingData(true);
        break;
      case "task":
        newModule = "accessLog";
        break;
      default:
        break;
    }
    setModule(newModule);
  };

  return (
    <Row>
      <Col md={6} className="mt-2">
        <p className="fs-5 fw-bolder">{t("reports:filterSelect.today")}</p>
      </Col>
      <Col md={6} className="mt-2">
        <Row className="mt-2 fs-6">
          <Col md={9}  className="text-end pe-0">
            <i
              className="bi bi-chevron-left"
              style={
                module == "expiration"
                  ? customStyles.disabledChevrol
                  : customStyles.chevrolColor
              }
              onClick={
                module == "expiration" ? null : () => changeModule("left")
              }
            ></i>
          </Col>
          <Col md={3} className="text-end pe-0">
            <i
              className="bi bi-chevron-right"
              style={
                module == "accessLog"
                  ? customStyles.disabledChevrol
                  : customStyles.chevrolColor
              }
              onClick={module == "accessLog" ? null : () => changeModule("right")}
            ></i>
          </Col>
        </Row>
      </Col>
      <Col
        md={12}
        style={{
          minHeight: heightListContainer + "px",
          maxHeight: heightListContainer + "px",
        }}
      >
        {module === "expiration" && (
          <DeadlinesModule
            modulesActive={props.modulesActive}
            readyFilters={props.readyFilters}
            waitingData={deadLinesLoadindData}
            setWaitinData={setDeadLinesLoadindData}
          />
        )}
        {module === "accessLog" && (
          <AccessLogModule
            modulesActive={props.modulesActive}
            readyFilters={props.readyFilters}
            filters={props.filters}
            accessLogLoadingData={props.accessLogLoadingData}
            setAccessLogLoadingData={props.setAccessLogLoadingData}
          />
        )}
        {module === "task" && (
          <TasksModule modulesActive={props.modulesActive} />
        )}
      </Col>
    </Row>
  );
};

export default TodayModule;

TodayModule.propTypes = {
  modulesActive: PropTypes.object,
  filters: PropTypes.object,
  readyFilters: PropTypes.bool,
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  accessLogLoadingData: PropTypes.bool,
  setAccessLogLoadingData: PropTypes.func,
}