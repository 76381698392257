import axios from "axios";
import { validateErrorResponse } from "./AxiosExtension";

let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Get the notifications
 * @param {object} [data={}]
 * @returns Object
 */
export const notificationsGet = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/notifications/notification_list_reduced`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Read user notifications
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const ReadNotifications = async (data = {}) => {
  data.token = localStorage.getItem(`cmToken${prefix}`);
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/notifications/read`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
