import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import SeeAllModule from "../SeeAllModule";
import OverlayBlockModule from "../OverlayBlockModule";
import PropTypes from "prop-types";
import { LoadingDeadlinesDashboard } from "../../../lazyLoading/LazyLoading";
import { LoadingCompletePaymetnsDashboard } from "../../../lazyLoading/LazyLoading";
import { GetOverduePayments } from "../../../../api/Dashboard";
import { GetCompletedPayments } from "../../../../api/Dashboard";

const customStyles = {
  moduleContainerFixedHeight: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    paddingTop: "10px",
    padding: " 5px 15px",
  },
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "35px",
    marginBlock: "10px",
    marginTop: "0px",
    marginBottom: "0px",
  },
  lazy: {
    height: "25px",
  },
};

const dummyOverduePayments = [
  {
    name: "Primaria",
    value: "7500",
  },
  {
    name: "Secundaria",
    value: "9000",
  },
  {
    name: "Universidad",
    value: "34500",
  },
];

const dummyCompletedPayments = [
  {
    name: "Primaria",
    value: "25000",
  },
  {
    name: "Secundaria",
    value: "9000",
  },
  {
    name: "Universidad",
    value: "23000",
  },
];

const DeadlinesModule = (props) => {
  const [t] = useTranslation(["dashboard"]);
  const [overduePayments, setOverduePayments] = useState([]);
  const [completedPayments, setCompletedPayments] = useState([]);

  /**
   * Obtains the information of today's overdue payments
   */
  const getOverduePayments = () => {
    let request = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetOverduePayments(request).then((result) => {
      if (result.status) {
        let data = result.data;
        let overdue = [];
        overdue = data.map((item) => {
          return {
            name: item.name,
            value: item.value,
          };
        });
        setOverduePayments(overdue);
      }
      props.setWaitinData(false);
    });
  };

  /**
   * Gets today's completed payments information
   */
  const getCompleted = () => {
    let request = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetCompletedPayments(request).then((result) => {
      if (result.status) {
        let data = result.data;
        let completed = [];
        completed = data.map((item) => {
          return {
            name: item.name,
            value: item.value,
          };
        });
        setCompletedPayments(completed);
      }
      props.setWaitinData(false);
    });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.modulesActive.Payments == false) {
      setOverduePayments(dummyOverduePayments);
      setCompletedPayments(dummyCompletedPayments);
      props.setWaitinData(false);
      return;
    }
  }, [props.modulesActive.Payments]);

  useEffect(() => {
    if (props.readyFilters) {
      if (props.modulesActive.Payments == false) return;
      getOverduePayments();
      getCompleted();
    }
  }, [props.readyFilters]);
  return (
    <>
      {props.waitingData ? (
        <LoadingDeadlinesDashboard />
      ) : (
        <Row>
          <OverlayBlockModule
            active={props.modulesActive.Payments}
            today={true}
            customWidth="100%"
            module="payments"
            content={
              <Col
                className="mt-3"
                md={12}
                style={customStyles.moduleContainerFixedHeight}
              >
                <Row>
                  <Col md={3} className="text-center pe-0">
                    <i
                      className="bi bi-x-lg"
                      style={{ color: "#EA1B17", fontSize: "24px" }}
                    ></i>
                  </Col>
                  <Col md={9} className="mt-1 fs-6 ps-0">
                    <b>{t("textViewToday.titleExpirations")}</b>
                  </Col>
                </Row>
                {overduePayments.length > 0 ? (
                  <div>
                    {overduePayments.map((item) => {
                      return (
                        <Row key={item} style={{ padding: "5px 10px" }}>
                          <Col md={12} style={customStyles.rowContainer}>
                            <Row>
                              <Col md={12}>
                                <p
                                  className="mt-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  {item.name}
                                  {": "} {" $"}
                                  {item.value}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                ) : (
                  <Row>
                    <Col md={12} className=" align-middle">
                      <p className="text-center">
                        {t("dashboard:labels.noData")}
                      </p>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={12} className="mt-3 mb-2">
                    <SeeAllModule path={"/payments/debt"}></SeeAllModule>
                  </Col>
                </Row>
              </Col>
            }
          />
        </Row>
      )}
      <br />

      {props.waitingData ? (
        <LoadingCompletePaymetnsDashboard />
      ) : (
        <Row>
          <OverlayBlockModule
            active={props.modulesActive.Payments}
            today={true}
            customWidth="100%"
            module="payments"
            content={
              <Col
                className="mt-3"
                md={12}
                style={customStyles.moduleContainerFixedHeight}
              >
                <Row>
                  <Col md={3} className="text-center  pe-0">
                    <i
                      className="bi bi-check-lg"
                      style={{ color: "#6ee7b7", fontSize: "24px" }}
                    ></i>
                  </Col>
                  <Col md={9} className="mt-1 fs-6 ps-0">
                    <b>{t("textViewToday.titlePayments")}</b>
                  </Col>
                </Row>

                {completedPayments.length > 0 ? (
                  <div>
                    {completedPayments.map((item) => {
                      return (
                        <Row key={item} style={{ padding: "5px 10px" }}>
                          <Col md={12} style={customStyles.rowContainer}>
                            <Row>
                              <Col md={12}>
                                <p
                                  className="mt-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  {item.name}
                                  {": "} {" $"}
                                  {item.value}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                ) : (
                  <Row>
                    <Col md={12} className=" align-middle">
                      <p className="text-center">
                        {t("dashboard:labels.noData")}
                      </p>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={12} className="mt-3 mb-2">
                    <SeeAllModule path={"/payments/income"}></SeeAllModule>
                  </Col>
                </Row>
              </Col>
            }
          />
        </Row>
      )}
    </>
  );
};

export default DeadlinesModule;

DeadlinesModule.propTypes = {
  modulesActive: PropTypes.object,
  readyFilters: PropTypes.bool,
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
};
