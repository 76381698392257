import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
//Components
import SuperAdminView from "../../components/superAdmin/SuperAdminsView";
import ServicesView from "../../components/services/ServicesView";
import OrganizationsView from "../../components/organizations/OrganizationsView";
import PermissionsView from "../../components/permissions/PermissionsView";
//Design resources
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { validateUserRoleIds } from "../../components/global/GlobalTools";

/**
 *  Component for managing the content to be displayed through tabs
 * @param {obj} props  {params}: to obtain the subroute
 * @returns
 */
const AdminView = (props) => {
  const [t] = useTranslation(["global", "superAdmin"]);
  const defaultTab = props.match.params?.view || "admin";
  const [focusedTab, setFocusedTab] = useState(defaultTab);
  const history = useHistory();

  /**Initial loading */
  useEffect(() => {
    if(!validateUserRoleIds([1])) {
      history.push("/dashboard");
    }
    //Validates that no subroutes are entered outside the section
    const availableTabs = ["admin", "organizations", "services", "permissions"];
    if (!availableTabs.includes(defaultTab)) {
      history.push("/admin");
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={12} className="mx-4">
          <h2> {t("superAdmin:tabBar." + focusedTab)}</h2>
        </Col>
      </Row>
      <Tab.Container id="left-tabs-example" defaultActiveKey={focusedTab}>
        <Nav
          variant="pills"
          activeKey={focusedTab}
          style={{ width: "fit-content", margin: "0 auto" }}
        >
          <Nav.Item>
            <Nav.Link
              eventKey="admin"
              onClick={() => {
                setFocusedTab("admin");
                history.push("/admin");
              }}
            >
              {t("buttonsPermissions.btnUser")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="organizations"
              onClick={() => {
                setFocusedTab("organizations");
                history.push("/admin/organizations");
              }}
            >
              {t("buttonsPermissions.btnOrg")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="services"
              onClick={() => {
                setFocusedTab("services");
                history.push("/admin/services");
              }}
            >
              {t("buttonsPermissions.btnServices")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="permissions"
              onClick={() => {
                setFocusedTab("permissions");
                history.push("/admin/permissions");
              }}
            >
              {t("titlePermissions.permissions")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
      <Row className="mt-4">
        <Col md={12}>
          {focusedTab === "admin" && <SuperAdminView />}
          {focusedTab === "services" && <ServicesView />}
          {focusedTab === "organizations" && <OrganizationsView />}
          {focusedTab === "permissions" && <PermissionsView />}
        </Col>
      </Row>
    </Container>
  );
};
export default AdminView;
AdminView.propTypes = {
  match: PropTypes.object,
};
