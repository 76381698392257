import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { goToHref } from "../../global/GlobalTools";

const defaultStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "5px",
  },
  textDanger: {
    color: "#ea2c54",
    fontSize: "12px",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
  },
  iconChevron: {
    color: "#ea2c54",
    width: "16px",
    height: "16px",
    marginTop: "3px",
  },
};

export default function SeeAllModule(props) {
  const [t] = useTranslation(["dashboard", "staff"]);
  const history = useHistory();
  const path = props.path || "/dashboard";

  return (
    <div style={defaultStyles.container}>
      <span
        className="me-1"
        role="button"
        style={defaultStyles.textDanger}
        onClick={() => (props.isCMv1 ? goToHref(path) : history.push(path))}
      >
        {props.perfil
          ? t("staff:modalConfirmationsStaff.viewProfileText")
          : t("dashboard:labels.seeAll")}
      </span>
      <i
        className="fal fa-chevron-right"
        role="button"
        style={defaultStyles.iconChevron}
        onClick={() => (props.isCMv1 ? goToHref(path) : history.push(path))}
      />
    </div>
  );
}

SeeAllModule.propTypes = {
  path: PropTypes.string,
  isCMv1: PropTypes.bool,
  perfil: PropTypes.bool,
};
