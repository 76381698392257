import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, Col, Row, Table, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import FormCheck from "../../../components/global/form/FormCheck";
import { Formik, Form } from "formik";
import * as moment from "moment";
import * as Sentry from "@sentry/react";
import {
  LoadingTableModal,
  LoadingBodyModal,
} from "../../../components/lazyLoading/LazyLoading";
import MassBillingConfirmationModal from "./MassBillingConfirmationModal";

/*Services */
import { UnbilledPayments } from "../../../api/PaymentsReport";
const stylesCustom = {
  alertRfc: {
    zIndex: "+2",
    backgroundColor: "#cff4fc",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "10px 20px",
    position: "fixed",
    left: "25%",
    bottom: "20%",
    height: "60px",
    width: "50%",
  },
};

export default function MassBillingModal(props) {
  const [t] = useTranslation(["global", "reports"]);
  const [paymentsForBilling, setPaymentsForBilling] = useState([]);
  const [loadingView, setLoadingView] = useState(true);
  const [showMassBillinConfirmationModal, setShowMassBillinConfirmationModal] =
    useState(false);
  const [groupedPayments, setGroupedPayments] = useState([]);
  const [rfcCurrentToBill, setRfcCurrentToBill] = useState({
    issuer_rfc_name: "",
    issuer_rfc: "",
  });
  const [differentRfcAlert, setDifferentRfcAlert] = useState(false);

  /**
   * Get all unbilled payments
   */
  const getPaymetNoBilled = () => {
    UnbilledPayments().then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setLoadingView(false);
        return;
      }
      let data = result.data.data;
      data.forEach((element) => {
        element.checked = false;
        element.create_time = moment(element.create_time).format("DD/MM/YYYY");
      });
      groupingMassiveInvoicing(data);
      setLoadingView(false);
    });
  };

  /*Group payments */
  const groupingMassiveInvoicing = (payments) => {
    let groupRfcs = [];
    payments.forEach((payment) => {
      let found = groupRfcs.findIndex(
        (item) => item.issuer_rfc == payment.issuer_rfc
      );
      if (found == -1) {
        let obj = {
          groupPayments: [payment],
          issuer_rfc: payment.issuer_rfc,
          issuer_rfc_name: payment.issuer_rfc_name,
          allSelected: false,
        };
        groupRfcs.push(obj);
      } else {
        groupRfcs[found].groupPayments.push(payment);
      }
    });
    setGroupedPayments(groupRfcs);
  };

  /**
   * Selects and scrolls through the table checks
   * @param {Boolean} value
   * @param {Number} position
   */
  const selectCheckBox = (value, position, positionGroup) => {
    if (groupedPayments[positionGroup].issuer_rfc == null) {
      return;
    }
    let paymentId = groupedPayments[positionGroup].groupPayments[position].id;
    let paymetsForBill = [...paymentsForBilling];
    if (value) {
      if (paymetsForBill.length > 0) {
        if (
          !isSameRfc(groupedPayments[positionGroup].groupPayments[position])
        ) {
          groupedPayments.forEach((element) => {
            element.allSelected = false;
            element.groupPayments.forEach((item) => {
              item.checked = false;
            });
          });
          paymetsForBill = [];
          setDifferentRfcAlert(true);
          //The Alert is hidden after a certain period of time
          setTimeout(() => {
            setDifferentRfcAlert(false);
          }, 3000);
        }
      }
      setRfcCurrentToBill({
        issuer_rfc_name: groupedPayments[positionGroup].issuer_rfc_name,
        issuer_rfc: groupedPayments[positionGroup].issuer_rfc,
      });
      paymetsForBill.push(paymentId);
    } else {
      let index = paymetsForBill.indexOf(paymentId);
      paymetsForBill.splice(index, 1);
    }
    groupedPayments[positionGroup].groupPayments[position].checked = value;
    //Every is used to see when the last checked position is checked and is marked as true.
    groupedPayments[positionGroup].allSelected = groupedPayments[
      positionGroup
    ].groupPayments.every((item) => item.checked == true);
    setGroupedPayments(groupedPayments);
    setPaymentsForBilling(paymetsForBill);
  };

  /**
   * Mark and unmark all checkboxes
   * @param {Boolean} value
   */
  const selectAllCheckbox = (value, positionGroup) => {
    if (groupedPayments[positionGroup].issuer_rfc == null) {
      return;
    }
    let paymetsForBill = [...paymentsForBilling];
    if (value) {
      if (paymetsForBill.length > 0) {
        if (!isSameRfc(groupedPayments[positionGroup])) {
          groupedPayments.forEach((element) => {
            element.allSelected = false;
            element.groupPayments.forEach((item) => {
              item.checked = false;
            });
          });
          setPaymentsForBilling([]);
          setDifferentRfcAlert(true);
          //The Alert is hidden after a certain period of time
          setTimeout(() => {
            setDifferentRfcAlert(false);
          }, 3000);
        }
      }
      setRfcCurrentToBill({
        issuer_rfc_name: groupedPayments[positionGroup].issuer_rfc_name,
        issuer_rfc: groupedPayments[positionGroup].issuer_rfc,
      });
      let paymentsBilling = [];
      groupedPayments[positionGroup].groupPayments.forEach((element) => {
        element.checked = true;
        paymentsBilling.push(element.id);
      });
      setPaymentsForBilling(paymentsBilling);
    } else {
      groupedPayments[positionGroup].groupPayments.forEach((element) => {
        element.checked = false;
      });
      setPaymentsForBilling([]);
    }
    groupedPayments[positionGroup].allSelected = value;
    setGroupedPayments(groupedPayments);
  };

  /**
   * It is used to know when the data is coming in or is empty
   * @param {Object} payment
   * @returns
   */
  const isSameRfc = (payment) => {
    let isSame = false;
    if (payment.issuer_rfc_name != null && payment.issuer_rfc != null) {
      isSame =
        rfcCurrentToBill.issuer_rfc_name === payment.issuer_rfc_name &&
        rfcCurrentToBill.issuer_rfc === payment.issuer_rfc;
    }
    return isSame;
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Mass Billing Modal");
    if (props.showMassBillingModal) {
      setLoadingView(true);
      setPaymentsForBilling([]);
      getPaymetNoBilled();
    }
  }, [props.showMassBillingModal]);

  return (
    <div>
      <Modal
        show={props.showMassBillingModal}
        size="xl"
        aria-labelledby="contained-modal-title-lg"
      >
        {loadingView ? (
          <LoadingTableModal />
        ) : (
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title text-center">
              {t("buttons.massiveInvoicing")}
            </Modal.Title>
          </Modal.Header>
        )}

        {loadingView ? null : (
          <Modal.Body>
            <div>
              <p>{t("reports:modals.massBillingModalTitle")}</p>
              <p className="fw-bold">{t("reports:modals.BillingModalText")}</p>
            </div>
          </Modal.Body>
        )}
        <Formik initialValues={{}}>
          {() => (
            <Form>
              {loadingView ? (
                <LoadingBodyModal />
              ) : (
                <div>
                  <div
                    className="px-3 mx-3"
                    style={{ border: "solid 1px #eef0f2" }}
                  >
                    <ModalBody className="modal-body mbottom10 pt-0">
                      <Row
                        className={"d-lg mt-4"}
                        style={{ maxHeight: "500px", overflow: "auto" }}
                      >
                        {groupedPayments.map((group, indexGroup) => (
                          <div key={indexGroup}>
                            <Col md={12}>
                              <p className="col-md-12 fw-bold">
                                RFC:{" "}
                                {group.issuer_rfc == null
                                  ? t("reports:modals.dataNotFound")
                                  : group.issuer_rfc}
                              </p>
                            </Col>
                            <Col md={12}>
                              <p className="col-md-12 fw-bold">
                                {t("reports:modals.nameCompanyName")}
                                {group.issuer_rfc_name == null
                                  ? t("reports:modals.dataNotFound")
                                  : group.issuer_rfc_name}
                              </p>
                            </Col>
                            <Table bordered hover>
                              <thead>
                                <tr>
                                  <th className="text-center" width="5%">
                                    <Row className="d-lg mt-0">
                                      {/**CheckBox Paymentst*/}
                                      <Col md={12}>
                                        <FormCheck
                                          name="selectAll"
                                          type="checkbox"
                                          onClick={() =>
                                            selectAllCheckbox(
                                              !group.allSelected,
                                              indexGroup
                                            )
                                          }
                                          checked={group.allSelected}
                                        />
                                      </Col>
                                    </Row>
                                  </th>
                                  <th width="35%">
                                    {t("reports:headerTable.transaction")}
                                  </th>
                                  <th width="12%">
                                    {t("reports:headerTable.date")}
                                  </th>
                                  <th width="35%">
                                    {t("reports:headerTable.customer")}
                                  </th>
                                  <th width="12%">
                                    {t("reports:headerTable.amount")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="overflow-auto">
                                {group.groupPayments.map((row, index) => (
                                  <tr key={index}>
                                    <td className="text-center" width="5%">
                                      <FormCheck
                                        name={index}
                                        label=""
                                        type="checkbox"
                                        checked={row.checked}
                                        onClick={() =>
                                          selectCheckBox(
                                            !row.checked,
                                            index,
                                            indexGroup
                                          )
                                        }
                                      />
                                    </td>
                                    <td width="35%">{row.confirmation}</td>
                                    <td width="12%"> {row.create_time} </td>
                                    <td width="35%">{row.client}</td>
                                    <td width="12%">$ {row.amount}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        ))}
                      </Row>
                    </ModalBody>
                    <div
                      className={
                        !differentRfcAlert ? "hide" : "alert alert-info"
                      }
                      style={stylesCustom.alertRfc}
                    >
                      <div className="col-md-12  mtop10">
                        {t("reports:modals.tileAlertRfc")}
                      </div>
                    </div>
                  </div>
                  <Modal.Footer>
                    <Button
                      variant="light"
                      onClick={() => props.setShowMassBillingModal(false)}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setShowMassBillinConfirmationModal(true);
                        props.setShowMassBillingModal(false);
                      }}
                      disabled={!paymentsForBilling.length}
                    >
                      {t("reports:headerTable.invoice")}
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Modal>
      <MassBillingConfirmationModal
        showMassBillinConfirmationModal={showMassBillinConfirmationModal}
        setShowMassBillinConfirmationModal={setShowMassBillinConfirmationModal}
        paymentIds={paymentsForBilling}
        setShowMassBillingModal={props.setShowMassBillingModal}
      />
    </div>
  );
}

MassBillingModal.propTypes = {
  showMassBillingModal: PropTypes.bool,
  setShowMassBillingModal: PropTypes.func,
};
