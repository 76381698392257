import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// TODO: will be enabled as validations are added.
import APPMOVIL from "../../../img/gifModulesDashboard/APPMOVIL.gif";
// import CONTENIDODIGITAL from "../../../img/gifModulesDashboard/CONTENIDODIGITAL.gif";
// import DESARROLLODESOFTWARE from "../../../img/gifModulesDashboard/DESARROLLODESOFTWARE.gif";
// import GESTIONDEREPUTACION from "../../../img/gifModulesDashboard/GESTIONDEREPUTACION.gif";
// import GOOGLEPARALAEDUCACION from "../../../img/gifModulesDashboard/GOOGLEPARALAEDUCACION.gif";
import PAGOSYFACTURACION from "../../../img/gifModulesDashboard/PAGOSYFACTURACION.gif";
import SCE from "../../../img/gifModulesDashboard/SCE.gif";
// import SITIOWEB from "../../../img/gifModulesDashboard/SITIOWEB.gif";
import CRM from "../../../img/gifModulesDashboard/CRM.gif";
const prefix = process.env.REACT_APP_PREFIX;

export default function ModalScheduleDemo(props) {
  const [t] = useTranslation(["global", "dashboard"]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState(null);
  const [contact, setContact] = useState(
    "https://calendly.com/comercial-campus-movil/reunion-30-min-nueva-funcionalidad?month=2023-08"
  );
  const [language] = useState(localStorage.getItem(`cmLanguage${prefix}`));
  const gotoSceduleDemo = () => {
    window.open(contact, "_blank");
  };

  const messageAccessLog = () => {
    return language == "english" ? (
      "Keep track of attendance at a different level by scanning student IDs as they come in. This is a safe and efficient way for parents to know their children have arrived and left school safely."
    ) : (
      <>
        Agiliza el ingreso de los estudiantes a las instalaciones, registra los
        horarios de entrada y salida de manera segura y eficiente. Para poder
        utilizar la bitácora de accesos obtenga las herramientas de{" "}
        <a
          href="#"
          style={{ color: "#ea2c54" }}
          onClick={() =>
            window.open(
              "https://www.campusmovil.com.mx/sistema-control-escolar/"
            )
          }
        >
          control escolar
        </a>{" "}
        y{" "}
        <a
          href="#"
          style={{ color: "#ea2c54" }}
          onClick={() =>
            window.open("https://www.campusmovil.com.mx/aplicacion-movil/")
          }
        >
          aplicación móvil
        </a>
        , para conocer más sobre esta herramienta haz clic en agendar demo.
      </>
    );
  };

  useEffect(() => {
    switch (props.module) {
      case "payments":
        setTitle("contractPayments");
        setMessage(t("dashboard:overlayBlock.messagePayments"));
        setImage(PAGOSYFACTURACION);
        setContact("https://calendly.com/comercial-campus-movil/reunion-30-min-nueva-funcionalidad?month=2023-08");
        break;
      case "events":
        setTitle("contractMobileApplication");
        setMessage(t("dashboard:overlayBlock.messageEvents"));
        setImage(APPMOVIL);
        break;
      case "average":
        setTitle("contractsSCSystem");
        setMessage(t("dashboard:overlayBlock.messageAverage"));
        setImage(SCE);
        break;
      case "students":
        setTitle("contractsSCSystem");
        setMessage(t("dashboard:overlayBlock.messageStudents"));
        setImage(SCE);
        break;
      case "crm":
        setTitle("contractCRM");
        setMessage(t("dashboard:overlayBlock.messageCrm"));
        setImage(CRM);
        break;
      case "accessLog":
        setTitle("unblockAccessLog");
        setMessage(messageAccessLog());
        setImage(SCE);
        break;
      default:
        break;
    }
  }, []);

  return (
    <Modal
      show={props.showModalScheduleDemo}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
      centered
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("dashboard:overlayBlock." + title)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={12} className="mb-2">
            {message}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <Button onClick={() => gotoSceduleDemo()}>
              {t("dashboard:overlayBlock.scheduleDemo")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Image className="mt-4" fluid rounded src={image} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowModalScheduleDemo(false)}
        >
          {t("global:buttons.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalScheduleDemo.propTypes = {
  showModalScheduleDemo: PropTypes.bool, //Required
  setShowModalScheduleDemo: PropTypes.func,
  module: PropTypes.string,
};
