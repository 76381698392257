import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";

let axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "get",
};
const prefix = process.env.REACT_APP_PREFIX;

/**
 *  Purpose: Get the school levels
 * @returns
 */
export const getSchoolLevels = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/school_levels`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};
/**
 * Purpose: Service to obtain the grades and groups of a level
 * @param {id} gradeId
 * @returns {Array}
 */
export const getGradeGroups = async (gradeId) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/grade_groups`;
  axiosOptions.params = {
    grade_level_id: gradeId,
  };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Service to obtain the grades of a level
 * @returns {Array}
 */
export const getGradeLevels = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`${prefix}pwtoken`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/grade_levels`;
  axiosOptions.params = {};

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Service to create a new prospect
 * @argument {object} leaflet //object containing the parameters for creating a new prospectus
 * @returns {JSON object} JSON object
 */
export const createleaflet = async (leaflet) => {
  let data = Object.assign({}, leaflet);
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/prospects`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Service to obtain procurement methods
 * @argument {string} apiKey //optional if no token exists
 * @returns {Array}
 */
export const GetAcquisitionMethods = async (apiKey = false) => {
  let data = {};
  axiosOptions = {};
  let token = apiKey ? apiKey : localStorage.getItem(`cmToken${prefix}`);
  axiosOptions.method = "get";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: token,
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/acquisition_methods`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Create a new acquisition method for the organization
 * @argument {string} apiKey //optional if no token exists
 * @argument {string} methodName //name for the new acquisition method
 * @returns {JSON object} JSON object
 */
export const CreateAcquisitionMethods = async (methodName, apiKey = false) => {
  let data = {};
  data.name = methodName;
  axiosOptions = {};
  let token = apiKey ? apiKey : localStorage.getItem(`cmToken${prefix}`);
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: token,
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/acquisition_methods`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Service that obtains academic offers
 * @returns {Array}
 */
export const GetCollagesSchool = async () => {
  let data = {};
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/colleges_and_schools`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Service that obtains the school levels
 * @returns {Array}
 */
export const GetSchoolLevels = async () => {
  let data = {};
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/school_levels`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Service to create a new top level
 * @param {obj} data // object containing the parameters of the new level
 * @returns {JSON object} JSON object
 */
export const CreateProgram = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/programs`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Service to create a new facultad universitaria
 * @param {obj} data
 * @returns {JSON object} JSON object
 */
export const CollagesSchool = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/colleges_and_schools`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Service to obtain the school cycles of an organization
 * @returns {Array}
 */
export const getSchoolCycles = async () => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/school_cycles`;
  axiosOptions.params = {
    visible: "admin",
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Get a pre-signed link from a path that only lasts one minute *
 * @param {string} data
 * @returns
 */
export const GetPreSignedLink = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/amazon_requests/presigned_urls`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return ErrorResponse(error.response);
  }
};

/**
 * Get all organization categories
 * @returns {JSON array} JSON array, Categories response
 */
export const GetQrCodeUrl = async () => {
  let data = {};
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/prospects/qrcode`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get all prospects
 * @param {Int} limit // Number of records returned
 * @param {Obj} data // brings the object of prospectus response limits
 * @returns
 */
export const GetProspects = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/prospects`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Service that obtains the school levels
 * @returns {Array}
 */
export const GetOrgatinzationSchoolLevels = async () => {
  let data = {
    organization_id: localStorage.getItem(`cmOrganizationID${prefix}`),
  };
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organization_school_levels`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Bringing the organization's programs
 * @returns {Array}
 */
export const GetProgram = async () => {
  let data = {};
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/programs`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Service to generate/validate an automatic code for the academic offer.
 * @param {obj} data // object containing the parameters of
 * @return {JSON object} JSON object, offer response
 */
export const GetCodeProgram = async (data) => {
  let urlData = "";
  if (data.code) {
    urlData = "?code=" + data.code;
  } else {
    urlData = "?name=" + data.name;
  }
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "X-Requested-With": data.code ? data.code : data.name,
  };
  axiosOptions.url =
    `${process.env.REACT_APP_REST_URL}/v2_1/students/code_program` + urlData;
  try {
    const response = await axios(axiosOptions);
    response.data["X-Requested-With"] =
      response.config.headers["X-Requested-With"];
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Description: Service to update the status of lead
 * @param {obj} data - object that has the ids and status to update
 * @return {JSON object} JSON object
 */
export const ChangeStatusLead = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/prospects/status`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Service to get archived leads
 * @param {object} data Contains the parameters to get one or more archived prospects
 * @returns {JSON object}
 */

export const GetArchivedProspects = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/prospects/archived`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Prospect Archiving Service
 * @param {object} data Contains the parameters to archive one or more leads
 * @returns {JSON object}
 */

export const ArchivedProspects = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/prospects/archive`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Download Import Prospects Tesmplate
 * @returns {CSV} template
 */
export const DownloadImportProspectsTemplate = async () => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/prospects/import_prospects_template`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

 /**
 * Description: Function to import prospects from CSV file
 * @param {file} file
 * @param {int} schoolCycleId
 * @return {JSON Object} Response from API 
 */
export const ImportProspects = async (file, schoolCycleId) => {
  const data = new FormData();
  data.append("import_prospects_csv", file);
  data.append("school_cycle_id", schoolCycleId);

  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "multipart/form-data",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/prospects/import_prospects`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};
