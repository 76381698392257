import axios from "axios";
import { ErrorResponse, validateErrorResponse } from "./AxiosExtension";

let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Gets the organizations that come with the user
 * @param {Number} userId
 * @returns
 */
export const userGet = async (userId, token) => {
  let data = {
    token: token || localStorage.getItem(`cmToken${prefix}`),
    user_id: userId,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REST_URL}/v1/users`,
      data
    );
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Description: Function to get custom token from firebase
 * @return {JSON Object} Response from API
 */
export const GetCustomToken = async () => {
  const axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/users/custom_token`;
  axiosOptions.params = {};

  try {
    const response = await axios(axiosOptions);
    return response.data?.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Function for sending user a review request
 * @param {object} [data={}]
 * @return {JSON Object} Response from API
 */
export const UserReviewsPost = async (data = {}) => {
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/user_reviews`;
  axiosOptions.data = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};
