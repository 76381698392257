import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { getOrganizationAdditionalFields } from "../../api/StudentsReport";
import { RadioButton, CheckBox } from "../global/form/AdditionalFields";
import FieldInput from "../global/form/FieldInput";
import FormSelect from "../global/form/FormSelect";
import { Row, Col } from "react-bootstrap";

const customStyles = {
  lazy: {
    height: "25px",
  },
  scrollClass: {
    height: "295px",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "13px",
  },
  rowClassContainer: {
    paddingTop: "10px",
    paddingBottom: "5px",
    border: "1px solid #ececee",
    borderRadius: "0px",
  },
};

const AdditionalFieldsBlock = (props) => {
  const [t] = useTranslation(["global"]);

  const [additionalFields, setAdditionalFields] = useState([]);
  const [lazyLoad, setLazyLoad] = useState(true);

  useEffect(() => {
    getAdditionalFields();
  }, []);

  /**
   * Get additional fields from the API
   */
  const getAdditionalFields = () => {
    getOrganizationAdditionalFields({ additional_fields_sections_ids: [1] })
      .then((response) => {

        let fields = response.data[0].fields;
        // Filter to remove options from fields with active in 1
        fields = fields.map((field) => {
          if (field.options) {
            field.options = field.options.filter((option) => option.active == 1);
          }
          return field;
        });
        setLazyLoad(false);
        setAdditionalFields(fields);
        props.setAdditionalFields(fields);
        let newSchema = Yup.object().shape({});
        for (let item in fields) {
          // Set empty value to the fields for register the field
          props.formik.setFieldValue(fields[item].additional_fields_id, "");
          // Add validations to the schema
          if (fields[item].required == 1) {
            //if the field is type check, the validation is boolean
            if (fields[item].type == "check") {
              newSchema = newSchema.concat(
                Yup.object().shape({
                  [fields[item].additional_fields_id]: Yup.array().min(1).required(t("global:thisFieldIsRequired")),
                })
              );
            } else if (fields[item].type == "multiple_option") {
              newSchema = newSchema.concat(
                Yup.object().shape({
                  [fields[item].additional_fields_id]: Yup.number().required(t("global:thisFieldIsRequired")),
                })
              );
            } else {
              newSchema = newSchema.concat(
                Yup.object().shape({
                  [fields[item].additional_fields_id]: Yup.string().required(t("global:thisFieldIsRequired")),
                })
              );
            }
          }
        }
        props.overwriteValidations(newSchema);
      })
      .catch(() => {
      })
      .finally(() => {
        setLazyLoad(false);
      });
  }

  return (
    <Row className="additional-fields">
      {lazyLoad &&
        <Col md={12}>
          <Row className="table-lazyLoading mt-4">
            <Col md={12}>
              <div style={customStyles.lazy}></div>
              <div style={customStyles.lazy} className="mt-4"></div>
              <div style={customStyles.lazy} className="mt-4"></div>
            </Col>
          </Row>
        </Col>
      }
      {
        additionalFields.map((field, index) => {
          if (field.type == "short_answer") {
            return (
              <Col md={12} key={index}>
                <FieldInput
                  label={field.name}
                  name={field.additional_fields_id}
                  type="text"
                  placeholder=""
                  value={field.value}
                  required={false}
                  showRequired={field.required == 1}
                  maxLength={30}
                />
              </Col>
            )
          }
          if (field.type == "drop_down") {
            const optionsList = field.options.map((option) => ({ value: option.value, label: option.name }));
            const optionSelected = optionsList.find((option) => option.value == field.value);
            return (
              <Col md={12} key={index}>
                <FormSelect
                  label={field.name}
                  name={field.additional_fields_id}
                  options={optionsList} //options
                  onChange={(event) => {
                    props.formik.setFieldValue(field.additional_fields_id, event.value);
                  }}
                  value={optionSelected}
                  required={field.required == 1}
                  showRequired={false}
                  placeholder={t("student:ModalAdditionalFields.selectAnOption")}
                />
              </Col>
            )
          }
          if (field.type == "multiple_option") {
            return (
              <Col md={6} key={index}>
                <RadioButton
                  name={field.additional_fields_id}
                  title={field.name}
                  value={props.formik.values[field.additional_fields_id]}
                  options={field.options}
                  required={field.required == 1}
                  setValue={props.formik.setFieldValue}
                  validation={props.formik.errors[field.additional_fields_id] && props.formik.touched[field.additional_fields_id] ? "error" : ""}
                />
              </Col>
            )
          }
          if (field.type == "check") {
            return (
              <Col md={6} key={index}>
                <CheckBox
                  name={field.additional_fields_id}
                  title={field.name}
                  value={props.formik.values[field.additional_fields_id]}
                  options={field.options}
                  required={field.required == 1}
                  setValue={props.formik.setFieldValue}
                  validation={props.formik.errors[field.additional_fields_id] && props.formik.touched[field.additional_fields_id] ? "error" : ""}
                />
              </Col>
            )
          }
        })
      }
    </Row>
  );
}

export default AdditionalFieldsBlock;

AdditionalFieldsBlock.propTypes = {
  formik: PropTypes.object,
  setAdditionalFields: PropTypes.func,
  validateSchema: PropTypes.array,
  overwriteValidations: PropTypes.func,
}