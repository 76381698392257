import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { MainContext } from "../../App";

import { Container, Col, Row, Button } from 'react-bootstrap';
import { LoadingTable } from "../lazyLoading/LazyLoading";
import ContentSolid from "../global/ContentSolid";
import ContentTable from "../table/ContentTable";
import SelectFilter from "../table/SelectFilter";
import { Table } from "../table/Table";

import ModalConfirmDeletedRecords from "../global/modals/ModalConfirmDeletedRecords";
import ModalCategory from "./modals/ModalCategory";

import { GetCategoriesPosts, DeleteCategories } from "../../api/News";

//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const CategoriesView = () => {
  const [t] = useTranslation(["global", "news", "table", "reports"]);
  const componentRef = useRef(); //Create the reference for printing
  const [readyLanguages, setReadyLanguages] = useState(false);
  const { permissions } = useContext(MainContext);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [count, setCount] = useState(0);
  const [loadingView, setLoadingView] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showModalCategory, setShowModalCategory] = useState(false);

  const [categories, setCategories] = useState([]);
  const [categoryForEdit, setCategoryForEdit] = useState(null);

  // Define table columns
  const columns = React.useMemo(
    () => [
      {
        Header: t("news:categoriesPage.name"),
        accessor: "name",
        width: 540,
      },
      {
        Header: t("news:categoriesPage.mainCategory"),
        accessor: "parent_name",
        width: 540,
        Filter: (props) => <SelectFilter {...props} />,
      },
      {
        Header: t("news:categoriesPage.description"),
        accessor: "description",
        width: 540,
      },
    ],
    [loadingView]
  );

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => categories, [loading]);

  /**
  * Get categories
  */
  const getCategoriesPosts = () => {
    setLoading(true);
    GetCategoriesPosts({no_limit: true})
      .then((response) => {
        if (response.error) return;
        const categories = response.data;
        categories.forEach(element => {
          element.index = element.id;
        });
        setCategories(response.data)
      })
      .finally(() => {
        setLoadingView(false);
        setLoading(false);
      });
  }

  /**
 * Description: Function to open the modal to edit the category
 * @param {object} news 
 */
  const rowOnclick = (category) => {
    setCategoryForEdit({
      id: category.id,
      name: category.name,
      parentCategory: category.parent,
      description: category.description,
    });
    setShowModalCategory(true);
  };

  /**
 * Purpose: Function to select or deselect all records
 */
  const selectAllRecords = () => {
    let selectAll = {
      switch: !selectAllRows.switch,
      value: !selectAllRows.value,
    };
    setSelectAllRows(selectAll);
  };

  /**
   * Description: Function to delete categories
   */
  const deleteCategories = () => {
    const payload = {
      ids: selectedRowIds.map((row) => row.original.id),
    };
    DeleteCategories(payload)
      .then((response) => {
        if (response.error) return;
        getCategoriesPosts();
        setSelectedRowIds([]);
        setIsAllRowsSelected(false);
      })
      .finally(() => {
        setShowModalConfirmDeleted(false);
      });
  }

  // Effect for loading initial translations
  useEffect(() => {
    if (typeof t == "function") {
      setTimeout(() => {
        setReadyLanguages(true);
      }, 500);
    }
  }, [t]);

  /**
 * Description: Effect to know if all prospects are selected
 */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  // Effect to get the categories
  useEffect(() => {
    getCategoriesPosts();
  }, []);

  return readyLanguages && (
    <Container fluid>
      <Row className="mb-3">
        <Col md={6}>
          {permissions.news_categories.delete &&
            <Button
              id="downloadStudentCredentialsBtn"
              variant="outline-secondary"
              onClick={() => setShowModalConfirmDeleted(true)}
              disabled={!selectedRowIds.length}
            >
              {t("global:buttons.eliminate")}
            </Button>
          }
          {/* Total counter of records and selected records in Classes table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col md={6}>
          {permissions.news_categories.create &&
            <Button
              id="downloadStudentCredentialsBtn"
              className="float-end"
              onClick={() => setShowModalCategory(true)}
            >
              {t("news:categoriesPage.create")}
            </Button>
          }
        </Col>
      </Row>

      {loadingView ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable startColumFilter={1} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            loading={loading}
            setSelectedRowIds={setSelectedRowIds}
            setIsAllRowsSelected={setIsAllRowsSelected}
            selectAllRows={selectAllRows}
            setRowsDisplayed={setCount}
            selectAllRecords={selectAllRecords}
            rowSelect={true}
            enableCheckToSelectAllRecords={true}
            rowOnclick={permissions.news_categories.edit ? rowOnclick : null}
          ></Table>
        </ContentTable>
      )}

      {/* modals */}
      {showModalConfirmDeleted &&
        <ModalConfirmDeletedRecords
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          confirmAction={deleteCategories}
          title={t("news:modalCategory.deleteCategories")}
          message={t("news:modalCategory.deleteCategoriesMenssage")}
          confirmText={t("global:buttons.eliminate")}
        />
      }

      {showModalCategory &&
        <ModalCategory
          showModalCategory={showModalCategory}
          setShowModalCategory={() => {
            setCategoryForEdit(null);
            setShowModalCategory(false);
          }}
          categories={categories}
          getCategoriesPosts={getCategoriesPosts}
          categoryDetails={categoryForEdit}
        />
      }
    </Container>
  );
}

export default CategoriesView;
