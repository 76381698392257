import styled from "styled-components";
import {} from "react-bootstrap";

const ContentSolid = styled.div`
  background: #ffffff;
  border: 1px solid #d6dadb;
  border-radius: 8px;
  padding: 5px;
  width: ${(props) => props.width};
  margin: 0 auto;

  .nav {
    width: fit-content;
    margin: 0px auto;
    height: 30px;
  }

  .nav-pills .nav-link {
    line-height: 14px;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: var(--red-primary);
    cursor: pointer;
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
  }

  .nav-link {
    padding: 0.45rem 1rem;
  }
`;

export default ContentSolid;
