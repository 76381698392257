// Catalog of Permits
export function LocalCatalogues() {
  //Get prefix token
  const prefix = process.env.REACT_APP_PREFIX;
  const language = localStorage.getItem(`cmLanguage${prefix}`);
  const data = {
    userRoles: [
      {
        id: 7,
        value: 7,
        nameId: "teacher",
        name: language === "spanish" ? "Maestro" : "Teacher",
        label: language === "spanish" ? "Maestro" : "Teacher",
      },
      {
        id: 2,
        value: 2,
        nameId: "admin",
        name: language === "spanish" ? "Administrador" : "Administrator",
        label: language === "spanish" ? "Administrador" : "Administrator",
      },
      {
        id: 16,
        value: 16,
        nameId: "school_control",
        name: language === "spanish" ? "Control Escolar" : "School Control",
        label: language === "spanish" ? "Control Escolar" : "School Control",
      },
      {
        id: 18,
        value: 18,
        nameId: "caja",
        name: language === "spanish" ? "Caja" : "Cashier",
        label: language === "spanish" ? "Caja" : "Cashier",
      },
      {
        id: 19,
        value: 19,
        nameId: "comunicación",
        name: language === "spanish" ? "Comunicación" : "Communication",
        label: language === "spanish" ? "Comunicación" : "Communication",
      },
      {
        id: 20,
        value: 20,
        nameId: "staffGeneral",
        name: language === "spanish" ? "Personal General" : "Staff General",
        label: language === "spanish" ? "Personal General" : "Staff General",
      },
      {
        id: 17,
        value: 17,
        nameId: "admissions",
        name: language === "spanish" ? "Admisiones" : "Admissions",
        label: language === "spanish" ? "Admisiones" : "Admissions",
      },
    ],
  };
  //Alphabetization of roles
  data.userRoles.sort(function (a, b) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
  return data;
}
