// Import libraries
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form as FormFormik, Formik } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";

// Import Custom Components
import ContentButton from "../../../components/global/ContentButton";
import FieldDaysInput from "../../../components/global/form/FieldDaysInput";
import { MainContext } from "../../../App";
import {
  saveDebtsRestrictions,
  GetDebtsRestrictions,
} from "../../../api/PaymentsReport";

// Custom Styles
const customStyles = {
  description: {
    fontFamily: "Mulish",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
  },
};

/**
 * Set Configurations Modal View
 * @returns JSX Modal View
 */
const ModalSetRestrictions = (props) => {
  const {
    open = false,
    close = () => {},
    modalSuccessTitle = () => {},
    modalSuccessMessage = () => {},
    getDebts = () => {},
  } = props;
  const [t] = useTranslation(["global"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const { permissions } = useContext(MainContext);
  const [save, setSave] = useState(false);
  const [error, setError] = useState({
    active: false,
    message: "",
    variant: "warning",
  });
  // Restrictions params
  const [initialValues, setInitialValues] = useState({
    debt_days_to_total_restriction: "",
    debt_days_to_partial_restriction: "",
  });
  const [update, setUpdate] = useState(false);
  // Form Validations
  const validationSchema = Yup.object().shape(
    {
      debt_days_to_total_restriction: Yup.string().when(
        "debt_days_to_partial_restriction",
        {
          is: (debt_days_to_partial_restriction) =>
            !debt_days_to_partial_restriction && !update,
          then: Yup.string().required(
            t("global:modalSetRestrictions.requiredField")
          ),
        }
      ),
      debt_days_to_partial_restriction: Yup.string().when(
        "debt_days_to_total_restriction",
        {
          is: (debt_days_to_total_restriction) =>
            !debt_days_to_total_restriction && !update,
          then: Yup.string().required(
            t("global:modalSetRestrictions.requiredField")
          ),
        }
      ),
    },
    [
      ["debt_days_to_total_restriction", "debt_days_to_partial_restriction"],
      ["debt_days_to_partial_restriction", "debt_days_to_total_restriction"],
    ]
  );

  /**
   * Puporse: Save restriction settings
   * @param values Data restriction settings
   */
  const debtsRestrictions = (values) => {
    setSave(true);
    // Set timezone
    values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    saveDebtsRestrictions(values)
      .then((result) => {
        if (result && result.data) {
          modalSuccessMessage(t("global:modalSetRestrictions.successMessage"));
          modalSuccessTitle(t("global:modalSetRestrictions.successTitle"));
          GetdebtsRestrictions();
          getDebts();
          close(false, true);
        } else {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError({
            active: true,
            message: `${
              result && result.code
                ? result.description
                : t("scholarship:scholarshipDetails.error")
            }`,
            variant: "warning",
          });
        }
      })
      .catch()
      .then(() => setSave(false));
  };

  /**
   * Clean error message
   */
  useEffect(() => {
    if (error.active) {
      setTimeout(() => {
        setError({ active: false, message: "", variant: "warning" });
      }, 3500);
    }
  }, [error]);

  // Initial values
  useEffect(() => {
    Sentry.setTag("section", "Modal Save restriction settings");
    GetdebtsRestrictions();
  }, []);

  /**
   * Puporse: Get restriction settings
   */
  const GetdebtsRestrictions = () => {
    // Set timezone
    const values = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetDebtsRestrictions(values).then((result) => {
      if (result && result.data && result.data.data) {
        const data = result.data.data;
        const exist =
          data.debt_days_to_partial_restriction &&
          data.debt_days_to_total_restriction;
        setInitialValues(data);
        setUpdate(exist);
      } else {
        setUpdate(false);
        Sentry.captureException(Error(JSON.stringify(result)));
        setError({
          active: true,
          message: `${
            result && result.code
              ? result.description
              : t("scholarship:scholarshipDetails.error")
          }`,
          variant: "warning",
        });
      }
    });
  };

  return (
    <div>
      <Modal
        show={open}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("global:buttons.set_restrictions")}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={debtsRestrictions}
        >
          {(values) => (
            <FormFormik>
              <Modal.Body className="modal.body mbottom10">
                <Row>
                  {/** Description 1*/}
                  <Col lg={12} md={12}>
                    <div className="mt-o">
                      <p
                        style={{
                          ...customStyles.description,
                          marginTop: "-20px",
                        }}
                      >
                        {t("global:modalSetRestrictions.description1")}
                      </p>
                    </div>
                  </Col>
                  {/** Description 2*/}
                  <Col lg={12} md={12}>
                    <div className="mt-4">
                      <p
                        style={{
                          ...customStyles.description,
                          marginTop: "-20px",
                        }}
                      >
                        {t("global:modalSetRestrictions.description2")}
                      </p>
                    </div>
                  </Col>
                  {/** Clases restriction */}
                  <Col lg={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                      <FieldDaysInput
                        label={t(
                          "global:modalSetRestrictions.setRestrictionslabel1"
                        )}
                        name="debt_days_to_partial_restriction"
                        type="number"
                        placeholder="15"
                      />
                    </div>
                  </Col>
                  {/** Total restriction */}
                  <Col lg={12} md={12}>
                    <FieldDaysInput
                      label={t(
                        "global:modalSetRestrictions.setRestrictionslabel2"
                      )}
                      name="debt_days_to_total_restriction"
                      type="number"
                      placeholder="15"
                    />
                  </Col>
                  {/** Warning errors */}
                  {error && error.active && (
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div style={{ marginTop: "20px" }}>
                        <Alert variant={error.variant}>{error.message}</Alert>
                      </div>
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <ContentButton
                  className="content-button"
                  style={{ marginTop: "-50px" }}
                  disabled={
                    !permissions.payments_debts_restrictions.create || save
                  }
                >
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      values.resetForm();
                      close(false, false);
                    }}
                    disabled={save}
                  >
                    {t("global:buttons.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      (!permissions.payments_debts_restrictions.create &&
                        !permissions.payments_debts_restrictions.edit &&
                        !permissions.payments_debts_restrictions.delete) ||
                      (!values.values.debt_days_to_partial_restriction &&
                        !values.values.debt_days_to_total_restriction &&
                        !update) ||
                      save
                    }
                  >
                    {t("global:buttons.save")}
                  </Button>
                </ContentButton>
              </Modal.Footer>
            </FormFormik>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ModalSetRestrictions;

ModalSetRestrictions.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  modalSuccessTitle: PropTypes.func,
  modalSuccessMessage: PropTypes.func,
  getDebts: PropTypes.func,
};
