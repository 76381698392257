/**
 * Purpose: Action completed confirmation modal
 *
 * Props:
 *    showModalSuccessArchived: boolean type value to show or hide the modal
 */

import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
/** Elements */
import ContentButton from "../../components/global/ContentButton";

export default function ModalSuccessArchived(props) {
  const [t] = useTranslation(["crm", "global"]);
  return (
    <Modal
      show={props.showModalSuccessArchived}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">
          {t("ModalSuccessArchived.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.countActives > 0
          ? t("ModalSuccessArchived.leadsArchivedWithExeptions", {
              count: props.countArchived,
            })
          : t("ModalSuccessArchived.leadsArchived", {
              count: props.countArchived,
            })}
      </Modal.Body>
      <Modal.Footer>
        <ContentButton className="content-button">
          <Button onClick={() => props.setShowModalSuccesArchived(false)}>
            {t("global:buttons.accept")}
          </Button>
        </ContentButton>
      </Modal.Footer>
    </Modal>
  );
}

ModalSuccessArchived.propTypes = {
  showModalSuccessArchived: PropTypes.bool,
  setShowModalSuccesArchived: PropTypes.func,
  countArchived: PropTypes.number,
  countActives: PropTypes.number,
};
