import React, { useEffect } from "react";
import PropTypes from "prop-types";
//import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

export default function TextLoading(props) {
  //const [t] = useTranslation(["table"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module

  /**Initial loading */
  useEffect(() => {}, []);

  return (
    <div style={{ display: "inline" }}>
      {props.text}...
      <Spinner
        as="span"
        animation="border"
        role="status"
        aria-hidden="true"
        variant={props.variant}
        style={{
          width: "0.81rem",
          height: "0.81rem",
          marginLeft: "3px",
          borderWidth: "0.17em",
          verticalAlign: "middle",
        }}
      />
    </div>
  );
}

TextLoading.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
};
