import React from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ModalImportResult(props) {
  const [t] = useTranslation(["staff"]);

  return (
    <div>
      <Modal
        show={props.showImportResult}
        size="md"
        aria-labelledby="contained-modal-title-md"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("modalImportResult.title")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body mbottom10">
          <Row>
            <Col md={12}>
              <center>
                {props.importResult.errors &&
                props.importResult.errors.length > 0 ? (
                  <i
                    className="far fa-5x fa-times-circle"
                    style={{ color: "#EA2C54" }}
                  ></i>
                ) : (
                  <i
                    className="far fa-5x fa-check-circle"
                    style={{ color: "#07C25F" }}
                  ></i>
                )}
              </center>

              <b style={{ fontSize: "14px" }}>
                {t("modalImportResult.importOk")}
                {props.importResult.errors &&
                props.importResult.errors.length > 0
                  ? `${t("modalImportResult.withErrors")} `
                  : ""}

                {props.importResult.created ? (
                  <p>
                    {t("modalImportResult.importedRecords")}{" "}
                    {props.importResult.created.length}
                  </p>
                ) : (
                  ""
                )}

                {props.importResult.updated ? (
                  <p>
                    {t("modalImportResult.updatedRecords")}{" "}
                    {props.importResult.updated.length}
                  </p>
                ) : (
                  ""
                )}

                {props.importResult.errors ? (
                  <p>
                    {t("modalImportResult.recordsNotImported")}{" "}
                    {props.importResult.errors.length}
                  </p>
                ) : (
                  ""
                )}
              </b>

              <p style={{ textAlign: "right" }}>
                <a
                  style={{
                    color: "var(--base-color)",
                    textDecoration: "none",
                  }}
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  {t("modalImportResult.viewDetails")}
                </a>
              </p>
            </Col>
          </Row>

          <div className="collapse" id="collapseExample">
            <div
              className="card card-body"
              style={{ background: "var(--white-hover)" }}
            >
              {props.importResult.created &&
              props.importResult.created.length == 1 ? (
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {props.importResult.created.length}{" "}
                    {t("modalImportResult.importedRecord")}
                  </p>
                  {props.importResult.created.map((staff, index) => {
                    return (
                      <p style={{ fontSize: "12px" }} key={index}>
                        {staff.last_name}, {staff.first_name}
                      </p>
                    );
                  })}
                </div>
              ) : props.importResult.created &&
                props.importResult.created.length >= 0 ? (
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {props.importResult.created.length}{" "}
                    {t("modalImportResult.importedRecords")}
                  </p>
                  {props.importResult.created.map((staff, index) => {
                    return (
                      <p style={{ fontSize: "12px" }} key={index}>
                        {staff.last_name}, {staff.first_name}
                      </p>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              {props.importResult.updated &&
              props.importResult.updated.length == 1 ? (
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {props.importResult.updated.length}{" "}
                    {t("modalImportResult.updatedRecord")}
                  </p>
                  {props.importResult.updated.map((staff, index) => {
                    return (
                      <p
                        style={{ fontSize: "12px", paddingLeft: "15px" }}
                        key={index}
                      >
                        {staff.last_name}, {staff.first_name}
                      </p>
                    );
                  })}
                </div>
              ) : props.importResult.updated &&
                props.importResult.updated.length >= 0 ? (
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {props.importResult.updated.length}{" "}
                    {t("modalImportResult.updatedRecords")}
                  </p>
                  {props.importResult.updated.map((staff, index) => {
                    return (
                      <p
                        style={{ fontSize: "12px", paddingLeft: "15px" }}
                        key={index}
                      >
                        {staff.last_name}, {staff.first_name}
                      </p>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              {props.importResult.errors &&
              props.importResult.errors.length == 1 ? (
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {props.importResult.errors.length}{" "}
                    {t("modalImportResult.recordNotImported")}
                  </p>
                  {props.importResult.errors.map((error, index) => {
                    return (
                      <p style={{ fontSize: "12px" }} key={index}>
                        {error}
                      </p>
                    );
                  })}
                </div>
              ) : props.importResult.errors &&
                props.importResult.errors.length >= 0 ? (
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {props.importResult.errors.length}{" "}
                    {t("modalImportResult.recordsNotImported")}
                  </p>
                  {props.importResult.errors.map((error, index) => {
                    return (
                      <p style={{ fontSize: "12px" }} key={index}>
                        {error}
                      </p>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{ marginRight: "10px" }}
            variant="primary"
            onClick={() => props.setShowImportResult(false)}
          >
            {t("modalImportResult.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalImportResult.propTypes = {
  showImportResult: PropTypes.bool,
  importResult: PropTypes.object,
  setShowImportResult: PropTypes.func,
};
