import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, FormLabel, Form as FormBoostrap, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormSelect from "../../global/form/FormSelect";
import FieldInput from "../../global/form/FieldInput";
import TextLoading from "../../global/TextLoading";
import Dropzone from 'react-dropzone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Yup from "yup";
import { validateUserRoleIds } from "../../global/GlobalTools";
import {
  getAuthorBlogPosts,
  CreateAuthor,
  GetCategoriesPosts,
  CreateCategory,
  GetTagsPosts,
  CreateTags,
  CreateBlogPosts,
  UpdateBlogPosts,
} from "../../../api/News";

const customStyles = {
  circleButtonPlus: {
    cursor: "pointer",
    float: "right",
    fontSize: "17px",
    marginTop: "-4px",
    color: "var(--red-primary)",
    marginLeft: "5px",
  },
  circleButtonPlusDisabled: {
    cursor: "pointer",
    float: "right",
    fontSize: "17px",
    marginTop: "-4px",
    color: "var(--red-primary)",
    marginLeft: "5px",
    opacity: "50%",
  }
}

/**
 * Disable add new button
 * @param {object} values 
 * @returns boolean
 */
const disableAddNew = (values) => {
  let disabled = values.newAuthor || values.newCategory || values.newTag;
  return disabled;
}

const baseErroItem = {
  author: false,
  category: false,
  tag: false,
  message: "",
}

const ModalNews = (props) => {
  const [t] = useTranslation(["global", "news", "modalCreateStudent"]);
  const [saving, setSaving] = useState(false);
  const [image, setImage] = useState([]);
  const [quillValue, setQuillValue] = useState(props.newsDetails.id ? props.newsDetails.description : "");
  const [authors, setAuthors] = useState([]);
  const [loadingAuthors, setLoadingAuthors] = useState(true);
  const [categories, setCategoriess] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);
  const [error, setError] = useState(false);
  const [errorOversized, setErrorOversized] = useState(false);
  const prefix = process.env.REACT_APP_PREFIX;
  const [creatingItem, setCreatingItem] = useState(false);
  const [errorItem, setErrorItem] = useState({ ...baseErroItem });
  const [newsInfo, setNewsInfo] = useState(props.newsDetails.id ? props.newsDetails : {
    author: "",
    newAuthor: false,
    authorName: "",
    title: "",
    //categorie
    categories: [],
    newCategory: false,
    categoryName: "",
    parentCategory: "",
    categoryDescription: "",
    //tags
    tags: [],
    newTag: false,
    tagName: "",
    coverImage: "",
    description: "",
  });

  /**
   * Validate fields
   */
  const validateSchema = Yup.object().shape({
    author: Yup.string().required(
      t("news:modalNew.author") +
      " " +
      t("modalCreateStudent:labels.isRequired")
    ),
    title: Yup.string().required(
      t("news:modalNew.title") +
      " " +
      t("modalCreateStudent:labels.isRequired")
    ),
    coverImage: Yup.mixed().required(
      t("news:newsPage.coverImage") +
      " " +
      t("modalCreateStudent:labels.isRequired")
    ),
    description: Yup.string().required(
      t("news:modalNew.description") +
      " " +
      t("modalCreateStudent:labels.isRequired")
    )
      // validate empty description
      .test("empty-description", t("news:modalNew.description") + " " + t("modalCreateStudent:labels.isRequired"), (value) => {
        return value !== "<p><br></p>";
      }),
  });

  /**
   * Get authors
   */
  const getAuthor = () => {
    getAuthorBlogPosts().then((response) => {
      if (!response.status) {
        setLoadingAuthors(false);
        return;
      }
      if (response) {
        let authors = response.data.map((author) => {
          return {
            value: author.data.ID,
            label: author.data.display_name,
            email: author.data.user_email,
          };
        }
        );
        if (validateUserRoleIds([1], null, "||", true) && !props.newsDetails.id) {
          const userInfo = JSON.parse(localStorage.getItem(`cmUserProfile${prefix}`));
          // Validate user email exist
          if (userInfo && userInfo.email) {
            const userEmail = userInfo.email;
            let author = authors.find((author) => author.email == userEmail);
            if (!author) {
              // If it doesn't exist hardcode it
              author = {
                id: "0",
                name: `${userInfo.first_name} ${userInfo.last_name}`,
                email: userEmail,
                label: `${userInfo.first_name} ${userInfo.last_name}`,
                value: "0",
                preselect: true,
              };
              // Adding to author's array
              authors.push(author);
            } else {
              const position = authors.findIndex((author) => author.email == userEmail);
              authors[position].preselect = true;
            }
            setNewsInfo({ ...newsInfo, author: author.value });
          }
        }
        setAuthors(authors);
        setLoadingAuthors(false);
      }
    })
      .finally(() => {
      });
  }

  /**
   * Create author
   * @param {object} formik
   */
  const createAuthor = (formik) => {
    setCreatingItem(true);
    const payload = {
      author_name: formik.values.authorName
    }
    CreateAuthor(payload)
      .then((response) => {
        if (response.error) {
          let error = { ...errorItem };
          error.author = true;
          error.message = response.error.description;
          setErrorItem(error);
          setTimeout(() => {
            setErrorItem({ ...baseErroItem });
          }, 3000);
          return;
        }
        formik.setFieldValue("authorName", "")
        formik.setFieldValue("newAuthor", false)
        formik.setFieldValue("author", response.data.data.ID);
        let newAuthors = [...authors];
        //remove preselect from the author
        newAuthors.forEach((author) => author.preselect = false);
        newAuthors.push({ value: response.data.data.ID, label: response.data.data.display_name });
        setAuthors(newAuthors);
      })
      .finally(() => {
        setCreatingItem(false);
      });
  }

  /**
   * Get categories
   */
  const getCategoriesPosts = () => {
    GetCategoriesPosts({ no_limit: true })
      .then((response) => {
        if (response.error) {
          setLoadingCategories(false);
          return;
        }
        const categories = response.data.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        });
        setCategoriess(categories)
        setLoadingCategories(false);
      })
      .finally(() => {
      });
  }

  /**
   * Create category
   * @param {object} formik 
   */
  const createCategory = (formik) => {
    setCreatingItem(true);
    let payload = {
      name: formik.values.categoryName,
      description: formik.values.categoryDescription
    }
    if (formik.values.parentCategory != "") {
      payload.parent = formik.values.parentCategory;
    }
    CreateCategory(payload)
      .then((response) => {
        if (response.error) {
          let error = { ...errorItem };
          error.category = true;
          error.message = response.error.description;
          setErrorItem(error);
          setTimeout(() => {
            setErrorItem({ ...baseErroItem });
          }, 3000);
          return;
        }
        formik.setFieldValue("categoryName", "");
        formik.setFieldValue("parentCategory", "");
        formik.setFieldValue("categoryDescription", "");
        formik.setFieldValue("newCategory", false);
        // Update the list and value set in the form
        let oldCategories = formik.values.categories;
        let newCategories = [...categories];
        const newCategory = { value: response.data.id, label: response.data.name }
        oldCategories.push(newCategory);
        formik.setFieldValue("categories", oldCategories);
        newCategories.push(newCategory);
        setCategoriess(newCategories);
      })
      .finally(() => {
        setCreatingItem(false);
      });
  }

  /**
   * Get tags
   */
  const GetTags = () => {
    GetTagsPosts({ no_limit: true })
      .then((response) => {
        if (response.error) {
          setLoadingTags(false);
          return;
        }
        const tags = response.data.map((tag) => {
          return {
            value: tag.id,
            label: tag.name,
          };
        });
        setTags(tags);
        setLoadingTags(false);
      })
      .finally(() => {
      })
  }

  /**
   * Create tag
   * @param {object} formik 
   */
  const createTag = (formik) => {
    setCreatingItem(true);
    const payload = {
      name: formik.values.tagName.toUpperCase()
    }
    CreateTags(payload)
      .then((response) => {
        if (response.error) {
          let error = { ...errorItem };
          error.tag = true;
          error.message = response.error.description;
          setErrorItem(error);
          return;
        }
        formik.setFieldValue("tagName", "");
        formik.setFieldValue("newTag", false);
        // Update the list and value set in the form
        let oldTags = formik.values.tags;
        let newTags = [...tags];
        const newTag = { value: response.data.id, label: response.data.name }
        oldTags.push(newTag);
        formik.setFieldValue("tags", oldTags);
        newTags.push(newTag);
        setTags(newTags);
      })
      .finally(() => {
        setCreatingItem(false);
      });
  }

  /**
   * Save news
   */
  const saveNews = (values) => {
    setSaving(true);
    let payload = new FormData();
    payload.append("title", values.title);
    payload.append("content", values.description);
    if (values.author != 0) payload.append("author_id", parseInt(values.author));
    if (typeof values.coverImage != "string") {
      payload.append("image", values.coverImage);
    }
    for (const index in values.categories) {
      payload.append("categories[" + index + "]", values.categories[index].value);
    }
    for (const index in values.tags) {
      payload.append("tags[" + index + "]", values.tags[index].value);
    }
    if (newsInfo.id) {
      payload.append("id", newsInfo.id);
      updateNews(payload);
    } else {
      createNews(payload);
    }
  }

  /**
   * Create news
   * @param {FormData} payload 
   */
  const createNews = (payload) => {
    CreateBlogPosts(payload)
      .then((response) => {
        if (response.error) {
          setError(response.error.description);
          setTimeout(() => setError(false), 3000);
          setSaving(false);
          return;
        }
        props.setShowModalNew("create");
      })
      .finally(() => {
        props.getNews();
      });
  }

  /**
   * Update news
   * @param {FormData} payload 
   */
  const updateNews = (payload) => {
    UpdateBlogPosts(payload)
      .then((response) => {
        if (response.error) {
          setError(response.error.description);
          setTimeout(() => setError(false), 3000);
          setSaving(false);
          return;
        }
        props.setShowModalNew("update");
      })
      .finally(() => {
        props.getNews();
      });
  }

  useEffect(() => {
    getAuthor();
    getCategoriesPosts();
    GetTags();
  }, []);

  return props.readOnly ?
    (
      <Modal show={props.showModalNew} >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {newsInfo.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <img style={{ maxWidth: "100%" }} src={newsInfo.coverImage} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <b>{t("news:modalNew.author")} : </b> {newsInfo.authorName}
            </Col>
            <Col md={12}>
              <b>{t("news:modalNew.categoryLabel")} : </b> {newsInfo.categories.map((category) => category.label).join(", ")}
            </Col>
            <Col md={12}>
              <b>{t("news:modalNew.tagsLabel")} : </b> {newsInfo.tags.map((tag) => tag.label).join(", ")}
            </Col>
            <Col md={12}>
              <b>{t("news:modalNew.dateLabel")} : </b> {newsInfo.date + " " + newsInfo.time}
            </Col>
          </Row>
          {/* ReactQuill */}
          <Row>
            <Col md={12} className="mt-3" style={{ paddingLeft: "0px" }}>
              <ReactQuill
                value={newsInfo.description}
                theme="bubble" readOnly
                style={{ maxHeight: "300px", overflow: "auto" }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="cancelCreateStudentBtn"
            variant="outline-secondary"
            onClick={() => {
              props.setShowModalNew(false);
            }}
          >
            {t("global:buttons.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
    : (
      <Modal show={props.showModalNew} >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {newsInfo.id ?
              t("news:modalNew.editNew")
              :
              t("news:modalNew.createNew")
            }
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={newsInfo}
          validationSchema={validateSchema}
          onSubmit={async (values) => {
            await new Promise((r) => setTimeout(r, 500));
            !saving ? saveNews(values) : null;
          }}
        >
          {(formik) => (
            <Form>
              <Modal.Body>
                {/* Authors */}
                <Row>
                  <Col md={12}>
                    {formik.values.newAuthor ? (
                      <FieldInput
                        label={t("news:modalNew.authorName")}
                        name="authorName"
                        type="text"
                        placeholder={t("news:modalNew.authorName")}
                        required={true}
                      />
                    ) : (
                      <FormSelect
                        label={t("news:modalNew.author")}
                        name="author"
                        options={authors}
                        onChange={(event) => {
                          // remove preselect from the author
                          authors.forEach((author) => author.preselect = false);
                          formik.setFieldValue("author", event.value);
                        }}
                        value={
                          authors.find((author) => {
                            if ((author.value == formik.values.author || author.preselect) && formik.values.author == "") {
                              formik.setFieldValue("author", author.value);
                            }
                            return (author.value == formik.values.author || author.preselect)
                          })
                          || null
                        }
                        showRequired={true}
                        isLoading={loadingAuthors}
                        placeholder={t("news:modalNew.selectAnAuthor")}
                      />
                    )}
                  </Col>
                  {(errorItem.author && formik.values.newAuthor) &&
                    <Col md={12}>
                      <Alert variant="warning" style={{ paddingTop: "5px", paddingBottom: "5px" }}>{errorItem.message}</Alert>
                    </Col>
                  }
                  {formik.values.newAuthor ? (
                    <Col md={12} className="text-end">
                      <Button
                        id="cancelCreateStudentBtn"
                        variant="outline-secondary"
                        className="mr-2"
                        onClick={() => {
                          formik.setFieldValue("authorName", "")
                          formik.setFieldValue("newAuthor", false)
                        }}
                        disabled={creatingItem}
                      >
                        {t("global:buttons.cancel")}
                      </Button>
                      <Button
                        className="ms-2"
                        onClick={() => createAuthor(formik)}
                        disabled={formik.values.authorName === "" || creatingItem}
                      >
                        {creatingItem ? <TextLoading text={t("global:buttons.textAdding")} /> : t("news:modalNew.add")}
                      </Button>
                    </Col>
                  ) : !newsInfo.id ?
                    <Col md={12} className="text-end" >
                      <div className="d-inline-block"
                        role="button"
                        onClick={disableAddNew(formik.values) ? null : disableAddNew(formik.values) ? null : () => formik.setFieldValue("newAuthor", true)}
                      >
                        {t("news:modalNew.addAuthor")}
                      </div>
                      <i
                        value="add"
                        className="bi bi-plus-circle-fill"
                        style={disableAddNew(formik.values) ? customStyles.circleButtonPlusDisabled : customStyles.circleButtonPlus}
                        onClick={disableAddNew(formik.values) ? null : () => formik.setFieldValue("newAuthor", true)}
                      />
                    </Col>
                    : null
                  }

                </Row>
                {/* Title */}
                <Row>
                  <Col md={12}>
                    <FieldInput
                      label={t("news:modalNew.title")}
                      name="title"
                      type="text"
                      placeholder={t("news:modalNew.title")}
                      required={false}
                      maxlength={200}
                    />
                  </Col>
                </Row>
                {/* Cateries */}
                <Row>
                  {formik.values.newCategory ?
                    <>
                      <Col md={12}>
                        <FieldInput
                          label={t("news:modalNew.categoryName")}
                          name="categoryName"
                          type="text"
                          placeholder={t("news:modalNew.categoryName")}
                          required={true}
                          maxlength={200}
                        />
                        <FormSelect
                          label={t("news:modalNew.parentCategory")}
                          name="parentCategory"
                          options={categories}
                          onChange={(event) => {
                            formik.setFieldValue("parentCategory", event.value);
                          }}
                          value={categories.find((category) => category.value == formik.values.parentCategory) || null}
                          showRequired={true}
                          className="scholarship-select"
                          isLoading={categories.length === 0}
                          placeholder={t("news:modalNew.parentCategory")}
                        />
                        <FieldInput
                          label={t("news:modalNew.description")}
                          name="categoryDescription"
                          type="text"
                          as="textarea"
                          placeholder=""
                        />
                      </Col>
                    </>
                    :
                    <Col md={12}>
                      <FormSelect
                        label={t("news:modalNew.categories")}
                        name="categories"
                        options={categories}
                        onChange={(event) => {
                          formik.setFieldValue("categories", event);
                        }}
                        value={formik.values.categories}
                        showRequired={true}
                        isMulti
                        className="scholarship-select"
                        isLoading={loadingCategories}
                        placeholder={t("news:modalNew.categories")}
                      />
                    </Col>
                  }
                  {(errorItem.category && formik.values.newCategory) &&
                    <Col md={12}>
                      <Alert variant="warning" style={{ paddingTop: "5px", paddingBottom: "5px" }}>{errorItem.message}</Alert>
                    </Col>
                  }
                  {formik.values.newCategory ? (
                    <Col md={12} className="text-end">
                      <Button
                        id="cancelCreateStudentBtn"
                        variant="outline-secondary"
                        className="mr-2"
                        onClick={() => {
                          formik.setFieldValue("categoryName", "")
                          formik.setFieldValue("parentCategory", "")
                          formik.setFieldValue("categoryDescription", "")
                          formik.setFieldValue("newCategory", false)
                        }}
                        disabled={creatingItem}
                      >
                        {t("global:buttons.cancel")}
                      </Button>
                      <Button
                        className="ms-2"
                        onClick={() => createCategory(formik)}
                        disabled={formik.values.categoryName === "" || creatingItem}
                      >
                        {creatingItem ? <TextLoading text={t("global:buttons.textAdding")} /> : t("news:modalNew.add")}
                      </Button>
                    </Col>
                  ) : !newsInfo.id ?
                    (<Col md={12} className="text-end">
                      <div className="d-inline-block" role="button" onClick={disableAddNew(formik.values) ? null : () => formik.setFieldValue("newCategory", true)}>
                        {t("news:modalNew.addCategory")}
                      </div>
                      <i
                        value="add"
                        className="bi bi-plus-circle-fill"
                        style={disableAddNew(formik.values) ? customStyles.circleButtonPlusDisabled : customStyles.circleButtonPlus}
                        onClick={disableAddNew(formik.values) ? null : () => formik.setFieldValue("newCategory", true)}
                      />
                    </Col>
                    ) : null
                  }
                </Row>
                {/* Tags */}
                <Row>
                  <Col md={12}>
                    {formik.values.newTag ?
                      <FieldInput
                        label={t("news:modalNew.nameOfTheTag")}
                        name="tagName"
                        type="text"
                        placeholder={t("news:modalNew.nameOfTheTag")}
                        required={true}
                        maxlength={200}
                      />
                      :
                      <FormSelect
                        label={t("news:modalNew.tags")}
                        name="tags"
                        options={tags}
                        onChange={(event) => {
                          formik.setFieldValue("tags", event);
                        }}
                        value={formik.values.tags}
                        showRequired={true}
                        isMulti
                        className="scholarship-select"
                        isLoading={loadingTags}
                        placeholder={t("news:modalNew.tags")}
                      />
                    }
                  </Col>
                  {(errorItem.tag && formik.values.newTag) &&
                    <Col md={12}>
                      <Alert variant="warning" style={{ paddingTop: "5px", paddingBottom: "5px" }}>{errorItem.message}</Alert>
                    </Col>
                  }
                  {formik.values.newTag ? (
                    <Col md={12} className="text-end">
                      <Button
                        id="cancelCreateStudentBtn"
                        variant="outline-secondary"
                        className="mr-2"
                        onClick={() => {
                          formik.setFieldValue("tagName", "")
                          formik.setFieldValue("newTag", false)
                        }}
                        disabled={creatingItem}
                      >
                        {t("global:buttons.cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className="ms-2"
                        onClick={() => createTag(formik)}
                        disabled={formik.values.tagName === "" || creatingItem}
                      >
                        {creatingItem ? <TextLoading text={t("global:buttons.textAdding")} /> : t("news:modalNew.add")}
                      </Button>
                    </Col>
                  ) : !newsInfo.id ?
                    <Col md={12} className="text-end">
                      <div className="d-inline-block" role="button" onClick={disableAddNew(formik.values) ? null : () => { formik.setFieldValue("newTag", true) }}>
                        {t("news:modalNew.addTag")}
                      </div>
                      <i
                        value="add"
                        className="bi bi-plus-circle-fill"
                        style={disableAddNew(formik.values) ? customStyles.circleButtonPlusDisabled : customStyles.circleButtonPlus}
                        onClick={disableAddNew(formik.values) ? null : () => { formik.setFieldValue("newTag", true) }}
                      />
                    </Col>
                    : null
                  }

                </Row>
                {/* Dropzone */}
                <Row>
                  <Col md={12} className={errorOversized ? "mb-5" : "mb-3"}>
                    <FormLabel> {t("news:modalNew.coverImage")} <span className="pull-right text-danger">*</span> </FormLabel>
                    <Dropzone
                      accept="image/*"
                      onDrop={acceptedFiles => {
                        //identify resolution of image
                        const reader = new FileReader();
                        reader.readAsDataURL(acceptedFiles[0]);
                        reader.onload = () => {
                          const image = new Image();
                          image.src = reader.result;
                          image.onload = () => {
                            const width = image.width;
                            const height = image.height;
                            if (width >= 4096 || height >= 3072) {
                              setErrorOversized(t("news:modalNew.imageOversize"));
                              return
                            } else {
                              setErrorOversized(false)
                              setImage(acceptedFiles.map(file => URL.createObjectURL(file)));
                              formik.setFieldValue("coverImage", acceptedFiles[0]);
                            }
                          }
                        }
                      }
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()} style={{
                            position: "relative",
                            height: "auto",
                            borderWidth: "2px",
                            borderColor: "rgb(194, 194, 194)",
                            borderStyle: "dashed",
                            borderRadius: "5px",
                          }}>
                            <center>
                              {image.length ? image.map(path =>
                                <img style={{ maxWidth: "100%" }} key={path} src={path} />)
                                :
                                // if formik.values.coverImage  exist and is string
                                typeof formik.values.coverImage === "string" ?
                                  <img style={{ maxWidth: "100%" }} src={formik.values.coverImage} />
                                  : null
                              }
                              <div>
                                <p
                                  style={{
                                    fontWeight: "normal",
                                    fontFamily: "'Open Sans', sans-serif",
                                    fontSize: "14px",
                                    marginTop: "20px",
                                  }}
                                >
                                  {t("news:modalNew.dropImageHere")}
                                </p>
                                {t("importCSV.dropFileOr")}
                                <br />
                                <br />
                                <input {...getInputProps()} />
                                <Button
                                  type="button"
                                  variant="outline-secondary"
                                  className="btn mb-3"
                                >
                                  {t("importCSV.uploadFile")}
                                </Button>
                              </div>
                            </center>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {errorOversized ?
                      <FormBoostrap.Text className="text-muted error">{errorOversized} </FormBoostrap.Text>
                      : formik.touched.coverImage && formik.errors.coverImage ? (
                        <FormBoostrap.Text className="text-muted error">{formik.errors.coverImage}</FormBoostrap.Text>
                      ) : null}
                  </Col>
                </Row>
                {/* ReactQuill */}
                <Row>
                  <Col md={12} className="mt-3">
                    <ReactQuill
                      value={quillValue}
                      onChange={(e) => {
                        setQuillValue(e);
                        formik.setFieldValue("description", e);
                      }}
                      placeholder={t("news:modalNew.enterAescription")}
                      theme="snow"
                      modules={{
                        toolbar:
                          ['bold',
                            'italic',
                            'underline',
                            'strike',
                            { list: "ordered" },
                            { list: "bullet" },
                            { align: "" },
                            { align: "center" },
                            { align: "right" },
                            { align: "justify" },
                            "link",
                            "image",
                            "video",
                          ]
                      }}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <FormBoostrap.Text className="text-muted error">{formik.errors.description}</FormBoostrap.Text>
                    ) : null}
                  </Col>
                </Row>
                {error && (
                  <Row>
                    <Col md={12}>
                      <Alert variant="warning">{error}</Alert>
                    </Col>
                  </Row>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="cancelCreateStudentBtn"
                  variant="outline-secondary"
                  onClick={() => {
                    props.setShowModalNew(false);
                  }}
                  disabled={saving}
                >
                  {t("global:buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={saving || disableAddNew(formik.values)}
                >
                  {
                    saving && newsInfo.id ?
                      <TextLoading text={t("global:buttons.saving")} />
                      : saving ?
                        <TextLoading text={t("global:buttons.creating")} />
                        :
                        newsInfo.id ?
                          t("global:buttons.save")
                          :
                          t("news:modalNew.createNew")}
                </Button>
              </Modal.Footer>
            </Form>)}
        </Formik>
      </Modal>
    );
};

ModalNews.propTypes = {
  showModalNew: PropTypes.bool,
  setShowModalNew: PropTypes.function,
  newsDetails: PropTypes.object,
  getNews: PropTypes.function,
  readOnly: PropTypes.bool,
};
export default ModalNews;
