import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import FieldInput from "../../../components/global/form/FieldInput";
export default function NewTamplateModal(props) {
  const [t] = useTranslation(["reportCards", "global"]);
  return (
    <Modal
      show={props.showNewTamplateModal}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title>{t("reportCards:newTemplateModal.title")}</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{ name: "" }}
        onSubmit={(values) => {
          let newTemplate = {
            name: values.name,
            format: "",
            thumbnailUrl: "",
          };
          props.setListFormatItems([]);
          props.setNewTemplate(newTemplate);
        }}
      >
        {(formikProps) => (
          <Form>
            <Modal.Body>
              <Row>
                <Col lg={12}>
                  <FieldInput
                    name="name"
                    type="text"
                    label={t("reportCards:newTemplateModal.title")}
                    placeholder=""
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => props.setShowNewTamplateModal(false)}
              >
                {t("global:buttons.cancel")}
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={formikProps.values.name == ""}
                onClick={() => props.setShowNewTamplateModal(false)}
              >
                {t("global:buttons.save")}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

NewTamplateModal.propTypes = {
  showNewTamplateModal: PropTypes.bool,
  setShowNewTamplateModal: PropTypes.func,
  setNewTemplate: PropTypes.func,
  setListFormatItems: PropTypes.func,
};
