import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, FormGroup, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { UpdateTool } from "../../../api/ExtraLink";
export default function ModalEditTools(props) {
  const [t] = useTranslation(["extraLink", "global", "reminder"]);
  const [tool, setTool] = useState({ ...props.editToTools });

  /**
   * Function to update tools
   */
  const updateTools = async () => {
    const request = {
      id: tool.id,
      name: tool.name,
      description: tool.description,
    };
    await UpdateTool(request).then((result) => {
      props.setShowModalEditTools(false);
      props.getTools();
      return result.request;
    });
  };

  /**
   * Function to save what is written in the input
   * @param {Obj} event
   */
  const onChange = (event) => {
    let toolEdit = { ...tool };
    let value = event.target.value;

    toolEdit.description = value;
    setTool(toolEdit);
  };

  return (
    <Modal
      show={props.showModalEditTools}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("modalTools.editModalTools")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="textAplication"
                id="inputText"
                value={tool.name}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <FormGroup className="mt-2">
              <Form.Label>
                {t("reminder:configureReminder.fieldDescription")}
              </Form.Label>
              <Form.Control
                type="text"
                id="description"
                value={tool.description}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowModalEditTools(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button variant="primary" onClick={() => updateTools()}>
          {t("global:buttons.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalEditTools.propTypes = {
  showModalEditTools: PropTypes.bool,
  setShowModalEditTools: PropTypes.func,
  getTools: PropTypes.func,
  editToTools: PropTypes.obj,
};
