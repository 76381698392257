import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DeleteClasses } from "../../../api/class";

export default function ModalConfirmDeleted(props) {
  const [t] = useTranslation(["global", "class"]);

  const deleteClasses = () => {
    let classesIds = props.items.map((item) => item.original.id);
    const request = {
      ids: classesIds,
    };

    DeleteClasses(request).then(() => {
      props.getClasses();
      props.setShowModalConfirmDeleted(false)
    });
  };

  return (
    <Modal
      show={props.showModalConfirmDeleted}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("class:modalRemove.deleteClass")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={12} className="mb-2">
            {t("class:modalRemove.menssage")}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowModalConfirmDeleted(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button onClick={deleteClasses}>{t("global:buttons.accept")}</Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalConfirmDeleted.propTypes = {
  showModalConfirmDeleted: PropTypes.bool, //Required
  setShowModalConfirmDeleted: PropTypes.func, //Required
  items: PropTypes.array,
  getClasses: PropTypes.func
};
