import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
//Hooks components
import FieldInput from "../../../components/global/form/FieldInput";
import ContentButton from "../../../components/global/ContentButton";
import TextLoading from "../../../components/global/TextLoading";
//Api
import { CreateGrade } from "../../../api/Cycles";

export default function AddDegreeModal(props) {
  const {
    open,
    handleClose,
    selectCycleId,
    selectGradeId,
    getGrades,
    selectSchoolLevel,
  } = props;
  //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [t] = useTranslation(["cycles", "global"]);
  const [loadCreateGrade, setLoadCreateGrade] = React.useState(false);
  //initial valaues
  const initialValues = {
    degree: "",
    Nogroup: "",
  };

  /**
   * Formik validations schema
   */
  const validateSchema = yup.object({
    degree: yup
      .string("Degree")
      .required(
        t("addDegreeModal.deegre") + " " + t("ModalCreateCycles.isRequired")
      ),
    Nogroup: yup
      .string("No# Groups")
      .required(
        t("addDegreeModal.noGroups") + " " + t("ModalCreateCycles.isRequired")
      ),
  });

  return (
    <div>
      <Modal
        show={open}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("addDegreeModal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body mbottom10">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchema}
              onSubmit={(values) => {
                //Disable buttons while charging
                setLoadCreateGrade(true);
                //Organizing parameters
                let params = {
                  school_cycle_id: selectCycleId,
                  organization_school_level_id: selectGradeId,
                  grade_level: values.degree,
                  grade_groups: values.Nogroup,
                };
                //creating record
                CreateGrade(params)
                  .then((result) => {
                    if (result && result.length != 0) {
                      getGrades(selectSchoolLevel.id);
                      handleClose();
                    }
                  })
                  .catch((error) => {
                    console.log("Error:", error);
                  })
                  .then(() => {
                    setLoadCreateGrade(false);
                  });
              }}
            >
              {() => (
                <Form>
                  <Row>
                    {/**Grade input*/}
                    <Col md={6}>
                      <FieldInput
                        label={t("addDegreeModal.deegre")}
                        name="degree"
                        type="text"
                        placeholder="3"
                      />
                    </Col>
                    {/**Group input*/}
                    <Col md={6}>
                      <FieldInput
                        label={t("addDegreeModal.noGroups")}
                        name="Nogroup"
                        type="text"
                        placeholder="3"
                      />
                    </Col>
                  </Row>
                  <ContentButton className="content-button">
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleClose()}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loadCreateGrade}
                    >
                      {loadCreateGrade ? (
                        <TextLoading
                          text={t("global:buttons.saving")}
                          variant="light"
                        />
                      ) : (
                        t("global:buttons.save")
                      )}
                    </Button>
                  </ContentButton>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

AddDegreeModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectCycleId: PropTypes.string,
  selectGradeId: PropTypes.string,
  getGrades: PropTypes.func,
  selectSchoolLevel: PropTypes.object,
};
