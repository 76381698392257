import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function ModalInvoicePrinting(props) {
  const [t] = useTranslation(["reports", "global"]);
  return (
    <div>
      <Modal
        show={props.showModalInvoicePrinting}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Title className="modal-title ms-3 mt-3">
          {props.actionType == "dowload"
            ? t("modals.titleDowloadInvoice")
            : t("modals.titleInvoicePrinting")}
        </Modal.Title>
        <Modal.Body>
          <div>
            <p
              className="mt-4"
              style={{ fontWeight: "lighter", fontSize: "14px" }}
            >
              {props.actionType == "dowload"
                ? t("modals.textDowloadInvoiceModal")
                : t("modals.textPrintInvoices")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => props.setShowModalInvoicePrinting(false)}
          >
            {t("global:buttons.continue")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalInvoicePrinting.propTypes = {
  showModalInvoicePrinting: PropTypes.bool,
  setShowModalInvoicePrinting: PropTypes.func,
  actionType: PropTypes.string,
};
