/**
 *       **** Readme ****
 *
 * Component: <FormBasicTable />
 * Purpose: Returns the table element.
 *
 * Props:
 *   -head: {Object} -> table header
 *   -body: {Object} -> table body
 *   -noContenp: {JSX} -> Icon or image to display when there are no items
 *   -remove: {Function} -> Generates an action in case of having JSX Button or Icon
 *
 *
 * Creation date: 20/March/2023
 * Last update: 2/May/2023
 */

import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
// Custom Styles
const customStyles = {
  container: {
    border: "1px solid",
    minHeight: "260px",
    maxHeight: "600px",
  },
  rowText: {
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  studentList: {
    maxHeight: "550px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  textSkeleton: {
    width: "100%",
    height: "14px",
    marginTop: "10px",
  },
};
export default function FormBasicTable({ ...props }) {
  const {
    head = [],
    body = [],
    noContent,
    removeStudents = () => {},
    loading = false,
  } = props;

  return (
    <div style={customStyles.container}>
      <div style={{ margin: "10px" }}>
        {/**HEAD TABLE */}
        <Row>
          {head.map((item, index) => {
            return item.name == "jsx" ? (
              <Col md={item.Col}>{item.jsx}</Col>
            ) : (
              <Col md={item.col} key={index}>
                <div className="mt-0">
                  <h6>
                    <p style={customStyles.rowText}>
                      <b>{item.name}</b>
                    </p>
                  </h6>
                </div>
              </Col>
            );
          })}
        </Row>
        <div style={customStyles.studentList}>
          <Row>
            {/**BODY TABLE */}
            {loading ? (
              [1, 2, 3, 4, 5].map((index) => {
                return (
                  // Skeleton Loading
                  <Col lg={12} md={12} key={index}>
                    <span
                      className="skeleton"
                      style={customStyles.textSkeleton}
                    ></span>
                  </Col>
                );
              })
            ) : body.length == 0 ? (
              // No content Icon or Image
              <Col md={12}>{noContent}</Col>
            ) : (
              // Data to display
              body.map((item) => {
                let cols = [];
                let i = 0;
                for (let [key, value] of Object.entries(item)) {
                  if (
                    key !== "id" &&
                    key !== "value" &&
                    key !== "label" &&
                    key !== "jsx"
                  ) {
                    const item = {
                      name: value,
                      col: head[i].col,
                    };
                    cols.push(item);
                    i++;
                  } else if (key === "jsx") {
                    const item = {
                      name: key,
                      col: head[i].col,
                      jsx: value,
                    };
                    cols.push(item);
                    i++;
                  }
                }
                return cols.map((element, index) => {
                  return element.name == "jsx" ? (
                    <Col md={element.col} key={[index]}>
                      <div onClick={() => removeStudents(item)}>
                        {element.jsx}
                      </div>
                    </Col>
                  ) : (
                    <Col md={element.col} key={index}>
                      <div className="mt-o">
                        <p style={customStyles.rowText}>{element.name}</p>
                      </div>
                    </Col>
                  );
                });
              })
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

FormBasicTable.propTypes = {
  head: PropTypes.array,
  body: PropTypes.array,
  noContent: PropTypes.any,
  removeStudents: PropTypes.func,
  loading: PropTypes.bool,
};
