import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, FormGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/*Services */
import { AddRoles } from "../../api/permissions";

export default function ModalEditUserRoles(props) {
  const [t] = useTranslation(["permissions", "crm", "global"]);
  const [userGroups, setUserGroups] = useState([]);
  const [showNewRol, setShowNewRol] = useState(false);
  const [newGroup, setNewGroup] = useState({
    name: "",
    description: "",
  });

  /**
   * Change input value
   * @param {Obj} event
   * @param {Id} index
   */
  const changeValueGroups = (event, index) => {
    let updateGroups = [...userGroups];
    let value = event.target.value;
    updateGroups[index].description = value;
    setUserGroups(updateGroups);
  };

  /** Add and Edit a new Role
   * It is used to update the input and know which one brings id
   */
  const UpdateGroup = () => {
    let dataGroups = [];
    for (let item of userGroups) {
      //Agrega los nuevos grupos
      if (!item.id) {
        dataGroups.push({ description: item.description });
      }
      if (item.id && item.edited) {
        dataGroups.push({ description: item.description, id: item.id });
      }
    }
    let obj = {
      groups: dataGroups,
    };
    AddRoles(obj).then((result) => {
      props.setUserGroups(result.data.data);
      setShowNewRol(false);
      props.setShowModalEditUserRoles(false);
    });
  };

  /**
   *  It is used to add a new role and also to update and clean it.
   */
  const AddNewGroup = () => {
    let updateGroups = [...userGroups];
    updateGroups.unshift(newGroup);
    setUserGroups(updateGroups);
    setNewGroup({
      name: "",
      description: "",
    });
    setShowNewRol(false);
  };

  //Used to add a new role
  const onChangeNewGroup = (event) => {
    let group = { ...newGroup };
    let value = event.target.value;
    group.description = value;
    let code = value;
    //Replace "ñ" with "n" and "tildes".
    code = code.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //Replaces special characters
    code = code.replace(/[^a-zA-Z ]/g, "");
    //Replaces spaces with underscores
    code = code.replace(/\s+/g, "_");
    //Returns the lowercase value of the calling string.
    group.name = code.toLowerCase();
    setNewGroup(group);
  };

  /**
   * Blur the selected position
   * @param {Id} position
   */
  const handleOnBlur = (position) => {
    let updateGroups = [...userGroups];
    updateGroups[position].edited = true;
    updateGroups[position].editing = false;
    setUserGroups(updateGroups);
  };

  /**
   * Focus on the selected position
   * @param {Id} position
   */
  const handleOnFocus = (position) => {
    let updateGroups = [...userGroups];
    updateGroups[position].editing = true;
    setUserGroups(updateGroups);
  };

  useEffect(() => {
    let groups = [];
    //remove dependency of subobjects
    for (let item of props.userGroups) {
      let obj = JSON.parse(JSON.stringify(item));
      groups.push(obj);
    }
    setUserGroups(groups);
  }, [props.userGroups]);

  return (
    <Modal
      show={props.showModalEditUserRoles}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("titlesModals.titleRoles")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} style={{ height: "500px", overflow: "auto" }}>
            {userGroups.map((group, index) => {
              return (
                <div className="mt-2" key={index}>
                  <FormGroup>
                    <div
                      className="inner-icon right-addon input-group"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type="text"
                        id="inputText"
                        placeholder={t("crm:tableCrm.name")}
                        value={group.description}
                        onChange={(e) => changeValueGroups(e, index)}
                        style={{ cursor: "pointer" }}
                        onBlur={() => handleOnBlur(index)}
                        onFocus={() => handleOnFocus(index)}
                      />
                      {group.editing && (
                        <i
                          className="bi bi-check2"
                          style={{
                            position: "absolute",
                            padding: "10px",
                            pointerEvents: "none",
                            right: "5px",
                            zIndex: "3",
                          }}
                        ></i>
                      )}
                    </div>
                  </FormGroup>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-4">
            {showNewRol ? (
              <div>
                <Row>
                  <b>{t("textModals.newRole")}</b>
                </Row>
                <Row className="mt-2">
                  <Col md={12}>
                    <Form.Control
                      type="text"
                      id="inputText"
                      placeholder={t("crm:tableCrm.name")}
                      value={newGroup.description}
                      onChange={onChangeNewGroup}
                      onKeyDown={(e) => {
                        if (e.keyCode == 13 && newGroup.description != "") {
                          AddNewGroup();
                        }
                      }}
                    />
                    <i
                      className="bi bi-arrow-return-left"
                      style={{
                        position: "absolute",
                        padding: "10px",
                        pointerEvents: "none",
                        right: "15px",
                        marginTop: "-39px",
                      }}
                    ></i>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col md={12}>
                  <Button
                    id="btnAddGroupRol"
                    variant="primary"
                    onClick={() => setShowNewRol(true)}
                  >
                    {t("textModals.addRole")}
                    <i
                      className="bi bi-plus"
                      style={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        verticalAlign: "sub",
                      }}
                    ></i>
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          id="btnCancelEditUSer"
          variant="outline-secondary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            props.setShowModalEditUserRoles(false);
            setShowNewRol(false);
          }}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button
          id="btnSaveEditUser"
          variant="primary"
          style={{ marginRight: "10px" }}
          onClick={() => UpdateGroup()}
        >
          {t("global:buttons.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalEditUserRoles.propTypes = {
  showModalEditUserRoles: PropTypes.bool,
  setShowModalEditUserRoles: PropTypes.func,
  userGroups: PropTypes.array,
  setUserGroups: PropTypes.func,
};
