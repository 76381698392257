import React, { useContext, useState, useRef, useEffect } from "react";

import { MainContext } from "../../App";
import { useTranslation } from "react-i18next";

import { Container, Row, Col, Button } from "react-bootstrap";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import ContentSolid from "../global/ContentSolid";
import ContentTable from "../table/ContentTable";
import { Table } from "../table/Table";

import ModalConfirmDeletedRecords from "../global/modals/ModalConfirmDeletedRecords";
import ModalTicker from './modals/ModalTicker';

import { GetTickers, DeleteTickers } from "../../api/News";

//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const TickersView = () => {
  const [t] = useTranslation(["global", "news", "table", "reports"]);
  const componentRef = useRef(); //Create the reference for printing
  const { permissions } = useContext(MainContext);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [count, setCount] = useState(0);
  const [loadingView, setLoadingView] = useState(true);
  const [tickers, setTickers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [showModalTicker, setShowModalTicker] = useState(false);
  const [tickerDetails, setTickerDetails] = useState({});

  // Define table columns
  const columns = React.useMemo(
    () => [
      {
        Header: t("news:tickerPage.title"),
        accessor: "name",
        width: "96%",
      },
    ],
    [loadingView]
  );

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => tickers, [loading]);

  /**
   * Purpose: Function to delete selected records
   */
  const deleteTickers = () => {
    const payload = {
      ids: selectedRowIds.map((row) => row.original.id),
    };
    DeleteTickers(payload)
      .then((response) => {
        if (!response.status) return;
        getTickers();
        setSelectedRowIds([]);
        setIsAllRowsSelected(false);
      })
      .finally(() => {
        setShowModalConfirmDeleted(false);
      });
  }

  /**
   * Purpose: Function to select or deselect all records
   */
  const selectAllRecords = () => {
    let selectAll = {
      switch: !selectAllRows.switch,
      value: !selectAllRows.value,
    };
    setSelectAllRows(selectAll);
  };

  /**
 * Function to open the modal to edit a news
 * @param {object} news 
 */
  const rowOnclick = (news) => {
    const editTicker = {
      id: news.id,
      name: news.name,
    }
    setTickerDetails(editTicker);
    setShowModalTicker(true);
  };

  /**
   * Function to get the list of Tickers
   */
  const getTickers = () => {
    setLoading(true);
    GetTickers()
      .then((response) => {
        if (!response.status) return;
        let tickerList = response.data.map((ticker) => {
          return {
            index: ticker.id,
            id: ticker.id,
            name: ticker.ticker,
          };
        }
        );
        setTickers(tickerList);
      })
      .finally(() => {
        setLoadingView(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    getTickers();
  }, []);

  /**
  * Description: Effect to know if all prospects are selected
  */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col md={6}>
          {permissions.news_labels.delete &&
            <Button
              id="downloadStudentCredentialsBtn"
              variant="outline-secondary"
              onClick={() => setShowModalConfirmDeleted(true)}
              disabled={!selectedRowIds.length}
            >
              {t("global:buttons.eliminate")}
            </Button>
          }
          {/* Total counter of records and selected records in Classes table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col md={6}>
          {permissions.news_labels.create &&
            <Button
              id="downloadStudentCredentialsBtn"
              className="float-end"
              onClick={() => { setShowModalTicker(true) }}
            >
              {t("news:tickerPage.create")}
            </Button>
          }
        </Col>
      </Row>

      {loadingView ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable className="mt-3" startColumFilter={1} lastColumFilter={2}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            loading={loading}
            setSelectedRowIds={setSelectedRowIds}
            setIsAllRowsSelected={setIsAllRowsSelected}
            setRowsDisplayed={setCount}
            selectAllRows={selectAllRows}
            selectAllRecords={selectAllRecords}
            rowSelect={true}
            enableCheckToSelectAllRecords={true}
            rowOnclick={permissions.news_labels.edit ? rowOnclick : null}
          ></Table>
        </ContentTable>
      )}

      {/* Modals */}
      {showModalTicker &&
        <ModalTicker
          showModalTicker={showModalTicker}
          setShowModalTicker={() => {
            setTickerDetails({})
            setShowModalTicker(false)
          }}
          tickerDetails={tickerDetails}
          getTickers={getTickers}
        />
      }
      {showModalConfirmDeleted &&
        <ModalConfirmDeletedRecords
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          confirmAction={deleteTickers}
          title={t("news:modalTicker.eliminateTickers")}
          message={t("news:modalTicker.deleteTickersMenssage")}
          confirmText={t("global:buttons.eliminate")}
        />
      }
    </Container>
  );
};

export default TickersView;