/**
 *       **** Readme ****
 *
 * Component: <FormSchoolCycleFlter />
 * Purpose: Returns the Input element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 26/April/2023
 * Last update: 27/April/2023
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

// Components
import FormSelect from "./FormSelect";

export default function FormSchoolCycleFilter({ label, ...props }) {
  const [t] = useTranslation(["scholarship"]);
  const {
    currentCycle = false,
    setSchoolCycle = () => {},
    value,
    setSchoolLevels = () => {},
    schoolCycles = [],
    setSchoolCycles = () => {},
    schoolCyclesBackup = [],
    setSchoolCycleBackup = () => {},
  } = props;
  const prefix = process.env.REACT_APP_PREFIX;

  // Functions

  /**
   * Get school cycles by local storage
   */
  const getSchoolCycles = () => {
    // Get user school cycles cookie
    const schoolCycles = `cycles${prefix}`;
    // Get user school cycle data
    const data = JSON.parse(localStorage.getItem(schoolCycles)) || [];
    if (data && Object.entries(data).length != 0) {
      let schoolCycles = [];
      // Get current school cycle
      let currentCycleFind = null;
      // Get current date
      const today = moment().unix();
      for (let schoolCycle of data) {
        if (
          today > schoolCycle.start_time &&
          today < schoolCycle.end_time &&
          schoolCycle.current_cycle == 1
        ) {
          currentCycleFind = schoolCycle;
        }
        const item = {
          label: schoolCycle.school_cycle,
          value: schoolCycle.id,
          startTime: new Date(moment.unix(schoolCycle.start_time).toString()),
          endTime: new Date(moment.unix(schoolCycle.end_time).toString()),
        };
        schoolCycles.push(item);
      }
      // Find current cycle
      if (currentCycle) {
        if (currentCycleFind) {
          setSchoolCycle({
            label: currentCycleFind.school_cycle,
            value: currentCycleFind.id,
            startTime: new Date(
              moment.unix(currentCycleFind.start_time).toString()
            ),
            endTime: new Date(
              moment.unix(currentCycleFind.end_time).toString()
            ),
          });
        }
      }
      // Set school cycles for the select
      setSchoolCycles(schoolCycles);
      // Set school cycle backup
      setSchoolCycleBackup(data);
    } else {
      setTimeout(() => {
        getSchoolCycles();
      }, 1000);
    }
  };

  /**Initial values */
  useEffect(() => {
    // Get school cycles
    getSchoolCycles();
  }, []);

  /**
   * Obtains the school levels (School levels) of the selected School cycle.
   * @param {Id} schoolCycleId
   */
  const getLevels = (schoolCycle) => {
    // Filtering school cycles levels
    let schoolLevels = [];
    //Check if the school cycle has higher education
    let universityStudies = false;
    let listOffers = [];
    schoolCycle.colleges_and_schools.forEach((colleges_and_school) => {
      colleges_and_school.school_levels.forEach((school_level) => {
        school_level.programs.forEach((program) => {
          if (program.enabled == "1") {
            universityStudies = true;
            // Get levels
            let levels = [];
            if (
              program &&
              program.program_levels &&
              program.program_levels.length != 0
            ) {
              const programLevels = program.program_levels;
              for (const programLevel of programLevels) {
                const item = {
                  value: programLevel.id,
                  label: programLevel.level,
                };
                levels.push(item);
              }
            }
            listOffers.push({
              id: program.id,
              value: program.id,
              label: program.name,
              data: program,
              programLevels: levels,
            });
          }
        });
      });
    });
    // Get school cycle levels from school cycle
    const schoolCycleLevels = schoolCycle.school_levels;
    if (schoolCycleLevels && schoolCycleLevels.length != 0) {
      for (let level of schoolCycleLevels) {
        // Get grades grups
        let gradeGroups = [];
        if (level.grades && level.grades.length !== 0) {
          const grades = level.grades;
          for (let grade of grades) {
            if (grade.groups && grade.groups.length !== 0) {
              const groups = grade.groups;
              groups.forEach((group) => {
                const item = {
                  id: group.grade_group_grade_level_id,
                  value: group.grade_group_grade_level_id,
                  label: `${grade.grade_level} ${group.name}`,
                };
                gradeGroups.push(item);
              });
            }
          }
        }
        // Get school level item
        schoolLevels.push({
          id: level.id,
          value: level.organization_school_level_id,
          label: level.school_level_name,
          grades: gradeGroups,
        });
      }
    }
    // Add higher education option
    if (universityStudies) {
      schoolLevels.push({
        id: "higherLevel",
        value: "higherLevel",
        label: t("scholarshipDetails.university"),
        offers: listOffers,
      });
    }
    setSchoolLevels(schoolLevels);
  };

  /**Get current levels */
  useEffect(() => {
    if (schoolCyclesBackup && schoolCyclesBackup.length !== 0 && value) {
      // Get school cycle
      const schoolCycle = schoolCyclesBackup.find(
        (cycle) => cycle.id == value.value
      );
      // Get Schol levels
      if (schoolCycle) {
        getLevels(schoolCycle);
      }
    }
  }, [value]);

  return (
    <>
      <FormSelect
        {...props}
        label={label}
        placeholder={label}
        options={schoolCycles}
      />
    </>
  );
}

FormSchoolCycleFilter.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  currentCycle: PropTypes.bool,
  setSchoolCycle: PropTypes.func,
  value: PropTypes.any,
  setSchoolLevels: PropTypes.func,
  schoolCycles: PropTypes.array,
  setSchoolCycles: PropTypes.func,
  schoolCyclesBackup: PropTypes.array,
  setSchoolCycleBackup: PropTypes.func,
};
