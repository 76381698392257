import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** Services */
import { DeleteTemplate } from "../../../api/ReportCards";

export default function ConfirmDeleteModal(props) {
  const [t] = useTranslation(["reportCards", "global"]);

  /**
   * Removes a template using the id
   */
  const deleteTemplate = () => {
    DeleteTemplate(props.templateToDelete).then((result) => {
      if (!result.description) {
        if (props.templateToDelete == props.templateInformation.id) {
          props.setTemplateInformation({
            name: "Nueva plantilla", //initial default value
            format: "",
            thumbnailUrl: "",
          });
          props.setListFormatItems([{
            id: "0",
            type: "phantomElement",
            disabled: true,
            averaging: true,
          }]);
        }
        props.getTemplates();
        props.setShowConfirmDeleteModal(false);
      }
    });
  };

  return (
    <Modal
      show={props.showConfirmDeleteModal}
      size="lg"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title>{t("reportCards:confirmDeleteModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("reportCards:confirmDeleteModal.message")}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={() => props.setShowConfirmDeleteModal(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button variant="primary" onClick={deleteTemplate}>
          {t("global:buttons.delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmDeleteModal.propTypes = {
  showConfirmDeleteModal: PropTypes.bool,
  setShowConfirmDeleteModal: PropTypes.func,
  templateToDelete: PropTypes.string,
  getTemplates: PropTypes.func,
  templateInformation: PropTypes.object,
  setTemplateInformation: PropTypes.func,
  setListFormatItems: PropTypes.func,
};
