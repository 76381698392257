//Libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { addDays, subDays } from "date-fns";
import es from "date-fns/locale/es";
//Bootstrap Components
import { Button, Modal } from "react-bootstrap";
//Hooks Components
import { DatePicker } from "../../../components/global/form/DatePicker";
import FieldInput from "../../../components/global/form/FieldInput";
import FormCheck from "../../../components/global/form/FormCheck";
import ContentButton from "../../../components/global/ContentButton";
import TextLoading from "../../../components/global/TextLoading";
//API
import { createSchoolCycle } from "../../../api/Cycles";
export default function CreateCycleModal(props) {
  //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [t] = useTranslation(["cycles", "global"]);
  const [loadCreateCycle, setloadCreateCycle] = useState(false);
  const {
    open = false,
    importView = "cycle",
    handleClose = () => {},
    getSchoolCycleFromComponent = () => {},
    organizationSchoolLevels = [],
    setOrganizationSchoolLevels = () => {},
    programsIds = [],
    setProgramsIds = () => {},
  } = props;
  //get language
  const prefix = process.env.REACT_APP_PREFIX;
  const language = localStorage.getItem(`cmLanguage${prefix}`);
  //Traslate calendar
  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }
  //Initial values
  let initialValues = {
    cycleName: "",
    startDate: "",
    endDate: "",
    cycleActive: false,
  };

  /**
   * Formik validations schema
   */
  const validationSchema = yup.object({
    cycleName: yup
      .string("Cycle Name")
      .required(
        t("ModalCreateCycles.cycleName") +
          " " +
          t("ModalCreateCycles.isRequired")
      ),
    startDate: yup
      .string("Start Date")
      .required(
        t("ModalCreateCycles.startDate") +
          " " +
          t("ModalCreateCycles.isRequired")
      )
      .nullable(),
    endDate: yup
      .string("End Date")
      .required(
        t("ModalCreateCycles.endDate") + " " + t("ModalCreateCycles.isRequired")
      )
      .nullable(),
  });

  /**
   * Create cycle
   * @param {Object} values
   */
  const saveCycle = (values) => {
    //Disable buttons while charging
    setloadCreateCycle(true);
    //Datas
    let newCycle = {};
    newCycle.school_cycle = values.cycleName;
    //Organization school leves
    organizationSchoolLevels && organizationSchoolLevels.length != 0
      ? (newCycle.organization_school_levels = organizationSchoolLevels)
      : "";
    newCycle.start_time = moment(values.startDate).format("YYYY-MM-DD");
    newCycle.end_time = moment(values.endDate).format("YYYY-MM-DD");
    newCycle.start_date = newCycle.start_time;
    newCycle.end_date = newCycle.end_time;
    newCycle.start_time = moment(newCycle.start_time).unix();
    newCycle.end_time = moment(newCycle.end_time).unix();
    //program ids
    if (programsIds && programsIds.length != 0) {
      newCycle.programs_ids = programsIds;
      newCycle.program_enabled = 1;
    }
    //Current cycle
    newCycle.current = values.cycleActive;
    //Create school cycle
    createSchoolCycle(newCycle)
      .then((result) => {
        let data = result.data;
        if (data && data.id) {
          addNewCycleInStorage(data);
          getSchoolCycleFromComponent();
          setOrganizationSchoolLevels([]);
          setProgramsIds([]);
          setloadCreateCycle(false);
          handleClose(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .then(() => {
        setloadCreateCycle(false);
      });
  };

  /**
   * Description: Function to add a new cycle to the list of local storage cycles
   * @param {object} newCycle
   */
  const addNewCycleInStorage = (newCycle) => {
    if (newCycle.id) {
      const cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`));
      const hasCycles = Array.isArray(cyclesLocal) && cyclesLocal.length;
      const newCyclesList = hasCycles ? cyclesLocal : [];
      newCycle.label = newCycle.school_cycle;
      newCycle.value = newCycle.id;
      newCyclesList.unshift(newCycle);
      localStorage.setItem(`cycles${prefix}`, JSON.stringify(newCyclesList));
    }
  };

  return (
    <Modal
      show={open}
      id="createCycleModal"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("ModalCreateCycles.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {importView === "cycle" ? (
          <React.Fragment>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (values && values.cycleName && values.cycleName != "") {
                  values.cycleName = values.cycleName.toUpperCase();
                }
                saveCycle(values);
              }}
            >
              {(formik) => (
                <Form>
                  {/**Cycle name*/}
                  <FieldInput
                    label={t("ModalCreateCycles.cycleName")}
                    capitalize={true}
                    name="cycleName"
                    type="text"
                    placeholder={t("ModalCreateCycles.cycleName")}
                  />
                  {/**Cycle start date*/}
                  <DatePicker
                    label={t("ModalCreateCycles.startDate")}
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    name="startDate"
                    maxDate={
                      formik.values.endDate
                        ? subDays(formik.values.endDate, 1)
                        : ""
                    }
                    value={formik.values.startDate}
                    {...Locallanguage}
                  />
                  {/**Cycle end date*/}
                  <DatePicker
                    label={t("ModalCreateCycles.endDate")}
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    name="endDate"
                    minDate={
                      formik.values.startDate
                        ? addDays(formik.values.startDate, 1)
                        : ""
                    }
                    value={formik.values.endDate}
                    {...Locallanguage}
                  />
                  {/**Cycle Active*/}
                  <div style={{ marginTop: "20px" }}>
                    <FormCheck
                      name="cycleActive"
                      label={t("ModalCreateCycles.cycleActive")}
                      type="switch"
                    />
                  </div>
                  {/**Conten buttons*/}
                  <ContentButton
                    className="content-button"
                    disabled={loadCreateCycle}
                  >
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleClose(false)}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loadCreateCycle}
                    >
                      {loadCreateCycle ? (
                        <TextLoading
                          text={t("global:buttons.saving")}
                          variant="light"
                        />
                      ) : (
                        t("global:buttons.save")
                      )}
                    </Button>
                  </ContentButton>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
}

CreateCycleModal.propTypes = {
  open: PropTypes.bool,
  importView: PropTypes.string,
  handleClose: PropTypes.func,
  getSchoolCycleFromComponent: PropTypes.func,
  organizationSchoolLevels: PropTypes.array,
  setOrganizationSchoolLevels: PropTypes.func,
  programsIds: PropTypes.array,
  setProgramsIds: PropTypes.func,
};
