/**
 * Countries
 *
 * Puporse: Get all countries
 *
 * @author Mario Simbron Gonzalez <mario@inclancommunications.com>
 * @date 27/04/2022 10:02:25 AM
 */
//Get libraries
import axios from "axios";
import { ErrorResponse, validateErrorResponse } from "./AxiosExtension";
//Url and method
let axiosOptions = {
  url: process.env.REACT_APP_REST_URL,
  method: "get",
};
//Get prefix token
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Description: Get all countries
 * @return Array
 */
export const getCountries = async () => {
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/countries`;

  try {
    const response = await axios(axiosOptions);
    //Get language
    const language = localStorage.getItem(`cmLanguage${prefix}`);
    for (const country of response.data.data) {
      country.name =
        language === "spanish" && country.translations.es
          ? country.translations.es
          : country.name;
    }
    //Alphabetical order of the countries when the language is Spanish.
    if (language === "spanish") {
      response.data.data = response.data.data.sort(function (
        country,
        countryCompare
      ) {
        if (country.name > countryCompare.name) {
          return 1;
        }
        if (country.name < countryCompare.name) {
          return -1;
        }
        return 0;
      });
    }
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Get the states of the country
 * @param {Object} values state params: stateId, name, short, countryId
 * @returns Array states
 */
export const getStates = async (values) => {
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/states`;
  axiosOptions.params = {
    state_id: values.stateId,
    name: values.name,
    short: values.short,
    country_id: values.countryId,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
  }
};

/**
 * Description: Get the cities of the state
 * @param {Object} values city params: cityId, stateId, countryId, search, limit, offset
 * @returns Array cities
 */
export const getCities = async (values) => {
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/cities`;
  axiosOptions.params = {
    id: values.cityId,
    state_id: values.stateId,
    country_id: values.countryId,
    search: values.search,
    limit: values.limit,
    offset: values.offset,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
  }
};
