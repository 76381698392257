import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ModalSuccessImport = (props) => {
  const [t] = useTranslation(["global", "class"]);

  return (
    <Modal show={props.showModalSuccessImport} size="lg">
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("class:importDetails.importOfSchedules")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> {t("class:importDetails.succesMessage")}</Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          onClick={() => {
            props.setShowModalSuccessImport(false);
          }}
        >
          {t("global:buttons.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalSuccessImport.propTypes = {
  showModalSuccessImport: PropTypes.bool,
  setShowModalSuccessImport: PropTypes.function,
};
export default ModalSuccessImport;
