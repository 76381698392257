import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

/** Services */
import { sendInvitationToParentsOrGuardians } from "../../api/StudentsReport";

export default function ModalRelativeOrGuardians(props) {
  const [disableSend, setDisableSend] = useState(false);
  const [t] = useTranslation(["modalImportStudent", "global"]);
  const [sendSuccessful, setSendSuccessful] = useState(false);

  /* Initial loading */
  useEffect(() => {
    Sentry.setTag("section", "Invite Relative Or Guardians Modal");
  }, []);

  /**
   * Purpose: Close the "Invite Relatives or Guardians" modal
   */
  const cancel = () => {
    props.setShowInviteRelativeOrGuardians(false);
    setSendSuccessful(false);
  };

  const send = () => {
    setDisableSend(true);
    let studentIds = [];
    if (props.dataTable.length > 0) {
      props.dataTable.map((details) => {
        studentIds.push(details.index);
      });
    } else {
      cancel();
      return;
    }
    sendInvitationToParentsOrGuardians(studentIds)
      .then((result) => {
        if (result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          return;
        }
        setSendSuccessful(true);
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .then(() => {
        setDisableSend(false);
      });
  };

  return (
    <Modal
      show={props.showInviteRelativeOrGuardians}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("global:buttons.inviteRelativeOrGuardians")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sendSuccessful
          ? t("modalImportStudent:labels.successfulSendInvitation")
          : t("modalImportStudent:labels.sendInvitation")}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancelRelativeInvitationBtn"
          style={{ marginRight: "10px" }}
          variant="outline-secondary"
          onClick={() => cancel()}
        >
          {sendSuccessful ? t("global:buttons.close") : t("buttons.cancel")}
        </Button>
        {!sendSuccessful ? (
          <Button
            id="sendRelativeInvitationBtn"
            style={{ marginRight: "10px" }}
            onClick={() => send()}
            disabled={disableSend}
          >
            {t("global:buttons.send")}
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
}

ModalRelativeOrGuardians.propTypes = {
  showInviteRelativeOrGuardians: PropTypes.bool,
  setShowInviteRelativeOrGuardians: PropTypes.func,
  dataTable: PropTypes.array,
};
