import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/styles.css";
import "../src/css/print.css";
//Import Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
//Initialize Sentry and send the exceptions to the campusmovil-react project
Sentry.init({
  dsn: "https://58dc4e30e5414000ac41ab0f001e80c3@o481206.ingest.sentry.io/4503972780048384",
  integrations: [new BrowserTracing()],
  environment: null,
  initialScope: {
    tags: {
      app_name: window.location.hostname, //platform name
      app_project: "Campus Movil React v2", //Platform version
    },
  },
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));
