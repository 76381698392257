import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Components
import FormSelect from "../../../components/global/form/FormSelect";
import ContentButton from "../../../components/global/ContentButton";

// Custom Styles
const customStyles = {
  studentCol: {
    border: "1px solid #ccc",
    height: "37px",
    padding: "8px",
    marginBottom: "15px",
  },
  text: {
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  studentList: {
    maxHeight: "300px",
    overflowY: "auto",
    overflowX: "hidden",
  },
};

/**
 * Add scholarship students Modal
 * @param {Object} props Students data
 * @returns MOdal View
 */
const AddScholarshipStudents = (props) => {
  // Get student data
  let {
    open = false,
    schoolCycle,
    schoolLevels = [],
    schoolLevel = null,
    setSchoolLevel = () => {},
    addingStudents = [],
    setAddingStudents = () => {},
    students = [],
    setStudents = () => {},
    loadingStudents = false,
    getStudentsBySchoolLevel = () => {},
    gradeGroup = null,
    setGradeGroup = () => {},
    AddStudents = () => {},
    clean = () => {},
    removeStudents = () => {},
    programLevel = null,
    setProgramLevel = () => {},
  } = props;
  const [student, setStudent] = useState(null);
  if (!schoolCycle) {
    schoolCycle = { label: "" };
  }
  // Get languaje
  const [t] = useTranslation([
    "global",
    "scholarship",
    "modalCreateStudent",
    "cycles",
    "schoolCycleGroup",
  ]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module

  /***
   * Scholarship Modal View
   */
  return (
    <div>
      <Modal
        show={open}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("scholarship:scholarshipDetails.addStudents")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal.body mbottom10">
          <Row>
            {/**School cycle */}
            <Col lg={12} md={12}>
              <div className="mt-o">
                <b>
                  <p style={{ fontSize: "14px" }}>{schoolCycle.label}</p>
                </b>
              </div>
            </Col>
            {/**School level */}
            <Col lg={12} md={12}>
              <FormSelect
                label={t("cycles:schoolCycleGroup.schoolLevel")}
                placeholder={t("cycles:select.placeholderSchoolLevels")}
                name="schoolLevel"
                options={schoolLevels}
                onChange={(event) => {
                  if (
                    !schoolLevel ||
                    (schoolLevel.id && schoolLevel.id !== event.id)
                  ) {
                    setGradeGroup(null);
                    setProgramLevel(null);
                    setStudents([]);
                    setStudent(null);
                    setAddingStudents([]);
                    setSchoolLevel(event);
                  }
                }}
                value={schoolLevel}
                isDisabled={schoolLevels.length == 0}
              />
            </Col>
            {/**School grade groups or academic offer */}
            <Col lg={12} md={12}>
              <FormSelect
                label={
                  schoolLevel && schoolLevel.value == "higherLevel"
                    ? t("cycles:schoolCycleGroup.academicOffer")
                    : t("scholarship:scholarshipDetails.gradeGroup")
                }
                placeholder={
                  schoolLevel && schoolLevel.value == "higherLevel"
                    ? t("cycles:select.placeholderOfferAcademic")
                    : t("scholarship:scholarshipDetails.selectGradeGroup")
                }
                name="schoolLevels"
                options={
                  schoolLevel &&
                  schoolLevel.value == "higherLevel" &&
                  schoolLevel.offers &&
                  schoolLevel.offers.length !== 0
                    ? schoolLevel.offers
                    : schoolLevel &&
                      schoolLevel.grades &&
                      schoolLevel.grades.length !== 0
                    ? schoolLevel.grades
                    : []
                }
                onChange={(event) => {
                  if (!gradeGroup || gradeGroup.id !== event.id) {
                    setStudents([]);
                    setStudent(null);
                    setAddingStudents([]);
                    setGradeGroup(event);
                    const key = schoolLevel.value;
                    if (key !== "higherLevel") {
                      getStudentsBySchoolLevel(event.id);
                    } else {
                      setProgramLevel(null);
                    }
                  }
                }}
                value={gradeGroup}
                isDisabled={
                  !schoolLevel ||
                  (schoolLevel.value == "higherLevel"
                    ? schoolLevel.offers.length == 0
                    : schoolLevel.grades.length == 0)
                }
              />
            </Col>
            {/**Program levels */}
            {schoolLevel && schoolLevel.value == "higherLevel" && (
              <Col lg={12} md={12}>
                <FormSelect
                  label={t("cycles:schoolCycleGroup.levelAcademicOffer")}
                  name="level"
                  options={
                    gradeGroup &&
                    gradeGroup.programLevels &&
                    gradeGroup.programLevels.length !== 0
                      ? gradeGroup.programLevels
                      : []
                  }
                  value={programLevel}
                  onChange={(event) => {
                    if (!programLevel || programLevel.value !== event.value) {
                      setStudents([]);
                      setStudent(null);
                      setProgramLevel(event);
                      setAddingStudents([]);
                      getStudentsBySchoolLevel(event);
                    }
                  }}
                  isDisabled={
                    !gradeGroup || gradeGroup.programLevels.length == 0
                  }
                  placeholder={t("cycles:select.placeholderLevel")}
                  isClearable
                />
              </Col>
            )}
            {/**Students */}
            <Col lg={12} md={12}>
              <FormSelect
                name="studentIds"
                label={t("scholarship:scholarshipDetails.students")}
                placeholder={t("scholarship:scholarshipDetails.selectStudents")}
                options={students}
                isDisabled={
                  loadingStudents ||
                  !schoolLevel ||
                  (schoolLevel.value == "higherLevel"
                    ? !programLevel
                    : !gradeGroup)
                }
                onChange={(event) => {
                  // Find if already selected
                  const exists = addingStudents.find(
                    (item) => item.value == event.value
                  );
                  if (!exists) {
                    let newStudents = [...addingStudents];
                    newStudents.push(event);
                    setAddingStudents(newStudents);
                    // Remove from selector
                    const index = students.findIndex(
                      (item) => item.value == event.value
                    );
                    students.splice(index, 1);
                    setStudents(students);
                  }
                  setStudent(event);
                }}
                value={student}
              />
            </Col>
          </Row>
          {/**Student list */}
          <div style={customStyles.studentList}>
            {addingStudents.length !== 0 &&
              addingStudents.map((student, index) => {
                return (
                  <React.Fragment key={index}>
                    <div style={customStyles.studentCol}>
                      <Row>
                        {/**Student Name */}
                        <Col lg={11} md={11}>
                          <div className="mt-o">
                            <p style={customStyles.text}>{student.label}</p>
                          </div>
                        </Col>
                        {/**Circle gray icon */}
                        <Col lg={1} md={1}>
                          <div onClick={() => removeStudents(student)}>
                            {student.jsx}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ContentButton
            className="content-button"
            style={{ marginTop: "-30px" }}
            disabled={loadingStudents}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                // Remove students from selector
                setStudent(null);
                clean();
                setProgramLevel(null);
              }}
              disabled={loadingStudents}
            >
              {t("global:buttons.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={AddStudents}
              disabled={loadingStudents || addingStudents.length == 0}
            >
              {t("scholarship:scholarshipDetails.addStudents")}
            </Button>
          </ContentButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddScholarshipStudents;

AddScholarshipStudents.propTypes = {
  open: PropTypes.bool,
  schoolCycle: PropTypes.object,
  schoolLevels: PropTypes.array,
  schoolLevel: PropTypes.any,
  setSchoolLevel: PropTypes.func,
  addingStudents: PropTypes.array,
  setAddingStudents: PropTypes.func,
  students: PropTypes.array,
  setStudents: PropTypes.func,
  loadingStudents: PropTypes.bool,
  getStudentsBySchoolLevel: PropTypes.func,
  gradeGroup: PropTypes.any,
  setGradeGroup: PropTypes.func,
  AddStudents: PropTypes.func,
  clean: PropTypes.func,
  removeStudents: PropTypes.func,
  programLevel: PropTypes.any,
  setProgramLevel: PropTypes.func,
};
