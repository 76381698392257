import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

export default function HelpBlock(props) {
  const { label = "" } = props;
  return <Form.Text className="text-muted error">{label}</Form.Text>;
}

HelpBlock.propTypes = {
  label: PropTypes.string,
};
