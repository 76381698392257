import axios from "axios";

const prefix = process.env.REACT_APP_PREFIX;

/**
 *  Purpose: To obtain scholarship records.
 * @param {Object} data
 * @returns
 */
export const GetTypeScholarships = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/scholarships`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Service to remove a scholarship
 * @param {Object} request
 * @returns
 */
export const DeleteScholarships = async (request) => {
  let axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = request;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/scholarships`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
