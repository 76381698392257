import React, { useState } from "react";
import { Modal, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";

import FieldInput from "../../../components/global/form/FieldInput";
import ContentButton from "../../../components/global/ContentButton";
import TextLoading from "../../../components/global/TextLoading";

import { CreateLink, UpdateLink } from "../../../api/ExtraLink";
export default function ModalAddEditExtraLink(props) {
  const [t] = useTranslation(["extraLink", "global", "crm"]);
  const [initialValues] = useState(props.extraLink);

  const [disabledCreateUSer, setDisabledCreateUSer] = useState(false);

  const validateSchema = Yup.object().shape({
    name: Yup.string().required(t("theName") + " " + t("crm:isRequired")),
    url: Yup.string().required(t("theUrl") + " " + t("crm:isRequired")),
  });

  /**
   * Function to create an extra link
   * @param {Obj} values
   */
  const createExtraLink = async (values) => {
    setDisabledCreateUSer(true);
    let data = {};
    data.link_name = values.name;
    data.link_url = values.url;
    await CreateLink(data).then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      } else {
        props.setShowAddEditExtraLink(false);
        setDisabledCreateUSer(false);
        props.getExtraLinks();
        return result.data;
      }
    });
  };

  /**
   * Function to update an extra link
   * @param {Obj} item
   */
  const updateExtraLink = async (item) => {
    setDisabledCreateUSer(true);
    let data = {};
    data.hyperlink_id = item.id;
    data.link_name = item.name;
    data.link_url = item.url;
    await UpdateLink(data).then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      } else {
        props.setShowAddEditExtraLink(false);
        setDisabledCreateUSer(false);
        props.getExtraLinks();
        return result.data;
      }
    });
  };

  return (
    <Modal
      show={props.showAddEditExtraLink}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {initialValues.id ? t("editLind") : t("addLink")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={(values) => {
              values.id ? updateExtraLink(values) : createExtraLink(values);
            }}
          >
            <Form>
              <Row>
                <Col lg={12}>
                  <FieldInput
                    name="name"
                    type="text"
                    label={t("name")}
                    placeholder={t("nameLink")}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FieldInput
                    name="url"
                    type="text"
                    label={t("URL")}
                    placeholder={t("nameUrl")}
                    required={true}
                  />
                </Col>
              </Row>
              <ContentButton className="content-button">
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    props.setShowAddEditExtraLink(false);
                    props.closeModal();
                  }}
                >
                  {t("global:buttons.cancel")}
                </Button>
                <Button type="submit" disabled={disabledCreateUSer}>
                  {disabledCreateUSer ? (
                    <TextLoading
                      text={t("global:buttons.saving")}
                      variant="white"
                    />
                  ) : (
                    <>{t("global:buttons.save")}</>
                  )}
                </Button>
              </ContentButton>
            </Form>
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ModalAddEditExtraLink.propTypes = {
  setShowAddEditExtraLink: PropTypes.func,
  showAddEditExtraLink: PropTypes.bool,
  extraLink: PropTypes.object,
  closeModal: PropTypes.func,
  getExtraLinks: PropTypes.func,
};
