import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, Button, Table, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import * as moment from "moment";

export default function ModalSuccessfulBilling(props) {
  const [t] = useTranslation(["reports", "global", "crm"]);

  /**
   * Change the format type to what it comes with in the api
   * @param {String} date
   * @returns
   */
  const formatDate = (date) => {
    let dateMoment = moment(date, "YYYY-MM-DD");
    let formantDate = dateMoment.format("DD/MM/YYYY");
    return formantDate;
  };

  /**
   * Purpose: To edit any errors that may occur.
   * @param {String} value
   * @param {Number} studentId
   */
  const editMissingData = (value, studentId = 0) => {
    let baseUrl = process.env.REACT_APP_V1;
    let view =
      value == "student"
        ? "/estudiante/" + studentId
        : value == "school_level"
        ? "/configuraciones/ciclo"
        : "/configuraciones/organizacion";
    let url = baseUrl + view;
    window.open(url, "_blank");
  };

  /**
   * Purpose: It is useful to see when an error occurs and to know what action to take whether to download or print and close the action modal.
   */
  const actionsButton = () => {
    if (props.invoicingErrors.length) {
      props.bill(true);
    } else {
      props.actionType === "dowload"
        ? props.downloadInvoices(props.idsPrint)
        : props.printInvoices(props.confirmations);
    }
    props.setShowModalSuccessfulBilling(false);
  };

  return (
    <div>
      <Modal
        show={props.showModalSuccessfulBilling}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("modals.titleSuccesBilling")}
          </Modal.Title>
        </Modal.Header>
        <ModalBody className="modal-body pt-0">
          {props.invoicingErrors.length ? (
            <div>
              <Row className="my-3">
                <Col md={12} className="fw-light">
                  {t("modals.textOcurredDetails")}
                </Col>
              </Row>
              {props.invoicingErrors.map(function (error, index) {
                return (
                  <Row key={index} className="mx-0">
                    {error.error_type === "student" ? (
                      <div
                        style={{ marginBottom: "10px" }}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {' "' + error.error_message + '" '}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            editMissingData("student", error.student_id)
                          }
                        >
                          <a href="#" className="stretched-link">
                            {t("modals.textEditData")}
                          </a>
                        </a>
                      </div>
                    ) : error.error_type === "school_level" ? (
                      <div
                        style={{ marginBottom: "10px" }}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {' "' + error.error_message + '" '}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => editMissingData("school_level")}
                        >
                          <a href="#" className="stretched-link">
                            {t("modals.textSchoolData")}
                          </a>
                        </a>
                      </div>
                    ) : error.error_type === "organization" ? (
                      <div
                        style={{ marginBottom: "10px" }}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {' "' + error.error_message + '" '}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => editMissingData("organization")}
                        >
                          <a href="#" className="stretched-link">
                            {t("modals.textSchoolData")}
                          </a>
                        </a>
                      </div>
                    ) : (
                      <Col
                        md={12}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {error.error_message}
                      </Col>
                    )}
                  </Row>
                );
              })}
            </div>
          ) : (
            <div>
              <div className="mx-3 fw-light">
                <p>{t("modals.textAllPayments")}</p>
              </div>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>{t("headerTable.transaction")}</th>
                    <th>{t("headerTable.date")}</th>
                    <th>{t("headerTable.customer")}</th>
                    <th>{t("headerTable.amount")}</th>
                  </tr>
                </thead>
                <tbody className="overflow-auto">
                  {props.billablePayments.map((row, index) => (
                    <tr key={index}>
                      <td>{row.confirmation}</td>
                      <td> {formatDate(row.data)} </td>
                      <td>{row.students}</td>
                      <td>$ {row.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </ModalBody>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              props.setShowModalSuccessfulBilling(false);
              props.clearInvoiceDataModal();
            }}
          >
            {props.invoicingErrors.length
              ? t("crm:modalAddProspect.btnClose")
              : t("global:buttons.accept")}
          </Button>
          <Button variant="primary" onClick={actionsButton}>
            {props.invoicingErrors.length
              ? t("reports:modals.textRetry")
              : props.actionType === "dowload"
              ? t("modals.dowloadInvoices")
              : t("modals.printInvoiced")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalSuccessfulBilling.propTypes = {
  showModalSuccessfulBilling: PropTypes.bool,
  setShowModalSuccessfulBilling: PropTypes.func,
  invoicingErrors: PropTypes.array,
  confirmations: PropTypes.array,
  bill: PropTypes.func,
  billablePayments: PropTypes.array,
  clearInvoiceDataModal: PropTypes.func,
  actionType: PropTypes.string,
  downloadInvoices: PropTypes.func,
  printInvoices: PropTypes.func,
  idsPrint: PropTypes.array,
};
