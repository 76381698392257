import React from "react";
import PropTypes from "prop-types";
import { Image, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BirthdayModal = (props) => {
  const prefix = process.env.REACT_APP_PREFIX;
  const orgInfoName = `cmOrganizationInfo${prefix}`;
  const orgInfo = JSON.parse(localStorage.getItem(orgInfoName));
  const [t] = useTranslation(["global"]);
  // Default optional props
  const {
    congratulatoryTitle = t("birthdayModal.happyBirthday"),
    congratulatoryImage = {
      alt: "birthday-cake-gif",
      src: "/img/birthday-cake.gif",
      width: "600",
      height: "350",
      loading: "lazy",
    },
    congratulatoryMessage = t("birthdayModal.birthdayMessage", {
      name: orgInfo?.name,
    }),
    birthdayFarewellMessage = t("birthdayModal.birthdayFarewellMessage"),
    imageOrganization = {
      alt: "organization-logo",
      src: orgInfo?.logo_image_url,
      width: "100",
      height: "100",
      loading: "lazy",
    },
  } = props;
  return (
    <Modal
      show={props.showBirthdayModal}
      onHide={props.toggleBirthdayModal}
      size="xl"
    >
      <Modal.Header
        id="right-close-button"
        className="d-block"
        closeButton
        style={{ padding: "1rem 1rem 0 1rem" }}
      >
        <div
          style={{
            color: "#ea2c54",
            fontSize: "40px",
            fontWeight: "700",
            textAlign: "center",
            lineHeight: "1.42857143",
          }}
        >
          {congratulatoryTitle}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {props.birthdayBoys && (
            <h2
              className="mtop0 mbottom20"
              style={{ fontSize: "26px", lineHeight: "1.1" }}
            >
              <ul className="ps-0">
                {props.birthdayBoys.map((person) => {
                  return (
                    <li
                      className="mbottom10"
                      key={person.id}
                      style={{ listStyle: "none" }}
                    >
                      {person.name}
                    </li>
                  );
                })}
              </ul>
            </h2>
          )}
          {congratulatoryImage && (
            <div style={{ textAlign: "-webkit-center" }}>
              <Image fluid {...congratulatoryImage} />
            </div>
          )}
          {congratulatoryMessage && (
            <h5 className="mtop10 mbottom20 ps-5 pe-5 fw-normal">
              {congratulatoryMessage}
            </h5>
          )}
          {birthdayFarewellMessage && (
            <h5 className="mtop10 mbottom20 fw-bold">
              {birthdayFarewellMessage}
            </h5>
          )}
          {imageOrganization && (
            <Image fluid className="thumbnail-image" {...imageOrganization} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
BirthdayModal.propTypes = {
  showBirthdayModal: PropTypes.bool.isRequired,
  toggleBirthdayModal: PropTypes.func.isRequired,
  birthdayBoys: PropTypes.array.isRequired,
  congratulatoryTitle: PropTypes.string,
  congratulatoryImage: PropTypes.string,
  congratulatoryMessage: PropTypes.string,
  birthdayFarewellMessage: PropTypes.string,
  imageOrganization: PropTypes.string,
};
export default BirthdayModal;
