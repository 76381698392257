import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalSuccessArchived from "./ModalSuccessArchived";
/*Services */
import { ChangeStatusLead, ArchivedProspects } from "../../api/Crm";

/** Elements */
import ContentButton from "../../components/global/ContentButton";
//Custom Hooks components
import TextLoading from "../../components/global/TextLoading";
export default function ModalChangeStatusLead(props) {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["crm", "global"]);
  const [prospectIds, setProspectIds] = useState([]);
  const [isSameStatus, setIsSameStatus] = useState(false);
  const [loadChangeStatus, setLoadChangeStatus] = useState(false);
  // Success archived modal Hooks
  const [showModalSuccessArchived, setShowModalSuccesArchived] =
    useState(false);
  const [countArchived, setCountArchived] = useState(0);
  const [countActives, setCountActives] = useState(0);
  /**
   * Description: Update status lead
   */
  const changeStatus = () => {
    if (props.statusToChange == "archived") {
      archivedLeads();
      return;
    }
    //if any leads have the same status, a warning modal will be displayed
    if (isSameStatus) {
      props.setShowModalOperationNotAllowed(true);
      props.setShowModalChangeStatusLead(false);
    } else {
      //otherwise the status of the prospect will be changed
      let request = {
        prospect_ids: prospectIds,
        status: props.statusToChange,
      };
      //Loading for change status
      setLoadChangeStatus(true);
      ChangeStatusLead(request)
        .then(() => {
          const cycleStorage = localStorage.getItem(`cycleCrmStorage${prefix}`);
          props.setShowModalChangeStatusLead(false);
          props.listProspect(cycleStorage);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          setLoadChangeStatus(false);
        });
    }
  };
  /**
   * Function to archive leads from table view
   */
  const archivedLeads = () => {
    const activesLeads = props.selectedRowIds.filter(
      (item) =>
        item.original.status === "Estudiante" ||
        item.original.status === "Student"
    );
    const toArchived = props.selectedRowIds.filter(
      (item) =>
        item.original.status !== "Estudiante" &&
        item.original.status !== "Student"
    );
    setCountArchived(toArchived.length);
    setCountActives(activesLeads.length);
    let prospectIds = toArchived.map((item) => {
      return item.original.id;
    });
    const request = {
      prospect_ids: prospectIds,
      status: "archived",
    };
    ArchivedProspects(request).then(() => {
      const cycleStorage = localStorage.getItem(`cycleCrmStorage${prefix}`);
      props.listProspect(cycleStorage);
      props.setShowModalChangeStatusLead(false);
      setShowModalSuccesArchived(true);
    });
  };

  /**
   * Description: Close the  modal
   */
  const cancel = () => {
    props.setShowModalChangeStatusLead(false);
  };

  /**
   * Purpose: Gets the id's of the selected prospects to change status
   */
  useEffect(() => {
    let prospectIds = [];
    let isSameStatus = false;
    let statusProspect = t(
      props.statusToChange === "archived"
        ? "tableCrm.textArchived"
        : "tableCrm.textContact"
    );
    //If at least one checkbox from the list is selected
    if (props.selectedRowIds.length && props.selectedRowIds[0].original) {
      //to find out if selected prospects can change their status
      isSameStatus = props.selectedRowIds.some(
        (prospect) => prospect.original.status === statusProspect
      );
      //save the ids in prospectIds
      for (let i = 0; i < props.selectedRowIds.length; i++) {
        prospectIds.push(props.selectedRowIds[i].original.id);
      }
    }
    //update the state
    setProspectIds(prospectIds);
    setIsSameStatus(isSameStatus);
  }, [props.selectedRowIds, props.statusToChange]);

  return (
    <>
      <Modal
        show={props.showModalChangeStatusLead}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">
            {t(
              props.statusToChange === "archived"
                ? "crm:modalChangeStatus.archiveTitle"
                : "crm:modalChangeStatus.contactTitle"
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            props.statusToChange === "archived"
              ? "crm:modalChangeStatus.archiveText"
              : "crm:modalChangeStatus.contactText"
          )}
        </Modal.Body>
        <Modal.Footer>
          <ContentButton className="content-button" disabled={loadChangeStatus}>
            <Button variant="outline-secondary" onClick={cancel}>
              {t("global:buttons.cancel")}
            </Button>
            <Button onClick={changeStatus} disabled={loadChangeStatus}>
              {loadChangeStatus ? (
                <TextLoading
                  text={t("global:buttons.changing")}
                  variant="light"
                />
              ) : (
                t("global:buttons.accept")
              )}
            </Button>
          </ContentButton>
        </Modal.Footer>
      </Modal>
      <ModalSuccessArchived
        showModalSuccessArchived={showModalSuccessArchived}
        setShowModalSuccesArchived={setShowModalSuccesArchived}
        countArchived={countArchived}
        countActives={countActives}
      />
    </>
  );
}

ModalChangeStatusLead.propTypes = {
  showModalChangeStatusLead: PropTypes.bool,
  setShowModalChangeStatusLead: PropTypes.func,
  statusToChange: PropTypes.string,
  selectedRowIds: PropTypes.array,
  setShowModalOperationNotAllowed: PropTypes.func,
  listProspect: PropTypes.func,
};
