import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";

let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Gets the response from the event data
 * @param {Object} data
 * @returns
 */
export const GetEvents = async (data) => {
  data.token = localStorage.getItem(`cmToken${prefix}`);
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;

  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/apps/home`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Get information about the type of user
 * @param {Object} data
 * @returns
 */
export const GetUserRelativeStudent = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/users`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Bring payments made
 * @param {Object} data
 * @returns
 */
export const GetCompletedPayments = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/completed_payments`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Bring payments due
 * @param {Object} data
 * @returns
 */
export const GetExpirationPayments = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/overdue_payments`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Obtains student input and output data
 * @param {Object} data
 * @returns
 */
export const GetAccessLog = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/relative_student_acces_log`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
  }
};

/**
 * Gets the votes for each service
 * @param {Object} data
 * @returns
 */
export const VoteServices = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/users/user_vote_service`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
  }
};
