import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ExcellentExport from "excellentexport";
import ReactToPrint from "react-to-print";

import {
  Col,
  Container,
  Row,
  Button,
  Dropdown,
  Tooltip,
  OverlayTrigger,
  Badge,
} from "react-bootstrap";

import { GetOrganization, DeleteOrganizations } from "../../api/Organizations";

//componentes
import ModalCreateOrganization from "./ModalCreateOrganization";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import ContentTable from "../table/ContentTable";
import ContentSolid from "../global/ContentSolid";
import SelectFilter from "../table/SelectFilter";
import { Table } from "../table/Table";
import { goTo, goToHref } from "../global/GlobalTools";
import ModalConfirmDeletedRecords from "../global/modals/ModalConfirmDeletedRecords";
import { MainContext } from "../../App";

//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

//Record reporting options
const actionOptions = [
  { value: "list", label: "global:buttons.downloadPDF" },
  { value: "excel", label: "global:buttons.downloadExcel" },
];

const OrganizationsView = () => {
  const componentRef = useRef(); //Create the reference for printing
  const [t] = useTranslation(["global", "organizations", "crm"]);
  const { permissions } = useContext(MainContext);
  const [loadingView, setLoadingView] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [showModalCreateOrganization, setShowModalCreateOrganization] =
    useState(false);
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [orgsToDelete, setOrgsToDelete] = useState([]);
  const [disabledInactive, setDisabledInactive] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  const tooltipInactive = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("organizations:view.textDisabledBtn")}
      </Tooltip>
    );
  };

  /**
   * table columns
   */
  const columns = React.useMemo(
    () => [
      {
        Header: t("organizations:view.tableColName"),
        accessor: "name",
        width: 500,
      },
      {
        Header: t("organizations:view.tableColStatus"),
        accessor: "active",
        width: 450,
        Filter: (props) => (
          <SelectFilter defaultValues={statusOptions} {...props} />
        ),
        Cell: ({ cell: { value } }) => {
          return (
            <div>
              {value == "active" ? (
                <Badge pill bg="success">
                  {" "}
                </Badge>
              ) : (
                <Badge pill bg="danger">
                  {" "}
                </Badge>
              )}{" "}
              {value == "active"
                ? t("crm:tableCrm.textActive")
                : t("crm:tableCrm.textInactive")}
            </div>
          );
        },
      },
      {
        Header: t("organizations:view.tableColEmail"),
        accessor: "email",
        width: 600,
      },
    ],
    [loading]
  );

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => organizations, [loading]);

  /**
   * Options to export to excel
   */
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("organizations:view.organizationReport"),
  };

  /**
   * Export to Excel
   * @param {id} exportOptions
   * @param {id} exportData
   */
  const downloadFile = (exportOptions, exportData) => {
    ExcellentExport.convert(exportOptions, exportData);
  };

  /**
   * Function to fetch information to be displayed
   */
  const listOrganizations = () => {
    GetOrganization({ plan_type_id: 9 }).then((result) => {
      let data = result.data.data;
      setCount(data.length);
      let organizations = [];
      organizations = data.map((item) => {
        return {
          id: item.id,
          index: item.id,
          name: item.name || "N/A",
          email: item.email || "N/A",
          active: item.active == 1 ? "active" : "inactivo",
        };
      });

      setStatusOptions([
        {
          value: "active",
          label: t("crm:tableCrm.textActive"),
          id: "active",
        },
        {
          value: "inactivo",
          label: t("crm:tableCrm.textInactive"),
          id: "inactivo",
        },
      ]);

      setOrganizations(organizations);
      setLoading(false);
    });
  };

  /**
   * Function to call the service to delete records
   */
  const deleteOrganizations = () => {
    const request = {
      organization_ids: orgsToDelete.map((item) => item.id),
    };
    DeleteOrganizations(request).then(() => {
      setLoading(true);
      listOrganizations();
      setShowModalConfirmDeleted(false);
    });
  };

  /**
   * Function that displays modal to confirm deletion of records
   */
  const alertToDelete = () => {
    const selectedOrgRowIds = [...selectedRowIds];
    const deleteOrganizations = selectedOrgRowIds.map((org) => {
      return {
        id: org.original.id,
        name: org.original.name,
      };
    });
    setOrgsToDelete(deleteOrganizations);
    setShowModalConfirmDeleted(true);
  };

  /**
   *Redirect to version 1
   * @param {string} path
   */
  const goApp1 = (path) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/${path}`);
  };

  /**
   * Select the entire row and enter the profile redirecting to version 1
   * @param {string} organization
   */
  const rowOnclick = (organization) => {
    goApp1("admin/organization/" + organization.id);
  };

  /*
   *Effect to know which ones are active or inactive
   * */
  useEffect(() => {
    let disabledInactive = false;
    if (selectedRowIds.length) {
      const sample = "active";
      const isValid = selectedRowIds.every(
        (item) => item.original.active == sample
      );
      if (!isValid) {
        disabledInactive = true;
      }
    }
    setDisabledInactive(disabledInactive);
  }, [selectedRowIds]);

  /**
   * Initial loading
   */
  useEffect(() => {
    listOrganizations();
    setTimeout(() => {
      setLoadingView(false);
    }, 2000);
  }, []);

  return permissions.admin_organizations.access ? (
    <Container fluid>
      <Row className="mb-3">
        <Col md={6}>
          {!!selectedRowIds.length && (
            <Fragment>
              {disabledInactive ? (
                <OverlayTrigger placement="top" overlay={tooltipInactive}>
                  <Button variant="primary" style={{ opacity: "30%" }}>
                    {t("organizations:view.inactivate")}
                  </Button>
                </OverlayTrigger>
              ) : (
                <Button
                  variant="primary"
                  onClick={alertToDelete}
                  disabled={disabledInactive}
                >
                  {t("organizations:view.inactivate")}
                </Button>
              )}
            </Fragment>
          )}
          {/* Total counter of records and selected records in Organizations table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col md={6}>
          <Button
            className="float-end ms-3 "
            variant="primary"
            size="lg"
            onClick={() => setShowModalCreateOrganization(true)}
          >
            {t("organizations:view.createOrganization")}
          </Button>
          <Dropdown className="float-end" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="dropdownReportCards"
              variant="outline-secondary"
              disabled={printExport}
            >
              <i className="bi bi-download"></i>
              {t("global:buttons.download")}
            </Dropdown.Toggle>
            <Dropdown.Menu disabled={printExport}>
              {actionOptions.map((action) => {
                return action.value == "list" ? (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => componentRef.current}
                  />
                ) : (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => downloadFile(exportOptions, exportData)}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {loadingView ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable startColumFilter={1} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            setExportData={setExportData}
            setPrintExport={setPrintExport}
            rowSelect={true}
            rowOnclick={rowOnclick}
            setSelectedRowIds={setSelectedRowIds}
            limitRowsShown={50}
          />
        </ContentTable>
      )}

      {/* Modals */}
      {showModalCreateOrganization && (
        <ModalCreateOrganization
          showModalCreateOrganization={showModalCreateOrganization}
          setShowModalCreateOrganization={setShowModalCreateOrganization}
          updateList={listOrganizations}
        />
      )}
      {showModalConfirmDeleted && (
        <ModalConfirmDeletedRecords
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          confirmAction={deleteOrganizations}
          items={orgsToDelete}
          title={t("organizations:messageModal.titleModalDelete")}
          message={
            orgsToDelete.length +
            " " +
            t("organizations:messageModal.textDelete")
          }
        />
      )}
    </Container>
  ) : (
    goTo("/dashboard")
  );
};

OrganizationsView.propTypes = {
  cell: PropTypes.object,
};
export default OrganizationsView;
