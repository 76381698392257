import React, { useEffect, useState } from "react";
import { Formik, Form /*, useField*/ } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";

/** Services */
import { SaveReminder } from "../../../api/Reminder";

/** Elements */
import FieldInput from "../../../components/global/form/FieldInput";
import ContentButton from "../../../components/global/ContentButton";
import TextLoading from "../../../components/global/TextLoading";

export default function ModalConfigurePaymentReminder(props) {
  const [t] = useTranslation(["reminder", "global"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Purpose: Save the reminder
   * @param {*} values
   */
  const saveReminder = (values) => {
    // Converting the first letter to uppercase
    values.description =
      values.description.charAt(0).toUpperCase() + values.description.slice(1);
    setIsSaving(true);
    props.setIsSavingReminder(true);
    SaveReminder(values).then(() => {
      props.setShowConfigureReminder(false);
      props.setIsSavingReminder(false);
      props.setShowSendReminder(props.reopenModalSendReminder);
      setIsSaving(false);
    });
  };

  /**
   * Purpose: Close te modal
   */
  const closeModal = () => {
    props.setShowConfigureReminder(false);
    props.setShowSendReminder(props.reopenModalSendReminder);
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Modal
        show={props.showConfigureReminder}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">
            {t("configureReminder.title")}
            <p>{t("configureReminder.subTitle")}</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body mbottom10">
          <div>
            <Formik
              initialValues={{
                title: props.reminder.title,
                description: props.reminder.description,
              }}
              validationSchema={Yup.object({
                title: Yup.string().required(
                  t("configureReminder.requiredTitle")
                ),
                description: Yup.string().required(
                  t("configureReminder.requiredDescription")
                ),
              })}
              onSubmit={(values) => saveReminder(values)}
            >
              <Form>
                <FieldInput
                  label={t("configureReminder.fieldTitle")}
                  name="title"
                  type="text"
                  placeholder={t("configureReminder.placeholderTitle")}
                />

                <FieldInput
                  label={t("configureReminder.fieldDescription")}
                  name="description"
                  type="text"
                  placeholder={t("configureReminder.placeholderDescription")}
                  as="textarea"
                  rows={3}
                />

                <ContentButton className="content-button">
                  <Button
                    variant="outline-secondary"
                    onClick={() => closeModal()}
                    disabled={isSaving}
                  >
                    {t("global:buttons.cancel")}
                  </Button>
                  <Button variant="primary" type="submit" disabled={isSaving}>
                    {isSaving ? (
                      <TextLoading
                        text={t("global:buttons.saving")}
                        variant="light"
                      />
                    ) : (
                      t("global:buttons.save")
                    )}
                  </Button>
                </ContentButton>
              </Form>
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ModalConfigurePaymentReminder.propTypes = {
  showConfigureReminder: PropTypes.bool,
  setShowConfigureReminder: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  reminder: PropTypes.object,
  isSavingReminder: PropTypes.bool,
  setIsSavingReminder: PropTypes.func,
  reopenModalSendReminder: PropTypes.bool,
  setShowSendReminder: PropTypes.func,
  handleBlur: PropTypes.func,
};
