import axios from "axios";
import { ErrorResponse, validateErrorResponse } from "./AxiosExtension";
import * as moment from "moment";

let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Get the debit records
 * @param {Object} data
 * @returns
 */
export const GetDebts = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/payments/debts`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get payment records
 * @param {Object} data
 * @param {Number} limit -Set to 0 so that it returns all always
 * @returns
 */
export const GetPayments = async (data = {}, limit = 0) => {
  axiosOptions = {};
  data.limit = limit;
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Generates the PDF of the payment receipts selected
 * @param {Array} paymentIds Ids of payment receipts
 * @param {String} nameFile Name to be assigned to the PDF file
 * @param {Boolean} justDownload Values: true = Downloads only the file, false = Downloads the file and followed by the download opens it in the browser
 * @returns
 */
export const GetPdfReceipts = async (paymentIds) => {
  axiosOptions = {};
  let sendData = {};
  sendData.payment_ids = paymentIds;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/payments/print_multiple_receipt`;
  axiosOptions.params = sendData;
  axiosOptions.responseType = "blob";

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Returns the object with unbilled payments.
 * @param {object} data
 * @returns
 */
export const UnbilledPayments = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/payments/not_billed`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Generate multiple invoices in pdf format with confirmation code
 * @param {Object} data
 * @returns
 */
export const printMultipleInvoice = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/invoices/print_multiple`;
  axiosOptions.responseType = "blob";
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response;
  }
};

/**
 * Purpose: Generates bulk billing of selected unbilled payments.
 * @param {Object} data
 * @returns
 */
export const multipleBilling = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/invoices/public`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Download the .zip file with the invoices generated from the payments.
 * @param {Object} data
 * @returns
 */
export const DownloadMultiple = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "application/zip",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/invoices/download_multiple`;
  axiosOptions.responseType = "blob";
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response;
  }
};

/**
 * Purpose:  Download the .zip file with the receipts generated from the payments.
 * @param {Array} paymentIds
 * @returns
 */
export const DownloadMultipleReceipts = async (paymentIds) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "application/zip",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/download_zip`;
  axiosOptions.responseType = "blob";
  axiosOptions.params = { payment_ids: paymentIds };

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the data from the income view
 * @param {Array} data
 * @returns
 */
export const GetIncome = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/income`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the data from the categories
 * @param {Array} values params
 * @returns API response
 */
export const GetCategories = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_categories`;
  axiosOptions.params = values;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Get the data from the sub concepts
 * @param {Array} values params
 * @returns API response
 */
export const GetSubConcetps = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/products`;
  axiosOptions.params = values;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Get the data from the Subconcepts by Concept IDs
 * @param {Array} values params
 * @returns API response
 */
export const GetSubConcetpsByMultipleConceptIds = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/products/multiple_concepts`;
  axiosOptions.params = values;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Save restriction settings
 * @param {Object} data
 * @returns {Object} API response - Data restriction settings
 */
export const saveDebtsRestrictions = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organizations/debts_restrictions`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get restriction settings
 * @param {Object} values Params filters
 * @returns {Object} API response - Data restriction settings
 */
export const GetDebtsRestrictions = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organizations/debts_restrictions`;
  axiosOptions.params = values;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Save manual restriction settings
 * @param {Object} data
 * @returns {Object} API response - Data restriction settings
 */
export const saveManulDebtsRestrictions = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/debts_restrictions`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get Users Debts Restriction
 * @param {Object} values Params filters
 * @returns {Object} API response - Data User Filters
 */
export const GetUserGetDebtsRestrictions = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/debts_restrictions/get_debt_restrictions`;
  axiosOptions.params = values;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Get the SAP report for the current cycle day
 * @returns dowload csv file
 */
export const DownloadSapRepot = async (payload, closeModal) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = payload;
  axiosOptions.responseType = "blob";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/sap_report_csv`;

  try {
    const response = await axios(axiosOptions);
    if (response.status == 200) {
      const today = moment().format("DDMM");
      const nameFile = "PC" + today + "SP - Póliza de corte.csv";
      let url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile);
      document.body.appendChild(link);
      link.click();
      closeModal({ showModalReportSAP: false, });

    }
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the SAP monthly report
 * @returns
 */
export const DownloadSapRepotMonth = async (payload, modalConfirm) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = payload;
  axiosOptions.responseType = "blob";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/cartera_de_cobranza_csv`;

  try {
    const response = await axios(axiosOptions);
    if (response.status == 200) {
      modalConfirm(true);
    }
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the SAP report for the current cycle
 * @returns 
 */
export const GetSapReport = async (payload) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = payload;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/sap_report_txt`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Get the SAP report for the current cycle
 * @returns 
 */
export const GetSapReportMonth = async (payload) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = payload;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/payments/poliza_corte_mensual`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};


