import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, FormGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/*Services */
import { AddSections } from "../../api/permissions";

export default function ModalEditSections(props) {
  const [t] = useTranslation(["permissions", "crm", "global"]);
  const [sections, setSections] = useState([]);
  const [showNewSection, setShowNewSection] = useState(false);
  const [newSection, setNewSction] = useState({
    section: "",
    text: "", 
  });

  /**
   * Change input value
   * @param {Obj} event
   * @param {Id} index
   */
  const changeValueSection = (event, index) => {
    let updateSection = [...sections];
    let value = event.target.value;
    updateSection[index].text = value;
    setSections(updateSection);
  };

  /**
   * It is used to update the input and know which one brings id
   */
  const UpdateSections = () => {
    let dataSections = [];
    for (let item of sections) {
      //Agregar nuevas secciones
      if (!item.id) {
        dataSections.push({
          text: item.text,
        });
      }
      if (item.id && item.edited) {
        dataSections.push({ id: item.id, text: item.text });
      }
    }
    let obj = {
      sections: dataSections,
    };
    AddSections(obj).then((result) => {
      props.setSections(result.data.data);
      setShowNewSection(false);
      props.setShowModalEditSections(false);
    });
  };

  /**
   * It is used to add a new section and also to update and clean it.
   */
  const AddNewSection = () => {
    let updateSection = [...sections];
    updateSection.unshift(newSection);
    setSections(updateSection);
    setNewSction({
      section: "",
      text: "",
    });
    setShowNewSection(false);
  };

  /**
   * Used to add a new section
   * @param {Obj} event
   */
  const onChangeNewSection = (event) => {
    let section = { ...newSection };
    let value = event.target.value;
    section.text = value;
    let code = value;
    //Replace "ñ" with "n" and "tildes".
    code = code.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //Replaces special characters
    code = code.replace(/[^a-zA-Z ]/g, "");
    //Replaces spaces with underscores
    code = code.replace(/\s+/g, "_");
    //Returns the lowercase value of the calling string.
    section.name = code.toLowerCase();
    setNewSction(section);
  };

  /**
   * Blur the selected position
   * @param {Id} position
   */
  const handleOnBlur = (position) => {
    let updateSection = [...sections];
    updateSection[position].edited = true;
    updateSection[position].editing = false;
    setSections(updateSection);
  };

  /**
   * Focus on the selected position
   * @param {Id} position
   */
  const handleOnFocus = (position) => {
    let updateSection = [...sections];
    updateSection[position].editing = true;
    setSections(updateSection);
  };

  useEffect(() => {
    let sections = [];
    //remove dependency of subobjects
    for (let item of props.sections) {
      let obj = JSON.parse(JSON.stringify(item));
      sections.push(obj);
    }
    setSections(sections);
  }, [props.sections]);

  return (
    <Modal
      show={props.showModalEditSections}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("titlesModals.titleSections")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} style={{ height: "500px", overflow: "auto" }}>
            {sections.map((section, index) => {
              return (
                <div className="mt-2" key={index}>
                  <FormGroup>
                    <div
                      className="inner-icon right-addon input-group"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type="text"
                        id="inputText"
                        placeholder={t("crm:tableCrm.name")}
                        value={section.text}
                        onChange={(e) => changeValueSection(e, index)}
                        style={{ cursor: "pointer" }}
                        onBlur={() => handleOnBlur(index)}
                        onFocus={() => handleOnFocus(index)}
                      />
                      {section.editing && (
                        <i
                          className="bi bi-check2"
                          style={{
                            position: "absolute",
                            padding: "10px",
                            pointerEvents: "none",
                            right: "5px",
                            zIndex: "3",
                          }}
                        ></i>
                      )}
                    </div>
                  </FormGroup>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-4">
            {showNewSection ? (
              <div>
                <Row>
                  <b>{t("textModals.newSection")}</b>
                </Row>
                <Row className="mt-2">
                  <Col md={12}>
                    <Form.Control
                      type="text"
                      id="inputText"
                      placeholder={t("crm:tableCrm.name")}
                      value={newSection.text}
                      onChange={onChangeNewSection}
                      onKeyDown={(e) => {
                        if (e.keyCode == 13 && newSection.text != "") {
                          AddNewSection();
                        }
                      }}
                    />
                    <i
                      className="bi bi-arrow-return-left"
                      style={{
                        position: "absolute",
                        padding: "10px",
                        pointerEvents: "none",
                        right: "15px",
                        marginTop: "-39px",
                      }}
                    ></i>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col md={12}>
                  <Button
                    id="btnAddGroupRol"
                    variant="primary"
                    onClick={() => setShowNewSection(true)}
                  >
                    {t("textModals.addSection")}
                    <i
                      className="bi bi-plus"
                      style={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        verticalAlign: "sub",
                      }}
                    ></i>
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="btnCancelEditSection"
          variant="outline-secondary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            props.setShowModalEditSections(false);
            setShowNewSection(false);
          }}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button
          id="btnSaveEditUser"
          variant="primary"
          style={{ marginRight: "10px" }}
          onClick={() => UpdateSections()}
        >
          {t("global:buttons.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalEditSections.propTypes = {
  showModalEditSections: PropTypes.bool,
  setShowModalEditSections: PropTypes.func,
  sections: PropTypes.array,
  setSections: PropTypes.func,
};
