import React, { useContext } from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import { Row, Col, Button, Badge } from "react-bootstrap";
import { MainContext } from "../../App";
import { useEffect } from "react";
import { ReadNotifications } from "../../api/Notifications";
import Cookies from "js-cookie";
/**
 * Purpose: Muestra las notificaciones en el popover del menu
 * @param {*} props
 * @returns
 */
export default function NotificationsPopover(props) {
  const { permissions } = useContext(MainContext);
  let notificationsSent = props.notifications.sent;
  let notificationsScheduled = props.notifications.scheduled;
  const notificationStyles = {
    date: {
      fontSize: "xx-small",
      paddingTop: "5px",
      color: "#979998",
    },
    title: {
      fontSize: "12px",
      color: "#1E1E1E",
    },
  };

  useEffect(() => {
    if (props.unreadNotificationIds?.length) {
      viewNotifications();
    }
  }, []);

  /**
   * Function to change read 1 notifications only if there are new ones
   */
  const viewNotifications = () => {
    const request = {
      user_id: Cookies.get(`cmUserID${props.prefix}`),
      notification_ids: props.unreadNotificationIds,
      read: 1,
    };
    ReadNotifications(request).then((result) => {
      if (result.data) {
        props.setUnreadNotificationIds([]);
      }
    });
  };

  return (
    <div className="popover-notifications">
      <Row style={{ marginBottom: "20px" }}>
        {permissions.notifications.create && (
          <Col sm={6} xs={6}>
            <Button
              variant="outline-secondary"
              onClick={() => props.goToHref(`/notifications?send`)}
            >
              {props.t("popoverNotification.sendNotification")}
            </Button>
          </Col>
        )}
        {permissions.notifications.access && (
          <Col sm={6} xs={6}>
            <Button
              variant="outline-secondary"
              onClick={() => props.goToHref(`/notifications`)}
              style={{ float: "right" }}
            >
              {props.t("popoverNotification.seeNotifications")}
            </Button>
          </Col>
        )}
      </Row>
      {notificationsSent.length != 0 ||
      notificationsScheduled.length != 0 ||
      permissions.notifications.access ? (
        <div>
          {permissions.notifications.create || permissions.notifications.edit
            ? notificationsScheduled.map(function (notification, index) {
                return (
                  <Row
                    key={index}
                    style={{
                      borderBottom: "1px solid #c6c6c6",
                      padding: "10px 0 10px 0px",
                    }}
                    className="over-list over-list-click"
                    onClick={() => props.goToHref(`/notifications`)}
                  >
                    <Col xs={12} style={notificationStyles.title}>
                      <Row>
                        <Col sm={9}>
                        <b>{notification.title}</b>
                        </Col>
                        <Col sm={3}>
                          {notification.type_user || "" }
                        </Col>
                      </Row>
                      <p>{notification.text}</p>
                    </Col>
                    <Col sm={6} style={notificationStyles.date}>
                      {moment(notification.send_date).format(
                        "MM/DD/YY hh:mm a"
                      )}
                    </Col>
                    <Col sm={6}>
                      <Badge bg="warning" style={{ float: "right" }}>
                        {props.t("popoverNotification.scheduled")}
                      </Badge>
                    </Col>
                  </Row>
                );
              })
            : ""}
        </div>
      ) : (
        ""
      )}
      {notificationsSent.map(function (notification, index) {
        return (
          <Row
            key={index}
            style={{
              borderBottom: "1px solid #c6c6c6",
              padding: "10px 0 10px 0px",
            }}
            className="over-list over-list-click"
            onClick={() =>
              props.goToHref(
                `/notifications?msg&cmUserID${localStorage.getItem(
                  localStorage.getItem(`prefix`)
                )}&${notification.id}`
              )
            }
          >
            <Col xs={12} style={notificationStyles.title}>
              <Row>
                <Col sm={9}>
                <b>{notification.title}</b>
                </Col>
                <Col sm={3}>
                  {notification.type_user || "" }
                </Col>
              </Row>
              <p>{notification.text}</p>
            </Col>
            <Col sm={6} style={notificationStyles.date}>
              {moment(notification.send_date).format("MM/DD/YY hh:mm a")}
            </Col>
            <Col sm={6}>
              <Badge bg="success" style={{ float: "right" }}>
                {props.t("popoverNotification.sent")}
              </Badge>
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

NotificationsPopover.propTypes = {
  prefix: PropTypes.string,
  notifications: PropTypes.object,
  goToHref: PropTypes.func,
  t: PropTypes.func,
  unreadNotificationIds: PropTypes.array,
  setUnreadNotificationIds: PropTypes.func,
};
