import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetAccessLog } from "../../../../api/DashboardRelativeStudent";
import PropTypes from "prop-types";
import { goToHref } from "../../../global/GlobalTools";

const dummyData = [
  {
    time: " 09:44 AM",
    event: "Entrada",
    parent: "KEVIN M1",
    registered: "ADMIN-FERNANDO MORALES",
  },
];

const AccessModule = (props) => {
  const [t] = useTranslation([
    "dashboard",
    "global",
    "dashboardStudent",
    "table",
  ]);
  const [access, setAccess] = useState([]);
  const [loadingAccess, setLoadingAccess] = useState(true);
  const sizeContainerModule = props.heightListContainer / 1.4;
  const customStyles = {
    moduleContainerAccess: {
      background: "#ffffff",
      border: "1px solid #ececee",
      borderRadius: "8px",
      paddingTop: "10px",
      paddingBottom: "10px",
      minHeight: sizeContainerModule / 2,
    },
    rowContainer: {
      background: "#ffffff",
      border: "1px solid #ececee",
      borderRadius: "0px",
      height: "210px",
      marginBlock: "10px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    lazy: {
      height: "25px",
    },
    scrollClass: {
      height: sizeContainerModule + "px",
      overflowY: "auto",
      overflowX: "hidden",
      padding: " 5px 25px",
    },
  };

  const lazyLoadingClass = () => {
    return (
      <div>
        <Row className="table-lazyLoading mt-3">
          <Col md={12}>
            <div style={customStyles.lazy}></div>
          </Col>
        </Row>
        <Row className="table-lazyLoading mt-4">
          <Col md={8}>
            <div style={customStyles.lazy}></div>
          </Col>
          <Col md={8}>
            <div style={customStyles.lazy}></div>
          </Col>
          <Col md={8}>
            <div style={customStyles.lazy}></div>
          </Col>
          <Col md={8}>
            <div style={customStyles.lazy}></div>
          </Col>
          <Col md={8}>
            <div style={customStyles.lazy}></div>
          </Col>
          <Col md={8}>
            <div style={customStyles.lazy}></div>
          </Col>
        </Row>
        <Row className="table-lazyLoading mt-4">
          <Col md={4} className="offset-md-8">
            <div style={customStyles.lazy}></div>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Gets student input and output data
   */
  const getAccessLog = () => {
    setLoadingAccess(true);
    let request = {
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetAccessLog(request).then((result) => {
      if (result.status) {
        const data = result.data;
        let accessLog = [];
        accessLog = data.map((item) => {
          return {
            time: item.time,
            event: item.event_name,
            parent: item.parent_name,
            registered: item.registered_by,
            studentName: item.student_name,
            studentId: item.student_id,
          };
        });
        setAccess(accessLog);
        setLoadingAccess(false);
      }
    });
  };

  /**
   * initial effect of a single load
   */
  useEffect(() => {
    if (props.active == false) {
      setAccess(dummyData);
      setLoadingAccess(false);
    } else {
      getAccessLog();
    }
  }, []);

  return (
    <Row>
      {loadingAccess ? (
        lazyLoadingClass()
      ) : (
        <Col md={12} style={customStyles.moduleContainerAccess}>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={3} className="text-center pe-0">
                  <i
                    className="bi bi-person-check me-2"
                    style={{ color: "#58D68D", fontSize: "24px" }}
                  ></i>
                </Col>
                <Col md={9} className="mt-1 fs-6 ps-0">
                  <b>{t("textAccess.titleAccessLog")}</b>
                </Col>
              </Row>
            </Col>
          </Row>

          {access.length > 0 ? (
            <Row>
              <Col md={12} style={customStyles.scrollClass}>
                {access.map((item) => {
                  return (
                    <Row
                      key={item}
                      onClick={() =>
                        goToHref(`/estudiante/${item.studentId}?selectTab=9`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Col
                        style={customStyles.rowContainer}
                        xs={12}
                        md={12}
                        xl={12}
                        className="px-4 mb-3"
                      >
                        <Row>
                          <Col xs={12} sm={12} md={12}>
                            <Row className="mt-1">
                              {t("table:debtReport.student")}
                            </Row>
                            <Row className="opacity-50">{item.studentName}</Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={12} md={12}>
                            <Row className="mt-1">
                              {t("dashboardStudent:textToday.textEvent")}
                            </Row>
                            <Row className="opacity-50">{item.event}</Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={12} md={12}>
                            <Row className="mt-1">
                              {t("dashboardStudent:textToday.textTime")}
                            </Row>
                            <Row className="opacity-50"> {item.time} </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={12} md={12}>
                            <Row className="mt-1">
                              {t("dashboardStudent:textToday.textFamily")}
                            </Row>
                            <Row className="opacity-50"> {item.parent} </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={12} md={12}>
                            <Row className="mt-1">
                              {t("dashboardStudent:textToday.textScan")}
                            </Row>
                            <Row className="opacity-50 text-truncate">
                              {item.registered}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={12} className=" align-middle">
                <p className="text-center">{t("dashboard:labels.noData")}</p>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};

export default AccessModule;

AccessModule.propTypes = {
  studentId: PropTypes.number,
  heightListContainer: PropTypes.number,
  active: PropTypes.bool,
};
