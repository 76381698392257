import { React, useEffect, useState } from "react";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import PropTypes from "prop-types";

import {
  getScholarships,
  selectedScholarships,
} from "../../../api/Scholarship";

export default function ModalAssignScholarship(props) {
  const [t] = useTranslation(["scholarships"]);
  const [errors, setErrors] = useState(false);
  const [optionsScholarships, setOptionsScholarships] = useState([]);
  const [scholarshipsSelected, setScholarshipsSeleceted] = useState({});

  const getListScholarships = () => {
    let cycleId = props.cycleSelected?.id || null;
    const request = {
      school_cycle_ids: [cycleId],
      limit: 0,
    };
    getScholarships(request).then((result) => {
      const scholarshipsList = result.data;
      for (let item of scholarshipsList) {
        (item.value = item.id),
          (item.label =
            item.scholarship_name || t("viewScholarships.titleSinName"));
      }
      setOptionsScholarships(scholarshipsList);
    });
  };

  const asingScholarships = () => {
    let studentsIds = props.selectedRowIds.map(
      (item) => item.original.studentId
    );
    const request = {
      student_ids: studentsIds,
      scholarship_id: scholarshipsSelected.id,
    };
    selectedScholarships(request)
      .then((result) => {
        if (result.status) {
          props.getScholarshipStudents();
          props.setStudentAssignetComplete(result.data);
          props.setShowModalComplete(true);
          props.setShowModalAssign(false);
        } else {
          setErrors(result.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getListScholarships();
  }, []);

  return (
    <div>
      <Modal
        show={props.showModalAssign}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="pb-0">
          <Modal.Title className="modal-title">
            {t("viewScholarships.textAssignScholarship")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p>{t("textModal.bodyText")}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Select
                placeholder={t("textModal.placeholderSelect")}
                formatOptionLabel={function (data) {
                  return (
                    <span dangerouslySetInnerHTML={{ __html: data.label }} />
                  );
                }}
                options={optionsScholarships}
                onChange={(e) => {
                  setScholarshipsSeleceted(e);
                  setErrors(false);
                }}
              />
            </Col>
          </Row>
          {scholarshipsSelected.id && (
            <div>
              <Row className="mt-3">
                <Col
                  md={4}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <strong>{t("textModal.textBenefit")}</strong>
                </Col>
                <Col md={8}>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {scholarshipsSelected.scholarship_type || "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <strong>{t("textModal.textCycle")}</strong>
                </Col>
                <Col md={8}>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {scholarshipsSelected.school_cycle || "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <strong>{t("textModal.textCategory")}</strong>
                </Col>
                <Col md={8}>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {scholarshipsSelected.category_name || "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <strong>{t("textModal.textConcept")}</strong>
                </Col>
                <Col md={8}>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {scholarshipsSelected.concepts || "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <strong>{t("textModal.textSubconcept")}</strong>
                </Col>
                <Col md={8}>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {scholarshipsSelected.subconcepts || "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <strong>{t("viewScholarships.textPorcentage") + ":"} </strong>
                </Col>
                <Col md={8}>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {scholarshipsSelected.discount || "-"}
                  </p>
                </Col>
              </Row>
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            {errors ? (
              <Alert variant="warning">{errors.description}</Alert>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => props.setShowModalAssign(false)}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button variant="primary" onClick={() => asingScholarships()}>
            {t("viewScholarships.textAssignScholarship")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalAssignScholarship.propTypes = {
  showModalAssign: PropTypes.bool,
  setShowModalAssign: PropTypes.func,
  selectedRowIds: PropTypes.array,
  setStudentAssignetComplete: PropTypes.func,
  setShowModalComplete: PropTypes.func,
  getScholarshipStudents: PropTypes.func,
  cycleSelected: PropTypes.object,
};
