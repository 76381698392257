/**
 *       **** Readme ****
 *
 * Component: <FormCheck />
 * Purpose: Returns the checkbox element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React, { useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function FormCheck({ label, ...props }) {
  const [t] = useTranslation(["global"]);
  const [field] = useField(props);

  /**
   * Initial loading
   */
  useEffect(() => {}, []);

  return (
    <>
      <Form.Check
        label={label}
        placeholder={props.placeholder}
        className="for-element"
        {...field}
        {...props}
      />
      {props.required && props.showRequired ? (
        <span className="required">*</span>
      ) : !props.required && props.showRequired === false ? (
        <span className="optional">{t("optional")}</span>
      ) : (
        ""
      )}
    </>
  );
}

FormCheck.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
};
