//Library
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
//Services
import { CreateProductsMultiple } from "../../../api/StudentsReport";
//Custom Hooks components
import ContentButton from "../../global/ContentButton";
import TextLoading from "../../../components/global/TextLoading";
//Components
import ModalActionStudentsSuccess from "../../students/modals/ModalActionStudentsSuccess";

export default function ModalActionStudentsConfirmation(props) {
  const [t] = useTranslation(["student", "global"]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [someModalOpen, setSomeModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState({});
  const {
    open,
    toggleAddStudentChargesConfirmation,
    selectedRowIds,
    studentIds,
    numberCharges,
    setShowActionStudents,
    subconceptsId,
    isAllRowsSelected,
    students,
  } = props;

  //Functions

  /**
   * Function to add multiple charges
   */
  const addCharges = () => {
    const request = {
      product_ids: subconceptsId,
      student_ids: studentIds,
    };

    setIsLoading(true);
    CreateProductsMultiple(request)
      .then(() => {
        setSomeModalOpen(true);
        setShowSuccessModal(true);
        setIsLoading(false);
        setSuccessMessage({
          title: t("student:modalActionStudents.chargesAdded"),
          message: t("student:modalActionStudents.chargesAddedSuccess", {
            count: studentIds.length,
          }),
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <>
      <div>
        <Modal
          show={open}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg"
          contentClassName={someModalOpen ? "hide" : ""} //Hide this modal so the open one doesn't overlap
        >
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title text-center">
              {numberCharges === 1
                ? t("student:modalActionStudents.confirmationTitleSingular")
                : t("student:modalActionStudents.confirmationTitlePlural", {
                    numberCharges,
                  })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {numberCharges === 1
              ? t("student:modalActionStudents.confirmationTextSingular")
              : t("student:modalActionStudents.confirmationTextPlural", {
                  numberCharges,
                })}
            {isAllRowsSelected ? (
              <div className="mtop10">
                {t("student:modalActionStudents.allStudents", {
                  count: students.length,
                })}
              </div>
            ) : Math.floor(students.length / 2) < selectedRowIds.length ? (
              <div className="mtop10">
                {t("student:modalActionStudents.allStudentsExcept")}
                <div className="mtop10">
                  {students.map((row, index) => {
                    return !studentIds.find(
                      (studentId) => studentId === row.values.index
                    ) ? (
                      <div key={index}>{row.values.name}</div>
                    ) : null;
                  })}
                </div>
              </div>
            ) : (
              <div className="mtop10">
                {selectedRowIds.map((row, index) => {
                  return <div key={index}>{row.values.name}</div>;
                })}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {/**Conten buttons*/}
            <ContentButton className="content-button">
              <Button
                variant="outline-secondary"
                onClick={() => toggleAddStudentChargesConfirmation(false)}
              >
                {t("global:buttons.cancel")}
              </Button>
              <Button
                variant="primary"
                onClick={addCharges}
                disabled={isLoading}
              >
                {isLoading ? (
                  <TextLoading
                    text={t("global:buttons.textAdding")}
                    variant="light"
                  />
                ) : (
                  t("global:buttons.add")
                )}
              </Button>
            </ContentButton>
          </Modal.Footer>
        </Modal>
      </div>
      {showSuccessModal ? (
        <ModalActionStudentsSuccess
          open={showSuccessModal}
          setShowActionStudents={setShowActionStudents}
          title={successMessage.title}
          message={successMessage.message}
        />
      ) : null}
    </>
  );
}

ModalActionStudentsConfirmation.propTypes = {
  open: PropTypes.bool,
  toggleAddStudentChargesConfirmation: PropTypes.func,
  selectedRowIds: PropTypes.array,
  studentIds: PropTypes.array,
  numberCharges: PropTypes.number,
  cycleId: PropTypes.string,
  setShowActionStudents: PropTypes.func,
  subconceptsId: PropTypes.array,
  isAllRowsSelected: PropTypes.bool,
  students: PropTypes.array,
};
