import { goToHref } from "../components/global/GlobalTools";

/**
 * Validate error response from API service
 * @param {String} httpResponse
 */
export function validateErrorResponse(httpResponse) {
  if (httpResponse.data.error) {
    if (
      httpResponse.data.error.type &&
      httpResponse.data.error.type == "validation"
    ) {
      localStorage.removeItem(`cmToken${localStorage.getItem("prefix")}`);
      goToHref(`/login`);
    } else if (
      httpResponse.data.error.code &&
      httpResponse.data.error.code == "err_token_invalid"
    ) {
      localStorage.removeItem(`cmToken${localStorage.getItem("prefix")}`);
      goToHref(`/login`);
    }
  }
}

/**
 * Return error response from API service
 * @param {String} httpResponse
 */
export function ErrorResponse(httpResponse) {
  if (httpResponse.data?.error) {
    return httpResponse.data.error;
  }
}
