/**
 * firebase
 *
 * Purpose: Firebase configuration
 *
 * @author Marisol Torres <marisol@inclancommunications.com>
 * @date 08/03/2022 02:05:32 PM
 */

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/compat/app";
import "firebase/messaging";

// Your web app's Firebase configuration
const prodConfig = {
  apiKey: "AIzaSyAYayIdrb_IT1gs4M9TnT_YWk8oCVcSDZc",
  authDomain: "campus-movil-195e2.firebaseapp.com",
  databaseURL: "https://campus-movil-195e2.firebaseio.com",
  projectId: "campus-movil-195e2",
  storageBucket: "campus-movil-195e2.appspot.com",
  messagingSenderId: "642972289299",
  appId: "1:642972289299:web:ac257feb172169a88732e6",
  measurementId: "G-YLGNB0PZHK",
};

const devConfig = {
  apiKey: "AIzaSyAYayIdrb_IT1gs4M9TnT_YWk8oCVcSDZc",
  authDomain: "campus-movil-195e2.firebaseapp.com",
  databaseURL: "https://campus-movil-195e2.firebaseio.com",
  projectId: "campus-movil-195e2",
  storageBucket: "campus-movil-195e2.appspot.com",
  messagingSenderId: "642972289299",
  appId: "1:642972289299:web:ac257feb172169a88732e6",
  measurementId: "G-YLGNB0PZHK",
};

const config = window.location.href.includes("//app.campusmovil.com")
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(config);
}

export default firebase;
