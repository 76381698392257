import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ContentButton from "../../components/global/ContentButton";
import { ArchivedProspects } from "../../api/Crm";

export default function UnfileModal(props) {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["crm", "global"]);

  /**
   * Description: Close the  modal
   */
  const cancel = () => {
    props.setShowModalUnfile(false);
  };

  /**
   * Description: Unarchive Prospects
   */
  const unarchiveProspects = () => {
    let prospectIds = props.selectedRowIds.map((item) => {
      return item.original.id;
    });
    const request = {
      prospect_ids: prospectIds,
      status: "unarchived",
    };
    ArchivedProspects(request).then(() => {
      const cycleStorage = localStorage.getItem(`cycleCrmStorage${prefix}`);
      props.listArchivedProspect(cycleStorage);
      props.setShowModalUnfile(false);
    });
  };

  return (
    <Modal
      show={props.showModalUnfile}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">
          {t("modalUnfile.titleUnfile")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("modalUnfile.textBodyUnfile")}</Modal.Body>
      <Modal.Footer>
        <ContentButton className="content-button">
          <Button variant="outline-secondary" onClick={cancel}>
            {t("global:buttons.cancel")}
          </Button>
          <Button onClick={unarchiveProspects}>
            {t("global:buttons.accept")}
          </Button>
        </ContentButton>
      </Modal.Footer>
    </Modal>
  );
}

UnfileModal.propTypes = {
  showModalUnfile: PropTypes.bool,
  setShowModalUnfile: PropTypes.func,
  selectedRowIds: PropTypes.array,
  listArchivedProspect: PropTypes.func,
};
