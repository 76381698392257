import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import SeeAllModule from "./SeeAllModule";
import PropTypes from "prop-types";
import { GetProspect } from "../../../api/Dashboard";

const lazyLoading = () => {
  return (
    <Col>
      <Row className="table-lazyLoading">
        <Col md={4}>
          <div></div>
        </Col>
        <Col md={3} className="offset-md-5">
          <div></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={12}>
          <div style={{ height: "70px" }}></div>
        </Col>
      </Row>
    </Col>
  );
};

const customStyles = {
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "85px",
    marginBlock: "10px",
    marginLeft: "0px",
    marginRight: "0px",
  },
  rowLine: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    marginBlock: "10px",
    marginLeft: "0px",
    marginRight: "0px",
  },
};

const dummyData = [
  {
    candidates: 0,
    contacts: 140,
    converted: 5,
  },
];

const LeadsModule = (props) => {
  const [t] = useTranslation(["dashboard", "crm"]);
  const [data, setData] = useState([]);

  /**
   * Obtains the number of prospects with the school cycle
   */
  const getProspectDashboard = () => {
    let request = {
      school_cycle_id: props.filters.cycle?.id,
      school_level_id: props.filters.level?.id,
    };
    GetProspect(request).then((result) => {
      if (result.status) {
        let data = result.data;
        setData(data);
      }
      props.setWaitinData(false)
    });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.active == false) {
      setData(dummyData);
      props.setWaitinData(false);
      return;
    }
  }, [props.active]);
  useEffect(() => {
    if (!props.active) return;
    if (props.readyFilters && props.waitingData) {
      getProspectDashboard();
    }
  }, [props.readyFilters]);
  return (
    <Row>
      {props.waitingData ? (
        lazyLoading()
      ) : (
        <>
          <Col md={12}>
            <Row>
              <Col md={7}>
                <i className="bi bi-people me-2 fs-6"></i>
                <b className="fs-6">{t("prospectsText.titleProspects")}</b>
              </Col>
              <Col md={5}>
                <SeeAllModule path={"/crm/leads"}></SeeAllModule>
              </Col>
            </Row>
            <Row style={customStyles.rowContainer}>
              <Col md={12} style={{ marginTop: "10px" }}>
                <Row>
                  <Col md={4}>
                    <i
                      className="bi bi-telephone me-2"
                      style={{ color: "#73C6B6", fontSize: "25px" }}
                    ></i>
                    <b className="fs-3">{data.contacts}</b>
                  </Col>
                  <Col md={4}>
                    <i
                      className="bi bi-person me-2"
                      style={{ color: "#EB984E", fontSize: "25px" }}
                    ></i>
                    <b className="fs-3">{data.candidates}</b>
                  </Col>
                  <Col md={4}>
                    <i
                      className="bi bi-person-check me-2"
                      style={{ color: "#58D68D", fontSize: "25px" }}
                    ></i>
                    <b className="fs-3">{data.converted}</b>
                  </Col>
                </Row>
              </Col>
              <Col md={12} style={{ marginBottom: "20px" }}>
                <Row>
                  <Col md={4}>
                    <p className="fw-normal ms-4" style={{ fontSize: "12px" }}>
                      {t("prospectsText.textContact")}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="fw-normal ms-4" style={{ fontSize: "12px" }}>
                      {t("prospectsText.textCandidates")}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="fw-normal ms-4" style={{ fontSize: "12px" }}>
                      {t("prospectsText.textConverted")}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col md={12}>
            <Row style={customStyles.rowLine}></Row>
          </Col>
        </>
      )}
    </Row>
  );
};

export default LeadsModule;
LeadsModule.propTypes = {
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  filters: PropTypes.object,
  readyFilters: PropTypes.bool,
  active: PropTypes.bool,
};
