import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DeleteSubjects } from "../../../api/class";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import TextLoading from "../../global/TextLoading";

export default function ModalConfirmDeletedSubject(props) {
  const [t] = useTranslation(["global", "class"]);
  const [errors, setErrors] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Confirm Deleted Subject Modal");
  }, []);

  /**
   * removes one or more subjects by id
   */
  const deleteSubjects = () => {
    let subjectsIds = props.items.map((item) => item.original.index);
    const request = {
      subject_ids: subjectsIds,
    };

    DeleteSubjects(request)
      .then((result) => {
        if (result && result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          setErrors(result.description);
        } else {
          props.setShowModalConfirmDeleted(false);
          props.getSubjects();
        }
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  return (
    <Modal
      show={props.showModalConfirmDeleted}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("class:subjects.deleteSubject")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={12} className="mb-2">
            {t("class:subjects.deleteSubjectMessage")}
          </Col>
        </Row>
        {errors && (
          <Row>
            <Col md={12}>
              <Alert variant="warning">{errors}</Alert>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="float-end">
          <Button
            variant="outline-secondary"
            onClick={() => props.setShowModalConfirmDeleted(false)}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button
            onClick={() => {
              setIsLoadingButton(true);
              deleteSubjects();
            }}
            disabled={isLoadingButton}
          >
            {isLoadingButton ? (
              <TextLoading
                text={t("class:modalCreateSubject.deletingSubject")}
                variant="light"
              />
            ) : (
              t("class:main.delete")
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

ModalConfirmDeletedSubject.propTypes = {
  showModalConfirmDeleted: PropTypes.bool, //Required
  setShowModalConfirmDeleted: PropTypes.func, //Required
  items: PropTypes.array,
  getSubjects: PropTypes.func,
};
