import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, Stack, Spinner } from "react-bootstrap";
import { imgOrganization } from "../components/global/GlobalTools";
/*Services */
import { GetPreSignedLink } from "../api/Crm";
import TextLoading from "../components/global/TextLoading";

//Get params from url
const urlString = window.location.href;
const url = new URL(urlString);
const awsPath = url.searchParams.get("aws_path");
const section = url.searchParams.get("section");

const DownloadZipView = () => {
  const [t] = useTranslation(["global"]);
  const [organizationLogo, setOrganizationLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const tSection = {
    report_cards_templates: t("tickets"),
    invoices: t("invoices"),
  };

  /**Initial loading */
  useEffect(() => {
    //Download zip when component is loaded
    downloadZip(awsPath, section);
    //Get organization image url
    const interval = setInterval(() => {
      const urlImg = imgOrganization();
      if (urlImg) {
        setOrganizationLogo(urlImg?._currentValue?.imgOrganizationUrl);
      }
    }, 200);
    //Clear interval when loading organization image
    if (organizationLogo) {
      clearInterval(interval);
    }
    //componentWillUnmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * Description: Function to download zip
   * @param {string} path
   * @param {string} section
   */
  const downloadZip = (path, section) => {
    setIsLoading(true);
    const request = {
      section,
      aws_path: path,
    };
    GetPreSignedLink(request).then((result) => {
      const data = result.data;
      window.open(data);
      setIsLoading(false);
    });
  };

  return (
    <Container className="containerDowloadZip" fluid>
      <Stack gap={3}>
        {/* Organization logo */}
        <div
          style={{ width: "fit-content", margin: "auto" }}
          id="img-organization"
        >
          {!organizationLogo ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              variant="secondary"
              style={{
                top: "200px",
                left: "52%",
              }}
            />
          ) : (
            <img
              className="thumbnail-image"
              src={organizationLogo}
              style={{
                height: "6vh",
                borderRadius: "4px",
                verticaAlign: "top",
              }}
            />
          )}
        </div>
        <div>{t("textDowloadZip", { section: tSection[section] })}</div>
        <div>
          <Button
            id="downloadZipBtn"
            disabled={isLoading}
            onClick={() => downloadZip(awsPath, section)}
          >
            {isLoading ? (
              <TextLoading text={t("buttons.downloading")} variant="light" />
            ) : (
              t("buttons.download")
            )}
          </Button>
        </div>
      </Stack>
    </Container>
  );
};

export default DownloadZipView;
