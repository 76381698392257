import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button, Form as form, Alert } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import selectStyles, { isRequiredFields } from "../../global/GlobalTools";
import Select from "react-select";
import * as Sentry from "@sentry/react";
//Services
import {
  ChangeStudentLevel,
  DeleteStudent,
  DisableStudents,
} from "../../../api/StudentsReport";
//Custom Hooks components
import FormSchoolCycle from "../../global/form/FormSchoolCycle";
import TextLoading from "../../global/TextLoading";
import ContentButton from "../../global/ContentButton";
//Components
import ModalActionStudentsConfirmation from "../../students/modals/ModalActionStudentsConfirmation";
import ModalActionStudentsSuccess from "../../students/modals/ModalActionStudentsSuccess";

export default function ModalActionStudents(props) {
  const [t] = useTranslation(["global", "student", "modalCreateStudent"]);
  const [isLoading, setIsLoading] = useState(false);
  const [studentIds, setStudentsIds] = useState([]);
  const [selectSchoolCycle, setSelectSchoolCycle] = useState(null);
  const [selectLevel, setSelectLevel] = useState(null);
  const [selectgrade, setSelectGrade] = useState(null);
  const [selectGroup, setSelectGroup] = useState(null);
  const [isRequired, setIsRequired] = useState(false);
  const [arrayConcepts, setArrayConcepts] = useState([]);
  const [arraySubconcepts, setArraySubconcepts] = useState([]);
  const [subconceptsId, setSubconceptsId] = useState([]);
  const [showModalActionConfirmation, setShowModalActionConfirmation] =
    useState(false);
  const [someModalOpen, setSomeModalOpen] = useState(false);
  const [numberCharges, setNumberCharges] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [showSuccessModal] = useState(false);
  const [successMessage] = useState({});
  const [studentsResult, setStudentResult] = useState([]);
  const [errors, setErrors] = useState(false);

  const btnActionTxt = props.btnActionTxt || t("global:buttons.accept");

  /**
   * initial values for Form when change student level
   */
  const initialValues = {
    schoolCycle: "",
    schoolLevel: "",
    grade: "",
    group: "",
    academicOffer: "",
    level: "",
  };

  /**
   * Validate fields for Form when change student level
   */
  const validateSchema = Yup.object().shape({
    schoolCycle: Yup.string().required(
      t("modalCreateStudent:labels.schoolCycle") +
        " " +
        t("modalCreateStudent:labels.isRequired")
    ),
    schoolLevel: Yup.string("")
      .required(
        t("modalCreateStudent:labels.schoolLevel") +
          " " +
          t("modalCreateStudent:labels.isRequired")
      )
      .nullable(),
    grade:
      props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
        ? Yup.string("grade")
        : Yup.string("grade")
            .required(
              t("modalCreateStudent:labels.grade") +
                " " +
                t("modalCreateStudent:labels.isRequired")
            )
            .nullable(),
    group:
      props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
        ? Yup.string("group")
        : Yup.string("group")
            .required(
              t("modalCreateStudent:labels.group") +
                " " +
                t("modalCreateStudent:labels.isRequired")
            )
            .nullable(),
    academicOffer:
      props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
        ? Yup.string("academicOffer")
            .required(
              t("modalCreateStudent:labels.offer") +
                " " +
                t("modalCreateStudent:labels.isRequired")
            )
            .nullable()
        : Yup.string("academicOffer").nullable(),
    level:
      props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
        ? Yup.string("level")
            .required(
              t("modalCreateStudent:labels.level") +
                " " +
                t("modalCreateStudent:labels.isRequired")
            )
            .nullable()
        : Yup.string("level").nullable(),
  });

  /**
   * Description: Function to clear Form when change student level modal is closed
   */
  const cleanChangeLevel = () => {
    props.setShowActionStudents(false);
    setSelectSchoolCycle(null);
    props.setSelectCycleId("");
    props.setSelectSchoolLevel(null);
    setSelectGrade(null);
    props.setSelectGradeId("");
    setSelectGroup(null);
  };

  /**
   * Description: Function to trigger the operation of the modal
   */
  const actionBtnPrimary = () => {
    //Validate students
    if (!studentIds.length) {
      setErrors({
        description:
          props.language === "spanish"
            ? "Ha seleccionado solo estudiantes inactivos. Por favor verifique la selección"
            : "You have selected only inactive students. Please check the selection",
      });
      return;
    }
    //Clean errors
    setErrors(false);
    //Do action according option selected
    switch (props.actionStudent) {
      case "delete": {
        deleteStudents();
        break;
      }
      case "addCharges": {
        toggleAddStudentChargesConfirmation(true);
        break;
      }
      case "suspend": {
        props.setShowActionStudents(false);
        disableStudents("suspended");
        break;
      }
      case "unenrolled": {
        props.setShowActionStudents(false);
        disableStudents("unenrolled");
        break;
      }
    }
  };

  /**
   * Description: Function to disable multiple students
   */
  const disableStudents = (status) => {
    //Set loading
    setIsLoading(true);
    //parameters to send to the service
    const request = {
      student_ids: studentIds,
      status,
    };
    //Call to service
    DisableStudents(request)
      .then((result) => {
        if (result.data) {
          setStudentResult(result.data);
          props.setShowModalConfirmationSuspeendedStudent(true);
        } else {
          Sentry.captureException(Error(JSON.stringify(result)));
          setErrors(result);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  /**
   * Description: Function to change student level
   * @param {object} schoolCycleInfo
   */
  const changeLevel = (schoolCycleInfo) => {
    //parameters to send to the service according to the selected level
    const request =
      schoolCycleInfo.schoolLevel === "higherLevel"
        ? {
            student_ids: studentIds,
            school_cycle_id: schoolCycleInfo.schoolCycle,
            program_id: schoolCycleInfo.academicOffer,
            program_level_cycle_id: schoolCycleInfo.level,
            program_current_level: selectLevel.label,
          }
        : {
            student_ids: studentIds,
            school_cycle_id: schoolCycleInfo.schoolCycle,
            organization_school_level_id: schoolCycleInfo.schoolLevel,
            grade_group_grade_level_id: schoolCycleInfo.group,
          };
    //To enable spinner when students are deleting
    setIsLoading(true);
    //Call to service to change student level
    ChangeStudentLevel(request)
      .then((result) => {
        if (result.description) {
          // If the student is already enrolled in the level, show a modal indicating that the student is already enrolled
          result.code == "student_school_cycle_already_exist" && props.setShowRegisteredStudentModal(true);
          Sentry.captureException(Error(JSON.stringify(result)));
        }
        props.getStudentsList();
        cleanChangeLevel();
      })
      .catch((error) => {
        throw new Error(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  /**
   * Description: Function to delete selected students
   */
  const deleteStudents = () => {
    //To enable spinner when students are deleting
    setIsLoading(true);
    //Delete each student selected
    for (const index in studentIds) {
      deleteStudent(studentIds[index], index, studentIds.length);
    }
  };

  /**
   * Description: Function to delete one student
   * @param {number} studentId
   * @param {number} [index=0] default value is 0
   * @param {number} [length=1] default value is 1
   */
  const deleteStudent = (studentId, index = 0, length = 1) => {
    const student = {
      student_id: studentId,
    };
    DeleteStudent(student)
      .then((result) => {
        if (result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
        }
        //Update the student list when finished delete all selected students
        if (parseInt(index) + 1 === length) {
          props.getStudentsList();
          props.setShowActionStudents(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  /**
   * Description: Function to add one concept of the concepts list
   * @param {object} event
   */
  const addConcept = (event) => {
    const subconcepts = event.products;
    const arrayConceptsCopy = [...arrayConcepts]; //Contains the list of selected concepts
    const arraySubconceptsCopy = [...arraySubconcepts]; //Contains all sub-concepts of the selected concepts
    const subconceptsIdCopy = [...subconceptsId]; //Contains all the ids of the selected subconcepts.

    //We look for the concept to unlock it from the select
    const index = findInConcepts(event.value, props.optionsConcepts);
    props.optionsConcepts[index].isDisabled = true;

    //We create the array of the selected concepts
    event.allSubconceptsSelected = true;
    arrayConceptsCopy.push(event);

    //We create the array of subconcepts of each selected concept
    for (const subconcept of subconcepts) {
      subconcept.checked = true; //Select the checkbox
      arraySubconceptsCopy.push(subconcept); //Push all subconcepts into the array
      subconceptsIdCopy.push(subconcept.id); //Push the id of the subconcept to the array of id
    }

    setArrayConcepts(arrayConceptsCopy);
    setArraySubconcepts(arraySubconceptsCopy);
    setDisabledButton(subconceptsIdCopy.length === 0);
    setSubconceptsId(subconceptsIdCopy);
    setNumberCharges(arrayConceptsCopy.length);
  };

  /**
   * Description: Add the classes for the accordion
   * @param {string} cardId | accordion card id
   * @param {string} angleDownId | accordion down arrow id
   * @param {string} angleUpId | accordion up arrow id
   */
  const accordionToggle = (cardId, angleDownId, angleUpId) => {
    const cardConcept = global.document.querySelector("#" + cardId);
    const angleDown = global.document.querySelector("#" + angleDownId);
    const angleUp = global.document.querySelector("#" + angleUpId);

    cardConcept.classList.toggle("toggle-show");
    cardConcept.classList.toggle("toggle-hide");

    angleDown.classList.toggle("toggle-show");
    angleDown.classList.toggle("toggle-hide");

    angleUp.classList.toggle("toggle-show");
    angleUp.classList.toggle("toggle-hide");
  };

  /**
   * Description: Search the concept in the Array
   * @param {integer} conceptId
   * @param {Array} arrayConcepts
   * @return {integer}
   */
  const findInConcepts = (conceptId, arrayConcepts) => {
    return arrayConcepts.findIndex(function (concept) {
      return concept.value === conceptId;
    });
  };

  /**
   * Description: Look for the sub-concept in the Array
   * @param {integer} subconceptId
   * @param {Array} arraySubconcepts
   * @return {integer}
   */
  const findInSubconcepts = (subconceptId, arraySubconcepts) => {
    return arraySubconcepts.findIndex(function (subconcept) {
      return subconcept.id === subconceptId;
    });
  };

  /**
   * Description: Function to check or uncheck all all concepts
   * @param {object} concept
   * @param {number} index
   */
  const addArrayConceptAll = (concept, index) => {
    const arrayConceptsCopy = [...arrayConcepts];
    const subconcepts = concept.products;
    const checked = !concept.allSubconceptsSelected;
    const subconceptsIdCopy = [...subconceptsId];
    concept.allSubconceptsSelected = checked;
    for (const item of subconcepts) {
      item.checked = checked;
      const id = item.id;
      const index = subconceptsIdCopy.indexOf(id);
      if (checked) {
        if (index == -1) {
          subconceptsIdCopy.push(id);
        }
      } else {
        subconceptsIdCopy.splice(index, 1);
      }
    }
    concept.products = subconcepts;
    arrayConceptsCopy[index] = concept;

    setArrayConcepts(arrayConceptsCopy);
    setDisabledButton(subconceptsIdCopy.length === 0);
    setSubconceptsId(subconceptsIdCopy);
    setNumberCharges(arrayConceptsCopy.length);
  };

  /**
   * Description: Remove concepts and subconcepts from the array list
   * @param {number} idConcept
   */
  const removeConceptsAndSubconcepts = (idConcept) => {
    const arrayConceptsCopy = [...arrayConcepts]; //Contains the list of selected concepts
    const arraySubconceptsCopy = [...arraySubconcepts]; //Contains all sub-concepts of the selected concepts
    const subconceptsIdCopy = [...subconceptsId]; //Contains the id of the selected subconcepts
    const optionsConceptsCopy = [...props.optionsConcepts]; //Contains all the concepts shown in the select

    //We look for the concept to speak it in the select
    const index2 = findInConcepts(idConcept, optionsConceptsCopy);
    optionsConceptsCopy[index2].isDisabled = false;
    //We look for the concept to eliminate it from the array of concepts
    const index = findInConcepts(idConcept, arrayConceptsCopy);

    for (let i = 0; i < arrayConceptsCopy[index].products.length; i++) {
      const idSubconcept = arrayConceptsCopy[index].products[i].id;
      //We look for the subconcepts to remove them from the array of arraySubconcepts
      const indexs = findInSubconcepts(idSubconcept, arraySubconceptsCopy);
      arraySubconceptsCopy.splice(indexs, 1);
      //Remove the sub-concept id from the subconceptsId array
      const indexId = subconceptsIdCopy.indexOf(idSubconcept);
      subconceptsIdCopy.splice(indexId, 1);
    }

    //We delete the concept after having deleted its subconcepts
    arrayConceptsCopy.splice(index, 1);

    setArrayConcepts(arrayConceptsCopy);
    setArraySubconcepts(arraySubconceptsCopy);
    setDisabledButton(subconceptsIdCopy.length === 0);
    setSubconceptsId(subconceptsIdCopy);
    props.setOptionsConcepts(optionsConceptsCopy);
    setNumberCharges(arrayConceptsCopy.length);
  };

  /**
   * Description: Add / Remove the sub-concept through the checkbox
   * @param {object} event
   * @param {number} posConcept
   */
  const selectSubconcept = (event, posConcept) => {
    const subconceptsIdCopy = [...subconceptsId]; //Contains the id of the selected subconcepts
    const arraySubconceptsCopy = [...arraySubconcepts]; //Contains all sub-concepts of the selected concepts
    const id = event.target.value;
    const arrayConceptsCopy = [...arrayConcepts];
    let allSelected = false;
    //We look for the subconcept to change the checked in the array of arraySubconcepts
    const indexs = findInSubconcepts(id, arraySubconceptsCopy);
    arraySubconceptsCopy[indexs].checked = event.target.checked;
    if (!event.target.checked) {
      //Remove subconcept id at array
      const index = subconceptsIdCopy.indexOf(id);
      subconceptsIdCopy.splice(index, 1);
      //Remove check allSelected
      allSelected = false;
    } else {
      //Add subconcept id at array
      subconceptsIdCopy.push(id);
      //Find if all subconcepts are checked
      allSelected = arrayConceptsCopy[posConcept].products.every(
        (subConcept) => subConcept.checked
      );
    }
    //Check if all subconcepts are checked
    arrayConceptsCopy[posConcept].allSubconceptsSelected = allSelected;

    setDisabledButton(subconceptsIdCopy.length === 0);
    setSubconceptsId(subconceptsIdCopy);
    setArraySubconcepts(arraySubconceptsCopy);
    setArrayConcepts(arrayConceptsCopy);
    setNumberCharges(arrayConceptsCopy.length);
  };

  /**
   * Description: Open / Close the confirmation modal to add the charges
   * @param {Boolean} status
   */
  const toggleAddStudentChargesConfirmation = (status) => {
    setShowModalActionConfirmation(status);
    setSomeModalOpen(status);
  };

  /**
   * Description: Close modal action students
   */
  const cancel = () => {
    props.setShowActionStudents(false);
    setErrors(false);
  };

  /**Initial loading */
  useEffect(() => {
    Sentry.setTag("section", "Student Action Modal");
    if (props.actionStudent === "addCharges") {
      setDisabledButton(true);
    }
    let selectedRowIds = props.selectedRowIds;
    //Only filters by assets
    if (props.actionStudent == "inactivate") {
      selectedRowIds = selectedRowIds.filter(
        ({ original }) =>
          original.status == "Active" || original.status == "Activo"
      );
    }
    const studentIds = [];
    selectedRowIds.forEach((element) => {
      studentIds.push(element.original.index);
    });
    //Save students ids
    setStudentsIds(studentIds);
  }, []);

  /*
   * Description: update validate status when change student level
   */
  useEffect(() => {
    setIsRequired(isRequiredFields(initialValues, validateSchema));
  }, [initialValues, validateSchema]);

  return (
    <>
      <div>
        <Modal
          show={props.showActionStudents}
          bssize={props.size}
          aria-labelledby="contained-modal-title-md"
          contentClassName={someModalOpen ? "hide" : ""} //Hide this modal so the open one doesn't overlap
        >
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title text-center">
              {props.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body mbottom10">
            <Row>
              <Col md={12}>{props.message}</Col>
            </Row>
            {props.actionStudent === "changeLevel" ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  !isLoading ? changeLevel(values) : "";
                }}
              >
                {(values) => (
                  <Form>
                    <Row>
                      {/**School cycle */}
                      <Col md={12}>
                        <FormSchoolCycle
                          label={t("modalCreateStudent:labels.schoolCycle")}
                          name="schoolCycle"
                          onChange={(event) => {
                            //Remove touched items from Formik object
                            if (event == null) {
                              delete values.touched.schoolLevel;
                              delete values.touched.grade;
                              delete values.touched.group;
                              delete values.touched.level;
                              delete values.touched.academicOffer;
                            }
                            const cycleId = event && event.id ? event.id : "";
                            setSelectSchoolCycle(event);
                            values.setFieldValue("schoolCycle", cycleId);
                            values.setFieldValue("schoolLevel", "");
                            values.setFieldValue("grade", "");
                            values.setFieldValue("group", "");
                            values.setFieldValue("academicOffer", "");
                            values.setFieldValue("level", "");
                            props.setSelectSchoolLevel(null);
                            setSelectGrade(null);
                            setSelectGroup(null);
                            props.setSelectGradeId("");
                            props.setSelectCycleId(cycleId);
                          }}
                          value={selectSchoolCycle}
                          required={true}
                          showRequired={isRequired}
                          values={values}
                          useIn="form"
                          addButton={false}
                          //They are sent as props because when closing the create student modal the fields are cleaned
                          selectSchoolLevel={props.selectSchoolLevel}
                          selectgrade={selectgrade}
                          selectGroup={selectGroup}
                          selectGradeId={props.selectGradeId}
                          setSelectSchoolLevel={props.setSelectSchoolLevel}
                          setSelectGrade={setSelectGrade}
                          setSelectGroup={setSelectGroup}
                          setSelectGradeId={props.setSelectGradeId}
                          setSelectLevel={setSelectLevel}
                        />
                      </Col>
                    </Row>
                    <ContentButton className="content-button">
                      <Button
                        variant="outline-secondary"
                        onClick={cleanChangeLevel}
                      >
                        {t("global:buttons.cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <TextLoading
                            text={props.btnActionLoading}
                            variant="light"
                          />
                        ) : (
                          btnActionTxt
                        )}
                      </Button>
                    </ContentButton>
                  </Form>
                )}
              </Formik>
            ) : props.actionStudent === "addCharges" ? (
              <>
                <form.Group>
                  <form.Label>
                    {t("student:modalActionStudents.selectConcept")}
                  </form.Label>
                  <Select
                    name="addCharges"
                    placeholder={t(
                      "student:modalActionStudents.selectConceptPlaceholder"
                    )}
                    styles={selectStyles}
                    options={props.optionsConcepts}
                    noOptionsMessage={() => t("global:select.noMatches")}
                    formatOptionLabel={function (data) {
                      return (
                        <span
                          dangerouslySetInnerHTML={{ __html: data.label }}
                        />
                      );
                    }}
                    onChange={addConcept}
                  />
                </form.Group>
                {/**Charges List */}
                <div className="accordion mtop20">
                  {arrayConcepts.map(function (concept, index) {
                    const cardId = "toggle-card-m" + index;
                    const angleDownId = "angle-down-m" + index;
                    const angleUpId = "angle-up-m" + index;
                    const products = concept.products;
                    return (
                      <div className="card" key={index}>
                        {/* List of concepts */}
                        <div className="card-header">
                          <Row>
                            <Col xs={10} className="v2">
                              <input
                                type="checkbox"
                                id={`row${index}`}
                                name="checkbox_categorie"
                                checked={concept.allSubconceptsSelected}
                                onClick={() =>
                                  addArrayConceptAll(concept, index)
                                }
                              />
                              <label htmlFor={`row${index}`}>
                                {concept.text}
                              </label>
                            </Col>
                            <Col xs={2} className="v2">
                              <span className="pull-right">
                                <i
                                  className="fal fa-trash-alt"
                                  onClick={() =>
                                    removeConceptsAndSubconcepts(concept.value)
                                  }
                                ></i>
                                <i
                                  className="far fa-angle-down"
                                  onClick={() =>
                                    accordionToggle(
                                      cardId,
                                      angleDownId,
                                      angleUpId
                                    )
                                  }
                                  id={angleDownId}
                                ></i>
                                <i
                                  className="far fa-angle-up toggle-hide"
                                  onClick={() =>
                                    accordionToggle(
                                      cardId,
                                      angleDownId,
                                      angleUpId
                                    )
                                  }
                                  id={angleUpId}
                                ></i>
                              </span>
                            </Col>
                          </Row>
                        </div>
                        {/* List of subconcepts */}
                        <div
                          className="card-body modes_menu toggle-hide"
                          id={cardId}
                        >
                          {products.map(function (product, indexp) {
                            return (
                              <div className="row-subconcept" key={indexp}>
                                <Row>
                                  <Col xs={12} className="v2">
                                    <input
                                      type="checkbox"
                                      id={`subrow${index}${indexp}`}
                                      name="checkbox_categorie"
                                      value={product.id}
                                      checked={product.checked}
                                      onClick={(e) =>
                                        selectSubconcept(e, index)
                                      }
                                    />
                                    <label htmlFor={`subrow${index}${indexp}`}>
                                      {product.name}
                                    </label>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            {/**Show errors when creating item*/}
            <div style={{ marginTop: "20px" }}>
              {errors ? (
                <Alert variant="warning">{errors.description}</Alert>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
          <Modal.Footer
            className={props.actionStudent === "changeLevel" ? "hide" : ""}
          >
            <ContentButton className="content-button">
              <Button variant="outline-secondary" onClick={cancel}>
                {t("global:buttons.cancel")}
              </Button>
              <Button
                variant="primary"
                onClick={actionBtnPrimary}
                disabled={isLoading || disabledButton}
              >
                {isLoading ? (
                  <TextLoading text={props.btnActionLoading} variant="light" />
                ) : (
                  btnActionTxt
                )}
              </Button>
            </ContentButton>
          </Modal.Footer>
        </Modal>
      </div>
      {showModalActionConfirmation ? (
        <ModalActionStudentsConfirmation
          open={showModalActionConfirmation}
          toggleAddStudentChargesConfirmation={
            toggleAddStudentChargesConfirmation
          }
          selectedRowIds={props.selectedRowIds}
          studentIds={studentIds}
          setSomeModalOpen={setSomeModalOpen}
          numberCharges={numberCharges}
          cycleId={props.cycleId}
          setShowActionStudents={props.setShowActionStudents}
          subconceptsId={subconceptsId}
          isAllRowsSelected={props.isAllRowsSelected}
          students={props.students}
        />
      ) : null}
      {showSuccessModal ? (
        <ModalActionStudentsSuccess
          open={showSuccessModal}
          setShowActionStudents={props.setShowActionStudents}
          title={successMessage.title}
          message={successMessage.message}
          studentsResult={studentsResult}
        />
      ) : null}
    </>
  );
}

ModalActionStudents.propTypes = {
  size: PropTypes.string,
  showActionStudents: PropTypes.bool,
  setShowActionStudents: PropTypes.func,
  selectedRowIds: PropTypes.array,
  actionStudent: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  btnActionTxt: PropTypes.string,
  btnActionLoading: PropTypes.string,
  getStudentsList: PropTypes.func,
  setSelectSchoolLevel: PropTypes.func,
  setSelectGradeId: PropTypes.func,
  setSelectCycleId: PropTypes.func,
  selectSchoolLevel: PropTypes.object,
  selectGradeId: PropTypes.string,
  optionsConcepts: PropTypes.array,
  setOptionsConcepts: PropTypes.func,
  cycleId: PropTypes.string,
  isAllRowsSelected: PropTypes.bool,
  students: PropTypes.array,
  language: PropTypes.string,
  setShowModalConfirmationSuspeendedStudent: PropTypes.bool,
  setShowRegisteredStudentModal: PropTypes.bool,
};
