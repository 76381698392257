import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import FieldInput from "../../global/form/FieldInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { CreateTags, UpdateTags } from "../../../api/News";

export default function ModalTag(props) {
  const [t] = useTranslation(["global", "news", "modalCreateStudent"]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const tagInfo = props.tagDetails || {
    name: "",
    description: "",
  };

  const validateSchema = Yup.object().shape({
    name: Yup.string().required(
      t("news:modalCategory.name") +
      " " +
      t("modalCreateStudent:labels.isRequired")
    )
  });

  /**
   * Save the category
   * @param {*} values 
   */
  const saveTag = (values) => {
    let payload = {
      name: values.name,
      description: values.description
    }
    setSaving(true);
    if (tagInfo.id) {
      payload.id = tagInfo.id;
      updateTag(payload);
    } else {
      createTag(payload);
    }
  }

  /**
   * Create a new category
   * @param {object} values
   */
  const createTag = (payload) => {
    CreateTags(payload)
      .then((response) => {
        if (!response.status) {
          setError(response.error.description);
          setTimeout(() => setError(false), 3000);
          return;
        }
        props.getTags();
        props.setShowModalTag(false);
      })
      .finally(() => {
        setSaving(false);
      }
      );

  }

  /**
   * Update a category
   * @param {object} payload 
   */
  const updateTag = (payload) => {
    UpdateTags(payload)
      .then((response) => {
        if (!response.status) {
          setError(response.error.description);
          setTimeout(() => setError(false), 3000);
          return;
        }
        props.getTags();
        props.setShowModalTag(false);
      }
      )
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <Modal
      show={props.showModalTag}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {tagInfo.id ?
            t("news:modalTag.editTag")
            : t("news:tagsPage.create")
          }
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={tagInfo}
        validationSchema={validateSchema}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          !saving ? saveTag(values) : null;
        }}
      >
        {() => (
          <Form>
            <Modal.Body>
              {/* Name */}
              <Row>
                <Col md={12}>
                  <FieldInput
                    label={t("news:modalTag.tagName")}
                    placeholder={t("news:modalTag.namePlaceholder")}
                    name="name"
                    type="text"
                    required={true}
                    maxlength={200}
                  />
                </Col>
              </Row>
              {/* Description */}
              <Row>
                <Col md={12}>
                  <FieldInput
                    label={t("news:modalTag.description")}
                    placeholder={t("news:modalTag.descriptionPlaceholder")}
                    name="description"
                    type="text"
                    required={false}
                  />
                </Col>
              </Row>
              {error && (
                <Row>
                  <Col md={12}>
                    <Alert variant="warning">{error}</Alert>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={() => props.setShowModalTag(false)}
                disabled={saving}
              >
                {t("global:buttons.cancel")}
              </Button>
              <Button
                type="submit"
                disabled={saving}
              >
                {saving ?
                  t("global:buttons.saving")
                  :
                  tagInfo.id ?
                    t("global:buttons.save")
                    :
                    t("news:tagsPage.create")
                }
              </Button>
            </Modal.Footer>
          </Form>)}
      </Formik>
    </Modal>
  );
}

ModalTag.propTypes = {
  showModalTag: PropTypes.bool, //Required
  setShowModalTag: PropTypes.func, //Required
  getTags: PropTypes.func, //Required
  tagDetails: PropTypes.object,
};
