/**
 * ReportSapTxt
 *
 * Purpose: Create TXT report for SAP service
 *
 * @author Samuel Lucas <lucas@inclancommunications.com>
 * @date 05/06/2024
 * @last_modified 12/06/2024
 */

import * as moment from "moment";

/* Global variables */
let pageNumberGlobal = 1;
let tableInfo = {};
let reportData = {};
const mockDataSchoolLevels = [
  {
    transactions_details: [
      {
        recibo: "NA",
        matricula: "NA",
        sgg: "NA",
        clave: "NA",
        concepto: "NA",
        monto: "0.00",
        mes: "NA",
        cuenta: "NA",
        banco: "NA",
        t_pago: "NA",
        fecha_vencimiento: "NA",
        fecha_pago: "NA",
        obs: "NA",
      },
    ],
    total: "0.00",
  },
];
const mockCancelledPayments = [
  {
    recibo: "NA",
    matricula: "NA",
    sgg: "NA",
    concepto: "NA",
    monto: "0.00",
    mes: "NA",
    justificacion_cancelacion: "NA",
    hora: "NA",
  },
];
const mockInvoicesData = [
  {
    f_fiscal: "NA",
    recibo: "NA",
    matricula: "NA",
    sec: "NA",
    gdo: "NA",
    gpo: "NA",
    poliza: "NA",
    monto: "0.00 ",
    mes: "NA",
    cheque: "NA",
    banco: "NA",
    fecha_vencimiento: "NA",
    fecha_pago: "NA",
    obs: "NA",
  },
];
const categoriesMockData = [
  {
    cuenta_contable: "NA",
    clave_centro_beneficio: "NA",
    nombre_centro_beneficio: "NA",
    concepto: "NA",
    descripcion: "NA",
    debe: "0.00",
    haber: "0.00",
  },
];
const mockDataRPOL = {
  main_issuer_rfc: "NA",
  school_cycle_name: "NA",
  readable_date: "NA",
  reports: [
    {
      total_payments_month: "NA",
      concepts: [
        {
          cve_de_poliza: "NA",
          monto_de_poliza: "NA"
        },
        {
          cve_de_poliza: "NA",
          monto_de_poliza: "NA"
        }
      ]
    }
  ]
};

/**
 * At each line break evaluate if the limit of rows per page has been reached, if so create a new page.
 * - Limit to 62 rows per page
 * - Only 59 rows of content to perform a page change
 * @param {string} plainText 
 * @param {object} infoTable 
 * @returns string
 */
const pageControl = (plainText, infoTable) => {
  const totalLines = plainText.split('\n').length;
  // If 59 lines are created insert Footer and header and increase the pageNumberGlobal by 1
  if (Number.isInteger(totalLines / 59)) {
    plainText = createFooter(plainText, infoTable.totalWidth, infoTable.num_poliza);
    pageNumberGlobal += 1;
    plainText = createHeader(plainText, infoTable);
    plainText = createTableHeader(plainText, infoTable.totalWidth, infoTable.clavesHead, infoTable.colums);
    plainText += "\n\n"
  } else {
    plainText += "\n"
  }
  return plainText
}

/**
 * Create a string of pure spaces with the given length
 * @param {number} length 
 * @param {string} spacer character with which you want to generate the string by default are spaces
 * @returns string
 */
const insertSpaces = (length, spacer = " ") => {
  let spaces = "";
  for (let i = 0; i < length; i++) {
    spaces += spacer
  }
  return spaces;
}

/**
 * Aligns a string to the left from a designated column space.
 * @param {number} valueLength Length of the text to be aligned
 * @param {number} columnWidth Max. column spacing
 * @returns string
 */
const alignLeft = (valueLength, columnWidth) => {
  let defaultSpace = "  "; // Default spaces to separate between columns
  const emptySpaces = columnWidth - valueLength;
  if (emptySpaces > 0) {
    defaultSpace += insertSpaces(emptySpaces);
  }
  return defaultSpace;
}

/**
 * Aligns a string to the right from a designated column space.
 * @param {number} valueLength Length of the text to be aligned
 * @param {number} columnWidth Max. column spacing
 * @param {string} withReference Reference to align the text
 * @returns string
 */
const alignRight = (value, columnWidth, withReference = false) => {
  let leftspace = ""
  const emptySpaces = columnWidth - value;
  if (emptySpaces > 0) {
    if (withReference) {
      leftspace += withReference + " ";
      // Take two spaces for weights sign and one space
      const columnAdjuster = 2;
      leftspace += insertSpaces((emptySpaces - columnAdjuster));
    } else {
      leftspace += insertSpaces(emptySpaces);
    }
  }
  return leftspace
}

/**
 * Aligns a string to the center from a designated column spacing.
 * @param {string} value  chain to be aligned
 * @param {*} columnWidth Max. column spacing
 * @returns string
 */
const alignCenter = (value, columnWidth) => {
  let spacesLeft = "";
  let spacesRight = "";
  const emptySpaces = columnWidth - value.length;
  // Dividing the empty spaces to determine the sidewalls
  let spaces = emptySpaces / 2;
  // If not integer round up
  if (!Number.isInteger(spaces)) {
    spaces = Math.round(spaces);
    // Add the spaces to the right minus one to compensate for rounding.
    spacesRight += insertSpaces((spaces - 1));
  } else {
    spacesRight += insertSpaces(spaces);
  }
  // Add spaces on the left
  spacesLeft += insertSpaces(spaces);
  return spacesLeft + value + spacesRight;
}

/**
 * First header line consisting of organization name and page number
 * @param {string} organizationName
 * @param {number} columnWidth
 * @param {string} pageNumber
 * @returns srting
 */
const firstHeaderLine = (organizationName, columnWidth, pageNumber) => {
  let spaces = "";
  const contentLength = organizationName.length + (pageNumber?.toString()?.length || 0);
  const emptySpaces = columnWidth - contentLength;
  spaces += insertSpaces(emptySpaces);
  return spaces;
}

/**
 * Second header line with the name of the main issuer and date
 * @param {string} mainIssuerName
 * @param {number} columnWidth
 * @returns string
 */
const secondHeaderLine = (mainIssuerName, columnWidth) => {
  let spaces = "";
  const labelLength = 17; // label length and date
  const contentLength = labelLength + mainIssuerName.length;
  const emptySpaces = columnWidth - contentLength;
  spaces += insertSpaces(emptySpaces);
  return spaces;
}

/**
 * Third header line
 * @param {object} info
 * @param {number} columnWidth
 * @returns string
 */
const thirdHeaderLine = (info, columnWidth) => {
  let spacesLeft = "";
  let spacesRight = "";
  const labelLength = 31;
  const contentLength = labelLength + info.school_cycle_name.length + info.tableTitle.length + info.num_poliza.length;
  let emptySpaces = columnWidth - contentLength;
  // Divide by 2 to center
  emptySpaces = emptySpaces / 2;
  // If not integer round up
  if (!Number.isInteger(emptySpaces)) {
    emptySpaces = Math.round(emptySpaces);
    spacesRight += insertSpaces((emptySpaces - 1));
  } else {
    spacesRight += insertSpaces(emptySpaces);
  }
  spacesLeft += insertSpaces(emptySpaces);
  return spacesLeft + info.tableTitle + spacesRight;
}

const rfcCycleLine = (data, columnWidth) => {
  let spaces = "";
  let lineValue = "";
  let cicleName = data.school_cycle_name;
  if (cicleName.length > 40) {
    cicleName = cicleName.substring(0, 30) + "...";
  }
  const labelRFC = "R.F.C.: ";
  const labelCicle = "CICLO ESCOLAR: ";
  const labelsLength = labelRFC.length + labelCicle.length;
  const rfcValueLength = data.main_issuer_rfc.length;

  const cycleValueLength = cicleName.length;
  const contentLength = labelsLength + rfcValueLength + cycleValueLength;

  const emptySpaces = columnWidth - contentLength;
  spaces += insertSpaces(emptySpaces);
  lineValue = labelRFC + data.main_issuer_rfc + insertSpaces(emptySpaces) + labelCicle + cicleName + spaces;
  return lineValue;
}

/**
 *  Create the header of the page
 * @param {string} plainText
 * @param {object} info
 * @param {string} pageNumber
 * @returns string
 */
const createHeader = (plainText, info, pageNumber = ("Hoja: " + pageNumberGlobal)) => {
  plainText += "\n";
  plainText += info.organization_name + firstHeaderLine(info.organization_name, info.totalWidth, pageNumber) + pageNumber + "\n";
  plainText += info.main_issuer_name + secondHeaderLine(info.main_issuer_name, info.totalWidth) + "Fecha: " + info.readable_date + "\n";
  plainText += "CICLO ESCOLAR: " + info.school_cycle_name + thirdHeaderLine(info, info.totalWidth) + "Num. de Poliza: " + info.num_poliza + "\n";

  return plainText;
}

/**
 * Create the Footer of the page
 * @param {string} plainText
 * @param {number} totalWidth
 * @param {string} numPoliza
 * @param {string} pageNumber
 * @returns string
 */
const createFooter = (plainText, totalWidth, numPoliza, pageNumber = ("Hoja: " + pageNumberGlobal)) => {
  const totalLines = plainText.split('\n').length;
  const missingLines = (pageNumberGlobal * 59) - totalLines;
  if (missingLines > 0) {
    for (let index = 0; index < missingLines; index++) {
      plainText += "\n"
    }
    pageNumberGlobal += 1;
  } else {
    plainText += "\n\n";
  }
  plainText += insertSpaces(totalWidth, "_");
  let spacesLeft = "";
  let spacesRight = "";
  const time = "Hora: " + new Date().toLocaleTimeString();
  const occupiedSpaces = time.length + numPoliza.length + pageNumber.length;
  let freeSpaces = totalWidth - occupiedSpaces;
  // Divide by 2 to center
  freeSpaces = freeSpaces / 2;
  // If odd round value
  if (freeSpaces % 2 != 0) {
    freeSpaces = Math.round(freeSpaces);
  }
  spacesLeft += insertSpaces(freeSpaces);
  spacesRight += insertSpaces((freeSpaces - 1));
  plainText += "\n";
  plainText += time + spacesLeft + numPoliza + spacesRight + pageNumber;
  plainText += "\n\n";

  return plainText;
}

/**
 * Section to add comments
 * @param {string} plainText
 * @param {number} pageWidth
 * @param {object} data
 * @returns string
 */
const observationsSection = (plainText, pageWidth, data = tableInfo) => {
  // Evaluate if the section fits correctly
  const totalLines = plainText.split('\n').length;
  if (Number.isInteger((totalLines + 1) / 59) || Number.isInteger((totalLines + 2) / 59)) {
    Number.isInteger((totalLines + 1) / 59) ? plainText += "\n" : plainText += "\n\n";
    plainText = createFooter(plainText, data.totalWidth, data.num_poliza);
    pageNumberGlobal += 1;
    plainText += "\n\n"
    plainText = createHeader(plainText, data);
  }
  // Normal flow
  const title = "O B S E R V A C I O N E S : "
  const availableSpaces = pageWidth - title.length;
  plainText += "\n";
  plainText += title;
  plainText += insertSpaces(availableSpaces, "_");
  plainText += "\n";
  plainText += insertSpaces(pageWidth, "_");

  return plainText
}

/**
 * Section to add the signature
 * @param {string} plainText
 * @param {number} pageWidth
 * @param {object} data
 * @returns string
 */
const signatureSection = (plainText, pageWidth, data = tableInfo) => {
  // Evaluate if the section fits correctly
  const totalLines = plainText.split('\n').length;
  if (Number.isInteger((totalLines + 1) / 59)
    || Number.isInteger((totalLines + 2) / 59)
    || Number.isInteger((totalLines + 3) / 59)
    || Number.isInteger((totalLines + 4) / 59)
    || Number.isInteger((totalLines + 5) / 59)) {

    Number.isInteger((totalLines + 1) / 59) ?
      plainText += "\n" :
      Number.isInteger((totalLines + 2) / 59) ?
        plainText += "\n\n" :
        Number.isInteger((totalLines + 3) / 59) ?
          plainText += "\n\n\n" :
          Number.isInteger((totalLines + 4) / 59) ?
            plainText += "\n\n\n\n" :
            plainText += "\n\n\n\n\n";

    plainText = createFooter(plainText, data.totalWidth, data.num_poliza);
    pageNumberGlobal += 1;
    plainText += "\n\n"
    plainText = createHeader(plainText, data);
  }
  // Normal flow
  const baseSignature = 93;
  const lengthLabels = 70;

  let spaceBaseLeft = "";
  let spaceBaseRight = "";

  let spaceLabelsLeft = "";
  let spaceLabelsRight = "";

  let baseSeparation = (pageWidth - baseSignature) / 2
  if (!Number.isInteger(baseSeparation)) {
    baseSeparation = Math.round(baseSeparation);
    spaceBaseLeft = insertSpaces(baseSeparation - 1);
  } else {
    spaceBaseLeft = insertSpaces(baseSeparation);
  }
  spaceBaseRight = insertSpaces(baseSeparation);

  let labelSeparation = (pageWidth - lengthLabels) / 2
  if (!Number.isInteger(labelSeparation)) {
    labelSeparation = (Math.round(labelSeparation));
    spaceLabelsLeft = insertSpaces(labelSeparation - 1);
  } else {
    spaceLabelsLeft = insertSpaces(labelSeparation);
  }
  spaceLabelsRight = insertSpaces(labelSeparation);

  plainText += "\n";
  plainText += "\n";
  plainText += "\n";
  plainText += "\n";

  plainText += "_______________________________" + spaceBaseLeft + "_______________________________" + spaceBaseRight + "_______________________________"
  plainText += "\n";
  plainText += "        FIRMA CAJERA(O)" + spaceLabelsLeft + "FIRMA ADMINISTRADOR(A)" + spaceLabelsRight + "FIRMA CONTADOR(A)        "

  return plainText
}

/**
 * Section to add the reviewers
 * @param {string} plainText
 * @param {number} pageWidth
 * @param {object} data
 * @returns string
 */
const reviewersSection = (plainText, pageWidth, data = tableInfo) => {
  // Evaluate if the section fits correctly
  const totalLines = plainText.split('\n').length;
  if (Number.isInteger((totalLines + 1) / 59) || Number.isInteger((totalLines + 2) / 59)) {
    Number.isInteger((totalLines + 1) / 59) ? plainText += "\n" : plainText += "\n\n";
    plainText = createFooter(plainText, data.totalWidth, data.num_poliza);
    pageNumberGlobal += 1;
    plainText += "\n\n"
    plainText = createHeader(plainText, data);
  }
  // Normal flow
  const lengthLabels = 47;
  let spaceLabelsLeft = "";
  let spaceLabelsRight = "";
  let baseSeparation = (pageWidth - lengthLabels) / 3;
  if (!Number.isInteger(baseSeparation)) {
    baseSeparation = Math.round(baseSeparation);
    spaceLabelsLeft = insertSpaces(baseSeparation - 1, "_");
  } else {
    spaceLabelsLeft = insertSpaces(baseSeparation, "_");
  }
  spaceLabelsRight = insertSpaces(baseSeparation, "_");
  plainText += "\n";
  plainText += "\n";
  plainText += "F O R M U L O: " + spaceLabelsLeft + " R E V I S O: " + spaceLabelsLeft + " A U T O R I Z O: " + spaceLabelsRight;

  return plainText;
}

/**
 * Creates the table header with its column headings
 * @param {string} plainText
 * @param {number} width
 * @param {Array} clavesHead
 * @param {object} colums
 * @returns string
 */
const createTableHeader = (plainText, width, clavesHead, colums) => {
  // Dotted line
  plainText += insertSpaces(width, "-");
  // Agregar Encabezados
  plainText += "\n";
  clavesHead.forEach(key => {
    let columnName = colums[key].name;
    columnName = colums[key].name.replace(/_/g, " ");
    columnName = columnName.toUpperCase();
    plainText += columnName + alignLeft(colums[key].name.length, (colums[key]?.maxLength || 0));
  });
  // Dotted line
  plainText += "\n";
  plainText += insertSpaces(width, "-");

  return plainText;
}

/**
 * Aligns content by omitting column spacing
 * @param {number} availableSpaces
 * @param {string} label
 * @param {number} alignmentBase
 * @returns string
 */
const omittedColumnSpacing = (availableSpaces, label, alignmentBase = false) => {
  let value = "";
  if (alignmentBase) {
    const remainingSpaces = availableSpaces - alignmentBase;
    const additionalSpaces = alignmentBase - label.length;
    value += insertSpaces(remainingSpaces);
    value += label;
    value += insertSpaces(additionalSpaces);
  } else {
    const remainingSpaces = availableSpaces - label.length;
    value += insertSpaces(remainingSpaces);
    value += label;
  }

  return value;
}

const pageControlSPOL = (plainText) => {
  const totalLines = plainText.split('\n').length;
  // If 59 lines are created insert Footer and header and increase the pageNumberGlobal by 1
  if (Number.isInteger(totalLines / 59)) {
    let totalWidth = 80;
    const headLine1 = "Cve de    Monto  Total";
    const headLine2 = "Póliza    de la Póliza";
    const separator = 7;
    plainText = createFooter(plainText, totalWidth, "");
    pageNumberGlobal += 1;

    plainText += alignCenter("Lista  de  Pólizas  de  Cobranza", totalWidth) + "\n\n";
    plainText += tableInfo.organization_name + firstHeaderLine(tableInfo.organization_name, totalWidth, pageNumberGlobal) + pageNumberGlobal + "\n";
    plainText += tableInfo.main_issuer_name + secondHeaderLine(tableInfo.main_issuer_name, totalWidth) + "Fecha: " + tableInfo.readable_date + "\n";
    plainText += rfcCycleLine(tableInfo, totalWidth) + "\n";
    plainText += insertSpaces(totalWidth, "=") + "\n";
    plainText += headLine1 + insertSpaces(separator) + headLine1 + insertSpaces(separator) + headLine1 + "\n";
    plainText += headLine2 + insertSpaces(separator) + headLine2 + insertSpaces(separator) + headLine2 + "\n";
    plainText += insertSpaces(22, "=") + insertSpaces(separator) + insertSpaces(22, "=") + insertSpaces(separator) + insertSpaces(22, "=") + "\n";
    plainText += "\n"
  } else {
    plainText += "\n";
  }
  return plainText
}

/* ================ Tables creation ================ */

/**
 * Add all the content corresponding to the Corte De Caja
 * @param {string} plainText 
 * @returns string
 */
const corteDeCaja = (plainText) => {
  let colums = {}
  /* Obtain the lengths of all quantities */
  const allMontos = [];
  allMontos.push(reportData.corte_de_caja.total_cash.length);
  allMontos.push(reportData.corte_de_caja.total_check.length);
  allMontos.push(reportData.corte_de_caja.total_spei.length);
  allMontos.push(reportData.corte_de_caja.total_card.length);
  allMontos.push(reportData.corte_de_caja.total_store.length);
  allMontos.push(reportData.corte_de_caja.total_amount.length);
  const schoolLevelsData = reportData?.corte_de_caja?.school_levels?.length
    ? reportData.corte_de_caja.school_levels
    : mockDataSchoolLevels
  for (let level of schoolLevelsData) {
    allMontos.push(level.total.length)
  }
  /* Obtaining table dimension values */
  for (let level of schoolLevelsData) {
    for (let item of level.transactions_details) {
      const object = {
        recibo: level?.recibo || "",
        matricula: level?.matricula || "",
        sgg: level?.sgg || "",
        clave: level?.clave || "",
        concepto: level?.concepto || "",
        monto: level?.monto || "0.00",
        mes: level?.mes || "",
        cuenta: level?.cuenta || "",
        banco: level?.banco || "",
        t_pago: level?.t_pago || "",
        fecha_vencimiento: level?.fecha_vencimiento || "",
        fecha_pago: level?.fecha_pago || "",
        obs: level?.obs || "",
      };
      for (let param in object) {
        const largoActual = item[param]?.toString()?.length || 0;
        if (colums[param] && largoActual > colums[param].maxLength) {
          colums[param].maxLength = largoActual
        }
        if (!colums[param]) {
          let largoLlave = param.length
          if (param == "monto") {
            allMontos.push(param.length);
            largoLlave = Math.max(...allMontos);
          }
          const maxLength = Math.max(largoLlave, largoActual);
          const colum = {
            maxLength: maxLength,
            name: param
          }
          colums[param] = colum;
        }
      }
    }
  }
  let totalWidth = 0;
  const clavesHead = Object.keys(colums);
  clavesHead.forEach(key => {
    totalWidth += ((colums[key]?.maxLength || 0) + 2);
  });

  const maxLengthCycle = totalWidth / 4;
  if ((reportData.corte_de_caja.school_cycle_name.length + 15) > maxLengthCycle) {
    reportData.corte_de_caja.school_cycle_name = reportData.corte_de_caja.school_cycle_name.substring(0, maxLengthCycle - 16) + "...";
  }

  /* Creation page header */
  reportData.corte_de_caja.totalWidth = totalWidth;
  reportData.corte_de_caja.tableTitle = "POLIZA DE COBRANZA (CORTE DE CAJA)";
  reportData.corte_de_caja.clavesHead = clavesHead;
  reportData.corte_de_caja.colums = colums;
  tableInfo = reportData.corte_de_caja
  plainText = createHeader(plainText, reportData.corte_de_caja, "Hoja: 1");

  /* Create table header */
  plainText = createTableHeader(plainText, totalWidth, clavesHead, colums);

  /* Add the maxima of the columns to omit */
  const columnsToSkip = ["recibo", "matricula", "sgg", "clave", "concepto"];
  let totalColumnsToSkip = 0;
  const separationByColumn = 2;
  columnsToSkip.forEach(column => {
    totalColumnsToSkip += ((colums[column]?.maxLength || 0) + separationByColumn);
  });

  /* Fill table */
  plainText = pageControl(plainText, reportData.poliza_contabilidad);
  for (let level of schoolLevelsData) {
    for (let item of level.transactions_details) {
      const object = {
        recibo: level?.recibo || "",
        matricula: level?.matricula || "",
        sgg: level?.sgg || "",
        clave: level?.clave || "",
        concepto: level?.concepto || "",
        monto: level?.monto || "0.00",
        mes: level?.mes || "",
        cuenta: level?.cuenta || "",
        banco: level?.banco || "",
        t_pago: level?.t_pago || "",
        fecha_vencimiento: level?.fecha_vencimiento || "",
        fecha_pago: level?.fecha_pago || "",
        obs: level?.obs || "",
      };
      plainText = pageControl(plainText, reportData.corte_de_caja);
      for (let param in object) {
        if (param == "monto") {
          plainText += alignRight((item[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0)) + (item[param]?.toString() || "") + "  ";
        } else {
          plainText += (item[param]?.toString() || "") + alignLeft((item[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0))
        }
      }
    }

    /* Add totals by level */
    plainText = pageControl(plainText, reportData.corte_de_caja);
    plainText += omittedColumnSpacing(totalColumnsToSkip - 3, "Monto Total en la Seccion", 26) + " : " + alignRight((level?.total?.toString()?.length || 0), colums["monto"]?.maxLength || 0) + (level.total?.toString() || "");
    plainText = pageControl(plainText, reportData.corte_de_caja);
  }

  /* Add totals */
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText += omittedColumnSpacing(totalColumnsToSkip - 3, "TOTAL EN PAGOS EN EFECTIVO", 26) + " : " + alignRight(reportData.corte_de_caja.total_cash.length, (colums["monto"]?.maxLength || 0)) + reportData.corte_de_caja.total_cash + "  ";
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText += omittedColumnSpacing(totalColumnsToSkip - 3, "TOTAL EN PAGOS CON CHEQUE", 26) + " : " + alignRight(reportData.corte_de_caja.total_check.length, (colums["monto"]?.maxLength || 0)) + reportData.corte_de_caja.total_check + "  ";
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText += omittedColumnSpacing(totalColumnsToSkip - 3, "TOTAL EN PAGOS POR TRANSF", 26) + " : " + alignRight(reportData.corte_de_caja.total_spei.length, (colums["monto"]?.maxLength || 0)) + reportData.corte_de_caja.total_spei + "  ";
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText += omittedColumnSpacing(totalColumnsToSkip - 3, "TOTAL EN PAGOS CON TARJETA", 26) + " : " + alignRight(reportData.corte_de_caja.total_card.length, (colums["monto"]?.maxLength || 0)) + reportData.corte_de_caja.total_card + "  ";
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText += omittedColumnSpacing(totalColumnsToSkip - 3, "TOTAL EN PAGOS EN TIENDA", 26) + " : " + alignRight(reportData.corte_de_caja.total_store.length, (colums["monto"]?.maxLength || 0)) + reportData.corte_de_caja.total_store + "  ";
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText += omittedColumnSpacing(totalColumnsToSkip - 3, "MONTO TOTAL EN ESTA POLIZA", 26) + " : " + alignRight(reportData.corte_de_caja.total_amount.length, (colums["monto"]?.maxLength || 0)) + reportData.corte_de_caja.total_amount + "  ";

  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText = pageControl(plainText, reportData.corte_de_caja);

  const infoPayment = "LOS PAGOS MARCADOS CON UN ASTERISCO (*) EN LA COLUMNA DE  \"OBS. \", FUERON RECIBIDOS CON FECHA VENCIDA.";
  plainText += alignCenter(infoPayment, totalWidth);
  /* Add observationsSection */
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText = observationsSection(plainText, totalWidth);
  /* Add signatureSection */
  plainText = pageControl(plainText, reportData.corte_de_caja);
  plainText = signatureSection(plainText, totalWidth);
  /* Add footer */
  plainText = createFooter(plainText, totalWidth, reportData.corte_de_caja.num_poliza);

  return plainText;
}

/**
 * Add all the content corresponding to the Poliza de Contablidad
 * @param {string} plainText 
 * @returns string
 */
const polizaContablidad = (plainText) => {
  let colums = {}

  /* Obtain the lengths of all quantities */
  const allMontos = [];
  allMontos.push(reportData.poliza_contabilidad.suma.debe.length);
  allMontos.push(reportData.poliza_contabilidad.suma.haber.length);

  /* Obtaining table dimension values */
  const categories = reportData?.poliza_contabilidad?.categories?.length
    ? reportData.poliza_contabilidad.categories
    : categoriesMockData;
  for (let level of categories) {
    // re order the columns
    const object = {
      cuenta_contable: level?.cuenta_contable || "",
      clave_centro_beneficio: level?.clave_centro_beneficio || "",
      nombre_centro_beneficio: level?.nombre_centro_beneficio || "",
      concepto: level?.concepto || "",
      descripcion: level?.descripcion || "",
      debe: level?.debe || "0.00",
      haber: level?.haber || "0.00",
    };
    for (let param in object) {
      const largoActual = (level[param]?.toString()?.length || 0);
      if (colums[param] && largoActual > colums[param].maxLength) {
        colums[param].maxLength = largoActual
      }
      if (!colums[param]) {
        let largoLlave = param.length;
        if (param == "debe" || param == "haber") {
          allMontos.push(param.length);
          largoLlave = Math.max(...allMontos) + 1;
        }
        const maxLength = Math.max(largoLlave, largoActual)
        const colum = {
          maxLength: maxLength,
          name: param
        }
        colums[param] = colum;
      }
    }
  }
  let totalWidth = 0;
  const clavesHead = Object.keys(colums);
  clavesHead.forEach(key => {
    totalWidth += ((colums[key]?.maxLength || 0) + 2);
  });

  const maxLengthCycle = totalWidth / 4;
  if ((reportData.poliza_contabilidad.school_cycle_name.length + 15) > maxLengthCycle) {
    reportData.poliza_contabilidad.school_cycle_name = reportData.poliza_contabilidad.school_cycle_name.substring(0, maxLengthCycle - 16) + "...";
  }

  /* Creation page header */
  reportData.poliza_contabilidad.totalWidth = totalWidth;
  reportData.poliza_contabilidad.tableTitle = "REPORTE DE POLIZA PARA CONTABILIDAD";
  reportData.poliza_contabilidad.clavesHead = clavesHead;
  reportData.poliza_contabilidad.colums = colums;
  tableInfo = reportData.poliza_contabilidad;
  plainText = createHeader(plainText, reportData.poliza_contabilidad);

  /* Create table header */
  plainText = createTableHeader(plainText, totalWidth, clavesHead, colums);

  /* Add the maxima of the columns to omit */
  const columnsToSkipforLabel = ["cuenta_contable", "clave_centro_beneficio"];
  let totalColumnsToSkipforLabel = 0;
  let spaceColumnsToSkipforLabel = "";
  const separationByColumn = 2;

  columnsToSkipforLabel.forEach(column => {
    totalColumnsToSkipforLabel += ((colums[column]?.maxLength || 0) + separationByColumn);
  });

  for (let index = 0; index < totalColumnsToSkipforLabel; index++) {
    spaceColumnsToSkipforLabel += " "
  }

  /* Fill table */
  plainText = pageControl(plainText, reportData.poliza_contabilidad);
  for (let level of categories) {
    // re order the columns
    const object = {
      cuenta_contable: level?.cuenta_contable || "",
      clave_centro_beneficio: level?.clave_centro_beneficio || "",
      nombre_centro_beneficio: level?.nombre_centro_beneficio || "",
      concepto: level?.concepto || "",
      descripcion: level?.descripcion || "",
      debe: level?.debe || "0.00",
      haber: level?.haber || "0.00",
    };
    plainText = pageControl(plainText, reportData.poliza_contabilidad);
    for (let param in object) {
      if (param == "debe" || param == "haber") {
        plainText += alignRight((level[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0)) + (level[param]?.toString() || "") + "  ";
      } else {
        plainText += (level[param]?.toString() || "") + alignLeft((level[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0));
      }
    }
  }

  const totalValuesLength = (colums["debe"]?.maxLength || 0) + (colums["haber"]?.maxLength || 0) + spaceColumnsToSkipforLabel.length + 18;
  const separationLabelValue = totalWidth - totalValuesLength;

  /* Add totals */
  plainText = pageControl(plainText, reportData.poliza_contabilidad);
  plainText = pageControl(plainText, reportData.poliza_contabilidad);
  plainText += spaceColumnsToSkipforLabel + "S U M A S. . ." + insertSpaces(separationLabelValue)
    + alignRight((reportData?.poliza_contabilidad?.suma?.debe?.toString()?.length || 0), (colums["debe"]?.maxLength || 0)) + (reportData?.poliza_contabilidad?.suma?.debe?.toString() || "") + "  "
    + alignRight((reportData?.poliza_contabilidad?.suma?.haber?.toString()?.length || 0), (colums["haber"]?.maxLength || 0)) + (reportData?.poliza_contabilidad?.suma?.haber?.toString() || "") + "  "
  /* Add observationsSection */
  plainText = pageControl(plainText, reportData.poliza_contabilidad);
  plainText = observationsSection(plainText, totalWidth);
  /* Add reviewers section */
  plainText = reviewersSection(plainText, totalWidth);

  /* Add footer */
  plainText = createFooter(plainText, totalWidth, reportData.poliza_contabilidad.num_poliza);

  return plainText
}

/**
 * Add all the content corresponding to the Folio De Caja
 * @param {string} plainText 
 * @returns string
 */
const folioDeCaja = (plainText) => {
  let colums = {}
  /* Obtaining table dimension values */
  const invoices = reportData?.folio_de_caja?.invoices?.length
    ? reportData.folio_de_caja.invoices
    : mockInvoicesData;
  for (let level of invoices) {
    for (let param in level) {
      const largoActual = (level[param]?.toString()?.length || 0);
      if (colums[param] && largoActual > colums[param].maxLength) {
        colums[param].maxLength = largoActual
      }
      if (!colums[param]) {
        let largoLlave = param.length;
        if (param == "monto") {
          largoLlave = Math.max(largoLlave, reportData.folio_de_caja.total.length + 2);
        }
        const maxLength = Math.max(largoLlave, largoActual);
        const colum = {
          maxLength: maxLength,
          name: param
        }
        colums[param] = colum;
      }
    }
  }
  let totalWidth = 0;
  const clavesHead = Object.keys(colums);
  clavesHead.forEach(key => {
    totalWidth += ((colums[key]?.maxLength || 0) + 2);
  });

  const maxLengthCycle = totalWidth / 4;
  if ((reportData.folio_de_caja.school_cycle_name.length + 15) > maxLengthCycle) {
    reportData.folio_de_caja.school_cycle_name = reportData.folio_de_caja.school_cycle_name.substring(0, maxLengthCycle - 16) + "...";
  }

  /* Creation page header */
  reportData.folio_de_caja.totalWidth = totalWidth;
  reportData.folio_de_caja.tableTitle = "PÓLIZA DE COBRANZA (RESUMEN FOLIO DE CAJA)";
  reportData.folio_de_caja.clavesHead = clavesHead;
  reportData.folio_de_caja.colums = colums;
  tableInfo = reportData.folio_de_caja;

  plainText = createHeader(plainText, reportData.folio_de_caja);

  /* Create table header */
  plainText = createTableHeader(plainText, totalWidth, clavesHead, colums);

  /* Add the maxima of the columns to omit */
  const columnsToSkip = ["f_fiscal", "recibo", "matricula"];
  const maxLengthForMonto = ["f_fiscal", "recibo", "matricula", "sec", "gdo", "gpo", "poliza", "monto"];

  let totalColumnsToSkip = 0;
  let totalColumnsToSkipForMonto = -2;

  let spaceColumnsToSkipforLabel = "";
  const separationByColumn = 2;

  maxLengthForMonto.forEach(column => {
    totalColumnsToSkipForMonto += ((colums[column]?.maxLength || 0) + separationByColumn);
  });

  columnsToSkip.forEach(column => {
    totalColumnsToSkip += ((colums[column]?.maxLength || 0) + separationByColumn);
  });

  for (let index = 0; index < totalColumnsToSkip; index++) {
    spaceColumnsToSkipforLabel += " "
  }

  /* Fill table */
  plainText = pageControl(plainText, reportData.folio_de_caja);
  for (let level of invoices) {
    plainText += "\n";
    for (let param in level) {
      if (param == "monto" || param == "cheque") {
        if (param == "monto") plainText += alignRight((level[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0), "$") + (level[param]?.toString() || "") + "  ";
        if (param == "cheque") plainText += alignRight((level[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0)) + (level[param]?.toString() || "") + "  ";
      } else {
        plainText += (level[param]?.toString() || "") + alignLeft((level[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0))
      }
    }
  }

  const totalValuesLength = (colums["monto"]?.maxLength || 0) + spaceColumnsToSkipforLabel.length + 19;
  const separationLabelValue = totalColumnsToSkipForMonto - totalValuesLength;

  /* Add totals */
  plainText = pageControl(plainText, reportData.folio_de_caja);
  plainText = pageControl(plainText, reportData.folio_de_caja);
  plainText += spaceColumnsToSkipforLabel + "T O T A L  . . . . " + insertSpaces(separationLabelValue)
    + alignRight(reportData.folio_de_caja.total.length, (colums["monto"]?.maxLength || 0), "$") + reportData.folio_de_caja.total + "  ";

  /* Add observationsSection */
  plainText = pageControl(plainText, reportData.folio_de_caja);
  plainText = observationsSection(plainText, totalWidth);
  /* Add signatureSection */
  plainText = pageControl(plainText, reportData.folio_de_caja);
  plainText = signatureSection(plainText, totalWidth);
  /* Add footer */
  plainText = createFooter(plainText, totalWidth, reportData.folio_de_caja.num_poliza);

  return plainText;
}

/**
 * Add all the content corresponding to the Recibos Cancelados
 * @param {string} plainText 
 * @returns string
 */
const recibosCancelados = (plainText) => {
  let colums = {}
  const canceled_payments = reportData?.recibos_cancelados?.canceled_payments || mockCancelledPayments;
  /* Obtaining table dimension values */
  for (let level of canceled_payments) {
    const object = {
      recibo: level?.recibo || "",
      matricula: level?.matricula || "",
      sgg: level?.sgg || "",
      concepto: level?.concepto || "",
      monto: level?.monto || "0.00",
      mes: level?.mes || "",
      justificacion_de_la_cancelacion: level?.justificacion_cancelacion || "",
      hora: level?.hora || "",
    };
    for (let param in object) {
      const largoActual = (level[param]?.toString()?.length || 0);
      if (colums[param] && largoActual > colums[param].maxLength) {
        colums[param].maxLength = largoActual
      }
      if (!colums[param]) {
        let largoLlave = param.length;
        if (param == "monto") {
          largoLlave = Math.max(largoLlave, reportData.recibos_cancelados.total.length + 2);
        }
        if (param == "concepto") {
          largoLlave += 10;
        }
        if (param == "justificacion_de_la_cancelacion") {
          largoLlave += 20;
        }
        const maxLength = Math.max(largoLlave, largoActual);
        const colum = {
          maxLength: maxLength,
          name: param
        }
        colums[param] = colum;
      }
    }
  }
  let totalWidth = 0;
  const clavesHead = Object.keys(colums);
  clavesHead.forEach(key => {
    totalWidth += ((colums[key]?.maxLength || 0) + 2);
  });

  const maxLengthCycle = totalWidth / 4;
  if ((reportData.recibos_cancelados.school_cycle_name.length + 15) > maxLengthCycle) {
    reportData.recibos_cancelados.school_cycle_name = reportData.recibos_cancelados.school_cycle_name.substring(0, maxLengthCycle - 16) + "...";
  }

  /* Creation page header */
  reportData.recibos_cancelados.totalWidth = totalWidth;
  reportData.recibos_cancelados.tableTitle = "RELACION DE RECIBOS CANCELADOS";
  reportData.recibos_cancelados.clavesHead = clavesHead;
  reportData.recibos_cancelados.colums = colums;
  tableInfo = reportData.recibos_cancelados;
  plainText = createHeader(plainText, reportData.recibos_cancelados);

  /* Create table header */
  plainText = createTableHeader(plainText, totalWidth, clavesHead, colums);

  /* Add the maxima of the columns to omit */
  const columnsToSkip = ["recibo", "matricula"];
  const maxLengthForMonto = ["recibo", "matricula", "sgg", "concepto", "monto"];
  let totalColumnsToSkip = 0;
  let totalColumnsToSkipForMonto = -2;
  let spaceColumnsToSkipforLabel = "";
  const separationByColumn = 2;

  maxLengthForMonto.forEach(column => {
    totalColumnsToSkipForMonto += ((colums[column]?.maxLength || 0) + separationByColumn);
  });
  columnsToSkip.forEach(column => {
    totalColumnsToSkip += ((colums[column]?.maxLength || 0) + separationByColumn);
  });

  for (let index = 0; index < totalColumnsToSkip; index++) {
    spaceColumnsToSkipforLabel += " "
  }


  /* Fill table */
  plainText = pageControl(plainText, reportData.recibos_cancelados);
  for (let level of canceled_payments) {
    const object = {
      recibo: level?.recibo || "",
      matricula: level?.matricula || "",
      sgg: level?.sgg || "",
      concepto: level?.concepto || "",
      monto: level?.monto || "0.00",
      mes: level?.mes || "",
      justificacion_de_la_cancelacion: level?.justificacion_cancelacion || "",
      hora: level?.hora || "",
    };
    plainText += "\n";
    for (let param in object) {
      if (param == "monto") {
        plainText += alignRight((object[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0), "$") + (object[param]?.toString() || "") + "  ";
      } else {
        plainText += (object[param]?.toString() || "") + alignLeft((object[param]?.toString()?.length || 0), (colums[param]?.maxLength || 0));
      }
    }
  }

  const totalValuesLength = (colums["monto"]?.maxLength || 0) + spaceColumnsToSkipforLabel.length + 19;
  const separationLabelValue = totalColumnsToSkipForMonto - totalValuesLength;

  /* Add totals */
  plainText = pageControl(plainText, reportData.recibos_cancelados);
  plainText = pageControl(plainText, reportData.recibos_cancelados);
  plainText += spaceColumnsToSkipforLabel + "T O T A L  . . . . " + insertSpaces(separationLabelValue)
    + alignRight(reportData.recibos_cancelados.total.length, (colums["monto"]?.maxLength || 0), "$") + reportData.recibos_cancelados.total + " ";

  /* Add observationsSection */
  plainText = pageControl(plainText, reportData.recibos_cancelados);
  plainText = observationsSection(plainText, totalWidth);

  /* Add signatureSection */
  plainText = pageControl(plainText, reportData.recibos_cancelados);
  plainText = signatureSection(plainText, totalWidth);

  /* Add footer */
  plainText = createFooter(plainText, totalWidth, reportData.recibos_cancelados.num_poliza);

  return plainText
}

const spolPolizaDeCorte = (plainText) => {
  const data = Object.entries(tableInfo).length > 0 ? tableInfo : mockDataRPOL;
  let totalWidth = 80;
  const headLine1 = "Cve de    Monto  Total";
  const headLine2 = "Póliza    de la Póliza";
  const separator = 7;
  const pageNumber = "Hoja: " + pageNumberGlobal;

  plainText += alignCenter("Lista  de  Pólizas  de  Cobranza", totalWidth) + "\n\n";
  plainText += data.organization_name + firstHeaderLine(data.organization_name, totalWidth, pageNumber) + pageNumber + "\n";
  plainText += data.main_issuer_name + secondHeaderLine(data.main_issuer_name, totalWidth) + "Fecha: " + data.readable_date + "\n";
  plainText += rfcCycleLine(data, totalWidth) + "\n";
  plainText += insertSpaces(totalWidth, "=") + "\n";
  plainText += headLine1 + insertSpaces(separator) + headLine1 + insertSpaces(separator) + headLine1 + "\n";
  plainText += headLine2 + insertSpaces(separator) + headLine2 + insertSpaces(separator) + headLine2 + "\n";
  plainText += insertSpaces(22, "=") + insertSpaces(separator) + insertSpaces(22, "=") + insertSpaces(separator) + insertSpaces(22, "=") + "\n";
  plainText += "\n"

  const reports = data.reports;

  let iteratorSwitch = 0
  for (let item of reports) {
    if (iteratorSwitch == 3) {
      plainText = pageControlSPOL(plainText);
      iteratorSwitch = 0;
    }
    plainText += item.cve_de_poliza + insertSpaces(4) + alignRight(item.monto_de_poliza.length, 12) + item.monto_de_poliza + insertSpaces(separator);
    iteratorSwitch++;
  }
  plainText += "\n\n"
  plainText += insertSpaces(22, "-") + insertSpaces(separator) + insertSpaces(22, "-") + insertSpaces(separator) + insertSpaces(22, "-") + "\n\n";

  const month =  moment(data.reportDate,"MM").format("MMMM").toUpperCase();
  const tagTotal = "Monto Total de Pagos Registrados en el Mes de " + month + " :";
  const spaceAvailableForTotal = totalWidth - tagTotal.length;
  plainText += tagTotal + alignRight(data.total_payments_month.length, spaceAvailableForTotal) + data.total_payments_month + "\n";

  plainText = observationsSection(plainText, totalWidth);
  plainText += "\n\n"
  plainText += "________________________" + insertSpaces(4) + "________________________" + insertSpaces(4) + "________________________"
  plainText += "\n";
  plainText += "     FIRMA CAJERA(O)" + insertSpaces(9) + "FIRMA ADMINISTRADOR(A)" + insertSpaces(10) + "FIRMA CONTADOR(A)        "

  plainText = createFooter(plainText, totalWidth, "");

  return plainText;
}

/* ================ Main method ================ */

/**
 * Main method for grouping tables
 * @returns txt file
 */
export const GenerateSapReport = (data, closeModal) => {
  pageNumberGlobal = 1;
  let plainText = "";
  reportData = data;
  if (data.corte_de_caja) {
    plainText = corteDeCaja(plainText);
  }
  if (data.poliza_contabilidad) {
    plainText = polizaContablidad(plainText);
  }
  if (data.folio_de_caja) {
    plainText = folioDeCaja(plainText);
  } if (data.recibos_cancelados) {
    plainText = recibosCancelados(plainText);
  }

  let enlace = document.createElement("a");
  enlace.setAttribute("href", "data:text/plain;charset=uft-8," + encodeURIComponent(plainText));
  enlace.setAttribute("download", "PC" + data.reportDate + " - Poliza de corte.txt");
  enlace.style.display = "none"
  document.body.appendChild(enlace)
  enlace.click();
  closeModal({ showModalReportSAP: false, });
}

export const GeneratePolizaCorteMensual = (data, closeModal) => {
  pageNumberGlobal = 1;
  tableInfo = data;
  let plainText = "";
  plainText = spolPolizaDeCorte(plainText);
  let enlace = document.createElement("a");
  enlace.setAttribute("href", "data:text/plain;charset=uft-8," + encodeURIComponent(plainText));
  enlace.setAttribute("download", "RPOL_" + data.reportDate + " - Poliza de corte.txt");
  enlace.style.display = "none"
  document.body.appendChild(enlace);
  enlace.click();
  closeModal({ showModalReportSAP: false, });
}
