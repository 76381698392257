import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../App";
import PropTypes from "prop-types";

import { Col, Container, Row, Button } from "react-bootstrap";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import DateRangeFilter from "../table/DateRangeFilter";
import ContentSolid from "../global/ContentSolid";
import ContentTable from "../table/ContentTable";
import SelectFilter from "../table/SelectFilter";
import { Table } from "../table/Table";
import * as moment from "moment";

// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ModalConfirmDeletedRecords from "../global/modals/ModalConfirmDeletedRecords";
import ModalNews from "./modals/ModalNews";
import ModalConfirmNews from "./modals/ModalConfirmNews";

//  services
import { getBlogPosts, DeleteBlogPosts } from "../../api/News";

//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};


const NewsView = () => {
  const [t] = useTranslation(["global", "news", "table", "reports"]);
  const componentRef = useRef(); //Create the reference for printing
  const [readyLanguages, setReadyLanguages] = useState(false);
  const { permissions } = useContext(MainContext);

  const [count, setCount] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [news, setNews] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [showEditColumns, setShowEditColumns] = useState(false);

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [requestFilter, setRequestFilter] = useState({});
  const [newsDetails, setNewsDetails] = useState({});
  const [showModalConfirmNews, setShowModalConfirmNews] = useState(false);
  const [mensaggesConfirm, setMensaggesConfirm] = useState({});

  // Define table columns
  const columns = React.useMemo(
    () => [
      {
        Header: t("news:newsPage.coverImage"),
        accessor: "image",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Filter: () => {
          return (
            <input
              disabled={true}
              placeholder={t("table:columnFilter.search")}
            />
          );
        },
      },
      {
        Header: t("news:newsPage.Title"),
        accessor: "title",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Cell: ({ cell: { value } }) => {
          return (
            <div
              style={{
                height: "35px",
                overflow: "hidden",
                display: "-webkit-box",
                webkitLineClamp: "2",
                webkitBoxOrient: "vertical",
              }}
            >{value}
            </div>
          );
        },
      },
      {
        Header: t("news:newsPage.Description"),
        accessor: "content",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Cell: ({ cell: { value } }) => {
          return (
            <div
              style={{
                height: "50px",
                overflow: "hidden",
                display: "-webkit-box",
                webkitLineClamp: "3",
                webkitBoxOrient: "vertical",
              }}
            >{value.replace(/<[^>]*>/g, ' ')}
            </div>
          );
        },
      },
      {
        Header: t("news:newsPage.Author"),
        accessor: "author_name",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
        Filter: (props) => <SelectFilter {...props} />,
      },
      {
        Header: t("news:newsPage.Categories"),
        accessor: "categories",
        minWidth: 100,
        width: 150,
        maxWidth: 200,
        Filter: (props) => <SelectFilter {...props} />,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value.map((category, i) => {
                return (
                  <div key={i}>
                    {category}
                  </div>
                );
              })}
            </td>
          );
        },
      },
      {
        Header: t("news:newsPage.Tags"),
        accessor: "tags",
        minWidth: 100,
        width: 150,
        maxWidth: 200,
        Filter: (props) => <SelectFilter {...props} />,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value.map((tag, i) => {
                return (
                  <div key={i}>
                    {tag}
                  </div>
                );
              })}
            </td>
          );
        },
      },
      {
        Header: t("news:newsPage.Dates"),
        accessor: "date",
        minWidth: 120,
        width: 140,
        maxWidth: 150,
        Filter: (props) => (
          <DateRangeFilter
            getByDateRange={getNews}
            {...props}
            defaultOptionId={11}
            options={
              [
                {
                  label: t("reports:status.allFeminine"),
                  value: 7,
                  startDate: null,
                  endDate: null,
                },
                {
                  label: t("reports:filterSelect.today"),
                  value: 2,
                  startDate: moment().startOf("day").unix(),
                  endDate: moment().endOf("day").unix(),
                },
                {
                  label: t("reports:filterSelect.lastSevenDays"),
                  value: 4,
                  startDate: moment().subtract(7, "day").startOf("day").unix(),
                  endDate: moment().endOf("day").unix(),
                },
                {
                  value: 11,
                  startDate: moment().subtract(30, "day").startOf("day").unix(),
                  endDate: moment().endOf("day").unix(),
                  label: t("news:newsPage.lastThirtyDay"),
                },
                {
                  label: t("reports:filterSelect.selectRange"),
                  value: 6,
                  startDate: "",
                  endDate: "",
                },
              ]
            }
          />
        ),
        filter: "dateBetween",
      },
      {
        Header: t("news:newsPage.Time"),
        accessor: "time",
        minWidth: 150,
        width: 200,
        maxWidth: 250,
      },
    ],
    [loadingView]
  );

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => news, [loading]);

  /**
   * Description: Function to open the modal to edit the news
   * @param {object} news 
   */
  const rowOnclick = (news) => {
    setNewsDetails(news);
    const editNews = {
      id: news.id,
      author: news.authorID,
      authorName: news.author_name,
      title: news.title,
      categories: news.categoriesForEdit,
      tags: news.tagsForEdit,
      coverImage: news.imageUrl,
      description: news.content,
      date: news.date,
      time: news.time,
    }
    setNewsDetails(editNews);
    setShowModalNew(true);
  };

  /**
   * Description: Function to get the news
   * @param {object} filters 
   */
  const getNews = (filters = requestFilter) => {
    setRequestFilter(filters);
    const request = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      start_time: filters.start_date,
      end_time: filters.end_date,
      no_limit: true,
    };
    setLoading(true);
    getBlogPosts(request)
      .then((response) => {
        if (!response.status) {
          return;
        }
        const dataNews = response.data.map((item) => {
          const categories = item.categories.map((category) => {
            return category.name;
          });
          const categoriesForEdit = item.categories.map((category) => {
            return {
              value: category.id,
              label: category.name,
            };
          });
          const tags = item.tags.map((tag) => {
            return tag.name;
          });
          const tagsForEdit = item.tags.map((tag) => {
            return {
              value: tag.id,
              label: tag.name,
            };
          });
          return {
            id: item.id,
            index: item.id,
            imageUrl: item.image,
            image: item.image ? <img width="100%" src={item.image} alt={item.title} style={{ objectFit: "scale-down", maxHeight: "50px" }} /> : "",
            title: item.title,
            content: item.content,
            authorID: item.author_id,
            author_name: item.author_name,
            categories: categories,
            categoriesForEdit: categoriesForEdit,
            tags: tags,
            tagsForEdit: tagsForEdit,
            date: moment(moment(item.date_gmt, "YYYY-MM-DD hh:mm:ss")).format("DD MMM YYYY"),
            time: moment.unix(item.unix_date_gmt).format("hh:mm a"),

          };
        });
        setNews(dataNews);
      })
      .finally(() => {
        setLoadingView(false);
        setLoading(false);
      });
  }

  /**
  * Purpose: Function to select or deselect all records
  */
  const selectAllRecords = () => {
    let selectAll = {
      switch: !selectAllRows.switch,
      value: !selectAllRows.value,
    };
    setSelectAllRows(selectAll);
  };

  /**
   * Description: Function to delete the selected news
   */
  const deleteNews = () => {
    const payload = {
      ids: selectedRowIds.map((news) => news.original.id),
    }
    DeleteBlogPosts(payload)
      .then((response) => {
        if (!response.status) {
          return;
        }
        getNews(requestFilter);
        setSelectedRowIds([]);
        setIsAllRowsSelected(false);
        setShowModalConfirmDeleted(false);
      })
      .finally(() => { });
  }

  /**
   * Description: Effect to load the initial data of the table
   */
  useEffect(() => {
    let requestFilters = {
      start_date: moment().subtract(30, "day").startOf("day").unix(),
      end_date: moment().endOf("day").unix(),
    };
    getNews(requestFilters);
  }, []);

  /**
   * Description: Effect to know if all prospects are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  // Effect for loading initial translations
  useEffect(() => {
    if (typeof t == "function") {
      setTimeout(() => {
        setReadyLanguages(true);
      }, 500);
    }
  }, [t]);

  return readyLanguages && (
    <Container fluid>
      <Row className="mb-3">
        <Col md={6}>
          {permissions.news.delete &&
            <Button
              id="downloadStudentCredentialsBtn"
              variant="outline-secondary"
              onClick={() => setShowModalConfirmDeleted(true)}
              disabled={!selectedRowIds.length}
            >
              {t("global:buttons.eliminate")}
            </Button>
          }
          {/* Total counter of records and selected records in Classes table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col md={6}>
          {permissions.news.create &&
            <Button
              id="downloadStudentCredentialsBtn"
              className="float-end"
              onClick={() => { setShowModalNew(true) }}
            // disabled={!count}
            >
              {t("news:newsPage.createNew")}
            </Button>
          }
        </Col>
      </Row>

      {loadingView ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable className="mt-3" startColumFilter={1} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            loading={loading}
            rowSelect={true}
            setSelectedRowIds={setSelectedRowIds}
            setIsAllRowsSelected={setIsAllRowsSelected}
            selectAllRows={selectAllRows}
            // limitRowsShown={5}
            rowOnclick={rowOnclick}
            showEditColumns={showEditColumns} //Flag for the modal "Edit List Columns"
            setShowEditColumns={setShowEditColumns} //Toggle function of the "Edit List Columns" modal
            showColumnGear={true}
            setRowsDisplayed={setCount}
            enableCheckToSelectAllRecords={true}
            selectAllRecords={selectAllRecords}
          ></Table>
        </ContentTable>
      )}

      {/* modals */}
      {showModalNew &&
        <ModalNews
          showModalNew={showModalNew}
          setShowModalNew={(action) => {
            setNewsDetails({});
            setShowModalNew(false);
            if (action === "create") {
              setMensaggesConfirm({
                title: t("news:modalConfirm.newsCreated"),
                message: t("news:modalConfirm.nwsCreatedMenssage")
              })
              setShowModalConfirmNews(true);
            }
            if (action === "update") {
              setShowModalConfirmNews(true);
              setMensaggesConfirm({
                title: t("news:modalConfirm.savedChanges"),
                message: t("news:modalConfirm.editMessage")
              })
            }
          }}
          newsDetails={newsDetails}
          getNews={() => getNews(requestFilter)}
          readOnly={!permissions.news.edit}
        />
      }

      {showModalConfirmDeleted &&
        <ModalConfirmDeletedRecords
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          confirmAction={deleteNews}
          title={t("news:modalNew.removeNews")}
          message={t("news:modalNew.removeMessage")}
          confirmText={t("global:buttons.eliminate")}
          confirmTextLoading={t("global:buttons.eliminating")}
        />
      }

      {showModalConfirmNews &&
        <ModalConfirmNews
          showModalConfirmNews={showModalConfirmNews}
          setShowModalConfirmNews={setShowModalConfirmNews}
          mensaggesConfirm={mensaggesConfirm}
        />}
    </Container>
  );
}
export default NewsView;
NewsView.propTypes = {
  cell: PropTypes.object,
};