import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { goToHref, validateUserRoleIds } from "../../../components/global/GlobalTools";

export default function ModalStaffCreated(props) {
  const [t] = useTranslation(["staff", "global", "modalCreateStudent"]);

  /**
   * Redirects to version 1
   * @param {obj} profile
   */
  const goApp1 = (profile) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/staff/${profile}`);
  };

  /**
   * Display url with users by id
   */
  const viewProfile = () => {
    const path =
    validateUserRoleIds([7], null, "||", true)
        ? props.newUserCreated.id + "&" + props.newUserCreated.groups[0].id
        : props.newUserCreated.id;
    goApp1(path);
  };

  return (
    <div>
      <Modal
        show={props.showModalStaffConfirmations}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Title className="modal-title ms-3 mt-3">
          {t("modalConfirmationsStaff.titleConfirmationsModal")}
        </Modal.Title>
        <Modal.Body>
          <div>
            <p
              className="mt-4"
              style={{ fontWeight: "lighter", fontSize: "14px" }}
            >
              {t("modalConfirmationsStaff.textConfirmationsModal")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => props.setShowModalStaffConfirmations(false)}
          >
            {t("global:buttons.close")}
          </Button>
          <Button variant="primary" onClick={() => viewProfile()}>
            {t("modalConfirmationsStaff.viewProfileText")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              props.setShowModalStaff(true);
              props.setShowModalStaffConfirmations(false);
            }}
          >
            {t("modalCreateStudent:successModal.addNew")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalStaffCreated.propTypes = {
  showModalStaffConfirmations: PropTypes.bool,
  setShowModalStaffConfirmations: PropTypes.func,
  setShowModalStaff: PropTypes.func,
  newUserCreated: PropTypes.func,
};
