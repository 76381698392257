import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { goToHref } from "../../../components/global/GlobalTools";

/**Services */
import { GetPdfAccountStatus } from "../../../api/accountStatus";

export default function ModalAccountStatus(props) {
  const [t] = useTranslation(["accountStatus", "global"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  let today = moment().format("MMMM DD, YYYY");
  const columns = [
    { name: t("accountStatus:labels.sku") },
    { name: t("accountStatus:labels.concepts") },
    { name: t("accountStatus:labels.dueDate") },
    { name: t("accountStatus:labels.toBePaid") },
    { name: t("accountStatus:labels.scholarship") },
    { name: t("accountStatus:labels.surcharge") },
    { name: t("accountStatus:labels.payment") },
    { name: t("accountStatus:labels.balance") },
  ];

  //Custom styles for text and cells
  const customStyles = {
    textRightBold: {
      textAlign: "right",
      fontWeight: "bold",
    },
    textLeftBold: {
      textAlign: "left",
      fontWeight: "bold",
    },
    textLeft: {
      textAlign: "left",
    },
    textRight: {
      textAlign: "right",
    },
    smCell: {
      width: "5%",
      padding: "0px",
    },
    mdCell: {
      width: "10%",
      padding: "0px",
    },
    lgCell: {
      width: "15%",
      padding: "0px",
    },
    eLgCell: {
      width: "20%",
      padding: "0px",
    },
  };

  useEffect(() => {}, []);

  /**Close modal */
  const closeModal = () => {
    props.showAccountStatusModal();
  };

  /**Go to make student payment*/
  const makeStudentPayment = () => {
    goToHref("/payments?student=" + props.dataAccountStatus.student.id);
  };

  /**Download PDF*/
  const downloadPdfAccountStatus = () => {
    GetPdfAccountStatus(
      props.dataAccountStatus.student.id,
      "document.pdf",
      false
    ).then(() => {});
  };

  return (
    <div>
      <Modal
        show={props.showAccountStatus}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
        size="lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">
            {t("accountStatus:labels.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body mbottom10">
          {props.dataAccountStatus ? (
            <div
              style={{
                border: "1px solid #d0d5d6",
                padding: "10px",
              }}
            >
              <Row>
                <Col xs={2}>
                  <div style={customStyles.textRightBold}>
                    {t("accountStatus:labels.student")}
                  </div>
                </Col>
                <Col xs={4}>
                  <div style={customStyles.textLeft}>
                    {props.dataAccountStatus.fullStudentName}
                  </div>
                </Col>
                <Col xs={4}>
                  <div style={customStyles.textRightBold}>
                    {t("accountStatus:labels.balance") + ":"}
                  </div>
                </Col>
                <Col xs={2}>
                  <div style={customStyles.textRight}>
                    {"$" + props.dataAccountStatus.balance_amount}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <div style={customStyles.textRightBold}>
                    {t("accountStatus:labels.schoolLevel")}
                  </div>
                </Col>
                <Col xs={3}>
                  <div style={customStyles.textLeft}>
                    {props.dataAccountStatus.student.school_level}
                  </div>
                </Col>
                <Col xs={5}>
                  <div style={customStyles.textRightBold}>
                    {t("accountStatus:labels.balanceDueAt") + " " + today + ":"}
                  </div>
                </Col>
                <Col xs={2}>
                  <div style={customStyles.textRight} className="text-danger">
                    {"$" + props.dataAccountStatus.overdue_amount}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <div style={customStyles.textRightBold}>
                    {t("accountStatus:labels.grade")}
                  </div>
                </Col>
                <Col xs={4}>
                  <div style={customStyles.textLeft}>
                    {props.dataAccountStatus.student.grade}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <div style={customStyles.textRightBold}>
                    {t("accountStatus:labels.group")}
                  </div>
                </Col>
                <Col xs={4}>
                  <div style={customStyles.textLeft}>
                    {props.dataAccountStatus.student.group}
                  </div>
                </Col>
                <Col xs={6}>
                  <div
                    style={{
                      textAlign: "right",
                      position: "relative",
                      width: "100%",
                      bottom: "20px",
                    }}
                  >
                    <Button
                      variant="primary"
                      onClick={() => makeStudentPayment()}
                      style={{ width: "35%", height: "35px" }}
                    >
                      {t("global:buttons.pay")}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                <Col xs={6}>
                  <div style={customStyles.textLeftBold}>
                    {t("accountStatus:labels.summary")}
                  </div>
                </Col>
                <Col xs={6}>
                  <div style={customStyles.textRight}>
                    <Button
                      variant="info"
                      onClick={() => downloadPdfAccountStatus()}
                      style={{
                        width: "35%",
                        height: "35px",
                        marginLeft: "5px",
                      }}
                    >
                      <span style={{ marginLeft: "5px" }}>
                        {t("global:buttons.download")}
                      </span>
                      <i
                        className="bi bi-download"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row style={{ padding: "10px", marginTop: "20px" }}>
                <table>
                  <thead>
                    {columns.map((colum, index) => (
                      <th key={index} style={{ textAlign: "center" }}>
                        <p className="title-header">{colum.name}</p>
                      </th>
                    ))}
                  </thead>
                  <tbody>
                    {props.dataAccountStatus.balance_charges.length > 0
                      ? props.dataAccountStatus.balance_charges.map(
                          (charge, index) => (
                            <tr key={index}>
                              <td style={customStyles.smCell}>
                                <p className="list-text">{charge.sku}</p>
                              </td>
                              <td style={customStyles.eLgCell}>
                                <p className="list-text">
                                  {charge.sub_concept_name}
                                </p>
                              </td>
                              <td style={customStyles.lgCell}>
                                <p className="list-text">
                                  {charge.due_date ? charge.due_date : "-"}
                                </p>
                              </td>
                              <td style={customStyles.mdCell}>
                                <p className="list-text">
                                  {"$" + charge.sub_concept_amount}
                                </p>
                              </td>
                              <td style={customStyles.mdCell}>
                                <p className="list-text">
                                  {"$" + charge.studentship}
                                </p>
                              </td>{" "}
                              <td style={customStyles.mdCell}>
                                <p className="list-text">
                                  {"$" + charge.surcharge_amount}
                                </p>
                              </td>{" "}
                              <td style={customStyles.mdCell}>
                                <p
                                  className="list-text"
                                  style={{ color: "green" }}
                                >
                                  {"$" + charge.previous_paid}
                                </p>
                              </td>
                              <td style={customStyles.mdCell}>
                                <p
                                  className="list-text"
                                  style={{ color: "red" }}
                                >
                                  {"$" + charge.balance}
                                </p>
                              </td>
                            </tr>
                          )
                        )
                      : ""}
                  </tbody>
                </table>
              </Row>
            </div>
          ) : (
            <Col xs={12}>
              <div style={{ textAlign: "center" }}>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  style={{
                    width: "0.81rem",
                    height: "0.81rem",
                    marginLeft: "3px",
                    borderWidth: "0.17em",
                  }}
                />
              </div>
            </Col>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="pull-left">
            <Button variant="outline-secondary" onClick={() => closeModal()}>
              {t("global:buttons.cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalAccountStatus.propTypes = {
  showAccountStatus: PropTypes.bool,
  dataAccountStatus: PropTypes.object,
  showAccountStatusModal: PropTypes.func,
};
