import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import ExportToExcel from "../../components/table/ExportToExcel";
import { Table } from "../../components/table/Table";
import InputFilter from "../../components/table/InputFilter";
import ContentTable from "../../components/table/ContentTable";
import SelectFilter from "../../components/table/SelectFilter";
import ModalConfirmDeletedRecords from "../../components/global/modals/ModalConfirmDeletedRecords";
import ContentSolid from "../../components/global/ContentSolid";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Services
 */
import {
  GetTypeScholarships,
  DeleteScholarships,
} from "../../api/PaymentsScholarships";
import { GetSchoolCycle } from "../../api/Cycles";

//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const TypeScholarships = () => {
  const componentRef = useRef();
  const [t] = useTranslation(["scholarships", "global", "table"]);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const [cycles, setCycles] = useState([]);
  const [cycleSelected, setCycleSelected] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: "becasReport",
  };
  const [filterCycleId, setFilteCycleId] = useState(false); //checar como se manejara en el button
  const [loadingView, setLoadingView] = useState(true);
  const prefix = process.env.REACT_APP_PREFIX;

  /**
   * Stores the table column data in memory, and is not updated unless useMemo identifies a change in the data.
   */
  const columns = React.useMemo(
    () => [
      {
        Header: t("table:debtReport.scholarship"),
        accessor: "scholarshipName",
        Filter: (props) => <InputFilter {...props} />,
        width: 150,
        minWidth: 150,
        maxWidth: 180,
      },
      {
        Header: t("viewScholarships.textGrant"),
        accessor: "benefit",
        Filter: (props) => <SelectFilter {...props} />,
        maxWidth: 150,
        minWidth: 200,
        width: 200,
      },
      {
        Header: t("textTitleTable.textCategory"),
        accessor: "category",
        Filter: (props) => <SelectFilter {...props} />,
        maxWidth: 150,
        minWidth: 200,
        width: 200,
      },
      {
        Header: t("textTitleTable.textConcept"),
        accessor: "concept",
        Filter: (props) => <InputFilter {...props} />,
        width: 150,
        minWidth: 150,
        maxWidth: 180,
      },
      {
        Header: t("textTitleTable.textSubconcept"),
        accessor: "subconcept",
        Filter: (props) => <InputFilter {...props} />,
        width: 150,
        minWidth: 150,
        maxWidth: 180,
      },
      {
        Header: t("viewScholarships.textPorcentage"),
        accessor: "percentage",
        Filter: (props) => <InputFilter {...props} />,
        width: 150,
        minWidth: 150,
        maxWidth: 180,
      },
    ],
    [loading]
  );

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  /**
   * Gets the data to display in the table
   * @param {*} cycleId
   */
  const getScholarships = (cycleId = filterCycleId) => {
    let request = {
      limit: 0,
    };
    if (cycleId) {
      request.school_cycle_ids = [cycleId];
      setFilteCycleId(cycleId);
    }
    setLoading(true);
    GetTypeScholarships(request).then((result) => {
      if (result && result.description) {
        setError(result.description);
      } else {
        let data = result.data;
        setCount(data.length);
        data.forEach((item) => {
          item.index = item.id;
          item.scholarshipName = item.scholarship_name || "N/A";
          item.benefit = item.scholarship_type || "N/A";
          item.category = item.category_name || "N/A";
          item.concept = item.concepts || "N/A";
          item.subconcept = item.subconcepts || "N/A";
          item.percentage = item.discount || "N/A";
          item.linkToPage = `/scholarship/${item.id}`;
        });
        setDataTable(data);
      }
      setLoading(false);
      setLoadingView(false);
    });
  };

  /**
   * Function to call the service to delete records
   */
  const deleteScholarships = () => {
    const request = {
      scholarship_ids: selectedRowIds.map((item) => item.original.id),
    };
    DeleteScholarships(request).then(() => {
      setLoading(true);
      getScholarships();
      setShowModalConfirmDeleted(false);
    });
  };

  /**
   * Function that brings the current cycle
   * @param {array} cycles
   * @returns
   */
  const getCurrentCycle = (cycles) => {
    let currentCycle = false;
    const today = moment().unix();
    for (let cycle of cycles) {
      if (
        today > cycle.start_time &&
        today < cycle.end_time &&
        cycle.current_cycle == 1
      ) {
        currentCycle = cycle;
      }
    }
    if (!currentCycle) {
      currentCycle = cycles.find((cycle) => cycle.current_cycle === "1");
    }
    if (currentCycle) currentCycle.label = currentCycle.school_cycle;
    return currentCycle;
  };

  const getSchoolCycle = () => {
    GetSchoolCycle().then((result) => {
      let data = result.data;
      const cycles = data.map((item) => {
        return {
          ...item,
          label: item.school_cycle,
          value: item.id,
          id: item.id,
        };
      });
      let currentCycle = getCurrentCycle(data);
      setCycleSelected(currentCycle);
      setCycles(cycles);
      getScholarships(currentCycle.id);
      // Refresh school cycles in the localStorage
      localStorage.setItem(`cycles${prefix}`, JSON.stringify(cycles));
    });
  };

  /**
   * Function for cycle selection by id
   * @param {Object} cycle
   */
  const selectCycle = (cycle) => {
    setCycleSelected(cycle);
    getScholarships(cycle.id);
  };

  /**
   * Effect to get the cycles
   */
  useEffect(() => {
    getSchoolCycle();
  }, []);

  /*
   * Effect to be able to select and deselect all
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  return (
    <div>
      <Row className="mb-3">
        <Col xs={12} md={6} style={{ position: "relative" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              let selectAll = {
                switch: !selectAllRows.switch,
                value: !selectAllRows.value,
              };
              setSelectAllRows(selectAll);
            }}
          >
            {!isAllRowsSelected
              ? t("global:buttons.selectAll")
              : t("global:buttons.unselectAll")}
          </Button>
          {selectedRowIds.length > 0 && (
            <Button
              className="ms-3"
              variant="outline-secondary"
              onClick={() => setShowModalConfirmDeleted(true)}
            >
              {t("global:titlePermissions.delete")}
            </Button>
          )}

          <Dropdown className="ms-3" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="downloadOptionCycle"
              variant="outline-secondary"
            >
              {cycleSelected?.label || t("cycles:select.placeholder")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {cycles.map((cycle, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    eventKey={cycle.value}
                    onClick={() => selectCycle(cycle)}
                  >
                    {cycle.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {/* Total counter of records and selected records in Type of Scholarships table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col xs={12} md={6} style={{ position: "relative" }}>
          <div style={{ paddingTop: "5px", float: "right" }}>
            <Dropdown style={{ display: "inline-block", marginRight: "10px" }}>
              <Dropdown.Toggle
                id="downloadOptionPrint"
                variant="outline-secondary"
                disabled={printExport}
              >
                <i className="bi bi-download"></i>
                {t("global:buttons.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu disabled={printExport}>
                <ReactToPrint
                  trigger={() => (
                    <Dropdown.Item>
                      {t("global:buttons.downloadPDF")}
                    </Dropdown.Item>
                  )}
                  content={() => componentRef.current}
                />
                <ExportToExcel
                  exportData={exportData}
                  exportOptions={exportOptions}
                  printExport={printExport}
                  typeElement="dropdownItem"
                />
              </Dropdown.Menu>
            </Dropdown>
            <a href="/scholarship/new">
              <Button
                variant="primary"
                onClick={() => history.push("/scholarship/new")}
              >
                {t("viewScholarships.typeScholarship")}
              </Button>
            </a>
          </div>
        </Col>
      </Row>

      {/* TABLE */}

      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          {error}
          <ContentTable startColumFilter={1} lastColumFilter={8}>
            <Table
              data={data}
              rowSelect={true}
              loading={loading}
              columns={columns}
              ref={componentRef}
              showColumnGear={true}
              selectAllRows={selectAllRows}
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              showEditColumns={showEditColumns}
              setShowEditColumns={setShowEditColumns}
              setSelectedRowIds={setSelectedRowIds}
              setIsAllRowsSelected={setIsAllRowsSelected}
              rowOnclick={(row) => history.push(`/scholarship/${row.index}`)}
              isLinked={true}
              isLowerColumns={true}
            />
          </ContentTable>
        </>
      )}
      {showModalConfirmDeleted && (
        <ModalConfirmDeletedRecords
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          confirmAction={deleteScholarships}
          items={selectedRowIds}
          title={t("modalRemoveSholarships.titleModal")}
          message={t("modalRemoveSholarships.textModal")}
        />
      )}
    </div>
  );
};

TypeScholarships.propTypes = {
  cell: PropTypes.object,
};

export default TypeScholarships;
