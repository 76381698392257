import styled from "styled-components";
import {} from "react-bootstrap";

const ContentCalendar = styled.div`
  position: absolute;
  top: 8px;
  right: 0px;
  z-index: 99;
  width: 350px;
  display: ${(props) => props.show};

  .react-calendar {
    border-radius: 4px;
    border: 0px solid transparent;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%),
      0 4px 11px hsl(0deg 0% 0% / 10%);
    font-family: var(--font-family-mulish);
  }

  .react-calendar--doubleView .react-calendar__viewContainer {
    margin: 0;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    margin: 0.5em 0;
    padding: 0 0.5em;
  }

  .react-calendar--doubleView
    .react-calendar__viewContainer
    .react-calendar__month-view:nth-child(2) {
    border-left: 1px solid var(--gray-primary);
  }

  .react-calendar__month-view__weekdays {
    font-size: 0.65em;
    font-weight: 800;
  }
  .react-calendar__tile {
    padding: 0.5em 0.5em;
    color: var(--black-primay);
    font-size: 13px;
  }

  .react-calendar__tile--active {
    background: var(--red-light);
    color: var(--red-primary);
  }
  .react-calendar__tile:disabled {
    background-color: transparent;
    color: #c8c1c1;
  }

  .react-calendar__month-view__days__day--weekend {
    color: var(--black-primay);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--disabled);
  }

  .react-calendar__tile--now {
    background: var(--red-primary);
    color: #ffffff;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: var(--red-middle);
  }
`;

export default ContentCalendar;
