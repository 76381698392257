import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TextLoading from "../../../components/global/TextLoading";

import PropTypes from "prop-types";

export default function SaveChangesModal(props) {
  const [t] = useTranslation(["reportCards", "global"]);
  return (
    <Modal
      show={props.showSaveChanges}
      size="lg"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title>{t("reportCards:saveChangesModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("reportCards:saveChangesModal.message", {
          name: props.templateName,
        })}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            variant="light"
            onClick={() => props.setShowSaveChanges(false)}
            disabled={props.saving}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={props.saveTemplate}
            disabled={props.saving}
          >
            {props.saving ? (
              <TextLoading
                text={t("global:buttons.saving")}
                variant="light"
              />
            ) : (
              t("global:buttons.save")
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

SaveChangesModal.propTypes = {
  showSaveChanges: PropTypes.bool,
  setShowSaveChanges: PropTypes.func,
  saveTemplate: PropTypes.func,
  templateName: PropTypes.string,
  saving: PropTypes.bool,
};
