import React, { useEffect, forwardRef, useRef } from "react";
import PropTypes from "prop-types";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the Table)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components

export const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, index, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    const name =
      IndeterminateCheckbox.displayName || IndeterminateCheckbox.name;
    forwardRef.displayName = `logProps(${name})`;
    IndeterminateCheckbox.propTypes = {
      indeterminate: PropTypes.object,
      index: PropTypes.number,
    };

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="v2">
        <input
          type="checkbox"
          id={`check${index}`}
          ref={resolvedRef}
          {...rest}
        />
        <label htmlFor={`check${index}`}></label>
      </div>
    );
  }
);

IndeterminateCheckbox.propTypes = {};
