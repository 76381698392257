import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import PropTypes from "prop-types";

export default function Label(props) {
  //const [t] = useTranslation(["table"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  //const { filterValue, setFilter } = props.column;
  const [values, setValues] = useState(props.values);

  /**Initial loading */
  useEffect(() => {
    setValues(props.values);
  }, []);

  return (
    <Badge bg={props.bg} text={props.text} style={props.style}>
      {values}
    </Badge>
  );
}

Label.propTypes = {
  values: PropTypes.string,
  bg: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
};
