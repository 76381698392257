import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Dropdown,
  Button,
} from "react-bootstrap";
import TodayModule from "../../components/dashboard/relative/Today/TodayModule";
import { useTranslation } from "react-i18next";
import UserInfo from "../../components/dashboard/UserInfo";
import StudentInfo from "../../components/dashboard/StudentInfo";
import ClassView from "../../components/dashboard/ClassView";
import EventsView from "../../components/dashboard/EventsView";
import NewsView from "../../components/dashboard/NewsView";
import { GetServices } from "../../api/Organizations";
import Cookies from "js-cookie";
import OverlayBlockModule from "../../components/dashboard/admin/OverlayBlockModule";
import {
  GetUserRestriction,
  domainCookie,
  getFullName,
  getUrlParamValue,
  itsMyBirthday,
} from "../../components/global/GlobalTools";
import BirthdayModal from "../../components/global/modals/BirthdayModal";

const customStyles = {
  baseContainer: {
    padding: "10px 20px 0px 20px",
  },
  moduleContainerRelativeStudent: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    height: "260px",
  },
  moduleContainerClass: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    minHeight: "570px",
  },
  moduleContainerEvents: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    height: "303px",
    marginBottom: "20px",
  },
  moduleContainerNews: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    minHeight: "248px",
  },
};

const DashboardRelative = () => {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["dashboardStudent"]);
  const userId = localStorage.getItem(`cmUserID${prefix}`);
  const [studentSelected, setStudentSelected] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [modulesActive, setModulesActive] = useState({});
  const [modulesRealy, setModulesRealy] = useState(false);
  const [hasDebtRestriction, setHasDebtRestriction] = useState(false);
  const [refreshClasses, setRefreshClasses] = useState(false);
  const [loadClassModule, setLoadClassModule] = useState(false);
  const [showBirthdayModal, setShowBirthdayModal] = useState(false);
  const [birthdayBoys, setBirthdayBoys] = useState([]);
  const [nameBirthdayBoys, setNameBirthdayBoys] = useState("");

  const loadOrganizationServices = () => {
    let orgServices = {};
    GetServices(Cookies.get(`cmOrganizationID${prefix}`)).then((response) => {
      if (response.status) {
        localStorage.removeItem(`${prefix}Services`);
        let servicesUpdated = {};
        for (let service of response.data) {
          switch (service.service_id) {
            case "1": {
              //SIE
              servicesUpdated.SIE = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "2": {
              //Website
              servicesUpdated.Website = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "3": {
              //Payments
              servicesUpdated.Payments = {
                id: service.service_id,
                active: service.premium == "0" ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "4": {
              //App
              servicesUpdated.App = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "5": {
              //Reviews
              servicesUpdated.Reviews = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "6": {
              //Chat
              servicesUpdated.Chat = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "7": {
              //DocuSing
              servicesUpdated.DocuSing = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "8": {
              //SCE
              servicesUpdated.SCE = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "9": {
              //CRM
              servicesUpdated.CRM = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "10": {
              //DigitalContent
              servicesUpdated.DigitalContent = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "11": {
              //GoogleForEducations
              servicesUpdated.GoogleForEducations = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
          }
        }
        localStorage.setItem(
          `${prefix}Services`,
          JSON.stringify(servicesUpdated)
        );
        for (let item in servicesUpdated) {
          orgServices[item] = servicesUpdated[item].active;
        }
      } else {
        let services = JSON.parse(localStorage.getItem(`${prefix}Services`));
        for (let item in services) {
          orgServices[item] = services[item].active;
        }
      }
    });
    setModulesActive(orgServices);
    setTimeout(() => {
      setModulesRealy(true);
    }, 500);
  };

  /**
   * Function to select a student
   * @param {object} item
   */
  const selectStudent = (item) => {
    setStudentSelected(item);
    setRefreshClasses(true);
    const filterDashboard = JSON.parse(
      localStorage.getItem(`cmFiltersDashboard${prefix}`)
    );
    const filters = filterDashboard || {};
    filters.student = item;
    localStorage.setItem(
      `cmFiltersDashboard${prefix}`,
      JSON.stringify(filters)
    );
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    getUserData();
    loadOrganizationServices();
    // Get Restraction value from user session
    let hasDebtRestriction = GetUserRestriction();
    // Validate restriction
    hasDebtRestriction =
      hasDebtRestriction &&
      typeof hasDebtRestriction == "string" &&
      hasDebtRestriction !== "false"
        ? true
        : false;
    if (hasDebtRestriction) {
      setHasDebtRestriction(hasDebtRestriction);
    }
  }, []);

  useEffect(() => {
    if (studentSelected.id) setLoadClassModule(true);
  }, [studentSelected]);

  const getUserData = () => {
    let user = null;
    user = JSON.parse(localStorage.getItem(`cmUserProfile${prefix}`));
    if (!user) {
      setTimeout(() => getUserData(), 200);
    } else {
      validateBirthday(user);
    }
  };

  /**
   * Evaluate if a user has a birthday
   */
  const validateBirthday = (userInfo) => {
    // comprobate if the user has a birthday
    let birthdayBoys = [];
    if (itsMyBirthday(userInfo.birth_date)) {
      birthdayBoys.push({
        id: userInfo.id,
        name: getFullName(userInfo),
        firstName: userInfo.first_name.split(" ")[0],
      });
    }
    // comprobate if the user has a student with birthday
    if (userInfo.student && Array.isArray(userInfo.student)) {
      for (const student of userInfo.student) {
        // filters out students who have a birthday and are at a basic level
        if (
          student.its_basic_level == "1" &&
          itsMyBirthday(student.birth_date)
        ) {
          const user = {
            id: student.id,
            name: getFullName(student),
            firstName: student.first_name.split(" ")[0],
          };
          birthdayBoys.push(user);
        }
      }
    }
    setBirthdayBoys(birthdayBoys);
    // save the first name of those who have birthdays
    const nameBirthdayBoys = birthdayBoys
      .map((person) => person.firstName)
      .join(", ");
    setNameBirthdayBoys(nameBirthdayBoys);
  };

  // Show modal when you have a birthday boy and come from login
  useEffect(() => {
    if (
      nameBirthdayBoys &&
      (Cookies.get(`cmSourceView${prefix}`) === "login" ||
        getUrlParamValue("show") === "birthday")
    ) {
      toggleBirthdayModal();
      // Clean cookie
      Cookies.remove(`cmSourceView${prefix}`, {
        domain: domainCookie(),
        path: "/",
      });
      // Clean url param
      history.replaceState(
        null,
        document.title,
        location.origin + location.pathname
      );
    }
  }, [nameBirthdayBoys]);

  /**
   * Function to show or hide BirtdayModal
   */
  const toggleBirthdayModal = () => {
    setShowBirthdayModal((status) => !status);
  };

  return (
    <Container fluid>
      {modulesRealy && (
        <Row>
          <Col md={10} className="border-end">
            <Row>
              <Col md={4}>
                <p className="fs-5">{t("titleWelcome.welcome")}</p>
              </Col>
              <Col
                md={4}
                className="text-center"
                style={{ marginRight: "-3px" }}
              >
                {nameBirthdayBoys && (
                  <Button
                    onClick={toggleBirthdayModal}
                    size="lg"
                    style={{
                      backgroundColor: "#10B981",
                      color: "#fff",
                    }}
                    variant="outline-light"
                  >
                    <i className="fal fa-birthday-cake fa-lg" />
                    {t("birthdayModal.todayIsYouBirthday", {
                      name: nameBirthdayBoys,
                    })}
                  </Button>
                )}
              </Col>
              <Col md={4}>
                <FormGroup className="float-end">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="levelProgram"
                      variant="outline-secondary"
                      className="text-truncate"
                    >
                      {studentSelected.name
                        ? studentSelected.name
                        : "Selecciona el estudinte"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {studentList.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item}
                            onClick={() => selectStudent(item)}
                            className="text-truncate"
                          >
                            {item.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={7} xl={7} style={customStyles.baseContainer}>
                <Row style={customStyles.moduleContainerRelativeStudent}>
                  <Col sm={12} md={12} xl={12}>
                    <UserInfo
                      userId={userId}
                      setStudentSelected={setStudentSelected}
                      setStudentList={setStudentList}
                      setHasDebtRestriction={setHasDebtRestriction}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={5} xl={5} style={customStyles.baseContainer}>
                <Row style={customStyles.moduleContainerRelativeStudent}>
                  <Col sm={12} md={12} xl={12}>
                    <StudentInfo studentSelected={studentSelected} />
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Class section  */}
            <Row>
              <Col sm={12} md={7} xl={7} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.SCE}
                  textButton={t("dashboardStudent:textModals.textSeeMore")}
                  originRequest="classes"
                  nameService="SCE"
                  customWidth="103%"
                  content={
                    <Row style={customStyles.moduleContainerClass}>
                      <Col sm={12} md={12} xl={12}>
                        <ClassView
                          studentId={studentSelected.id}
                          active={modulesActive.SCE}
                          hasDebtRestriction={hasDebtRestriction}
                          refreshClasses={refreshClasses}
                          setRefreshClasses={setRefreshClasses}
                          loadClassModule={loadClassModule}
                        />
                      </Col>
                    </Row>
                  }
                />
              </Col>

              {/* Event section */}
              <Col sm={12} md={5} xl={5} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.App}
                  textButton={t("dashboardStudent:textModals.textSeeMore")}
                  originRequest="events_and_news"
                  nameService="App"
                  customWidth="105%"
                  content={
                    <Row style={customStyles.moduleContainerEvents}>
                      <Col sm={12} md={12} xl={12}>
                        <EventsView active={modulesActive.App} />
                      </Col>
                    </Row>
                  }
                />

                {/* News section */}

                <Col sm={12} md={12} xl={12}>
                  <OverlayBlockModule
                    active={modulesActive.App}
                    textButton={t("dashboardStudent:textModals.textSeeMore")}
                    originRequest="events_and_news"
                    nameService="App"
                    new={"new"}
                    customWidth="105%"
                    content={
                      <Row style={customStyles.moduleContainerNews}>
                        <Col sm={12} md={12} xl={12}>
                          <NewsView active={modulesActive.App} />
                        </Col>
                      </Row>
                    }
                  />
                </Col>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={2} xl={2}>
            <TodayModule
              modulesActive={modulesActive}
              active={modulesActive.App && modulesActive.SCE}
            />
          </Col>
        </Row>
      )}
      {showBirthdayModal && (
        <BirthdayModal
          showBirthdayModal={showBirthdayModal}
          toggleBirthdayModal={toggleBirthdayModal}
          birthdayBoys={birthdayBoys}
        />
      )}
    </Container>
  );
};

export default DashboardRelative;
