import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button } from "react-bootstrap";
import * as moment from "moment";

const heightWindow = window.innerHeight;
const maxHeightList = heightWindow - heightWindow / 2;

const customStyles = {
  imgContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "5px",
    height: "200px",
    backgroundPosition: "center",
  },
  newInfoContainer: {
    maxHeight: maxHeightList + "px",
    overflowY: "auto",
  },
};

export default function NewDetailsModal({
  showNewDetailsModal,
  size,
  newDetail,
  txtBtnCancel,
  fnCancelButton,
}) {
  const [t] = useTranslation(["dashboardStudent", "global"]);

  return (
    <div>
      <Modal
        show={showNewDetailsModal}
        size={size}
        aria-labelledby="contained-modal-title-md"
      >
        <Modal.Header>
          <Modal.Title>{newDetail.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <div
                style={{
                  backgroundImage: "url(" + newDetail.img + ")",
                  ...customStyles.imgContainer,
                }}
              ></div>
            </Col>
            <Col xs={6}>
              <p>
                <b>{t("textNewView.author")}</b>
                {newDetail.author}
              </p>
              <p>
                <b>{t("textNewView.category")}</b>
                {newDetail.category}
              </p>
              <p>
                <b>{t("textNewView.tags")}</b>
                {newDetail.tags}
              </p>
              <p>
                <b>{t("textNewView.date")}</b>
                {moment
                  .unix(newDetail.dateUnix)
                  .local()
                  .format("DD MMM YYYY hh:mm a")}
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <div
                style={customStyles.newInfoContainer}
                dangerouslySetInnerHTML={{
                  __html: newDetail.content,
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {fnCancelButton && (
            <Button variant="outline-secondary" onClick={fnCancelButton}>
              {txtBtnCancel}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

NewDetailsModal.propTypes = {
  showNewDetailsModal: PropTypes.bool,
  size: PropTypes.string,
  newDetail: PropTypes.object,
  txtBtnCancel: PropTypes.string,
  fnCancelButton: PropTypes.func,
};
