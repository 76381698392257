import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";

import FieldInput from "../../global/form/FieldInput";
import FormSelect from "../../global/form/FormSelect";
import { GetUsers } from "../../../api/Staff";
import { GenerateDiplomas } from "../../../api/StudentsReport";
import ModalPreviewDiploma from "./ModalPreviewDiploma";
import { DatePicker } from "../../global/form/DatePicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

export default function ModalGenerateDiplomas(props) {
  const [t] = useTranslation(["student", "global"]);
  const [initialValues] = useState({
    title: "",
    type: "",
    description: "",
    expeditionDate: new Date(),
    instructor: "",
    director: "",
  });
  const prefix = process.env.REACT_APP_PREFIX;
  const language = localStorage.getItem(`cmLanguage${prefix}`);
  const [staffList, setStaffList] = useState([]);
  const [someModalOpen, setSomeModalOpen] = useState(false);
  const [showModalPreviewDiploma, setShowModalPreviewDiploma] = useState(false);
  const [error, setError] = useState(false);

  // Traslate calendar
  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }

  const validateSchema = Yup.object().shape({
    title: Yup.string().required(t("ModalGenerateDiplomas.titleRequired")),
    type: Yup.object().required(t("ModalGenerateDiplomas.typeRequired")),
    description: Yup.string().required(
      t("ModalGenerateDiplomas.descriptionRequired")
    ),
    expeditionDate: Yup.date()
      .required(t("ModalGenerateDiplomas.expeditionDateRequired"))
      .nullable(),
    instructor: Yup.object().required(
      t("ModalGenerateDiplomas.instructorRequired")
    ),
    director: Yup.object().required(
      t("ModalGenerateDiplomas.directorRequired")
    ),
  });

  const classTypes = [
    {
      label: t("ModalGenerateDiplomas.onsite"),
      value: "on_site",
    },
    {
      label: t("ModalGenerateDiplomas.online"),
      value: "online",
    },
    {
      label: t("ModalGenerateDiplomas.hybrid"),
      value: "hybrid",
    },
  ];

  useEffect(() => {
    getUsersStaff();
  }, []);

  /**
   * Get the list of all users staff
   */
  const getUsersStaff = () => {
    const request = {
      all_group_staff: 1,
    };
    GetUsers(request).then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        const staffList = result.data?.map((staff) => {
          return {
            label: staff.name,
            value: staff.id,
          };
        });
        setStaffList(staffList);
      }
    });
  };

  /**
   * Function to start generating students' diplomas
   * @param {object} values
   */
  const generateDiplomas = (values) => {
    const request = {
      title: values.title,
      description: values.description,
      type: values.type.value,
      instructor_id: values.instructor.value,
      director_id: values.director.value,
      student_ids: props.studentIds.map((value) => value.original.index),
      issue_date: Math.trunc(Date.parse(values.expeditionDate) / 1000),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GenerateDiplomas(request).then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        props.toggleModalProcessDiplomas();
        props.toggleDownloadDiplomas();
      }
    });
  };

  /**
   * Description: Function to validate values in modal
   * @param {object} values
   * @return {boolean}
   */
  const isFormValid = (values) => {
    let isValid = true;
    for (const property in values) {
      if (values[property] == "") {
        return false;
      }
    }
    return isValid;
  };

  return (
    <Modal
      show={props.showModalGenerateDiplomas}
      size="large"
      aria-labelledby="contained-modal-title-lg"
      contentClassName={someModalOpen ? "hide" : ""}
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("ModalGenerateDiplomas.generateDiplomas")}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          generateDiplomas(values);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Modal.Body>
              <Row className="mb-3">
                <Col>{t("ModalGenerateDiplomas.completeNextFields")}</Col>
              </Row>

              <Row>
                <Col md={12} lg={12}>
                  <FieldInput
                    name="title"
                    label={t("ModalGenerateDiplomas.title")}
                    placeholder={t("ModalGenerateDiplomas.titlePlaceholder")}
                    required={true}
                    maxlength="150"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12}>
                  <FormSelect
                    name="type"
                    label={t("ModalGenerateDiplomas.classType")}
                    placeholder={t(
                      "ModalGenerateDiplomas.classTypePlaceholder"
                    )}
                    required={true}
                    options={classTypes}
                    onChange={(type) => setFieldValue("type", type)}
                    value={values.type}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12}>
                  <FieldInput
                    as="textarea"
                    name="description"
                    label={t(
                      "ModalGenerateDiplomas.custimizedMessageForDiploma"
                    )}
                    placeholder={t(
                      "ModalGenerateDiplomas.custimizedMessageForDiplomaPlaceholder"
                    )}
                    type="text"
                    required={true}
                    style={{ height: "100px" }}
                    maxlength="500"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12}>
                  <DatePicker
                    label={t("ModalGenerateDiplomas.expeditionDate")}
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    name="expeditionDate"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    required
                    {...Locallanguage}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12}>
                  <FormSelect
                    name="instructor"
                    label={t("ModalGenerateDiplomas.instructor")}
                    placeholder={t(
                      "ModalGenerateDiplomas.instructorPlaceholder"
                    )}
                    required={true}
                    options={staffList}
                    onChange={(instructor) =>
                      setFieldValue("instructor", instructor)
                    }
                    value={values.instructor}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12}>
                  <FormSelect
                    name="director"
                    label={t("ModalGenerateDiplomas.directorProgam")}
                    placeholder={t(
                      "ModalGenerateDiplomas.directorProgamPlaceholder"
                    )}
                    required={true}
                    options={staffList}
                    onChange={(director) => setFieldValue("director", director)}
                    value={values.director}
                  />
                </Col>
              </Row>
              {error && (
                <Row className="mt-2">
                  <Col>
                    <Alert variant="warning">{error}</Alert>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <Button
                variant="outline-danger"
                onClick={() => {
                  setSomeModalOpen(true);
                  setShowModalPreviewDiploma(true);
                }}
                disabled={!isFormValid(values)}
              >
                {t("ModalGenerateDiplomas.previewDiploma")}
              </Button>
              {showModalPreviewDiploma && (
                <ModalPreviewDiploma
                  showModalPreviewDiploma={showModalPreviewDiploma}
                  closeModalPreviewDiploma={() => {
                    setSomeModalOpen(false);
                    setShowModalPreviewDiploma(false);
                  }}
                  values={values}
                />
              )}
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={props.toggleDownloadDiplomas}
                >
                  {t("global:buttons.cancel")}
                </Button>
                <Button variant="primary" type="submit">
                  {t("global:buttons.generate")}
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

ModalGenerateDiplomas.propTypes = {
  showModalGenerateDiplomas: PropTypes.bool,
  toggleDownloadDiplomas: PropTypes.func,
  toggleModalProcessDiplomas: PropTypes.func,
  studentIds: PropTypes.array,
};
