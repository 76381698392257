/**
 *       **** Readme ****
 *
 * Component: <SchoolCycle />
 * Purpose: Returns the Select element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import selectStyles from "../GlobalTools";
import * as moment from "moment";

export default function SchoolCycle({ ...props }) {
  const [t] = useTranslation(["cycles"]);
  const { handleOnchange, useIn, value } = props;

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cycleSelected, setCycleSelected] = useState(null);
  const prefix = process.env.REACT_APP_PREFIX;

  /**
   * Get current leves
   * @param {Object} currentSchoolCycle
   */
  const getCurrentLevels = (currentSchoolCycle) => {
    //Extract the levels of the school cycle in use that belong to the organization.
    let organizationSchoolLevels = [];
    for (let singleLevel of currentSchoolCycle.school_levels) {
      let schoolLevel = {};
      schoolLevel.organization_school_level_id =
        singleLevel.organization_school_level_id;
      organizationSchoolLevels.push(schoolLevel);
    }
    //Extract the higher levels of the school cycle in use that belong to the organization.
    let programsIds = [];
    for (let singleColleges of currentSchoolCycle.colleges_and_schools) {
      let collegesLevels = singleColleges.school_levels;
      for (let singleLevel of collegesLevels) {
        let levelPrograms = singleLevel.programs;
        for (let singleProgram of levelPrograms) {
          if (singleProgram.enabled === "1") {
            programsIds.push(singleProgram.id);
          }
        }
      }
    }
  };

  /**
   * Purpose: Gets the school cycle
   */
  const getSchoolCycle = () => {
    setIsLoading(true);
    const cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const hasCycles =
      cyclesLocal && Array.isArray(cyclesLocal) && cyclesLocal.length;
    if (hasCycles) {
      setOptions(cyclesLocal);
      setDataCycle(cyclesLocal);
    } else {
      setTimeout(() => {
        getSchoolCycle();
      }, 1000);
    }
  };

  /**
   * Gets the current cycle and sets the default cycle values
   * @param {array} cycles
   */
  const setDataCycle = (cycles) => {
    //gets current cycle
    let currentSchoolCycle = {};
    currentSchoolCycle = getCurrentCycle(cycles);
    currentSchoolCycle = currentSchoolCycle
      ? currentSchoolCycle
      : cycles.length
      ? cycles[0]
      : false;

    if (currentSchoolCycle) {
      getCurrentLevels(currentSchoolCycle);
    }
    if (props.getDefaultValues) {
      props.getDefaultValues(currentSchoolCycle);
    }
    if (props.selectedByDefault) {
      setCycleSelected(currentSchoolCycle);
    }
    setIsLoading(false);
  };

  const getCurrentCycle = (cycles) => {
    if (props.selectedCycleId) {
      let selectedCycle = cycles.find(
        (item) => item.id == props.selectedCycleId
      );
      if (selectedCycle) {
        return selectedCycle;
      }
    }
    let currentCycle = false;
    const today = moment().unix();
    for (let cycle of cycles) {
      if (
        today > cycle.start_time &&
        today < cycle.end_time &&
        cycle.current_cycle == 1
      ) {
        currentCycle = cycle;
      }
    }
    if (!currentCycle) {
      currentCycle = cycles.find((cycle) => cycle.current_cycle === "1");
    }
    return value || currentCycle;
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getSchoolCycle();
  }, [props.reloadCycles]);

  return useIn === "filters" ? (
    <>
      <Select
        options={options}
        onChange={(e) => {
          handleOnchange(e);
          setCycleSelected(e);
        }}
        placeholder={t("select.placeholder")}
        styles={selectStyles}
        formatOptionLabel={function (data) {
          return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
        }}
        isDisabled={isLoading}
        noOptionsMessage={() => t("global:select.noMatches")}
        value={value || cycleSelected}
      />
    </>
  ) : (
    ""
  );
}

SchoolCycle.propTypes = {
  handleOnchange: PropTypes.func,
  useIn: PropTypes.string,
  reloadCycles: PropTypes.bool,
  getDefaultValues: PropTypes.func,
  selectedByDefault: PropTypes.bool,
  value: PropTypes.object,
  selectedCycleId: PropTypes.number,
};
