import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  FormControl,
  FormLabel,
  Alert,
} from "react-bootstrap";
//validation resources
import { Form as FormOfFormik, Formik } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
//*Services */
import { CreateSuperAdmin, UpdateSuperAdmin } from "../../api/SuperAdmins";
import FieldInput from "../global/form/FieldInput";
const stylesCustom = {
  biQuestion: {
    marginLeft: "5px",
    color: "#EA2C54",
  },
};

export default function ModalCreateSuperAdmin(props) {
  const [t] = useTranslation(["global", "superAdmin"]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [superAdmin] = useState(props.superAdmin);
  const [serviceErrors, setServiceErrors] = useState(false);

  const tooltipPassword = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("global:tooltipGlobal.textPassword")}
      </Tooltip>
    );
  };

  //Validation scheme
  const validateSchema = Yup.object().shape({
    name: Yup.string().required(
      t("superAdmin:modalCreateSuperAdmin.name") +
        " " +
        t("superAdmin:view.isRequired")
    ),
    lastName: Yup.string().required(
      t("superAdmin:modalCreateSuperAdmin.lastName") +
        " " +
        t("superAdmin:view.isRequired")
    ),
    email: Yup.string()
      .required(
        t("superAdmin:modalCreateSuperAdmin.email") +
          " " +
          t("superAdmin:view.isRequired")
      )
      .email(t("superAdmin:modalCreateSuperAdmin.invalidEmailFormat")),
    password: Yup.string()
      .required(
        t("superAdmin:modalCreateSuperAdmin.password") +
          " " +
          t("superAdmin:view.isRequired")
      )
      .test(
        "formatPassword",
        t("global:tooltipGlobal.textPassword"),
        (password) => {
          if (password) {
            let passwordRegex = new RegExp(
              /(?=^(?:[^A-Z]*[A-Z]))(?=^(?:[^a-z]*[a-z]))(?=^(?:\D*\d))(?=^(?:\w*\W))^[A-Za-z\d\W]{8,}$/
            );
            return passwordRegex.test(password);
          }
        }
      ),
    confirmPassword: Yup.string()
      .required(
        t("superAdmin:modalCreateSuperAdmin.confirmPassword") +
          " " +
          t("superAdmin:view.isRequired")
      )
      .test(
        "verificationPassword",
        t("superAdmin:modalChangePassword.verificationPasswordMessage"),
        (password, { parent }) => {
          return password === parent.password;
        }
      ),
  });

  const validateSchemaEdit = Yup.object().shape({
    name: Yup.string().required(
      t("superAdmin:modalCreateSuperAdmin.name") +
        " " +
        t("superAdmin:view.isRequired")
    ),
    lastName: Yup.string().required(
      t("superAdmin:modalCreateSuperAdmin.lastName") +
        " " +
        t("superAdmin:view.isRequired")
    ),
    email: Yup.string()
      .required(
        t("superAdmin:modalCreateSuperAdmin.email") +
          " " +
          t("superAdmin:view.isRequired")
      )
      .email(t("superAdmin:modalCreateSuperAdmin.invalidEmailFormat")),
  });

  /**
   * Method to create a super user
   * @param {object} values object that stores the values of the form
   */
  const createSuperAdmin = (values) => {
    const payload = {
      first_name: values.name,
      last_name: values.lastName,
      password: values.password,
      email: values.email,
      groups: [1], //is created as super administrator users
    };
    CreateSuperAdmin(payload).then((result) => {
      if (result.error) {
        setServiceErrors(result.error.message);
        return;
      }
      props.closeModalAdmin(false);
      props.listSuperAdmin();
    });
  };

  /**
   * Method for updating super user information
   * @param {*} values object that stores the values of the form
   */
  const updateSuperAdmin = (values) => {
    const payload = {
      user_id: values.id,
      first_name: values.name,
      last_name: values.lastName,
      email: values.email,
    };
    UpdateSuperAdmin(payload).then((result) => {
      if (result.error) {
        setServiceErrors(result.error.message);
        return;
      }
      props.closeModalAdmin(false);
      props.listSuperAdmin();
    });
  };

  /**
   * Initial loading
   */
  useEffect(() => {}, []);

  return (
    <Modal
      show={props.showModalCreateSuperAdmin}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {superAdmin.id
            ? t("superAdmin:modalCreateSuperAdmin.editSuperAdmin")
            : t("superAdmin:modalCreateSuperAdmin.createSuperAdmin")}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={superAdmin}
        validationSchema={superAdmin.id ? validateSchemaEdit : validateSchema}
        onSubmit={(values) => {
          superAdmin.id ? updateSuperAdmin(values) : createSuperAdmin(values);
        }}
      >
        {({ values, errors, touched, ...formik }) => (
          <Fragment>
            <FormOfFormik>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <FieldInput
                      label={t("superAdmin:modalCreateSuperAdmin.name")}
                      name="name"
                      type="text"
                      placeholder={t("superAdmin:modalCreateSuperAdmin.name")}
                      required={true}
                    />

                    <FieldInput
                      label={t("superAdmin:modalCreateSuperAdmin.lastName")}
                      name="lastName"
                      type="text"
                      placeholder={t(
                        "superAdmin:modalCreateSuperAdmin.lastName"
                      )}
                      required={true}
                    />

                    <FieldInput
                      label={t("superAdmin:modalCreateSuperAdmin.email")}
                      name="email"
                      type="email"
                      placeholder={t("superAdmin:modalCreateSuperAdmin.email")}
                      required={true}
                    />

                    {!values.id && (
                      <Fragment>
                        {/* Password field */}
                        <FormLabel>
                          {t("superAdmin:modalCreateSuperAdmin.password")}{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltipPassword}
                          >
                            <span
                              className="bi bi-question-circle-fill"
                              aria-hidden="true"
                              style={stylesCustom.biQuestion}
                            ></span>
                          </OverlayTrigger>
                        </FormLabel>
                        <InputGroup className="mb-3" controlId="password">
                          <Form.Control
                            name="password"
                            placeholder="**************"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onBlur={() =>
                              formik.setFieldTouched("password", true)
                            }
                            className={
                              errors.password && touched.password
                                ? "errorValidation"
                                : ""
                            }
                            onChange={(e) => {
                              formik.setFieldValue("password", e.target.value);
                            }}
                          />

                          <InputGroup.Text
                            role="button"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <img
                              id="type_password"
                              src={
                                showPassword
                                  ? "/img/eye.svg"
                                  : "/img/eye-slash.svg"
                              }
                            />
                          </InputGroup.Text>
                          {errors.password && touched.password && (
                            <Col md={12}>
                              <Form.Text className="text-muted error">
                                {errors.password}
                              </Form.Text>
                            </Col>
                          )}
                        </InputGroup>
                        {/* Confirm password field */}
                        <FormLabel htmlFor="confirmPassword">
                          {t(
                            "superAdmin:modalCreateSuperAdmin.confirmPassword"
                          )}
                        </FormLabel>
                        <InputGroup
                          className="mb-3"
                          controlId="confirmPassword"
                          validationState="error"
                        >
                          <FormControl
                            name="confirmPassword"
                            placeholder="**************"
                            type={showConfirmPassword ? "text" : "password"}
                            value={values.confirmPassword}
                            onBlur={() =>
                              formik.setFieldTouched("confirmPassword", true)
                            }
                            className={
                              errors.confirmPassword && touched.confirmPassword
                                ? "errorValidation"
                                : ""
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                "confirmPassword",
                                e.target.value
                              );
                            }}
                          />
                          <InputGroup.Text
                            role="button"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            <img
                              id="type_password"
                              src={
                                showConfirmPassword
                                  ? "/img/eye.svg"
                                  : "/img/eye-slash.svg"
                              }
                            />
                          </InputGroup.Text>
                          {errors.confirmPassword && touched.confirmPassword && (
                            <Col md={12}>
                              <Form.Text className="text-muted error">
                                {errors.confirmPassword}
                              </Form.Text>
                            </Col>
                          )}
                        </InputGroup>
                      </Fragment>
                    )}
                  </Col>
                </Row>
                {serviceErrors && (
                  <Row>
                    <Col md={12}>
                      <Alert variant="warning">{serviceErrors}</Alert>
                    </Col>
                  </Row>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-secondary"
                  onClick={() => props.closeModalAdmin(false)}
                >
                  {t("global:buttons.cancel")}
                </Button>
                <Button type="submit">
                  {values.id
                    ? t("global:buttons.save")
                    : t("superAdmin:modalCreateSuperAdmin.createSuperAdmin")}
                </Button>
              </Modal.Footer>
            </FormOfFormik>
          </Fragment>
        )}
      </Formik>
    </Modal>
  );
}

ModalCreateSuperAdmin.propTypes = {
  showModalCreateSuperAdmin: PropTypes.bool,
  closeModalAdmin: PropTypes.func,
  superAdmin: PropTypes.object,
  listSuperAdmin: PropTypes.func,
};
