/**
 *       **** Readme ****
 * Component: <DateRangeFilter />
 * Props:
 *    -getByDateRange:{Function} It receives the start and end date as a parameter and obtains the records within the date range. The request is made to the API, it is not a filter on the records of the table
 *    -optionNoSelection:{Boolean} It receives as parameter to know when it comes empty and apply the placeholder.
 *    -requestForAll:{Object} It receives as a parameter to know when it the filter does not have to be cleaned.
 *    -selectedByDefault:{Boolean}  It is a boolean when the extra option is selected, when it is not being passed it is only an available option.
 *    -lastNDays: {Object} It is an object that is passed the parameters with which it will be filled when a new option is needed in the date selector.
 *    -extraOptions: {Array} It is an array of objects that are passed the parameters with which it will be filled when more options are needed in the date picker.
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ContentCalendar from "../reports/ContentCalendar";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/locale/es";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "'Mulish', sans-serif",
    padding: "5px 16px 3px 16px",
    color: state.isSelected ? "white" : state.isDisabled ? "#d4d4d4" : null,
    backgroundColor: state.isSelected
      ? "var(--base-color)"
      : state.isDisabled
        ? "white"
        : state.isFocused
          ? "var(--red-light)"
          : null,
    cursor: state.isDisabled ? "not-allowed" : null,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    zIndex: "9999",
    fontWeight: "400",
    fontFamily: "'Mulish', sans-serif",
    color: "#4d4d4d",
    textAlign: "left",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "'Mulish', sans-serif",
    minHeight: "29px",
    maxHeight: "29px",
    borderRadius: 0,
    borderColor: "#dddddd",
    outline: "none",
    textAlign: "left",
    border: "1px solid #dddddd",
    borderLeftColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #dddddd",
    },
  }),
  container: (provided) => ({
    ...provided,
    minHeight: "29px",
    maxHeight: "29px",
    outline: "none",
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "'Mulish', sans-serif",
    minHeight: "29px",
    maxHeight: "29px",
    margin: 0,
    fontWeight: "400",
    outline: "none",
    minWidth: "100px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#D6DADB",
      fontSize: "14px",
      fontFamily: "'Mulish', sans-serif",
      fontWeight: "600",
      textAlign: "left",
    };
  },
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: "29px",
    maxHeight: "29px",
    cursor: "pointer",
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "29px",
    maxHeight: "29px",
    padding: "0 4px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: "normal",
    fontFamily: "'Mulish', sans-serif",
  }),
};

export default function DateRangeFilter(props) {
  const prefix = process.env.REACT_APP_PREFIX;
  const language = localStorage.getItem(`cmLanguage${prefix}`) == "english" ? "en" : "es";
  const [t] = useTranslation(["reports", "table"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeDefault] = useState([
    moment().subtract(1, "months").startOf("month"),
    moment().endOf("day"),
  ]);
  const [selectRange, setSelectRange] = useState(null);
  const [value] = useState(new Date());
  const [optionsDate, setOptionsDate] = useState([]);
  const { filterValue, setFilter } = props.column;
  let options = [
    {
      label: t("filterSelect.monthDate"),
      value: 1,
      startDate: moment().startOf("month").unix(),
      endDate: moment().endOf("day").unix(),
    },
    {
      label: t("filterSelect.today"),
      value: 2,
      startDate: moment().startOf("day").unix(),
      endDate: moment().endOf("day").unix(),
    },
    {
      label: t("filterSelect.yesterday"),
      value: 3,
      startDate: moment().subtract(1, "day").startOf("day").unix(),
      endDate: moment().subtract(1, "day").endOf("day").unix(),
    },
    {
      label: t("filterSelect.lastSevenDays"),
      value: 4,
      startDate: moment().subtract(7, "day").startOf("day").unix(),
      endDate: moment().endOf("day").unix(),
    },
    {
      label: t("filterSelect.lastMonth"),
      value: 5,
      startDate: moment().subtract(1, "months").startOf("month").unix(),
      endDate: moment().subtract(1, "months").endOf("month").unix(),
    },
    {
      label: t("filterSelect.selectRange"),
      value: 6,
      startDate: "",
      endDate: "",
    },
  ];
  const [optionsDateOriginal, setOptionsDateOriginal] = useState(props.options || options);
  //Clears all filters in the table when there is no relationship between them.
  const { setAllFilters } = props;
  //Indicates if the filters in the table are not related to each other and work independently.
  const { unrelatedFilters, filters } = props.state;
  const defaultOptionId = props.defaultOptionId ? props.defaultOptionId : false;
  /**
   * Purpose: Update the list of options with the date range
   * @param {Object} newValue
   */
  const updateOptions = (newValue) => {
    const optionsUpdated = optionsDate.map((option) => {
      if (option.value === 6) {
        return newValue;
      }
      return option;
    });
    setOptionsDate(optionsUpdated);
  };

  /**
   * Purpose: Select date range
   * @param {Object} event
   */
  const filterDate = (event) => {
    setSelectRange(event);
    setDateRange(null);

    if (event.value == 6) {
      //Show the calendar
      setToggleCalendar(true);
    } else {
      if (unrelatedFilters) {
        setAllFilters([]);
      }
      let newValue = {
        label: t("filterSelect.selectRange"),
        value: 6,
        startDate: "",
        endDate: "",
      };
      updateOptions(newValue);

      //Close the calendar
      setToggleCalendar(false);
      let filter =
        event.value == 7
          ? undefined
          : {
            startDate: event.startDate,
            endDate: event.endDate,
            value: event.value,
          };
      setFilter(filter || undefined);
      props.getByDateRange(
        {
          start_date: event.startDate,
          end_date: event.endDate,
        },
        props.column
      );
    }
  };

  /**
   * Purpose: Stores the date range selected in the calendar
   * @param {Object} event
   */
  const onChangeCalendar = (event) => {
    if (event.length == 2) {
      if (unrelatedFilters) {
        setAllFilters([]);
      }
      setToggleCalendar(false);
      setDateRange(event);
      let startMonth = moment(event[0]).format("MMM");
      startMonth = (
        startMonth.toUpperCase().charAt(0) +
        startMonth.substring(1, startMonth.length)
      ).replace(".", "");

      let endMonth = moment(event[1]).format("MMM");
      endMonth = (
        endMonth.toUpperCase().charAt(0) +
        endMonth.substring(1, endMonth.length)
      ).replace(".", "");

      //Dates to display in the select text
      let dateRangeTranslation = {
        startDate: `${moment(event[0]).format("DD")}-${startMonth}-${moment(
          event[0]
        ).format("YYYY")}`,
        endDate: `${moment(event[1]).format("DD")}-${endMonth}-${moment(
          event[1]
        ).format("YYYY")}`,
      };
      setSelectRange({
        label: t("filterSelect.selectRangeWithDate", { dateRangeTranslation }),
        value: 6,
        startDate: moment(event[0]).unix(),
        endDate: moment(event[1]).unix(),
      });
      let newValue = {
        label: t("filterSelect.selectRangeWithDate", { dateRangeTranslation }),
        value: 6,
        startDate: moment(event[0]).unix(),
        endDate: moment(event[1]).unix(),
      };
      updateOptions(newValue);
      setFilter(
        {
          label: t("filterSelect.selectRangeWithDate", {
            dateRangeTranslation,
          }),
          startDate: moment(event[0]).unix(),
          endDate: moment(event[1]).unix(),
          value: 6,
        } || undefined
      );
      props.getByDateRange(
        {
          start_date: moment(event[0]).unix(),
          end_date: moment(event[1]).unix(),
        },
        props.column
      );
    }
  };

  /**
   * Purpose: Hide the Calendar
   */
  const closeCalendar = () => {
    setToggleCalendar(false);
  };

  /*
   * Purpose: It can be used to set any option that is sent to be set in date, you only have to send the parameters in the general view.
   */
  useEffect(() => {
    if (props.lastNDays) {
      let newOptions = optionsDateOriginal;
      newOptions.push({
        label: props.lastNDays.label,
        value: 8,
        startDate: props.lastNDays.start_date,
        endDate: props.lastNDays.end_date,
      });
      setOptionsDateOriginal(newOptions);
      if (props.lastNDays.selectedByDefault && !filters.length) {
        setFilter(newOptions[newOptions.length - 1]);
      }
    }
    if (props.extraOptions && props.extraOptions.length) {
      const newOptions = optionsDateOriginal;
      for (const extraOption of props.extraOptions) {
        // add new option to filter
        newOptions.push({
          label: extraOption.label,
          value: extraOption.value,
          startDate: extraOption.start_date,
          endDate: extraOption.end_date,
        });
        // set filter default
        if (extraOption.isDefault) {
          setFilter(extraOption);
        }
      }
      setOptionsDateOriginal(newOptions);
    }
  }, []);

  /**
   * Purpose:A new value is added for filter cleaning
   */
  useEffect(() => {
    if (props.all) {
      let newOptions = [...optionsDateOriginal];
      let newOptionData = {};
      //If it has the prop it will fill the request with the corresponding data
      if (props.requestForAll) {
        newOptionData = props.requestForAll;
      } else {
        newOptionData.startDate = null;
        newOptionData.endDate = null;
      }
      //Add the label and value for the all option
      newOptionData.label = t("status.all");
      newOptionData.value = 7;
      //Add the new option to the beginning of the array
      newOptions.unshift(newOptionData);
      //Update the state of options to be able to filter the information
      setOptionsDateOriginal(newOptions);
    }
  }, [props.all]);

  /**
   * Initial loading
   * */
  useEffect(() => {
    setOptionsDate(optionsDateOriginal);
    let language =
      localStorage.getItem(`cmLanguage${prefix}`) == "spanish" ? "es" : "en";
    moment.locale(language);
  }, [t, optionsDateOriginal]);

  /**
   * Prupose: Preserves the value of the filter when the table data is updated
   */
  useEffect(() => {
    if (filterValue && optionsDate.length) {
      const result =
        filterValue.value == 6
          ? filterValue
          : optionsDate.find(({ value }) => value === filterValue.value);
      //When the filter is null, it will return to the default value.
      setSelectRange(result || null);
    } else {
      //Option to clear the date filters
      if (props.optionNoSelection) {
        setSelectRange(null);
      } else {
        setSelectRange(optionsDateOriginal[0]);
      }
    }
  }, [filterValue, optionsDate]);

  /**
   * Prupose: Effect for when there is an initial value and then it is set.
   */
  useEffect(() => {
    //Set the option selected by default if it exists, if not, the first position is taken.
    if (defaultOptionId) {
      if (!filters.length) {
        let optionFound = optionsDateOriginal.find(
          ({ value }) => value === defaultOptionId
        );
        setFilter(optionFound);
        setSelectRange(optionFound);
      }
    } else {
      setSelectRange(optionsDateOriginal[0]);
    }
  }, []);

  return (
    <div>
      <div style={{ zIndex: "101" }}>
        <Select
          options={optionsDate}
          onChange={(e) => filterDate(e)}
          placeholder={
            props.optionNoSelection
              ? t("table:columnFilter.search")
              : t("filterSelect.selectRange")
          }
          styles={customStyles}
          onMenuOpen={closeCalendar}
          formatOptionLabel={function (data) {
            return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
          }}
          value={selectRange}
        />
      </div>
      <div style={{ position: "relative" }}>
        <ContentCalendar show={toggleCalendar ? "block" : "none"} id="calendar">
          <Calendar
            allowPartialRange={true}
            selectRange={true}
            onChange={onChangeCalendar}
            className="select-date"
            value={dateRange}
            maxDate={props.futureDatesSelectables ? null : value}
            defaultValue={[
              new Date(dateRangeDefault[0]),
              new Date(dateRangeDefault[1]),
            ]}
            locale={language}
          />
        </ContentCalendar>
      </div>
    </div>
  );
}

DateRangeFilter.propTypes = {
  column: PropTypes.string,
  getByDateRange: PropTypes.func,
  all: PropTypes.bool,
  unrelatedFilters: PropTypes.bool,
  setAllFilters: PropTypes.func,
  state: PropTypes.object,
  defaultOptionId: PropTypes.number,
  optionNoSelection: PropTypes.bool,
  requestForAll: PropTypes.object,
  lastNDays: PropTypes.object,
  extraOptions: PropTypes.array,
  futureDatesSelectables: PropTypes.bool,
  options: PropTypes.array,
};
