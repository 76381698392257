import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import SeeAllModule from "./SeeAllModule";
import PropTypes from "prop-types";
import { GetIncomeAndDebtsDashboard } from "../../../api/Dashboard";
import { thousandsFormat } from "../../global/GlobalTools";
const customStyles = {
  lazy: {
    height: "25px",
  },
  lazyGraft: {
    height: "190px",
    borderRadius: "100px",
    width: "190px",
  },
};

const lazyLoading = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col md={4}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={4} className="offset-md-4">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={4}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={4} className="offset-md-4">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={4} className="offset-md-2">
          <div style={customStyles.lazyGraft}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={2} className="offset-md-2">
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={2}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={2}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={2}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-3">
        <Col md={4} className="offset-md-8">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};

const IncomeModule = (props) => {
  const [t] = useTranslation(["dashboard"]);
  const [options] = useState([
    {
      label: "dayTitle",
      value: "today",
    },
    {
      label: "weekTitle",
      value: "week",
    },
    {
      label: "monthTitle",
      value: "month",
    },
    {
      label: "allTitle",
      value: "all",
    },
  ]);
  const [optionView, setOptionView] = useState("weekTitle");
  const [total, setTotal] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentLabels, setPaymentLabels] = useState([]);
  const [paymentFormatLabels, setPaymentFormatLabels] = useState([]);
  const [interval, setInterval] = useState("week");
  const [loadingFilter, setLoaingFilter] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  /**
   * Load the test values in case the module is locked.
   */
  const loadDummyData = () => {
    const dummyData = [
      { name: "cash", value: "88046" },
      { name: "card", value: "10039" },
      { name: "spei", value: "15219" },
    ];

    let sumTotal = 0;
    let labels = [];
    let methods = [];
    for (let method of dummyData) {
      labels.push(method.name);
      methods.push(method.value);
      sumTotal += Number(method.value);
    }
    setTotal(sumTotal);
    setPaymentLabels(labels);
    setPaymentMethods(methods);
    props.setWaitinData(false);
  };

  /**
   * Obtains revenue by payment method
   */
  const getIncomeData = () => {
    const request = {
      data_from: "incomes",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      school_cycle_id: props.filters.cycle?.id,
      interval: interval,
    };
    if (props.filters.level?.id) {
      request.school_level_id = props.filters.level.id;
    }
    // parameters for higher level
    if (props.filters.programs?.id) {
      request.program_id = props.filters.programs.id;
    }
    if (props.filters.levelProgram?.id) {
      request.program_level_id = props.filters.levelProgram.id;
    }
    // parameters for basic level
    if (props.filters.grade?.id) {
      request.grade_level_id = props.filters.grade.id;
    }
    if (props.filters.group?.id) {
      request.grade_group_id = props.filters.group.id;
    }
    GetIncomeAndDebtsDashboard(request).then((result) => {
      setTotal(0);
      if (result == "NetworkError") {
        setNetworkError(true);
        props.setWaitinData(false);
        setLoaingFilter(false);
        return;
      }
      if (result.data) {
        const incomesPaymentMethods = result.data.incomes.payment_methods;
        let labels = [];
        let formatLabels = [];
        let methods = [];
        for (let method of incomesPaymentMethods) {
          labels.push(method.name);
          methods.push(method.value);
          formatLabels.push(thousandsFormat(method.value));
        }
        setTotal(result.data.incomes.income);
        setPaymentLabels(labels);
        setPaymentMethods(methods);
        setPaymentFormatLabels(formatLabels);
        props.setWaitinData(false);
      }
      setLoaingFilter(false);
    });
  };
  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.active == false) {
      loadDummyData();
      return;
    }
  }, [props.active]);

  useEffect(() => {
    if (!props.active) return;
    if (props.readyFilters) {
      getIncomeData();
    }
  }, [props.readyFilters, interval]);

  return (
    <Row>
      {props.waitingData ? (
        lazyLoading()
      ) : (
        <Col>
          <Row>
            <Col md={8}>
              <i className="bi bi-credit-card me-2 fs-6"></i>
              <b className="fs-6">{t("labels.incomeTitle")}</b>
              <p className="fs-3">
                {loadingFilter ? (
                  <Row className="table-lazyLoading mt-2">
                    <Col md={7}>
                      <div style={customStyles.lazy}></div>
                    </Col>
                  </Row>
                ) : (
                  <b>${total}</b>
                )}
              </p>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label className="mb-0">{t("labels.viewTile")}</Form.Label>
                <Dropdown className="d-grid">
                  <Dropdown.Toggle
                    id="dropdownSeeBy"
                    variant="outline-secondary"
                    className="text-truncate"
                  >
                    <span style={{ display: "inline-flex", width: "70%" }}>
                      {options[3].label &&
                        (t(`labels.${optionView}`) ||
                          t(`labels.${options[3].label}`))}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ minWidth: "7%" }}>
                    {options.map((option) => {
                      return (
                        <Dropdown.Item
                          key={option.value}
                          eventKey={option.value}
                          onClick={() => {
                            setOptionView(option.label);
                            setInterval(option.value);
                            setLoaingFilter(true);
                          }}
                        >
                          {t(`labels.${option.label}`)}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="my-2 ustify-content-center">
              {loadingFilter ? (
                <>
                  <Row className="table-lazyLoading">
                    <Col md={4} className="offset-md-2">
                      <div style={customStyles.lazyGraft}></div>
                    </Col>
                  </Row>
                  <Row className="table-lazyLoading">
                    <Col md={2} className="offset-md-2">
                      <div style={customStyles.lazy}></div>
                    </Col>
                    <Col md={2}>
                      <div style={customStyles.lazy}></div>
                    </Col>
                    <Col md={2}>
                      <div style={customStyles.lazy}></div>
                    </Col>
                    <Col md={2}>
                      <div style={customStyles.lazy}></div>
                    </Col>
                  </Row>
                </>
              ) : parseFloat(total) > 0 ? (
                <Doughnut
                  data={{
                    labels: paymentLabels,
                    datasets: [
                      {
                        data: paymentMethods,
                        backgroundColor: [
                          "rgba(218, 242, 242, 1)",
                          "rgba(89, 186, 183, 1)",
                          "rgba(65, 135, 133, 1)",
                          "rgba(40, 84, 83, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                    formatLabels: paymentFormatLabels,
                  }}
                  options={{
                    responsive: false,
                    tooltips: {
                      backgroundColor: "#ef90a4",
                      callbacks: {
                        label: function (tooltipItem, data) {
                          return (
                            data["labels"][tooltipItem["index"]] +
                            ": $" +
                            data["formatLabels"][tooltipItem["index"]]
                          );
                        },
                      },
                      displayColors: false,
                      xPadding: 10,
                    },
                  }}
                  legend={{
                    fullWidth: false,
                    labels: {
                      boxWidth: 25,
                    },
                    onClick: null,
                    position: "bottom",
                  }}
                  height="220px"
                />
              ) : (
                <Col
                  md={12}
                  style={{ height: "220px", paddingTop: "100px" }}
                  className=" align-middle"
                >
                  {networkError ? (
                    <p className="text-center">
                      {t("dashboard:labels.informationNotAvailable")}
                    </p>
                  ) : (
                    <p className="text-center">
                      {t("dashboard:labels.noData")}
                    </p>
                  )}
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <SeeAllModule path={"/payments/income"}></SeeAllModule>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default IncomeModule;
IncomeModule.propTypes = {
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  readyFilters: PropTypes.bool,
  filters: PropTypes.object,
  active: PropTypes.bool,
};
