const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Formatting the organization's services
 * @param {obj} data
 * @returns {obj} orgServices
 */
export function servicesFormat(data) {
  let orgServices = {};
  localStorage.removeItem(`${prefix}Services`);
  let servicesUpdated = {};
  for (let service of data) {
    switch (service.service_id) {
      case "1": {
        //SIE
        servicesUpdated.SIE = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "2": {
        //Website
        servicesUpdated.Website = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "3": {
        //Payments
        servicesUpdated.Payments = {
          id: service.service_id,
          active: service.premium == "0" ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "4": {
        //App
        servicesUpdated.App = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "5": {
        //Reviews
        servicesUpdated.Reviews = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "6": {
        //Chat
        servicesUpdated.Chat = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "7": {
        //DocuSing
        servicesUpdated.DocuSing = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "8": {
        //SCE
        servicesUpdated.SCE = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "9": {
        //CRM
        servicesUpdated.CRM = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "10": {
        //DigitalContent
        servicesUpdated.DigitalContent = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
      case "11": {
        //GoogleForEducations
        servicesUpdated.GoogleForEducations = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
          organizationServiceId: service.organization_service_id,
        };
        break;
      }
    }
  }
  for (let item in servicesUpdated) {
    orgServices[item] = servicesUpdated[item].active;
  }
  return orgServices;
}
