import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button,
  Form as FormBoost,
  Alert,
} from "react-bootstrap";
import { isRequiredFields } from "../../../components/global/GlobalTools";
import FormGender from "../../global/form/FormGender";
import selectStyles from "../../global/GlobalTools";
import { DatePicker } from "../../global/form/DatePicker";
import { getCountries } from "../../../api/Countries";
import FieldInput from "../../global/form/FieldInput";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import FormCheck from "../../global/form/FormCheck";
import { MainContext } from "../../../App";
import { GetSchoolCycle } from "../../../api/Cycles";
import { createStudent } from "../../../api/StudentsReport";

export default function CreateStudentModal(props) {
  const [isRequired, setIsRequired] = useState(false);
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["crm", "global", "modalCreateStudent", "cycles"]);
  const [countries, setCountries] = useState([]);
  const language = t("modalCreateStudent:language");
  const [showAlertCurpIdNo, setShowAlertCurpIdNo] = useState(true);
  const [schoolCycles, setSchoolCycles] = useState([]);
  const [cycleLevels, setCycleLevels] = useState([]);
  const [levelGrades, setLevelGrades] = useState([]);
  const [gradeGroups, setGradeGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [collageAndSchools, setCollageAndSchools] = useState([]);
  const [collegeLevels, setCollegeLevels] = useState([]);
  const [higherLevel, sethigherLevel] = useState(false);
  //Traslate calendar
  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }

  /**
   * initial values for Form
   */
  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    country: "",
    dateBirth: "",
    identificationNumber: "",
    email: "",
    curp: "",
    schoolCycle: "",
    schoolLevel: "",
    grade: "",
    group: "",
    academicOffer: "",
    studentTest: false,
    offerAcademic: "",
  };

  /**
   * Validate fields
   */
  const validateSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t("modalCreateStudent:labels.firstName") +
        " " +
        t("modalCreateStudent:labels.isRequired")
    ),
    lastName: Yup.string().required(
      t("modalCreateStudent:labels.lastName") +
        " " +
        t("modalCreateStudent:labels.isRequired")
    ),
    dateBirth: Yup.string()
      .required(
        t("modalCreateStudent:labels.dateBirth") +
          " " +
          t("modalCreateStudent:labels.isRequired")
      )
      .nullable(),
    identificationNumber: showAlertCurpIdNo
      ? Yup.string().required(t("modalCreateStudent:labels.dniCurpRequerid"))
      : Yup.string(),
    curp: showAlertCurpIdNo
      ? Yup.string("Curp").required(
          t("modalCreateStudent:labels.dniCurpRequerid")
        )
      : Yup.string("Curp").matches(
          /^([A-Za-z][AEIOUXaeioux][A-Za-z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HMhm](?:AS|as|B[CS]|b[cs]|C[CLMSH]|c[clmsh]|D[FG]|d[fg]|G[TR]|g[tr]|HG|hg|JC|jc|M[CNS]|m[cns]|N[ETL]|n[etl]|OC|oc|PL|pl|Q[TR]|q[tr]|S[PLR]|s[plr]|T[CSL]|t[csl]|VZ|vz|YN|yn|ZS|zs)[B-DF-HJ-NP-TV-Z-b-df-hj-np-tv-z]{3}[A-Za-z\d])(\d)$/,
          t("modalCreateStudent:labels.invalidCurpFormat")
        ),
    schoolCycle: Yup.object().required(
      t("modalCreateStudent:labels.schoolCycle") +
        " " +
        t("modalCreateStudent:labels.isRequired")
    ),
    schoolLevel: Yup.object()
      .required(
        t("modalCreateStudent:labels.schoolLevel") +
          " " +
          t("modalCreateStudent:labels.isRequired")
      )
      .nullable(),
    grade: !higherLevel
      ? Yup.object()
          .required(
            t("modalCreateStudent:labels.grade") +
              " " +
              t("modalCreateStudent:labels.isRequired")
          )
          .nullable()
      : Yup.string(),
    group: !higherLevel
      ? Yup.object()
          .required(
            t("modalCreateStudent:labels.group") +
              " " +
              t("modalCreateStudent:labels.isRequired")
          )
          .nullable()
      : Yup.string(),
    offerAcademic: higherLevel
      ? Yup.object()
          .required(
            t("modalCreateStudent:labels.offer") +
              " " +
              t("modalCreateStudent:labels.isRequired")
          )
          .nullable()
      : Yup.string(),
    level: higherLevel
      ? Yup.object()
          .required(
            t("modalCreateStudent:labels.level") +
              " " +
              t("modalCreateStudent:labels.isRequired")
          )
          .nullable()
      : Yup.string(),
  });

  /**
   * Description: Format the countries to be able to show them in the selector
   */
  const formaCountries = () => {
    getCountries().then((result) => {
      const countries = result.data;
      let formatCountries = [];
      if (countries && countries.length) {
        for (let country of countries) {
          let formatCountry = {
            label: country.name,
            value: country.id,
          };
          formatCountries.push(formatCountry);
        }
        setCountries(formatCountries);
      }
    });
  };

  const getSchoolCycles = () => {
    setIsLoading(true);
    GetSchoolCycle().then((result) => {
      let data = result.data;
      let cycles = data.map((cycle) => {
        return {
          label: cycle.school_cycle,
          value: cycle.id,
          levels: cycle.school_levels,
          collegesAndSchools: cycle.colleges_and_schools,
          cycleData: {
            label: cycle.school_cycle,
            value: cycle.id,
          },
        };
      });
      setSchoolCycles(cycles);
      setIsLoading(false);
    });
  };

  const formCollageAndSchool = (collageAndSchools) => {
    let formatCollageSchools = [];
    for (let college of collageAndSchools) {
      for (let level of college.school_levels) {
        for (let program of level.programs) {
          if (program.enabled == 1) {
            formatCollageSchools.push({
              label: program.name + "( " + level.school_level_name + ")",
              value: program.id,
              programLevels: program.program_levels.map((progLevel) => {
                return {
                  label: progLevel.level,
                  value: progLevel.id,
                };
              }),
            });
          }
        }
      }
    }
    return formatCollageSchools;
  };

  const formatLevels = (cycle) => {
    const levels = cycle.levels.map((level) => {
      return {
        label: level.school_level_name,
        value: level.id,
        grades: level.grades,
        levelData: {
          label: level.school_level_name,
          value: level.id,
          organization_school_level_id: level.organization_school_level_id,
        },
      };
    });
    if (cycle.collegesAndSchools.length > 0) {
      let collegeActives = formCollageAndSchool(cycle.collegesAndSchools);
      if (collegeActives.length > 0) {
        levels.push({
          label: t("modalCreateStudent:labels.university"),
          value: "otherOffer",
          collegesAndSchools: collegeActives,
          levelData: {
            label: t("modalCreateStudent:labels.university"),
            value: "otherOffer",
          },
        });
      }
    }
    return levels;
  };

  const formatGradesAndGroups = (levelGrades) => {
    const grades = levelGrades.map((grade) => {
      return {
        label: grade.grade_level,
        value: grade.id,
        groups: grade.groups.map((group) => {
          return {
            label: group.name,
            value: group.id,
            grade_group_grade_level_id: group.grade_group_grade_level_id,
          };
        }),
        gradeData: {
          label: grade.grade_level,
          value: grade.id,
        },
      };
    });
    return grades;
  };

  //Limpia selecotrees de nivel, grado y grupo
  const cleanSchoolSelectors = (selector, setFieldValue) => {
    setFieldValue("group", "");
    setFieldValue("level", "");
    if (selector === "grade") return;
    setFieldValue("grade", "");
    setGradeGroups([]);
    setCollegeLevels([]);
    if (selector === "level") return;
    setFieldValue("schoolLevel", "");
    setFieldValue("offerAcademic", "");
    setLevelGrades([]);
    setCollageAndSchools([]);
  };

  const saveStudent = (values) => {
    let student = createRequestStudent(values);
    createStudent(student).then((result) => {
      if (result.description) {
        setError(result.description);
      }
      if (result.data) {
        props.studentCreated(result.data);
      }
    });
  };

  const createRequestStudent = (values) => {
    let request = {};
    request.first_name = values.firstName.toUpperCase();
    values.middleName != "" &&
      (request.middle_name = values.middleName.toUpperCase());
    request.last_name = values.lastName.toUpperCase();
    values.gender != "" && (request.gender = values.gender);
    values.country != "" && (request.country = values.country.value);
    request.birth_date = Math.floor(values.dateBirth / 1000);
    values.identificationNumber != "" &&
      (request.student_unique_id = values.identificationNumber.toUpperCase());
    values.email != "" && (request.email = values.email);
    values.curp != "" && (request.curp = values.curp.toUpperCase());
    request.school_cycle_id = values.schoolCycle.value;
    request.organization_school_level =
      values.schoolLevel.organization_school_level_id;
    if (values.schoolLevel.value == "otherOffer") {
      request.program_id = values.offerAcademic.value;
      request.program_level_cycle_id = values.level.value;
    } else {
      request.grade_group_grade_level_id =
        values.group.grade_group_grade_level_id;
    }

    return request;
  };

  const closeModal = () => {
    props.setShowModalCreateStudent(false);
    props.studentCreated();
    setError(false);
  };

  useEffect(() => {
    setIsRequired(isRequiredFields(initialValues, validateSchema));
  }, [initialValues, validateSchema]);

  /**
   * Initial loading
   */
  useEffect(() => {
    //Format countries
    formaCountries();
    getSchoolCycles();
  }, []);

  return (
    <div>
      <Modal
        //show={true}
        show={props.showModalCreateStudent}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("global:buttons.createStudent")}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            saveStudent(values);
          }}
        >
          {(form) => (
            <Form>
              <ModalBody className="modal-body mbottom10">
                <Row>
                  {/**First name */}
                  <Col md={6}>
                    <FieldInput
                      label={t("modalCreateStudent:labels.firstName")}
                      capitalize={true}
                      name="firstName"
                      type="text"
                      placeholder=""
                      required={true}
                      showRequired={isRequired}
                    />
                  </Col>
                  {/**Second name */}
                  <Col md={6}>
                    <FieldInput
                      label={t("modalCreateStudent:labels.middleName")}
                      capitalize={true}
                      name="middleName"
                      type="text"
                      placeholder=""
                      showRequired={isRequired}
                    />
                  </Col>
                </Row>
                {/**lastname*/}
                <Row>
                  <Col md={12}>
                    <FieldInput
                      label={t("modalCreateStudent:labels.lastName")}
                      capitalize={true}
                      name="lastName"
                      type="text"
                      placeholder=""
                      required={true}
                      showRequired={isRequired}
                    />
                  </Col>
                </Row>
                <Row>
                  {/**Gender Male/Female*/}
                  <Col md={6}>
                    <FormGender
                      label={t("modalCreateStudent:labels.gender")}
                      name="gender"
                      gender={{
                        male: t("modalCreateStudent:labels.male"),
                        female: t("modalCreateStudent:labels.female"),
                      }}
                      showRequired={isRequired}
                    />
                  </Col>
                  {/**Select countries */}
                  <Col md={6}>
                    <FormBoost.Group>
                      <FormBoost.Label htmlFor="inputPassword5">
                        {t("modalCreateStudent:labels.countryOfOrigin")}
                      </FormBoost.Label>
                      <Select
                        name="country"
                        classNamePrefix="select"
                        className="select"
                        styles={selectStyles}
                        onBlur={() => form.setFieldTouched("country", true)}
                        noOptionsMessage={() => t("global:select.noMatches")}
                        options={countries}
                        onChange={(e) => form.setFieldValue("country", e)}
                      />
                    </FormBoost.Group>
                  </Col>
                </Row>
                <Row>
                  {/**Birth Day*/}
                  <Col md={5}>
                    <DatePicker
                      label={t("modalCreateStudent:labels.dateBirth")}
                      placeholderText="DD/MM/YYYY"
                      name="dateBirth"
                      maxDate={new Date()}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      {...Locallanguage}
                      required={true}
                      showRequired={isRequired}
                    />
                  </Col>
                  {/**Id number*/}
                  <Col md={7}>
                    <FieldInput
                      id="identificationNumber"
                      label={t("modalCreateStudent:labels.idNumber")}
                      capitalize={true}
                      name="identificationNumber"
                      type="text"
                      placeholder=""
                      showRequired={isRequired}
                    />
                  </Col>
                  {/**Validated optional values*/}
                  {form &&
                  form.values &&
                  form.values.identificationNumber === "" &&
                  form.values.curp === ""
                    ? setShowAlertCurpIdNo(true)
                    : setShowAlertCurpIdNo(false)}
                </Row>
                <Row>
                  {/**Email */}
                  <Col md={12}>
                    <FieldInput
                      id="email"
                      name="email"
                      label={t("modalCreateStudent:labels.email")}
                      type="email"
                      placeholder=""
                      showRequired={isRequired}
                    />
                  </Col>
                </Row>
                <Row>
                  {/**Curp */}
                  <Col md={12}>
                    <FieldInput
                      id="curp"
                      name="curp"
                      label={t("modalCreateStudent:labels.curp")}
                      capitalize={true}
                      type="text"
                      placeholder=""
                      showRequired={isRequired}
                    />
                  </Col>
                  {/**Validated optional values*/}
                  {form &&
                  form.values &&
                  form.values.identificationNumber === "" &&
                  form.values.curp === ""
                    ? setShowAlertCurpIdNo(true)
                    : setShowAlertCurpIdNo(false)}
                </Row>
                <Row>
                  <Col md={12}>
                    <FormBoost.Group>
                      <FormBoost.Label htmlFor="inputPassword5">
                        {t("modalCreateStudent:labels.schoolCycle")}
                      </FormBoost.Label>
                      <Select
                        name="schoolCycle"
                        classNamePrefix={"select"}
                        className={
                          form.touched.schoolCycle && form.errors.schoolCycle
                            ? `select errorValidation`
                            : `select`
                        }
                        placeholder={t("cycles:select.placeholder")}
                        styles={selectStyles}
                        isDisabled={isLoading}
                        onBlur={() => form.setFieldTouched("schoolCycle", true)}
                        options={schoolCycles}
                        onChange={(e) => {
                          form.setFieldValue("schoolCycle", e.cycleData);
                          setCycleLevels(formatLevels(e));
                          cleanSchoolSelectors("cycle", form.setFieldValue);
                        }}
                      />
                      {form.errors.schoolCycle && (
                        <FormBoost.Text className="text-muted error">
                          {form.errors.schoolCycle}
                        </FormBoost.Text>
                      )}
                    </FormBoost.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-3">
                    <FormBoost.Group>
                      <FormBoost.Label htmlFor="inputPassword5">
                        {t("modalCreateStudent:labels.schoolLevel")}
                      </FormBoost.Label>
                      <Select
                        name="schoolLevel"
                        classNamePrefix={"select"}
                        className={
                          form.touched.schoolLevel && form.errors.schoolLevel
                            ? `select errorValidation`
                            : `select`
                        }
                        placeholder={t("cycles:select.placeholder")}
                        styles={selectStyles}
                        onBlur={() => form.setFieldTouched("schoolLevel", true)}
                        options={cycleLevels}
                        onChange={(e) => {
                          form.setFieldValue("schoolLevel", e.levelData);

                          cleanSchoolSelectors("level", form.setFieldValue);
                          if (e.value == "otherOffer") {
                            setCollageAndSchools(e.collegesAndSchools);
                            sethigherLevel(true);
                          } else {
                            setLevelGrades(formatGradesAndGroups(e.grades));
                            sethigherLevel(false);
                          }
                        }}
                        value={form.values.schoolLevel}
                        isDisabled={form.values.schoolCycle == ""}
                      />
                      {form.errors.schoolLevel && (
                        <FormBoost.Text className="text-muted error">
                          {form.errors.schoolLevel}
                        </FormBoost.Text>
                      )}
                    </FormBoost.Group>
                  </Col>
                </Row>
                {form.values.schoolLevel.value != "otherOffer" ? (
                  <Row>
                    <Col md={6} className="mt-3">
                      <FormBoost.Group>
                        <FormBoost.Label htmlFor="inputPassword5">
                          {t("modalCreateStudent:labels.grade")}
                        </FormBoost.Label>
                        <Select
                          name="grade"
                          classNamePrefix={"select"}
                          className={
                            form.touched.grade && form.errors.grade
                              ? `select errorValidation`
                              : `select`
                          }
                          placeholder={t("cycles:select.placeholder")}
                          styles={selectStyles}
                          onBlur={() => form.setFieldTouched("grade", true)}
                          options={levelGrades}
                          onChange={(e) => {
                            form.setFieldValue("grade", e.gradeData);
                            setGradeGroups(e.groups);
                            cleanSchoolSelectors("grade", form.setFieldValue);
                          }}
                          value={form.values.grade}
                          isDisabled={form.values.schoolLevel == ""}
                          showRequired={isRequired}
                        />
                        {form.errors.grade && (
                          <FormBoost.Text className="text-muted error">
                            {form.errors.grade}
                          </FormBoost.Text>
                        )}
                      </FormBoost.Group>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormBoost.Group>
                        <FormBoost.Label htmlFor="inputPassword5">
                          {t("modalCreateStudent:labels.group")}
                        </FormBoost.Label>
                        <Select
                          name="group"
                          classNamePrefix={"select"}
                          className={
                            form.touched.group && form.errors.group
                              ? `select errorValidation`
                              : `select`
                          }
                          placeholder={t("cycles:select.placeholder")}
                          styles={selectStyles}
                          isDisabled={form.values.grade == ""}
                          onBlur={() => form.setFieldTouched("group", true)}
                          options={gradeGroups}
                          onChange={(e) => form.setFieldValue("group", e)}
                          value={form.values.group}
                          showRequired={isRequired}
                        />
                        {form.errors.group && (
                          <FormBoost.Text className="text-muted error">
                            {form.errors.group}
                          </FormBoost.Text>
                        )}
                      </FormBoost.Group>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col md={6} className="mt-3">
                      <FormBoost.Group>
                        <FormBoost.Label htmlFor="inputPassword5">
                          {t("modalCreateStudent:labels.offer")}
                        </FormBoost.Label>
                        <Select
                          name="offerAcademic"
                          classNamePrefix={"select"}
                          className={
                            form.touched.offerAcademic &&
                            form.errors.offerAcademic
                              ? `select errorValidation`
                              : `select`
                          }
                          placeholder={t(
                            "cycles:select.placeholderOfferAcademic"
                          )}
                          styles={selectStyles}
                          isDisabled={form.values.schoolLevel == ""}
                          onBlur={() =>
                            form.setFieldTouched("offerAcademic", true)
                          }
                          options={collageAndSchools}
                          onChange={(e) => {
                            form.setFieldValue("offerAcademic", e);
                            setCollegeLevels(e.programLevels);
                          }}
                          value={form.values.offerAcademic}
                          showRequired={isRequired}
                        />
                        {form.errors.offerAcademic && (
                          <FormBoost.Text className="text-muted error">
                            {form.errors.offerAcademic}
                          </FormBoost.Text>
                        )}
                      </FormBoost.Group>
                    </Col>
                    <Col md={6} className="mt-3">
                      {/* Level */}
                      <FormBoost.Group>
                        <FormBoost.Label htmlFor="inputPassword5">
                          {t("modalCreateStudent:labels.level")}
                        </FormBoost.Label>
                        <Select
                          name="level"
                          classNamePrefix={"select"}
                          className={
                            form.touched.level && form.errors.level
                              ? `select errorValidation`
                              : `select`
                          }
                          placeholder={t(
                            "cycles:schoolCycleGroup.levelAcademicOffer"
                          )}
                          styles={selectStyles}
                          isDisabled={form.values.offerAcademic == ""}
                          onBlur={() => form.setFieldTouched("level", true)}
                          options={collegeLevels}
                          onChange={(e) => form.setFieldValue("level", e)}
                          value={form.values.level}
                          showRequired={isRequired}
                        />
                        {form.errors.level && (
                          <FormBoost.Text className="text-muted error">
                            {form.errors.level}
                          </FormBoost.Text>
                        )}
                      </FormBoost.Group>
                    </Col>
                  </Row>
                )}
                {permissions.admin_students.edit && (
                  <Row>
                    {/**CheckBox Student test*/}
                    <Col md={12} className="mt-3">
                      <FormCheck
                        name="studentTest"
                        label={t("modalCreateStudent:labels.testStudent")}
                        type="checkbox"
                      />
                    </Col>
                  </Row>
                )}
                {/**Show errors when creating a prospect*/}
                {error && (
                  <div className="mtop20">
                    <Alert variant="warning">{error}</Alert>
                  </div>
                )}
              </ModalBody>
              <Modal.Footer>
                <Button variant="light" onClick={closeModal}>
                  {t("modalCreateRelative:buttons.cancel")}
                </Button>
                <Button variant="primary" type="submit">
                  {t("modalCreateStudent:buttons.createStudent")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

CreateStudentModal.propTypes = {
  showModalCreateStudent: PropTypes.bool,
  setShowModalCreateStudent: PropTypes.func,
  studentCreated: PropTypes.func,
};
