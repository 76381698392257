import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button, Image } from "react-bootstrap";

export default function ModalPreviewDiploma(props) {
  const [t] = useTranslation(["global"]);
  const { values = {} } = props;
  const prefix = process.env.REACT_APP_PREFIX;
  const orgInfo = JSON.parse(
    localStorage.getItem(`cmOrganizationInfo${prefix}`)
  );

  const styles = {
    diplomaContainer: {
      background: "#FFF",
      boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.24)",
      height: "38.25rem",
      display: "flex",
      padding: "1.5rem",
    },
    diploma: {
      border: "0.5px solid #000",
      padding: "1.5rem",
      display: "flex",
      background: "#FFF",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      flex: "1 0 0",
      alignSelf: "stretch",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "1.5rem",
      alignSelf: "stretch",
    },
    title: {
      color: "#2D2D2D",
      textAlign: "start",
      fontFamily: "Mulish",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.75rem",
      letterSpacing: "-0.03rem",
    },
    organizationContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0.5rem",
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "1rem",
      alignSelf: "stretch",
    },
    modality: {
      color: "#4884CA",
      fontFamily: "Mulish",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.0625rem",
      textAlign: "left",
    },
    containerName: {
      display: "flex",
      padding: "0.3125rem 0rem",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.625rem",
      alignSelf: "stretch",
      borderBottom: "1px solid",
    },
    studentName: {
      color: "#2D2D2D",
      fontFamily: "Mulish",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.4375rem",
      letterSpacing: "-0.025rem",
    },
    description: {
      textAlign: "justify",
      color: "#2D2D2D",
      fontFamily: "Mulish",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "0.875rem",
    },
    footerContainer: {
      display: "flex",
      width: "39.875rem",
      justifyContent: "space-between",
      alignItems: "flex-end",
      flexDirection: "column",
    },
    signaturesContainer: {
      display: "flex",
      width: "13.4375rem",
      flexDirection: "column",
      marginTop: "12%",
      alignItems: "center",
      gap: "0.25rem",
      flexShrink: 0,
    },
    qrContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      alignItems: "center",
      width: "13rem",
    },
    personSignature: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.625rem",
      alignSelf: "stretch",
      borderTop: "1px solid #2D2D2D",
    },
    textBold: {
      color: "#2D2D2D",
      textAlign: "center",
      fontFamily: "Mulish",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.0625rem",
    },
    smallText: {
      color: "#2D2D2D",
      fontFamily: "Mulish",
      fontSize: "0.5625rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "0.75rem",
    },
  };

  const modalityTypes = {
    on_site: "Presencial",
    online: "En línea",
    hybrid: "Híbrido",
  };

  return (
    <div>
      <Modal
        show={props.showModalPreviewDiploma}
        size="lg"
        aria-labelledby="contained-modal-title-md"
      >
        <Modal.Body className="modal-body mbottom10">
          <Row>
            <Col md={12}>
              <div className="text-center">
                <div id="diplomaContainer" style={styles.diplomaContainer}>
                  <div id="diploma" style={styles.diploma}>
                    <div id="diplomaHeader" style={styles.headerContainer}>
                      <Row>
                        <Col id="diplomaTitle" md={8} style={styles.title}>
                          {values.title}
                        </Col>
                        <Col
                          id="organizationInfo"
                          md={4}
                          style={styles.organizationContainer}
                        >
                          <Image
                            id="organizationLogo"
                            alt="organizationLogo"
                            responsive
                            src={orgInfo.logo_image_url}
                            width={50}
                            height={50}
                          />
                          <div id="organizationName" style={styles.textBold}>
                            {orgInfo.name}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div id="diplomaBody" style={styles.bodyContainer}>
                      <Row>
                        <Col id="modalityType" md={12} style={styles.modality}>
                          Modalidad{" " + modalityTypes[values.type?.value]}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          id="addressedTo"
                          md={12}
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {orgInfo.name + " otorga el presente diploma a:"}
                        </Col>
                      </Row>
                      <Row>
                        <Col id="studentNameContainer" md={12}>
                          <div
                            id="studentNameBorder"
                            style={styles.containerName}
                          >
                            <div id="studentName" style={styles.studentName}>
                              [Nombre del estudiante]
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          id="diplomaDescription"
                          md={12}
                          style={styles.description}
                        >
                          <div
                            id="descriptionWithLineBreaks"
                            dangerouslySetInnerHTML={{
                              __html: values.description.replace(/\n/g, "<br>"),
                            }}
                          ></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          id="addressedTo"
                          md={12}
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {orgInfo.state?.name
                            ? orgInfo.city?.name
                              ? `${orgInfo.city?.name}, ${orgInfo.state?.name}, a `
                              : `${orgInfo.state?.name}, a`
                            : "A "}
                          {new Date(values.expeditionDate)
                            .toLocaleDateString("es-mx", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })
                            .replace("de 2", "del 2")}
                        </Col>
                      </Row>
                    </div>
                    <div id="diplomaFooter" style={styles.footerContainer}>
                      <Row>
                        <Col
                          id="signatureInstructorContainer"
                          md={4}
                          style={styles.signaturesContainer}
                        >
                          <div
                            id="instructorName"
                            style={styles.personSignature}
                          >
                            {values.instructor?.label}
                          </div>
                          <div id="instructorLabel" style={styles.textBold}>
                            {orgInfo.id == 134 || orgInfo.id == 249
                              ? "Coordinador del DIT"
                              : "Instructor"}
                          </div>
                        </Col>
                        <Col
                          id="signatureDirectorContainer"
                          md={4}
                          style={styles.signaturesContainer}
                        >
                          <div id="directorName" style={styles.personSignature}>
                            {values.director?.label}
                          </div>
                          <div id="directorLabel" style={styles.textBold}>
                            Director
                          </div>
                        </Col>
                        <Col
                          id="folioNumberAndQRcode"
                          md={4}
                          style={styles.qrContainer}
                        >
                          <Image
                            id="qrValidarCampusmovil"
                            alt="qrValidarCampusmovil"
                            responsive
                            src="/img/qr_validar_campusmovil.png"
                            width={90}
                            height={90}
                          />
                          <div id="appValidationURL" style={styles.smallText}>
                            validar.campusmovil.com
                          </div>
                          <div id="folioDiploma" style={styles.textBold}>
                            Folio: [Núm. de folio]
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnCloseModalPreviewDiploma"
            variant="outline-secondary"
            onClick={props.closeModalPreviewDiploma}
          >
            {t("global:buttons.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalPreviewDiploma.propTypes = {
  showModalPreviewDiploma: PropTypes.bool.isRequired,
  closeModalPreviewDiploma: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};
