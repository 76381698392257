import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { MainContext } from "../../App";
import { goTo } from "../global/GlobalTools";

/**
 * Style Alert
 */
const stylesCustom = {
  zIndex: "+2",
  borderTop: "1px solid #E7E7E7",
  textAlign: "start",
  padding: "4px 20px",
  position: "static",
  left: "11%",
  bottom: "64%",
  height: "45px",
  width: "85%",
  marginTop: "20px",
};

/*Services */
import {
  GetPermissions,
  GetOptions,
  UpdatePermitions,
} from "../../api/permissions";

/*Elements */
import ModalEditUserRoles from "./ModalEditUserRoles";
import ModalEditSections from "./ModalEditSections";

const customStyles = {
  container: {
    background: "#fff",
    minHeight: "18px",
    display: "inline-block",
    border: "1px solid #e0e0e0",
    width: "100%",
    marginBottom: "10px",
    overflowX: "hidden",
  },
};

//starts the default values
const defaultPermissionts = { sectionId: 1, groupId: 1 };

//Nota: Stock values
//   { id: 1, active: false }, //Acces
//   { id: 2, active: false }, //Create
//   { id: 3, active: false }, //Delete
//   { id: 4, active: false }, //Edit

const PermissionsView = () => {
  const [t] = useTranslation(["global"]);
  const { permissions } = useContext(MainContext);
  const [sections, setSections] = useState([]); //sections
  const [userGroups, setUserGroups] = useState([]); //group rol
  const [actions, setActions] = useState([]);
  const [permissionsUser, setPermissionsUser] = useState(defaultPermissionts);
  const [showModalEditUserRoles, setShowModalEditUserRoles] = useState(false);
  const [showModalEditSections, setShowModalEditSections] = useState(false);
  const [alertSave, setAlertSave] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  //Gets the permissions that the role will be able to do
  const getPermissions = (obj) => {
    let request = {
      section_id: obj.sectionId,
      group_id: obj.groupId,
    };
    GetPermissions(request).then((response) => {
      let userActions = response.data;
      setActions(userActions);
    });
  };

  //Gets the role options
  const getOptions = () => {
    GetOptions().then((result) => {
      let options = result.data.data;
      setSections(options.sections);
      setUserGroups(options.groups);
    });
  };

  //Select permission combinations from selectors
  const changePermissionsCombination = (event) => {
    let permissionsCombination = { ...permissionsUser };
    let value = event.target.value;
    let field = event.target.id;
    permissionsCombination[field] = value;
    getPermissions(permissionsCombination);
    setPermissionsUser(permissionsCombination);
  };

  /**
   *Select each check box
   * @param {Obj} event
   */
  const onclickActions = (event) => {
    let updateActions = [...actions];
    let actionId = event.target.id;
    let actionName = event.target.name;
    if (actionAvailable(actionId)) {
      let position = updateActions.findIndex((action) => action.id == actionId);
      updateActions.splice(position, 1);
    } else {
      updateActions.push({ id: actionId, action: actionName });
    }
    setActions(updateActions);
  };

  /**
   * stock permits
   * @param {Array} id
   * @returns
   */
  const actionAvailable = (id) => {
    let available = actions.some((action) => action.id == id);
    return available;
  };

  /**
   * Saves the permissions of actions that the role will have
   */
  const savePermitions = () => {
    let request = {
      section_id: permissionsUser.sectionId,
      group_id: permissionsUser.groupId,
      actions: JSON.stringify(actions.map((action) => action.id)),
    };

    UpdatePermitions(request).then((result) => {
      setActions(result.data.actions);
      setAlertSave(true);
      setIsDisabled(true);
      setTimeout(() => {
        setAlertSave(false);
        setIsDisabled(false);
      }, 3000);
    });
  };

  useEffect(() => {
    getPermissions(permissionsUser);
    getOptions();
  }, []);

  return permissions.admin_permissions.access ? (
    <Container fluid>
      <div className="mx-4 mt-5">
        <Row>
          <Col xs={12} style={customStyles.container}>
            <div className="my-4">
              {t("global:titlePermissions.addPermissions")}
            </div>
            <div className="ms-3">
              <Row>
                <Col lg={4} sm={4} style={{ padding: "0px" }}>
                  <Form.Label>
                    {t("global:titlePermissions.userRoles")}
                  </Form.Label>
                  <i
                    className="bi bi-gear ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowModalEditUserRoles(true)}
                  ></i>
                  <Form.Select
                    id="groupId"
                    size="sm"
                    value={permissionsUser.groupId}
                    onChange={changePermissionsCombination}
                  >
                    {userGroups.map((group, index) => {
                      return (
                        <option key={index} value={group.id}>
                          {group.description}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col lg={4} sm={4}>
                  <Form.Label>
                    {t("global:titlePermissions.section")}
                  </Form.Label>
                  <i
                    className="bi bi-gear ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowModalEditSections(true)}
                  ></i>
                  <Form.Select
                    id="sectionId"
                    size="sm"
                    value={permissionsUser.sectionId}
                    onChange={changePermissionsCombination}
                  >
                    {sections.map((section, index) => {
                      return (
                        <option key={index} value={section.id}>
                          {section.text}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col lg={1} sm={1} xs={3}>
                  <div className="mt-3">
                    {t("global:titlePermissions.access")}
                    <Form.Check
                      aria-label="option1"
                      id="1"
                      onClick={onclickActions}
                      name="acces"
                      checked={actionAvailable(1)}
                    />
                  </div>
                </Col>
                <Col lg={1} sm={1} xs={3}>
                  <div className="mt-3">
                    {t("global:titlePermissions.create")}
                    <Form.Check
                      aria-label="option2"
                      id="2"
                      onClick={onclickActions}
                      name="create"
                      checked={actionAvailable(2)}
                    />
                  </div>
                </Col>
                <Col lg={1} sm={1} xs={3}>
                  <div className="mt-3">
                    {t("global:titlePermissions.edit")}
                    <Form.Check
                      aria-label="option3"
                      id="4"
                      onClick={onclickActions}
                      name="edit"
                      checked={actionAvailable(4)}
                    />
                  </div>
                </Col>
                <Col lg={1} sm={1} xs={3}>
                  <div className="mt-3">
                    {t("global:titlePermissions.delete")}
                    <Form.Check
                      aria-label="option4"
                      id="3"
                      onClick={onclickActions}
                      name="delete"
                      checked={actionAvailable(3)}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg={6}>
                <div
                  className={!alertSave ? "hide" : "alert alert-success"}
                  style={stylesCustom}
                >
                  <div className="col-md-12 col-xs-12 mtop10">
                    <i className="bi bi-check-circle"></i>{" "}
                    {t("textSavedSuccessfully")}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="py-4 float-end" sm={6} lg={6} xs={6}>
                  <Button
                    id="btnClean"
                    variant="outline-secondary"
                    style={{ marginRight: "10px" }}
                    onClick={() => getPermissions(permissionsUser)}
                  >
                    {t("global:buttons.clean")}
                  </Button>
                  <Button
                    id="btnSave"
                    variant="primary"
                    style={{ marginRight: "10px" }}
                    onClick={() => savePermitions()}
                    disabled={isDisabled}
                  >
                    {t("global:buttons.save")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <ModalEditUserRoles
        showModalEditUserRoles={showModalEditUserRoles}
        setShowModalEditUserRoles={setShowModalEditUserRoles}
        userGroups={[...userGroups]}
        setUserGroups={setUserGroups}
      />

      <ModalEditSections
        showModalEditSections={showModalEditSections}
        setShowModalEditSections={setShowModalEditSections}
        sections={[...sections]}
        setSections={setSections}
      />
    </Container>
  ) : (
    goTo("/dashboard")
  );
};

export default PermissionsView;
