import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";
let axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "get",
};

const prefix = process.env.REACT_APP_PREFIX;

/**
 * Service to create users
 * @param {object} data
 * @returns
 */
export const createUsers = async (data) => {
  data.token = localStorage.getItem(`cmToken${prefix}`);
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;

  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/users/create`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Service to obtain users
 * @param {object} data
 * @returns
 */
export const GetUsers = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/staff_users`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Service to inactivate staff
 * @param {Object} data
 * @returns
 */
export const inactivateStaff = async (data) => {
  data.token = localStorage.getItem(`cmToken${prefix}`);
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;

  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/users/delete`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return validateErrorResponse(error.response);
  }
};

/**
 * Purpose: Download template
 * @returns
 */
export const DownloadTemplate = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/assets/csv/maestrosV2.csv`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Import Staff csv
 * @param {File} file
 * @param {Id} schoolCycleId
 */
export const importStaffCSV = async (file) => {
  let data = new FormData();
  data.append("file", file);

  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "multipart/form-data",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/teachers/import`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};
