import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { MainContext } from "../../App";
import { ReactSortable } from "react-sortablejs";
import * as Sentry from "@sentry/react";

import ContentSolid from "../global/ContentSolid";
import { LoadingExtraLink } from "../lazyLoading/LazyLoading";
import ModalAddEditExtraLink from "../../pages/extraLink/modals.js/ModalAddEditExtraLink";
import ModalDeleteExtraLink from "../../pages/extraLink/modals.js/ModalDeleteExtraLink";

import { GetExtraLink, UpdateLink } from "../../api/ExtraLink";
import { getUrlParamValue } from "../global/GlobalTools";

const addModalId = getUrlParamValue("openModal");
const openModal = addModalId || false;
// gets the width of the window and extracts a calculated portion from it
const heightListContainer = window.innerHeight - window.innerHeight / 4;
const customStyles = {
  container: {
    background: "#fff",
    minHeight: "18px",
    display: "inline-block",
    border: "1px solid #e0e0e0",
    width: "100%",
    marginBottom: "10px",
    marginTop: "20px",
  },
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "50px",
    marginBlock: "10px",
    marginBottom: "20px",
  },
  listContainer: {
    maxHeight: `${heightListContainer}px`,
    overflowX: "hidden",
  },
};

const ExtraLinkView = () => {
  const [t] = useTranslation(["extraLink"]);
  const [showAddEditExtraLink, setShowAddEditExtraLink] = useState(openModal);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [extraLink, setExtraLink] = useState({
    name: "",
    url: "",
  });
  const [extraLinksList, setExtraLinksList] = useState([]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const { permissions } = useContext(MainContext);
  const [loadingView, setLoadingView] = useState(true);

  /**
   * Get the parameters to display
   */
  const getExtraLinks = () => {
    GetExtraLink().then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      } else {
        let data = result.data;
        let extraLinks = [];
        extraLinks = data.map((item) => {
          return {
            id: item.id,
            name: item.link_name,
            url: item.link_url,
          };
        });
        setExtraLinksList(extraLinks);
        setLoadingView(false);
      }
    });
  };

  /*
   * Select by Id to delete an item
   * @param {Id} extraLinkId
   */
  const selectItemToDelete = (extraLinkId) => {
    setItemIdToDelete(extraLinkId);
    setShowDeleteModal(true);
  };

  /**
   * Gets the id and new position of a reordered element
   * @param {object} evt
   */
  const updateOrder = (evt) => {
    const isSamePosition = evt.newIndex == evt.oldIndex;
    if (!isSamePosition) {
      const newPosition = evt.newIndex + 1;
      const itemId = evt.item.id;
      const item = extraLinksList.find((link) => link.id == itemId);
      updateExtraLink(item, newPosition);
    }
  };

  /**
   * Function to update an extra link
   * @param {object} item
   */
  const updateExtraLink = (item, newPosition) => {
    const request = {
      hyperlink_id: item.id,
      position: newPosition,
      link_name: item.name,
      link_url: item.url,
    };
    UpdateLink(request).then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      }
    });
  };

  /*
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Extra Links");
    getExtraLinks();
  }, []);

  /**
   * Edit an element by Id
   * @param {Id} extraLink
   */
  const editExtraLink = (extraLink) => {
    setExtraLink(extraLink);
    setShowAddEditExtraLink(true);
  };

  /**
   * Function to close modal
   */
  const closeModal = () => {
    setExtraLink({
      name: "",
      url: "",
    });
  };

  return (
    <div>
      {permissions.app_extra_links.create && (
        <Row>
          <Col md={12} lg={12}>
            <a
              href="?openModal=true"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Button
                className="float-end"
                variant="primary"
                onClick={() => {
                  setShowAddEditExtraLink(true);
                  closeModal();
                }}
              >
                {t("addLink")}
              </Button>
            </a>
          </Col>
        </Row>
      )}
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingExtraLink />
        </ContentSolid>
      ) : (
        <Col md={12} style={customStyles.container}>
          <Row className="ps-3 pe-3">
            <Col md={5} className="mt-4 ps-5 fs-5">
              <b className="ps-5">{t("name")} </b>
            </Col>
            <Col md={5} className="mt-4 fs-5">
              <b> {t("link")}</b>
            </Col>
            <Col md={2} className="mt-4"></Col>
          </Row>
          <Row className="ps-3 pe-3">
            <Col className="px-5" md={12} style={customStyles.listContainer}>
              <ReactSortable
                list={extraLinksList}
                setList={(list) => {
                  setExtraLinksList(list);
                }}
                onEnd={(evt) => updateOrder(evt)}
                handle=".bi-list"
              >
                {extraLinksList.map((item) => {
                  return (
                    <Row
                      key={item.id}
                      style={customStyles.rowContainer}
                      id={item.id}
                    >
                      <Col className="mt-2 text-truncate" md={5}>
                        <i
                          className="bi bi-list"
                          style={{ cursor: "n-resize" }}
                        />
                        <b className="ms-4">{item.name}</b>
                      </Col>
                      <Col className="mt-2 text-truncate" md={5}>
                        <a
                          href="#"
                          style={{ color: "#ea2c54" }}
                          onClick={() => window.open(item.url)}
                        >
                          {item.url}
                        </a>
                      </Col>
                      <Col className="mt-2" md={2}>
                        <i
                          className="bi bi-trash-fill mx-3 float-end"
                          style={{ color: "#969696" }}
                          role="button"
                          onClick={() => selectItemToDelete(item.id)}
                          disabled={!permissions.app_extra_links.delete}
                        ></i>
                        <i
                          className="bi bi-pencil-fill float-end"
                          style={{ color: "#969696" }}
                          role="button"
                          onClick={() => editExtraLink(item)}
                          disabled={!permissions.app_extra_links.edit}
                        ></i>
                      </Col>
                    </Row>
                  );
                })}
              </ReactSortable>
            </Col>
          </Row>
        </Col>
      )}
      {showAddEditExtraLink && (
        <ModalAddEditExtraLink
          showAddEditExtraLink={showAddEditExtraLink}
          setShowAddEditExtraLink={setShowAddEditExtraLink}
          extraLink={extraLink}
          closeModal={closeModal}
          getExtraLinks={getExtraLinks}
        />
      )}
      <ModalDeleteExtraLink
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        getExtraLinks={getExtraLinks}
        itemIdToDelete={itemIdToDelete}
      />
    </div>
  );
};

export default ExtraLinkView;
