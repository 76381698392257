import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalScheduleDemo from "./ModalScheduleDemo";
import ModalBlockedSection from "../ModalBlockedSection";

const OverlayBlockModule = (props) => {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["dashboard"]);
  const [showModalScheduleDemo, setShowModalScheduleDemo] = useState(false);
  const [titleBtn, setTitleBtn] = useState("");
  const [showModalSeeMore, setShowModalSeeMore] = useState(false);
  const userGroup = localStorage.getItem(`cmGroup${prefix}`);
  const studentId =
    userGroup !== "7" && localStorage.getItem(`cmStudentId${prefix}`);

  const launchAction = () => {
    if (props.originRequest) {
      setShowModalSeeMore(true);
    } else {
      setShowModalScheduleDemo(true);
    }
  };

  useEffect(() => {
    if (typeof t == "function") {
      let title = props.textButton || t("dashboard:overlayBlock.unlock");
      setTitleBtn(title);
    }
  }, [t]);

  return (
    <>
      <div style={{ paddingLeft: "0px", paddingRight: "0" }}>
        <div className="overlayBlock-container">
          {props.content}
          <div
            className={props.active ? "hide" : "overlayBlock-content"}
            style={{
              width: props.customWidth ? props.customWidth : "103%",
              marginLeft: props.today ? "" : "-11px",
            }}
          >
            <div className="overlayBlock-elements">
              <svg
                className="mb-2"
                height="70"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M35.8182 28.8387H14.1818C13.3621 28.8387 12.5759 29.1174 11.9962 29.6135C11.4166 30.1095 11.0909 30.7824 11.0909 31.4839V44.7097C11.0909 45.4112 11.4166 46.084 11.9962 46.5801C12.5759 47.0762 13.3621 47.3549 14.1818 47.3549H35.8182C36.6379 47.3549 37.4241 47.0762 38.0038 46.5801C38.5834 46.084 38.9091 45.4112 38.9091 44.7097V31.4839C38.9091 30.7824 38.5834 30.1095 38.0038 29.6135C37.4241 29.1174 36.6379 28.8387 35.8182 28.8387ZM14.1818 26.1936C12.5423 26.1936 10.9699 26.7509 9.81061 27.7431C8.6513 28.7352 8 30.0808 8 31.4839V44.7097C8 46.1128 8.6513 47.4584 9.81061 48.4505C10.9699 49.4426 12.5423 50 14.1818 50H35.8182C37.4577 50 39.0301 49.4426 40.1894 48.4505C41.3487 47.4584 42 46.1128 42 44.7097V31.4839C42 30.0808 41.3487 28.7352 40.1894 27.7431C39.0301 26.7509 37.4577 26.1936 35.8182 26.1936H14.1818ZM14.1818 18.2581C14.1818 15.8027 15.3216 13.4479 17.3504 11.7116C19.3792 9.97542 22.1308 10 25 10C27.8692 10 30.6208 9.97542 32.6496 11.7116C34.6784 13.4479 35.8182 15.8027 35.8182 18.2581V26.1936H32.7273V18.2581C32.7273 16.5042 31.9132 14.8222 30.464 13.5821C29.0149 12.3419 27.0494 12.6452 25 12.6452C22.9506 12.6452 20.9851 12.3419 19.536 13.5821C18.0868 14.8222 17.2727 16.5042 17.2727 18.2581V26.1936H14.1818V18.2581Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.6104 37.7341C27.3103 37.2543 27.763 36.4904 27.763 35.6299C27.763 34.1775 26.4729 33 24.8815 33C23.2901 33 22 34.1775 22 35.6299C22 36.4904 22.4527 37.2543 23.1526 37.7341V42.5197C23.1526 43.0719 23.6003 43.5197 24.1526 43.5197H25.6104C26.1627 43.5197 26.6104 43.0719 26.6104 42.5197V37.7341Z"
                  fill="black"
                />
              </svg>
              <br />
              <Button onClick={() => launchAction()}>{titleBtn}</Button>
            </div>
          </div>
        </div>
      </div>
      {showModalScheduleDemo && (
        <ModalScheduleDemo
          showModalScheduleDemo={showModalScheduleDemo}
          setShowModalScheduleDemo={setShowModalScheduleDemo}
          module={props.module}
        />
      )}
      {showModalSeeMore && (
        <ModalBlockedSection
          showModalSeeMore={showModalSeeMore}
          setShowModalSeeMore={setShowModalSeeMore}
          originRequest={props.originRequest}
          studentId={studentId}
          nameService={props.nameService}
          new={props.new}
        />
      )}
    </>
  );
};
export default OverlayBlockModule;
OverlayBlockModule.propTypes = {
  content: PropTypes.object,
  withScroll: PropTypes.bool,
  active: PropTypes.bool,
  module: PropTypes.string,
  today: PropTypes.bool,
  textButton: PropTypes.string,
  originRequest: PropTypes.string,
  nameService: PropTypes.string,
  new: PropTypes.string,
  todayRel: PropTypes.bool,
  customWidth: PropTypes.string,
};
