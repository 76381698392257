import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
/*Services */
import { GetPreSignedLink, GetQrCodeUrl } from "../../api/Crm";

/** Elements */
import ContentButton from "../../components/global/ContentButton";
export default function ModalCrmForm(props) {
  const [t] = useTranslation(["crm", "global"]);
  const [urlDecode, setUrlDecode] = useState("");
  const [dowloandQR, setDowloandQR] = useState(null);
  const [copied, setCopied] = useState(false);

  /**
   * Get all organization categories
   */
  const getQrCode = () => {
    GetQrCodeUrl().then((result) => {
      let data = result.data;
      let qrCodeAwsPath = data.qr_code_aws_path;
      let urlDecode = data.url_decode;
      setUrlDecode(urlDecode);
      getUrlForm(qrCodeAwsPath);
    });
  };

  /**
   *  Get the QR of the form
   * @param {*} qrCodeAwsPath
   */
  const getUrlForm = (qrCodeAwsPath) => {
    let request = {
      section: "prospects",
      aws_path: qrCodeAwsPath,
    };
    GetPreSignedLink(request).then((result) => {
      let data = result.data;
      let qrCodeUrl = data;
      setDowloandQR(qrCodeUrl);
    });
  };

  /**
   *Function to copy link
   */
  const copiLink = () => {
    setCopied(true);
    navigator.clipboard.writeText(urlDecode);
    setTimeout(
      function () {
        setCopied(false);
      }.bind(this),
      1500
    );
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getQrCode();
  }, []);

  /**
   * Purpose: Close the  modal
   */
  const cancel = () => {
    props.setShowModalCrmForm(false);
  };

  return (
    <Modal
      show={props.showModalCrmForm}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Body>
        <ContentButton className="content-button">
          <Button
            type="button"
            variant="outline-secondary"
            className={
              copied
                ? "btn btn-text btn-success"
                : "btn btn-link activity-break-text text-ellipsis"
            }
            style={{ width: "100%" }}
            onClick={copiLink}
          >
            {copied ? t("btnCopiedLink") : urlDecode}
          </Button>
        </ContentButton>
      </Modal.Body>
      <Modal.Footer>
        <ContentButton className="content-button">
          <Button variant="outline-secondary" onClick={() => cancel()}>
            {t("modalAddProspect.btnClose")}
          </Button>
          <Button onClick={() => window.open(dowloandQR)}>
            {t("downloadQR")}
          </Button>
        </ContentButton>
      </Modal.Footer>
    </Modal>
  );
}

ModalCrmForm.propTypes = {
  showModalCrmForm: PropTypes.bool,
  setShowModalCrmForm: PropTypes.func,
};
