import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { MainContext } from "../../App";

import ModalAddTools from "../../pages/extraLink/modals.js/ModalAddTools";
import ModalDeleteTools from "../../pages/extraLink/modals.js/ModalDeleteTools";
import ModalEditTools from "../../pages/extraLink/modals.js/ModalEditTools";
import ContentSolid from "../global/ContentSolid";
import { LoadingTools } from "../lazyLoading/LazyLoading";

import { GetTools } from "../../api/ExtraLink";

const customStyles = {
  container: {
    background: "#fff",
    minHeight: "18px",
    display: "inline-block",
    border: "1px solid #e0e0e0",
    width: "100%",
    marginBottom: "10px",
    overflowX: "hidden",
    marginTop: "20px",
  },
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "60px",
    marginBlock: "10px",
    marginBottom: "20px",
  },
};

const initialToolValue = {
  name: "",
  description: "",
};

const androidTools = (toolsList) => {
  return toolsList.filter((item) => item.type === "android");
};

const iosTools = (toolsList) => {
  return toolsList.filter((item) => item.type === "ios");
};

const ToolsView = () => {
  const [t] = useTranslation(["extraLink"]);
  const [showModalAddEditTools, setShowModalAddEditTools] = useState(false);
  const [showModalDeleteTools, setShowModalDeleteTools] = useState(false);
  const [showModalEditTools, setShowModalEditTools] = useState(false);
  const [toolsList, setToolsList] = useState([]);
  const [loadingView, setLoadingView] = useState(true);
  const [itemToDeleteTool, setItemToDeleteTool] = useState(null);
  const [editToTools, setEditToTools] = useState(initialToolValue);
  const { permissions } = useContext(MainContext);

  /**
   * Get the parameters to display
   */
  const getTools = () => {
    GetTools().then((result) => {
      let data = result.data;
      let tools = [];
      tools = data.map((item) => {
        return {
          id: item.id,
          blunde: item.bundle_id,
          name: item.name,
          img: item.image_url,
          description: item.description,
          type: item.type,
        };
      });
      setToolsList(tools);
      setLoadingView(false);
    });
  };

  /**
   *  Select by Id to delete an item
   * @param {*} toolId
   */
  const selectItemDeleteTool = (toolId) => {
    setItemToDeleteTool(toolId);
    setShowModalDeleteTools(true);
  };

  /**
   * Edit an element by Id
   * @param {Id} editToTool
   */
  const editTools = (editToTool) => {
    setEditToTools(editToTool);
    setShowModalEditTools(true);
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getTools();
  }, []);

  const rowApp = (item, index) => {
    return (
      <Row key={index} style={customStyles.rowContainer}>
        <Col className="mt-2" md={2}>
          <img style={{ height: "57px", marginTop: "-7px" }} src={item.img} />
        </Col>
        <Col className="mt-2" md={8}>
          <Row>
            <Col md={10}>{item.name}</Col>
          </Row>
          <Row>
            <Col className="text-truncate" md={10}>
              {item.description}
            </Col>
          </Row>
        </Col>
        <Col className="mt-2" md={2}>
          <i
            className="bi bi-trash-fill mt-2 mx-3 float-end"
            role="button"
            onClick={() => selectItemDeleteTool(item.blunde)}
            disabled={!permissions.app_other_tools.delete}
          ></i>
          <i
            className="bi bi-pencil-fill float-end mt-2"
            role="button"
            onClick={() => editTools(item)}
            disabled={!permissions.app_other_tools.edit}
          ></i>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {permissions.app_other_tools.create && (
        <Row>
          <Col md={12} lg={12}>
            <Button
              className="float-end"
              variant="primary"
              onClick={() => {
                setEditToTools(initialToolValue);
                setShowModalAddEditTools(true);
              }}
            >
              {t("addTool")}
            </Button>
          </Col>
        </Row>
      )}
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTools />
        </ContentSolid>
      ) : (
        <Col md={12} style={customStyles.container}>
          <Row>
            <Row>
              <Col md={5} className="mt-4 ps-5">
                <b>{t("textTool.titlePlayStore")} </b>
              </Col>
            </Row>
            <Row>
              <Col className="ps-5" md={12}>
                {androidTools(toolsList).map((item, index) => {
                  return rowApp(item, index);
                })}
              </Col>
            </Row>
          </Row>
        </Col>
      )}

      {/* Other Tools */}
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTools />
        </ContentSolid>
      ) : (
        <Col md={12} style={customStyles.container}>
          <Row>
            <Row>
              <Col md={5} className="mt-4 ps-5">
                <b>{t("textTool.titleAppStore")} </b>
              </Col>
            </Row>
            <Row>
              <Col className="ps-5" md={12}>
                {iosTools(toolsList).map((item, index) => {
                  return rowApp(item, index);
                })}
              </Col>
            </Row>
          </Row>
        </Col>
      )}
      {showModalAddEditTools && (
        <ModalAddTools
          showModalAddEditTools={showModalAddEditTools}
          setShowModalAddEditTools={setShowModalAddEditTools}
          getTools={getTools}
        />
      )}

      {showModalDeleteTools && (
        <ModalDeleteTools
          showModalDeleteTools={showModalDeleteTools}
          setShowModalDeleteTools={setShowModalDeleteTools}
          itemToDeleteTool={itemToDeleteTool}
          getTools={getTools}
        />
      )}
      {showModalEditTools && (
        <ModalEditTools
          showModalEditTools={showModalEditTools}
          setShowModalEditTools={setShowModalEditTools}
          editToTools={editToTools}
          getTools={getTools}
        />
      )}
    </div>
  );
};

export default ToolsView;
