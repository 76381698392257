
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "react-bootstrap";


/**
 *  RadioButton component for group of radio buttons
 * @param {{}} destructured props 
 * @returns JSX.Element
 */
export function RadioButton({
  id = "RadioButton",
  title = "",
  validation = null,
  required = false,
  value = null,
  options = [],
  help = null,
  name = "",
  setValue = () => { },
}) {
  const [t] = useTranslation(["global"]);
  return (
    <Form controlId={id} validationState={validation}>
      <Form.Label
        data-text={t("optional")}
        className={
          !required
            ? "optional"
            : ""
        }
      >
        {title}
      </Form.Label>
      <div style={{ display: "block" }}>
        <Row>
          {options.map((option, index) => {
            return (
              <Col md={12} key={index}>
                <Form.Check
                  id={"Checkbox-radio" + index}
                  type="radio"
                  onChange={() => {
                    setValue(name, option.value);
                  }}
                  className="text-truncate"
                  checked={value === option.value}
                  label={option.name}
                  style={{ paddingLeft: "25px", paddingTop: "5px" }}
                />
              </Col>
            )
          })
          }
        </Row>
      </div>
      {validation == "error" ? <Form.Text className="text-danger">{help ? help : t("global:thisFieldIsRequired")}</Form.Text> : null}
    </Form>
  );
}

RadioButton.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  validation: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array,
  help: PropTypes.node,
  name: PropTypes.string,
  setValue: PropTypes.func,
};

/**
 * CheckBox component for group of checkboxes
 * @param {{}} destructured props
 * @returns JSX.Element
 */
export function CheckBox({
  id = "dropDown",
  title = "",
  validation = null,
  required = false,
  value = null,
  options = [],
  help = null,
  name = "",
  setValue = () => { },
}) {
  const [t] = useTranslation(["global"]);
  return (
    <Form controlId={id} validationState={validation}>
      <Form.Label
        data-text={t("optional")}
        className={
          !required
            ? "optional"
            : ""
        }
        style={{ display: "block" }}
      >
        {title}
      </Form.Label>
      <Row>
        {options.map((option, index) => {
          const isMarked = Number.isInteger(Array.isArray(value) && value.find((element) => element == option.value));
          return (
            <Col md={12} key={index}>
              <Form.Check
                id={"Checkbox" + index}
                type="checkbox"
                onChange={() => {
                  //if option is marked, remove it from the array
                  if (isMarked) {
                    setValue(name, value.filter((element) => element !== option.value));
                    return;
                  }
                  //if option is not marked, add it to the array
                  if (Array.isArray(value)) {
                    value.push(option.value)
                    setValue(name, value);
                  } else {
                    setValue(name, [option.value]);
                  }
                }}
                className="text-truncate"
                checked={isMarked}
                label={option.name}
                style={{ paddingLeft: "25px", paddingTop: "5px" }}
              />
            </Col>
          )
        })
        }
      </Row>
      {validation == "error" ? <Form.Text className="text-danger">{help ? help : t("global:thisFieldIsRequired")}</Form.Text> : null}
    </Form >
  );
}

CheckBox.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  validation: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.string,
  help: PropTypes.node,
  name: PropTypes.string,
  setValue: PropTypes.func,
};