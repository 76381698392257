import axios from "axios";
import Cookies from "js-cookie";
import { ErrorResponse, validateErrorResponse } from "./AxiosExtension";

let axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "get",
};
//Get prefix token
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Get School cycles
 * @returns
 */
export const GetSchoolCycle = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: Cookies.get(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/school_cycles`;
  axiosOptions.params = {
    visible: "admin",
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Create new School cycle
 * @param {*} values
 */
export const createSchoolCycle = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/school_cycles`;
  axiosOptions.data = values;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Create new grade
 * @param {Object} values
 */
export const CreateGrade = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organization_school_levels/grade_group_grade_levels`;
  axiosOptions.data = values;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
