import React, { useState, useEffect } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import FormSelect from "../../../components/global/form/FormSelect";
import FormCheck from "../../../components/global/form/FormCheck";
import FieldInput from "../../../components/global/form/FieldInput";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as Yup from "yup";

export default function settingAveragingFormatModal(props) {
  const [t] = useTranslation(["reportCards", "global"]);
  const [rounding, setRounding] = useState(false);
  const [numberOptions, setNumberOptions] = useState([]);

  // Effect for loading initial translations
  useEffect(() => {
    if (typeof t == "function") {
      setNumberOptions([
        {
          label: t("reportCards:settingAveragingFormatModal.decimalNumbers"),
          value: "decimal",
        },
        {
          label: t("reportCards:settingAveragingFormatModal.integerNumbers"),
          value: "int",
        },
      ]);
    }
  }, [t]);
  return (
    <Modal
      show={props.showSettingAveraging}
      size="lg"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title>
          {t("reportCards:settingAveragingFormatModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={props.editingGradingFormat.averageFormat}
        validationSchema={Yup.object().shape({
          averageRounding: rounding
            ? Yup.string().test(
                "valueTwoDesimals",
                t("reportCards:settingAveragingFormatModal.enterValidValue"),
                (value) => {
                  if (value) {
                    return /^\d{1,2}\.?\d{0,2}$/.test(value);
                  }
                }
              )
            : null,
        })}
        onSubmit={(gradingFormat) => {
          props.saveAverageformat(gradingFormat, gradingFormat.position);
        }}
      >
        {(formik) => (
          <Form>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <FormSelect
                    label={t(
                      "reportCards:settingAveragingFormatModal.numbersPerEvaluationPeriod"
                    )}
                    name="gradePeriodFormat"
                    options={numberOptions}
                    placeholder={t(
                      "reportCards:settingAveragingFormatModal.numbersPerEvaluationPeriod"
                    )}
                    onChange={(event) => {
                      formik.setFieldValue("gradePeriodFormat", event);
                    }}
                    value={formik.values.gradePeriodFormat}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormSelect
                    label={t(
                      "reportCards:settingAveragingFormatModal.numbersToAverage"
                    )}
                    name="averageNumberFormat"
                    options={numberOptions}
                    placeholder={t(
                      "reportCards:settingAveragingFormatModal.numbersToAverage"
                    )}
                    onChange={(event) => {
                      formik.setFieldValue("averageNumberFormat", event);
                    }}
                    value={formik.values.averageNumberFormat}
                  />
                </Col>
                <Col md={6}>
                  <FormSelect
                    label={t(
                      "reportCards:settingAveragingFormatModal.numberDecimalToUse"
                    )}
                    name="decimalsToUse"
                    options={[
                      { label: "1", value: 1 },
                      { label: "2", value: 2 },
                    ]}
                    placeholder={t(
                      "reportCards:settingAveragingFormatModal.numberDecimalToUse"
                    )}
                    onChange={(event) => {
                      formik.setFieldValue("decimalsToUse", event);
                    }}
                    value={formik.values.decimalsToUse}
                  />
                </Col>
              </Row>
              <h5>
                {t("reportCards:settingAveragingFormatModal.averageRounding")}
              </h5>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <FormCheck
                        style={{ marginTop: "38px" }}
                        name="doNotRound"
                        label={t(
                          "reportCards:settingAveragingFormatModal.roundingMessage1"
                        )}
                        checked={!formik.values.doNotRound}
                        onChange={() => {
                          formik.setFieldValue("doNotRound", false);
                          setRounding(true);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} className="pe-1">
                      {t(
                        "reportCards:settingAveragingFormatModal.roundingMessage2"
                      )}
                    </Col>
                    <Col md={7} className="ps-1">
                      <FieldInput
                        style={{
                          padding: "0px",
                          width: "50px",
                          height: "25px",
                          textAlign: "center",
                        }}
                        name="averageRounding"
                        type="text"
                        label={""}
                        placeholder=""
                        disabled={formik.values.doNotRound}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <FormCheck
                    style={{ marginTop: "38px" }}
                    name="doNotRound"
                    label={t(
                      "reportCards:settingAveragingFormatModal.noRounding"
                    )}
                    checked={formik.values.doNotRound}
                    onChange={() => {
                      formik.setFieldValue("doNotRound", true);
                      setRounding(false);
                    }}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col className="float-start text-danger">
                <p
                  className="float-start"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.changeClassesSelectionMode(
                      true,
                      props.editingGradingFormat.averageFormat.position
                    ),
                      formik.handleSubmit();
                  }}
                >
                  {t(
                    "reportCards:settingAveragingFormatModal.editFieldsAverage"
                  )}
                </p>
              </Col>
              <Button
                variant="light"
                onClick={() => props.setshowSettingAveraging(false)}
              >
                {t("global:buttons.cancel")}
              </Button>
              <Button variant="primary" type="submit">
                {t("global:buttons.save")}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

settingAveragingFormatModal.propTypes = {
  showSettingAveraging: PropTypes.bool,
  setshowSettingAveraging: PropTypes.func,
  editingGradingFormat: PropTypes.object,
  saveAverageformat: PropTypes.func,
};
