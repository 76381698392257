import axios from "axios";
import { ErrorResponse, validateErrorResponse } from "./AxiosExtension";
let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Obtains the school cycles with their school level, grade and group.
 * @returns
 */
export const GetCyclesFullInfo = async () => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/school_cycles/all_school_levels`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Gets task information for the dashboard
 * @param {Object} request
 * @returns
 */
export const GetTaskDashboard = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/tasks`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Fetch the data to be able to display the tasks
 * @param {Strint} request
 * @returns
 */
export const GetTaskExpiration = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/expirated_tasks`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Gets the response of the events
 * @param {Object} request
 * @returns
 */
export const GetEventsDashboard = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/next_events`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Gets the response of the IncomeAndDebts
 * @param {Object} request
 * @returns
 */
export const GetIncomeAndDebtsDashboard = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/incomes_and_debts`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    if (!error.response) {
      return "NetworkError";
    }
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Gets response from access logs
 * @param {Object} request
 * @returns
 */
export const GetAccessLogDashboard = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/students_access_log`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Get the answer in base64 for pdf or excel
 * @param {Object} request
 * @returns
 */
export const GetDowloadAccess = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/print_access_log`;
  axiosOptions.params = request;
  axiosOptions.responseType = "blob";
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Gets the count of enrolled students
 * @param {Object} request
 * @returns
 */
export const GetStudentsCount = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/students_count`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Bring today's overdue payments.
 * @param {Object} request
 * @returns
 */
export const GetOverduePayments = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/overdue_payments`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Bring in today's completed payments
 * @param {Object} request
 * @returns
 */
export const GetCompletedPayments = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/completed_payments`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Obtains school averages
 * @param {Object} request
 * @returns
 */
export const GetSchoolAverages = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/school_average`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Gets the number of prospects
 * @param {Object} request
 * @returns
 */
export const GetProspect = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/prospects`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

/**
 * Purpose: Service to get the students of a class
 * @param {Object} request
 * @returns {JSON object} JSON object
 */
export const GetStudentsClass = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/dashboards/students_class`;
  axiosOptions.params = request;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};
