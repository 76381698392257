import { React, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../App";
import PropTypes from "prop-types";

//Design resources
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

// Componentes
import NewsView from "../../components/news/NewsView";
import CategoriesView from "../../components/news/CategoriesView";
import TagsView from "../../components/news/TagsView";
import TickersView from "../../components/news/TickersView";

/**
 *  Component for managing the content to be displayed through tabs
 * @param {obj} props  {params}: to obtain the subroute
 * @returns
 */
const MainNews = (props) => {
  const [t] = useTranslation(["global", "news"]);
  const view = props.match.params.view;
  const defaultTab = view || "news";
  const [focusedTab, setFocusedTab] = useState(defaultTab);
  const history = useHistory();
  const { permissions } = useContext(MainContext);

  /**Initial loading */
  useEffect(() => {
    //Validates that no subroutes are entered outside the section
    const availableTabs = ["news", "categories", "tags", "tickers"];
    if (!availableTabs.includes(defaultTab)) {
      history.push("/news");
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={12} className="mx-4">
          <h2> {t("news:main.news")}</h2>
        </Col>
      </Row>
      <Tab.Container id="classesAndSubjects" defaultActiveKey={focusedTab}>
        <Nav
          variant="pills"
          activeKey={focusedTab}
          style={{ width: "fit-content", margin: "0 auto" }}
        >
          {permissions.news.access && (
            <Nav.Item>
              <a
                href="news"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="news"
                  onClick={() => {
                    setFocusedTab("news");
                    history.push("/news");
                  }}
                >
                  {t("news:main.news")}
                </Nav.Link>
              </a>
            </Nav.Item>
          )}
          {permissions.news_categories.access && (
            <Nav.Item>
              <a
                href="/news/categories"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="categories"
                  onClick={() => {
                    setFocusedTab("categories");
                    history.push("/news/categories");
                  }}
                >
                  {t("news:main.categories")}
                </Nav.Link>
              </a>
            </Nav.Item>
          )}
          {permissions.news_labels?.access && (
            <Nav.Item>
              <a
                href="/news/tags"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="tags"
                  onClick={() => {
                    setFocusedTab("tags");
                    history.push("/news/tags");
                  }}
                >
                  {t("news:main.tags")}
                </Nav.Link>
              </a>
            </Nav.Item>
          )}
          {permissions.news_ticker?.access && (
            <Nav.Item>
              <a
                href="tickers"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="tickers"
                  onClick={() => {
                    setFocusedTab("tickers");
                    history.push("/news/tickers");
                  }}
                >
                  {t("news:main.tickers")}
                </Nav.Link>
              </a>
            </Nav.Item>
          )}
        </Nav>
      </Tab.Container>
      <Row>
        <Col md={12}>
          {focusedTab === "news" && <NewsView />}
          {focusedTab === "categories" && <CategoriesView />}
          {focusedTab === "tags" && <TagsView />}
          {focusedTab === "tickers" && <TickersView />}
        </Col>
      </Row>
    </Container>
  );
};

MainNews.propTypes = {
  match: PropTypes.object,
};

export default MainNews;