import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Container,
  Nav,
  Navbar,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import ServiceIcon from "../components/menu/ServiceIcon";
import {
  goToHref,
  imgOrganization,
  dateCookie,
  domainCookie,
  setUserRestriction,
  userInfo,
  validateUserRoleIds
} from "../components/global/GlobalTools";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { TokenDestroy } from "../api/Session";
import { MainContext } from "../App";
import ModalServicePremium from "../components/menu/ModalServicePremium";
import ModalNotificationSend from "../components/menu/ModalNotificationSend";
import { ValidateSection } from "../components/global/ValidateSections";
import ModalSuccess from "../components/global/modals/ModalSuccess";

import {
  serviceVote,
  changeCurrentOrganization,
  setCurrentOrganization,
} from "../api/Organizations";
import { GetUserRelativeStudent } from "../api/DashboardRelativeStudent";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6091954022988506vh",
    fontFamily: "'Mulish', sans-serif",
    padding: "5px 16px 3px 16px",
    color: state.isSelected ? "white" : state.isDisabled ? "#d4d4d4" : null,
    backgroundColor: state.isSelected
      ? "var(--base-color)"
      : state.isDisabled
      ? "white"
      : state.isFocused
      ? "var(--red-light)"
      : null,
    cursor: state.isDisabled ? "not-allowed" : null,
    width: "100%",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    zIndex: "9999 !important",
    marginTop: "1px",
    width: "100%",
    borderRadius: "0 0 3px 3px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "34.48275862068966vh",
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "1.6091954022988506vh",
    fontFamily: "'Mulish', sans-serif",
  }),
  container: (provided) => ({
    ...provided,
    minWidth: "15.625vh",
    width: "100%",
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "1.6091954022988506vh",
    fontFamily: "'Mulish', sans-serif",
    width: "100%",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#d4d4d4",
      fontSize: "1.6091954022988506vh",
      fontFamily: "'Mulish', sans-serif",
    };
  },
};

const MobileMenuView = (props) => {
  const [t, i18n] = useTranslation(["menu", "global", "reports", "crm"]);
  const history = useHistory();
  const prefix = process.env.REACT_APP_PREFIX;
  const token = Cookies.get(`cmToken${prefix}`); //Obtain cmToken's cookie from the domain .campusmovil.com
  const [pathName, setPathName] = useState(history.location.pathname);
  const [modalPremium, setModalPremium] = useState(false);
  const [typeOfService, setypeOfService] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showNotificationSend, setShowNotificationSend] = useState(false);
  const [showServicesIcons, setShowServicesIcons] = useState(false);
  const [showNavOptions, setShowNavOptions] = useState(false);
  const { permissions } = useContext(MainContext);
  const [hasDebtRestriction, setHasDebtRestriction] = useState(false);
  const [openModalSuccess, setOpenModalSucces] = useState(false);
  const [modalSuccessTtile, setModalSuccessTitle] = useState("");
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [txtBtnAccept, setTxtBtnAccept] = useState("");
  const [txtBtnCancel, setTxtBtnCancel] = useState("");

  const popoverIcons = () => {
    return (
      <Popover
        placement="bottom"
        style={{
          left: "18px",
          top: "50px",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <Nav
          activeKey={history.location.pathname}
          className="flex-column"
          variant="campus"
          onSelect={(eventKey) => {
            if (hasDebtRestriction && validateUserRoleIds(8) && eventKey == "/class") {
              showRestrictions();
            } else {
              moduleSelect(eventKey);
            }
          }}
          id="nav-icons"
        >
          {/** APP */}
          {ValidateSection(permissions, "app") && (
            <Nav.Link eventKey="/app">
              <ServiceIcon
                image="/img/dashboard-icons/default/app.svg"
                id="item-app"
                title="APP"
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          )}
          {/** CRM */}
          {props.subscriptions.crm && ValidateSection(permissions, "crm") ? (
            <Nav.Link eventKey="/crm/leads">
              <ServiceIcon
                image="/img/dashboard-icons/default/crm.svg"
                id="item-crm"
                title="CRM"
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Payments */}
          {props.subscriptions.payments &&
          ValidateSection(permissions, "payments") ? (
            <Nav.Link
              eventKey="/payments"
              active={
                pathName === "/payments/transactions" ||
                pathName === "/payments" ||
                pathName === "/payments/income" ||
                pathName === "/payments/scholarships" ||
                pathName === "/payments/typeScholarships"
              }
            >
              <ServiceIcon
                image="/img/dashboard-icons/default/payments.svg"
                id="item-payments"
                title={t("payments")}
                validation={
                  props.servicesPremium.payments &&
                  !props.servicesPremium.payments.premium
                    ? "premium-service"
                    : ""
                }
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Students */}
          {props.subscriptions.students && permissions.students_list.access ? (
            <Nav.Link eventKey="/estudiantes">
              <ServiceIcon
                image="/img/dashboard-icons/default/students.svg"
                id="item-students"
                title={t("students")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Parents */}
          {props.subscriptions.parents &&
          ValidateSection(permissions, "family") ? (
            <Nav.Link
              eventKey="/relatives"
              onClick={() => goToHref("/relatives")}
            >
              <ServiceIcon
                image="/img/dashboard-icons/default/relatives.svg"
                id="item-relatives"
                title={t("relatives")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Events */}
          {props.subscriptions.events &&
          ValidateSection(permissions, "events") ? (
            <Nav.Link eventKey="/eventos">
              <ServiceIcon
                image="/img/dashboard-icons/default/events.svg"
                id="item-events"
                title={t("events")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** News */}
          {props.subscriptions.web && ValidateSection(permissions, "news") ? (
            <Nav.Link eventKey="/news">
              <ServiceIcon
                image="/img/dashboard-icons/default/news.svg"
                id="item-news"
                title={t("news")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Class */}
          {props.subscriptions.class &&
          ValidateSection(permissions, "classes") ? (
            <Nav.Link eventKey="/class">
              <ServiceIcon
                image="/img/dashboard-icons/default/class.svg"
                id="item-class"
                title={t("classes")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Reviews  */}
          {props.subscriptions.reviews &&
          ValidateSection(permissions, "reviews") ? (
            <Nav.Link eventKey="/reviews/analytics">
              <ServiceIcon
                image="/img/dashboard-icons/default/reviews.svg"
                id="item-reviews"
                title={t("reviews")}
                validation={
                  props.servicesPremium.reviews &&
                  !props.servicesPremium.reviews.premium
                    ? "premium-service"
                    : ""
                }
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Teachers/Staff */}
          {props.subscriptions.staff &&
          ValidateSection(permissions, "personal") ? (
            <Nav.Link eventKey="/staff">
              <ServiceIcon
                image="/img/dashboard-icons/default/staff.svg"
                id="item-teachers"
                title={t("staff")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Reports */}
          {ValidateSection(permissions, "reports") && (
            <Nav.Link
              eventKey="/reports"
              onClick={() => history.push("/reports")}
            >
              <ServiceIcon
                image="/img/dashboard-icons/default/reports.svg"
                id="item-reports"
                title={t("reports")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          )}
          {/** Website */}
          {props.subscriptions.web &&
          ValidateSection(permissions, "website") ? (
            <Nav.Link eventKey="/website">
              <ServiceIcon
                image="/img/dashboard-icons/default/website.svg"
                id="item-website"
                title={t("website")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
          {/** Configuration */}
          {ValidateSection(permissions, "configurations") && (
            <Nav.Link eventKey="/configuraciones/ciclo">
              <ServiceIcon
                image="/img/dashboard-icons/default/configuration.svg"
                id="item-configuration"
                title={t("configuration")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          )}
          {/** Training */}
          {props.subscriptions.training &&
          ValidateSection(permissions, "trainings") ? (
            <Nav.Link eventKey="/training">
              <ServiceIcon
                image="/img/dashboard-icons/default/training.svg"
                id="item-training"
                title={t("training")}
                style={{ height: "5.412054120541206vh" }}
              />
            </Nav.Link>
          ) : (
            ""
          )}
        </Nav>
      </Popover>
    );
  };

  /**
   * Purpose: Modal for Premium services
   * @param {*} status
   * @param {*} typeOfService
   */
  const ModalPremium = (status, typeOfService) => {
    setModalPremium(status);
    setypeOfService(typeOfService);
  };

  /**
   * Purpose: Notification sending modality
   * @param {*} status
   */
  const modalNotificationSend = (status) => {
    setIsSaving(true);
    if (status) {
      // Based on which icon the request comes from, it is the id of the service that will be sent.
      // A switch was created to be able to expand if necessary
      let service_id = false;
      switch (typeOfService) {
        case "7": {
          //Payments
          service_id = props.servicesPremium.payments.organization_service_id;
          break;
        }
        case "9": {
          //Website
          service_id = props.servicesPremium.sie.organization_service_id;
          break;
        }
      }
      serviceVote(service_id).then(() => {
        setShowNotificationSend(status);
        setModalPremium(false);
        setIsSaving(false);
      });
    }
  };

  /**
   * Purpose: Redirect to the corresponding urls
   * @param {*} eventKey
   */
  const moduleSelect = (eventKey) => {
    setShowServicesIcons(false);
    let url = process.env.REACT_APP_V1;
    switch (eventKey) {
      case "/dashboard": {
        break;
      }
      case "/app": {
        window.location.href = `${url}/app`;
        break;
      }
      case "/crm/leads": {
        if (props.servicesPremium.sie && !props.servicesPremium.sie.premium) {
          ModalPremium(true, "9");
        } else {
          history.push("/crm/leads");
        }
        break;
      }
      case "/payments": {
        if (validateUserRoleIds(16)) {
          history.push("/payments/scholarships");
        } else if (validateUserRoleIds([1,2])) {
          history.push("/payments");
        } else {
          window.location.href = `${url}/reports`;
        }
        break;
      }
      case "/estudiantes": {
        prefix == "Beta" ? history.push("/students") : goToHref("/estudiantes");
        break;
      }
      case "/eventos": {
        window.location.href = `${url}/eventos`;
        break;
      }
      case "/news": {
        history.push("/news");
        break;
      }
      case "/class": {
        window.location.href = `${url}/class`;
        break;
      }
      case "/reviews/analytics": {
        if (
          props.servicesPremium.reviews &&
          props.servicesPremium.reviews.premium
        ) {
          window.location.href = `${url}/reviews/analytics`;
        } else {
          ModalPremium(true, "6");
        }
        break;
      }
      case "/admin/users": {
        window.location.href = `${url}/admin/users`;
        break;
      }
      case "/staff": {
        window.location.href = `${url}/staff`;
        break;
      }
      case "/activity_reports": {
        window.location.href = `${url}/activity_reports`;
        break;
      }
      case "/website": {
        window.location.href = `${url}/website`;
        break;
      }
      case "/configuraciones/ciclo": {
        window.location.href = `${url}/configuraciones/ciclo`;
        break;
      }
      case "/training": {
        window.location.href = `${url}/training`;
        break;
      }
      case "/admin/permissions": {
        prefix == "Beta"
          ? history.push("/admin/permissions")
          : (window.location.href = `${url}/admin/permissions`);
        break;
      }
      case "/chat": {
        if (props.servicesPremium.chat && props.servicesPremium.chat.premium) {
          window.location.href = `${url}/chat`;
        } else {
          ModalPremium(true, "10");
        }
        break;
      }
    }
  };

  const hiddenMenu = () => {
    const path = history.location.pathname;
    let options = <div></div>;
    switch (path) {
      case "/dashboard": {
        options = <div></div>;
        break;
      }
      case "/crm/leads": {
        options = (
          <div>
            <Col xs={12}>
              <h6
                id="task"
                style={{ cursor: "pointer" }}
                onClick={() => goToHref("/crm/tasks")}
              >
                {t("crm:viewProspectList.tasks")}
              </h6>
            </Col>
            <Col xs={12}>
              <h6
                id="leads"
                className={props.selectedTab === "leads" ? "text-danger" : ""}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.setSelectedTab("leads");
                  setShowNavOptions(false);
                }}
              >
                {t("crm:viewProspectList.prospectList")}
              </h6>
            </Col>
            <Col xs={12}>
              <h6
                id="activity"
                style={{ cursor: "pointer" }}
                onClick={() => goToHref("/crm/activities")}
              >
                {t("crm:viewProspectList.activity")}
              </h6>
            </Col>
          </div>
        );
        break;
      }
      case "/students": {
        break;
      }
    }
    return options;
  };

  //Esta tambien se quedaa
  const changeOrganization = (event) => {
    //Displays the overlay that locks the screen
    props.setDisabledScreen(true);
    changeCurrentOrganization(event.value).then(() => {
      setOrganization(event.value);
    });
  };

  //Esta es la que se va a quedar
  const setOrganization = (idOrganization) => {
    setCurrentOrganization(idOrganization).then((result) => {
      props.setDisabledScreen(false);
      let data = result.data.data;
      //Save the organization information
      let organization = data.find(
        (organization) => organization.id === idOrganization
      );
      //Change organization image url in the context
      imgOrganization(organization.logo_image_url, true);
      props.setOrganizationLogo(organization.logo_image_url);
      props.setOrganization(organization);

      //Save the selected organization
      let organizationSelected = props.options.find(
        (organization) => organization.value === idOrganization
      );
      props.setOrganizationSelected(organizationSelected);
      Cookies.set(`cmOrganizationID${prefix}`, idOrganization);
      Cookies.set("test", "valorDTest");
      props.activeSubscriptions(organization.subscriptions);
      props.services(organization.services);
      props.setGoogleForEducation(
        organization.organization_info.google_for_education
      );

      //Remove cookies from cycleId (current site / previous site)
      localStorage.removeItem(`cmCycleID${prefix}`);
      Cookies.set(`cmCycleID${prefix}`, "", {
        expires: dateCookie(),
        domain: domainCookie(),
      });
      //Update the cookie organizationID (current site / previous site)
      localStorage.setItem(`cmOrganizationID${prefix}`, idOrganization); //Update cookie organizationID on the new site
      //goToHref("/landing_page");
    });
  };

  useEffect(() => {
    const path = history.location.pathname;
    if (props.selectedTab != null) {
      return;
    }
    switch (path) {
      case "/dashboard": {
        props.setSelectedTab(null);
        break;
      }
      case "/crm/leads": {
        props.setSelectedTab("leads");
        break;
      }
      case "/payments": {
        props.setSelectedTab("transactions");
        break;
      }
    }
  });

  //Effect that listens for the change of the path to store it in a variable
  useEffect(() => {
    setPathName(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    //Check if you are logged in
    if (!token) {
      //clean localStorage
      TokenDestroy();
      history.push(`/`);
    }
  });

  /**Initial loading */
  useEffect(() => {
    if (!token) {
      goToHref(`/login`);
    } else {
      //Change the language
      i18n.changeLanguage(localStorage.getItem(`cmLanguage${prefix}`));
    }
    // Get group
    if (validateUserRoleIds([6,8])) {
      getUserRelativeStudent();
    }
  }, []);

  /**Update the height property of the menu*/
  useEffect(() => {}, []);

  /**
   * Puporse: Get User Relative and Student info
   */
  const getUserRelativeStudent = () => {
    const user = userInfo();
    const request = { user_id: user.id };
    GetUserRelativeStudent(request).then((result) => {
      if (result.status) {
        const userInfo = result.data;
        //Storage of votes in localStorage
        localStorage.setItem(
          `cmUserVotes${prefix}`,
          JSON.stringify(userInfo.votes)
        );
        // Get Restriction
        let hasDebtRestriction = false;
        const students = userInfo.students || [];
        if (students && Array.isArray(students) && students.length !== 0) {
          for (let item of students) {
            hasDebtRestriction =
              item &&
              item.has_debt_restriction &&
              item.has_debt_restriction == 1
                ? true
                : false;
            if (hasDebtRestriction) {
              break;
            }
          }
        }
        // Set restriction
        setHasDebtRestriction(hasDebtRestriction);
        // Set Cookie
        setUserRestriction(hasDebtRestriction);
      }
    });
  };

  /**
   * Puporse: Displays the restriction modal
   */
  const showRestrictions = () => {
    setHasDebtRestriction(true);
    setModalSuccessMessage(t("global:modalAlertClassesRestrictions.message"));
    setModalSuccessTitle(t("global:modalAlertClassesRestrictions.title"));
    setTxtBtnAccept(t("reports:paymentsTabs.statementAccount"));
    setTxtBtnCancel(t("global:buttons.close"));
    setOpenModalSucces(true);
  };

  return (
    <Container>
      <Row>
        {/* Menu Popover */}
        <Col xs={3} style={{ marginTop: "9px" }}>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popoverIcons}
            show={showServicesIcons}
            onToggle={(e) => {
              setShowServicesIcons(e), setShowNavOptions(false);
            }}
          >
            <a id="menu_iconos">
              <img
                src="/img/grip-horizontal-light.svg"
                style={{ height: "37px", cursor: "pointer" }}
                className="menu-logo"
              />
            </a>
          </OverlayTrigger>
        </Col>
        {/* Logo de CM */}
        <Col xs={6} style={{ marginTop: "9px" }}>
          <a href="/landing_page">
            <img src={"/img/logo_small.png"} />
          </a>
        </Col>
        {/* Menu hamburguesa */}
        <Col xs={3}>
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => {
                  setShowNavOptions(!showNavOptions),
                    setShowServicesIcons(false);
                }}
              />
            </Container>
          </Navbar>
        </Col>
      </Row>
      {showNavOptions && (
        <Row>
          {hiddenMenu()}
          <Col xs={12}>
            <Select
              options={props.options}
              onChange={changeOrganization}
              placeholder={t("searchOrganization")}
              styles={customStyles}
              formatOptionLabel={function (data) {
                return (
                  <span dangerouslySetInnerHTML={{ __html: data.label }} />
                );
              }}
              value={props.organizationSelected}
              isOptionDisabled={(option) =>
                option.value === props.organizationSelected.value
              }
              isLoading={props.options.length == 0}
              isDisabled={
                props.options.length === 0 || props.options.length === 1
              }
              closeMenuOnSelect={true}
              menuPortalTarget={document.body}
            />
          </Col>
          {/** Chat */}
          <Col xs={12}>
            {ValidateSection(permissions, "chat") &&
              (props.subscriptions.chat || !props.chatContact) && (
                <li style={{ listStyle: "none" }}>
                  <a
                    className={
                      props.servicesPremium.chat &&
                      !props.servicesPremium.chat.premium
                        ? "premium-icon-mobile"
                        : " "
                    }
                  >
                    <img
                      src="/img/dashboard-icons/default/icon_chat.svg"
                      style={{
                        height: "4.367816091954023vh",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        props.servicesPremium.chat &&
                        !props.servicesPremium.chat.premium
                          ? ModalPremium(true, "10")
                          : goToHref(`/chat`)
                      }
                    />
                  </a>
                </li>
              )}
          </Col>
          {/** Notification */}
          <Col xs={12}>
            {ValidateSection(permissions, "notifications") && (
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={props.popoverNotifications}
                rootClose
              >
                <div style={{ width: "min-content" }}>
                  <img
                    src={
                      props.unreadNotificationIds?.length
                        ? "/img/dashboard-icons/active/icon_notifications.svg"
                        : "/img/dashboard-icons/default/icon_notifications.svg"
                    }
                    id="item-notifications"
                    style={{
                      height: "4.367816091954023vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </OverlayTrigger>
            )}
          </Col>
          {/** Google */}
          <Col xs={12}>
            {props.googleForEducation &&
            ValidateSection(permissions, "google") ? (
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={props.popoverGoogle}
                rootClose
              >
                <div style={{ width: "min-content" }}>
                  <img
                    src="/img/dashboard-icons/default/icon_google.svg"
                    id="item-google"
                    style={{
                      height: "4.367816091954023vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </OverlayTrigger>
            ) : (
              ""
            )}
          </Col>
          {/** User */}
          <Col xs={12}>
            {ValidateSection(permissions, "perfil") && (
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={props.popoverUser}
                rootClose
              >
                <div style={{ width: "min-content" }}>
                  <img
                    src="/img/dashboard-icons/default/icon_user.svg"
                    id="item-user"
                    style={{
                      height: "4.367816091954023vh",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </OverlayTrigger>
            )}
          </Col>
        </Row>
      )}
      <ModalServicePremium
        modalPremium={modalPremium}
        setModalPremium={setModalPremium}
        typeOfService={typeOfService}
        modalNotificationSend={modalNotificationSend}
        isSaving={isSaving}
        t={t}
      />
      <ModalNotificationSend
        showNotificationSend={showNotificationSend}
        setShowNotificationSend={setShowNotificationSend}
        t={t}
      />
      {openModalSuccess && (
        <ModalSuccess
          showModalSuccess={openModalSuccess}
          size="md"
          title={modalSuccessTtile}
          message={modalSuccessMessage}
          txtBtnAccept={txtBtnAccept}
          fnAcceptButton={() => goToHref("/payments/accountStatus")}
          fnCancelButton={() => setOpenModalSucces(false)}
          txtBtnCancel={txtBtnCancel}
        />
      )}
    </Container>
  );
};

export default MobileMenuView;

MobileMenuView.propTypes = {
  setDisabledScreen: PropTypes.func,
  setOrganizationLogo: PropTypes.func,
  setOrganization: PropTypes.func,
  options: PropTypes.array,
  setOrganizationSelected: PropTypes.func,
  organizationSelected: PropTypes.object,
  activeSubscriptions: PropTypes.func,
  subscriptions: PropTypes.object,
  services: PropTypes.func,
  setGoogleForEducation: PropTypes.func,
  servicesPremium: PropTypes.object,
  googleForEducation: PropTypes.bool,
  popoverUser: PropTypes.func,
  popoverGoogle: PropTypes.func,
  popoverNotifications: PropTypes.func,
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.string,
  chatContact: PropTypes.bool,
  hideCards: PropTypes.bool,
  organization: PropTypes.object,
  unreadNotificationIds: PropTypes.array,
};
