import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";

let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Craete new Scholarship recoord
 * @param {Number} values
 * @returns API Response
 */
export const createScholarship = async (values) => {
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/scholarships`;
  axiosOptions.data = values;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the data from the students
 * @param {Array} values params
 * @returns API response
 */
export const GetScholarshipById = async (scholarshipId) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/scholarships/profile`;
  axiosOptions.params = { scholarship_id: scholarshipId };

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Update a Scholarship recoord
 * @param {Number} values
 * @returns API Response
 */
export const updateScholarship = async (values) => {
  axiosOptions.method = "put";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/scholarships`;
  axiosOptions.data = values;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the data from the students
 * @param {Array} values params
 * @returns API response
 */
export const GetScholarshipStudents = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/list_scholarships`;
  axiosOptions.params = values;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Show scholarships
 * @param {obj} data
 * @returns
 */
export const getScholarships = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/scholarships`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Adds scholarship to students
 * @param {obj} data
 * @returns
 */
export const selectedScholarships = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/add_scholarships`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
