// Import libraries
import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Form as FormFormik, Formik } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";

// Import Custom Components
import { MainContext } from "../../../App";
import ContentButton from "../../../components/global/ContentButton";
import { saveManulDebtsRestrictions } from "../../../api/PaymentsReport";

// Custom Styles
const customStyles = {
  description: {
    fontFamily: "Mulish",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignSelf: "stretch",
    marginTop: "10px",
  },
  secondayContainer: {
    padding: "10px",
    borderRadius: "4px",
    border: "solid 1px var(--secondary-gray-main-500, #D6DADB)",
    background: "var(--secondary-gray-200, #F6F6F6)",
    height: "65px",
  },
};

const ModalRestrictAccess = (props) => {
  const {
    open = false,
    close = () => {},
    modalSuccessTitle = () => {},
    modalSuccessMessage = () => {},
    selectedRowIds = [],
    setModalSuccessSize = () => {},
    setAcceptButtonVariant = () => {},
    setCancelModalSuccessFunction = () => {},
    getDebts = () => {},
    selectedRecords = { maanual_restriction: "", charge_ids: [] },
    setTextBtnAccept = () => {},
    setDeleteAllRestrictions = () => {},
    setSelectedRowIds = () => {},
    setKeepSelection = () => {},
  } = props;
  const [t] = useTranslation(["reports", "global"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const { permissions } = useContext(MainContext);
  const [error, setError] = useState({
    active: false,
    message: "",
    variant: "warning",
  });
  const [save, setSave] = useState(false);

  // Form Validations
  const validationSchema = Yup.object().shape({
    maanual_restriction: Yup.string(),
  });

  /**
   * Clean error message
   */
  useEffect(() => {
    if (error.active) {
      setTimeout(() => {
        setError({ active: false, message: "", variant: "warning" });
      }, 3500);
    }
  }, [error]);

  // Initial values
  useEffect(() => {
    Sentry.setTag("section", "Modal Save restriction settings");
  }, []);

  /**
   * Puporse: Save Manual restriction
   * @param {Object} values Manual restriction param
   */
  const saveManualRestriction = (values) => {
    setSave(true);
    // Set timezone
    values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setDeleteAllRestrictions(false);
    setKeepSelection(false);
    setSelectedRowIds([]);
    saveManulDebtsRestrictions(values)
      .then((result) => {
        if (result && result.data) {
          modalSuccessMessage(
            `${t("global:modalSetRestrictions.ManualSuccessMessage1")} ${
              selectedRowIds.length
            } ${t("global:modalSetRestrictions.ManualSuccessMessage2")}`
          );
          modalSuccessTitle(
            values.maanual_restriction == "partial_restriction"
              ? t("global:modalSetRestrictions.ManualSuccessTitle")
              : t("global:modalSetRestrictions.ManualSuccessTitle2")
          );
          setTextBtnAccept(t("global:buttons.close"));
          setModalSuccessSize("md");
          getDebts();
          close(false, true, true);
        } else {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError({
            active: true,
            message: `${
              result && result.code
                ? result.description
                : t("scholarship:scholarshipDetails.error")
            }`,
            variant: "warning",
          });
        }
      })
      .catch(() => {})
      .then(() => setSave(false));
  };

  /**
   * Puporse: Processes data to display options to remove restrictions
   * @param {Object} values Manual restriction param
   */
  const RemoveRestrictions = (values) => {
    setKeepSelection(true);
    const studentCount = selectedRowIds.length;
    const status = values.values.maanual_restriction;
    const startDescription = t(
      `global:modalRemoveRestriction.${
        status == "total_restriction"
          ? "startDescription2"
          : "startDescription1"
      }`
    );
    const endDescription = t("global:modalRemoveRestriction.endDescription");
    const description = `${startDescription} ${studentCount} ${endDescription}`;
    modalSuccessTitle(
      t(
        `global:modalRemoveRestriction.${
          status == "total_restriction" ? "title2" : "title"
        }`
      )
    );
    modalSuccessMessage(description);
    setModalSuccessSize(status == "total_restriction" ? "md" : "lg");
    setAcceptButtonVariant("primary");
    setCancelModalSuccessFunction(true);
    setTextBtnAccept(t("global:buttons.accept"));
    setDeleteAllRestrictions(true);
    close(false, true, false);
  };

  return (
    <div>
      <Modal
        show={open}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("global:buttons.restrict_access")}
          </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          initialValues={selectedRecords}
          validationSchema={validationSchema}
          onSubmit={saveManualRestriction}
        >
          {(values) => (
            <FormFormik>
              <Modal.Body className="modal.body mbottom10">
                <Row>
                  {/** Description 1*/}
                  <Col lg={12} md={12}>
                    <div className="mt-o">
                      <p
                        style={{
                          ...customStyles.description,
                          marginTop: "-20px",
                        }}
                      >
                        {t("global:modalSetRestrictions.restrictAccess")}
                      </p>
                    </div>
                  </Col>
                  {/** Configure Restrictions */}
                  <Col lg={12} md={12}>
                    <Form style={customStyles.container}>
                      <div
                        className="mb-3"
                        style={customStyles.secondayContainer}
                      >
                        {/** Parcial Restriction */}
                        <Form.Check
                          type="radio"
                          name="maanual_restriction"
                          label={t(
                            "global:modalSetRestrictions.setRestrictionslabel1"
                          )}
                          checked={
                            values.values.maanual_restriction ==
                            "partial_restriction"
                          }
                          onChange={() => {
                            values.setFieldValue(
                              "maanual_restriction",
                              "partial_restriction"
                            );
                          }}
                        />
                        {/** Total Restriction */}
                        <Form.Check
                          type="radio"
                          name="maanual_restriction"
                          label={t(
                            "global:modalSetRestrictions.setRestrictionslabel2"
                          )}
                          checked={
                            values.values.maanual_restriction ==
                            "total_restriction"
                          }
                          onChange={() => {
                            values.setFieldValue(
                              "maanual_restriction",
                              "total_restriction"
                            );
                          }}
                        />
                      </div>
                    </Form>
                  </Col>
                  {/** Warning errors */}
                  {error && error.active && (
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div style={{ marginTop: "20px" }}>
                        <Alert variant={error.variant}>{error.message}</Alert>
                      </div>
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer
                style={
                  selectedRecords.maanual_restriction !== ""
                    ? { justifyContent: "space-between" }
                    : {}
                }
              >
                {/** Delete Restriction Button */}
                {permissions.payments_debts_restrictions.delete &&
                  selectedRecords.maanual_restriction !== "" && (
                    <Button
                      variant="link"
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginTop: "-40px",
                      }}
                      onClick={() => RemoveRestrictions(values)}
                      disabled={save}
                    >
                      {t("global:modalRemoveRestriction.removeRestriction")}
                    </Button>
                  )}
                <ContentButton
                  className="content-button"
                  style={{ marginTop: "-50px" }}
                  disabled={
                    !permissions.payments_debts_restrictions.create || save
                  }
                >
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      values.resetForm();
                      close(false, false);
                    }}
                    disabled={save}
                  >
                    {t("global:buttons.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      (!permissions.payments_debts_restrictions.create &&
                        !permissions.payments_debts_restrictions.edit) ||
                      !values.values.maanual_restriction ||
                      save
                    }
                  >
                    {t("global:buttons.accept")}
                  </Button>
                </ContentButton>
              </Modal.Footer>
            </FormFormik>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ModalRestrictAccess;

ModalRestrictAccess.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  modalSuccessTitle: PropTypes.func,
  modalSuccessMessage: PropTypes.func,
  selectedRowIds: PropTypes.array,
  setModalSuccessSize: PropTypes.func,
  setAcceptButtonVariant: PropTypes.func,
  setCancelModalSuccessFunction: PropTypes.func,
  getDebts: PropTypes.func,
  selectedRecords: PropTypes.object,
  setTextBtnAccept: PropTypes.func,
  setDeleteAllRestrictions: PropTypes.func,
  setSelectedRowIds: PropTypes.func,
  setKeepSelection: PropTypes.func,
};
