import React from "react";
import PropTypes from "prop-types";
import { Modal, Row, Button } from "react-bootstrap";

const customStyles = {
  textOpacity: {
    opacity: "0.7",
  },
};

export default function EventDetailsModal({
  showEventDetailsModal,
  size,
  eventDetail,
  txtBtnCancel,
  fnCancelButton,
}) {
  return (
    <div>
      <Modal
        show={showEventDetailsModal}
        size={size}
        aria-labelledby="contained-modal-title-md"
      >
        <Modal.Header>
          <Modal.Title>{eventDetail.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <p style={customStyles.textOpacity}>{eventDetail.info}</p>
          </Row>
          <Row>
            <p style={customStyles.textOpacity}>
              <i className="bi bi-cursor-fill me-2"></i>
              {eventDetail.location}
            </p>
          </Row>
          <Row className="mt-2">
            <p style={customStyles.textOpacity}>
              <i className="bi bi-clock-fill me-2"></i>
              {eventDetail.starTime + " - " + eventDetail.endTime}
            </p>
          </Row>
          {eventDetail.website && (
            <Row className="mt-1">
              <p className="text-truncate" style={customStyles.textOpacity}>
                <i
                  className=" bold bi bi-link-45deg me-2"
                  style={{
                    textShadow: "0 0 black",
                    fontSize: "medium",
                    marginLeft: "-2px",
                  }}
                />
                <a href="#" onClick={() => window.open(eventDetail.website)}>
                  {eventDetail.website}
                </a>
              </p>
            </Row>
          )}
          {eventDetail?.socialMedia.map((social) => {
            return (
              <Row className="mt-2" key={social.type}>
                <p className="text-truncate" style={customStyles.textOpacity}>
                  <i className={`bi bi-${social.type} me-2`} />
                  <a href="#" onClick={() => window.open(social.social_url)}>
                    {social.social_url}
                  </a>
                </p>
              </Row>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          {fnCancelButton && (
            <Button variant="outline-secondary" onClick={fnCancelButton}>
              {txtBtnCancel}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

EventDetailsModal.propTypes = {
  showEventDetailsModal: PropTypes.bool,
  size: PropTypes.string,
  eventDetail: PropTypes.object,
  txtBtnCancel: PropTypes.string,
  fnCancelButton: PropTypes.func,
};
