import React from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as moment from "moment";

/*Services */
import {
  printMultipleInvoice,
  DownloadMultiple,
} from "../../../api/PaymentsReport";
export default function SuccesBatchBillingModal(props) {
  const [t] = useTranslation(["global", "reports", "crm"]);

  /*
   * Purpose: Print invoices in pdf
   */
  const printInvoices = () => {
    let confirmations = JSON.stringify(props.confirmations);
    printMultipleInvoice(confirmations).then((result) => {
      if (result.data) {
        let filePdf = result.data;
        let fileURL = window.URL.createObjectURL(filePdf);
        let isFirefox =
          navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
        if (!isFirefox) {
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = "document" + ".pdf";
          link.click();
        }
        window.open(fileURL);
      }
    });
  };

  /**
   * Purpose: Print .ZIP file
   */
  const downloadInvoices = () => {
    let paymentIds = JSON.stringify(props.invoicedPaymentIds);
    DownloadMultiple(paymentIds).then((result) => {
      //Create Blob and download
      let blob = new Blob([result.data]);
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Invoices" + moment().format("DDMMYYYY") + ".zip";
      link.click();
    });
  };

  /**
   * Purpose: To edit any errors that may occur.
   * @param {Boolean} value
   * @param {Number} studentId
   */
  const editMissingData = (value, studentId = false) => {
    let baseUrl = process.env.REACT_APP_V1;
    let view =
      value == "student"
        ? "/estudiante/" + studentId
        : value == "school_level"
        ? "/configuraciones/ciclo"
        : "/configuraciones/organizacion";
    let url = baseUrl + view;
    window.open(url, "_blank");
  };

  return (
    <div>
      <Modal
        show={props.showSuccesBatchBillingModal}
        size="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("reports:modals.titleSuccesBatchBilling", {
              countPayments: props.invoicedPaymentIds.length,
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("reports:modals.textBilling", {
            countPayments: props.invoicedPaymentIds.length,
          })}
          {props.invoicingErrors.length ? (
            <Row className="my-3">
              <Col md={12} className="fw-light">
                {t("reports:modals.textOcurredDetails")}
              </Col>
            </Row>
          ) : null}
          {props.invoicingErrors.length
            ? props.invoicingErrors.map(function (error, index) {
                return (
                  <Row key={index} className="mx-0">
                    {error.error_type === "student" ? (
                      <div
                        style={{ marginBottom: "10px" }}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {' "' + error.error_message + '" '}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            editMissingData("student", error.student_id)
                          }
                        >
                          <a href="#" className="stretched-link">
                            {t("reports:modals.textEditData")}
                          </a>
                        </a>
                      </div>
                    ) : error.error_type === "school_level" ? (
                      <div
                        style={{ marginBottom: "10px" }}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {' "' + error.error_message + '" '}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => editMissingData("school_level")}
                        >
                          <a href="#" className="stretched-link">
                            {t("reports:modals.textSchoolData")}
                          </a>
                        </a>
                      </div>
                    ) : error.error_type === "organization" ? (
                      <div
                        style={{ marginBottom: "10px" }}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {' "' + error.error_message + '" '}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => editMissingData("organization")}
                        >
                          <a href="#" className="stretched-link">
                            {t("reports:modals.textSchoolData")}
                          </a>
                        </a>
                      </div>
                    ) : (
                      <Col
                        md={12}
                        className="alert alert-warning fw-light"
                        role="alert"
                      >
                        {error.error_message}
                      </Col>
                    )}
                  </Row>
                );
              })
            : null}
        </Modal.Body>
        <Modal.Footer>
          <Col className="float-start text-danger">
            <p
              className="float-start fw-light"
              style={
                props.invoicedPaymentIds.length
                  ? { cursor: "pointer" }
                  : { cursor: "not-allowed", opacity: "50%" }
              }
              onClick={
                props.invoicedPaymentIds.length ? downloadInvoices : null
              }
            >
              {t("reports:modals.dowloadInvoices")}
            </p>
          </Col>
          <Button
            variant="outline-secondary"
            onClick={() => props.setShowSuccesBatchBillingModal(false)}
          >
            {t("crm:modalAddProspect.btnClose")}
          </Button>
          <Button
            onClick={
              props.invoicingErrors.length
                ? () => props.bill(true)
                : printInvoices
            }
          >
            {props.invoicingErrors.length
              ? t("reports:modals.textRetry")
              : t("reports:modals.printInvoiced")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

SuccesBatchBillingModal.propTypes = {
  showSuccesBatchBillingModal: PropTypes.bool,
  setShowSuccesBatchBillingModal: PropTypes.func,
  confirmations: PropTypes.array,
  invoicingErrors: PropTypes.array,
  invoicedPaymentIds: PropTypes.array,
  missingInvoiced: PropTypes.array,
  bill: PropTypes.func,
};
