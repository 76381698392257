import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
/**
 * Purpose: Bloquea la pantalla
 * @param {*} props 
 * @returns 
 */
export default function OverlayBlock(props) {
  return (
    <div>
      <div
        className="box-maintenance"
        id="box-maintenance"
        style={{ display: props.disabledScreen ? "block" : "none" }}
      >
        <div>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            variant="light"
          />
          <p style={{color: "#ffffff"}}>{props.message}</p>
        </div>
      </div>
      <div
        className={props.disabledScreen ? "overlay-maintenance" : ""}
        id="overlay-maintenance"
      ></div>
    </div>
  );
}

OverlayBlock.propTypes = {
  disabledScreen: PropTypes.bool,
  campusmovil: PropTypes.bool,
  message: PropTypes.string,
};
