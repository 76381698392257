import React from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DeleteTool } from "../../../api/ExtraLink";
export default function ModalDeleteTools(props) {
  const [t] = useTranslation(["extraLink", "global"]);

  /**
   * Function to delete a tool
   */
  const DeleteTools = () => {
    const request = { bundle_id: props.itemToDeleteTool };
    DeleteTool(request).then(() => {
      props.getTools();
      props.setShowModalDeleteTools(false);
    });
  };

  return (
    <Modal
      show={props.showModalDeleteTools}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("modalTools.titleModalDeleteTools")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body mbottom10">
        <Row>
          <Col lg={12}>{t("modalTools.textModalDeleteTools")}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowModalDeleteTools(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button type="submit" onClick={() => DeleteTools()}>
          <>{t("global:buttons.accept")}</>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalDeleteTools.propTypes = {
  showModalDeleteTools: PropTypes.bool,
  setShowModalDeleteTools: PropTypes.func,
  getTools: PropTypes.func,
  itemToDeleteTool: PropTypes.number,
};
