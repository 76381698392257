import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import SeeAllModule from "../SeeAllModule";
import OverlayBlockModule from "../OverlayBlockModule";
import PropTypes from "prop-types";
import { GetTaskExpiration } from "../../../../api/Dashboard";
import { LoadingOverdueTaskDashboard } from "../../../lazyLoading/LazyLoading";
const customStyles = {
  moduleContainerFixedHeight: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
    height: "170px",
  },
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "35px",
    marginBlock: "10px",
    marginLeft: "0px",
    marginRight: "0px",
  },
};

const TasksModule = (props) => {
  const [t] = useTranslation(["dashboard", "crm"]);
  const [count, setCount] = useState(0);
  const [loadingView, setLoadingView] = useState(false);

  /**
   * Obtains the task numbers, only by sending the timezone
   */
  const getExpirationsTask = () => {
    setLoadingView(true);
    let request = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetTaskExpiration(request).then((result) => {
      if (result.data) {
        let data = result.data;
        setCount(data.expirated_tasks);
      }
      setLoadingView(false);
    });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.modulesActive.CRM == false) {
      setLoadingView(false);
      return;
    }
  }, [props.modulesActive.CRM]);

  useEffect(() => {
    if (!props.modulesActive.CRM) return;
    getExpirationsTask();
  }, []);

  return (
    <Row>
      {loadingView ? (
        <LoadingOverdueTaskDashboard />
      ) : (
        <>
          <OverlayBlockModule
            active={props.modulesActive.CRM}
            today={true}
            customWidth="100%"
            module="crm"
            content={
              <Col
                className="mt-3"
                md={12}
                style={customStyles.moduleContainerFixedHeight}
              >
                <Row>
                  <Col md={3} className="text-center pe-0">
                    <i
                      className="bi bi-clipboard-x"
                      style={{ color: "#EA1B17", fontSize: "24px" }}
                    ></i>
                  </Col>
                  <Col md={9} className="mt-1 fs-6 ps-0">
                    <b className="fs-6">{t("textViewToday.titleTaskToday")}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="px-3">
                    <Row style={customStyles.rowContainer}>
                      <Col md={12} className="px-3">
                        <p className="mt-1" style={{ fontSize: "12px" }}>
                          {count + " " + t("crm:viewProspectList.tasks")}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-3">
                    <SeeAllModule
                      isCMv1={true}
                      path={"/crm/tasks"}
                    ></SeeAllModule>
                  </Col>
                </Row>
              </Col>
            }
          />
        </>
      )}
    </Row>
  );
};

export default TasksModule;
TasksModule.propTypes = {
  modulesActive: PropTypes.object,
};
