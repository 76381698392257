import React, { useContext } from "react";
import DashboardStudent from "./dashboard/DashboardStudent";
import DashboardRelative from "./dashboard/DashboardRelative";
import DashboardAdminView from "./dashboard/DashboardAdminView";
import DashboardStaff from "./dashboard/DashboardStaff";
import { goToHref, validateUserRoleIds } from "../components/global/GlobalTools";
import { MainContext } from "../App";
import { Col, Container, Row } from "react-bootstrap";

const DashboardView = () => {
  const { permissions } = useContext(MainContext);

  //Allows access to the new dashboard view when you have permissions.
  if (!permissions.admin_dashboard.access) {
    const searchParams = new URLSearchParams(window.location.search).toString();
    const params = searchParams ? `?${searchParams}` : "";
    goToHref("/landing_page" + params);
  }
  //Set the types of users that will see the Dashboard
  if (validateUserRoleIds([1,2])) {
    return <DashboardAdminView />;
  }
  if (validateUserRoleIds(7)) {
    return <DashboardStaff />;
  }
  if (validateUserRoleIds(6)) {
    return <DashboardRelative />;
  }
  if (validateUserRoleIds(8)) {
    return <DashboardStudent />;
  }

  // Default rendering
  return (
    <Container>
      <Row>
        <Col>
          <h1>Dashboard view</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardView;
