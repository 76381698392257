import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  GetCompletedPayments,
  GetExpirationPayments,
} from "../../../../api/DashboardRelativeStudent";
import { goToHref } from "../../../global/GlobalTools";
import PropTypes from "prop-types";
import OverlayBlockModule from "../../admin/OverlayBlockModule";

const dummyCompletePayment = [
  {
    total: "150.00",
    status: "$200.00",
    method: "$13,178.50",
  },
  {
    total: "150.00",
    status: "$200.00",
    method: "$13,178.50",
  },
];

const dummyExpiration = [
  {
    amount: "$5,200.00",
    surcharge: "Pagado",
    total: "Efectivo",
  },
];

const ExpirationModule = (props) => {
  const [t] = useTranslation([
    "dashboard",
    "dashboardStudent",
    "global",
    "accountStatus",
    "reports",
  ]);
  const [completedPayments, setCompletedPayments] = useState([]);
  const [expirationsPayments, setExpirationsPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const sizeContainerModule = props.heightListContainer / 2.4;
  const customStyles = {
    moduleContainerExpirations: {
      background: "#ffffff",
      border: "1px solid #ececee",
      borderRadius: "8px",
      paddingTop: "10px",
    },
    rowContainer: {
      background: "#ffffff",
      border: "1px solid #ececee",
      borderRadius: "0px",
      height: "130px",
      marginBlock: "10px",
      marginTop: "0px",
      marginBottom: "0px",
    },
    lazy: {
      height: "25px",
    },
    scrollClassExpirations: {
      height: sizeContainerModule - 40 + "px",
      overflowY: "auto",
      overflowX: "hidden",
      padding: " 5px 25px",
    },
    scrollClass: {
      height: sizeContainerModule + "px",
      overflowY: "auto",
      overflowX: "hidden",
      padding: " 5px 25px",
    },
  };

  const lazyLoadingExpirationsModule = () => {
    return (
      <div>
        <Row className="table-lazyLoading">
          <Col md={12}>
            <div style={customStyles.lazy}></div>
          </Col>
        </Row>
        <Row className="table-lazyLoading mt-2">
          <Col md={8}>
            <div style={customStyles.lazy}></div>
            <div style={customStyles.lazy}></div>
            <div style={customStyles.lazy}></div>
          </Col>
        </Row>
        <Row className="table-lazyLoading mt-3">
          <Col md={12}>
            <div style={customStyles.lazy}></div>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Obtains overdue payments
   *
   */
  const getCompletedPayments = () => {
    setLoading(true);
    GetCompletedPayments({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((result) => {
      if (result.status) {
        let data = result.data;
        let completed = [];
        completed = data.map((item) => {
          return {
            total: item.total,
            status: item.status_code,
            method: item.payment_method,
            confirmation: item.confirmation,
            pathPayment:
              "/payment_report/" + item.confirmation + "?payment=true",
          };
        });
        setCompletedPayments(completed);
        setLoading(false);
      }
    });
  };

  /**
   *Gets the payments made
   *
   */
  const getExpirationPayments = () => {
    setLoading(true);
    GetExpirationPayments({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((result) => {
      if (result.status) {
        let data = result.data;
        let expirations = [];
        expirations = data.map((item) => {
          return {
            amount: item.amount,
            surcharge: item.surcharge,
            total: item.total,
          };
        });
        setExpirationsPayments(expirations);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (props.modulesActive.Payments == false) {
      setCompletedPayments(dummyCompletePayment);
      setExpirationsPayments(dummyExpiration);
    } else {
      getCompletedPayments();
      getExpirationPayments();
    }
  }, [props.modulesActive.Payments]);
  return (
    <>
      {/* Overdue payments */}
      <OverlayBlockModule
        active={props.modulesActive.Payments}
        textButton={t("dashboardStudent:textModals.textSeeMore")}
        originRequest={"payments_and_invoices"}
        nameService="Payments"
        customWidth="110%"
        content={
          <Row style={customStyles.moduleContainerExpirations}>
            <Col md={12}>
              <Row>
                <Col md={3} className="text-center pe-0">
                  <i
                    className="bi bi-x-lg"
                    style={{ color: "#EA1B17", fontSize: "24px" }}
                  ></i>
                </Col>
                <Col md={9} className="mt-1 fs-6 ps-0">
                  <b>{t("textViewToday.titleExpirations")}</b>
                </Col>
              </Row>
            </Col>
            <Col md={12} style={customStyles.scrollClassExpirations}>
              {loading ? (
                lazyLoadingExpirationsModule()
              ) : (
                <>
                  {expirationsPayments.length > 0 ? (
                    <Col md={12}>
                      {expirationsPayments.map((item) => {
                        return (
                          <Row key={item}>
                            <Col
                              style={customStyles.rowContainer}
                              xs={12}
                              md={12}
                              xl={12}
                              className="px-4"
                            >
                              <Row>
                                <Col xs={12} sm={12} md={12}>
                                  <Row className="mt-1">
                                    {t("dashboardStudent:textToday.textAmount")}
                                  </Row>
                                  <Row className="opacity-50">
                                    {" "}
                                    {item.amount}{" "}
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} sm={12} md={12}>
                                  <Row className="mt-1">
                                    {t(
                                      "dashboardStudent:textToday.textCharges"
                                    )}
                                  </Row>
                                  <Row className="opacity-50">
                                    {item.surcharge}
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} sm={12} md={12}>
                                  <Row className="mt-1">
                                    {t(
                                      "dashboardStudent:textToday.textBalance"
                                    )}
                                  </Row>
                                  <Row className="opacity-50">
                                    {" "}
                                    {item.total}{" "}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  ) : (
                    <Row>
                      <Col md={12} className=" align-middle">
                        <p className="text-center">
                          {t("dashboard:labels.noData")}
                        </p>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Col>
            {expirationsPayments.length > 0 && (
              <Col mnd={12} className="mt-2 mb-2">
                <Button
                  variant="primary"
                  onClick={() => goToHref(`/payments/accountStatus`)}
                  className="w-100"
                >
                  {t("global:buttons.pay")}
                </Button>
              </Col>
            )}
          </Row>
        }
      />

      {/* Payments made */}
      <OverlayBlockModule
        active={props.modulesActive.Payments}
        textButton={t("dashboardStudent:textModals.textSeeMore")}
        originRequest={"payments_and_invoices"}
        nameService="Payments"
        customWidth="110%"
        content={
          <Row style={customStyles.moduleContainerExpirations} className="mt-2">
            <Col md={12}>
              <Row>
                <Col md={3} className="text-center pe-0">
                  <i
                    className="bi bi-check-lg"
                    style={{ color: "#6ee7b7", fontSize: "24px" }}
                  ></i>
                </Col>
                <Col md={9} className="mt-1 fs-6 ps-0">
                  <b>{t("textViewToday.titlePayments")}</b>
                </Col>
              </Row>
            </Col>
            <Col md={12} style={customStyles.scrollClass}>
              {loading ? (
                lazyLoadingExpirationsModule()
              ) : (
                <>
                  {completedPayments.length > 0 ? (
                    <Col md={12}>
                      {completedPayments.map((item) => {
                        return (
                          <Row key={item}>
                            <Col
                              style={customStyles.rowContainer}
                              xs={12}
                              md={12}
                              xl={12}
                              className="px-4"
                            >
                              <Row>
                                <Col xs={12} sm={12} md={12}>
                                  <Row className="mt-1">
                                    {t("accountStatus:labels.payment")}
                                  </Row>
                                  <Row className="opacity-50">
                                    {"$"} {item.total}
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} sm={12} md={12}>
                                  <Row className="mt-1">
                                    {t("reports:headerTable.status")}
                                  </Row>
                                  <Row>
                                    <Col md={6} className="px-0">
                                      <span className="badge bg-success">
                                        {item.status}
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} sm={12} md={12}>
                                  <Row className="mt-1">
                                    {t("reports:headerTable.paymentMethod")}
                                  </Row>
                                  <Row className="opacity-50">
                                    {" "}
                                    {item.method}{" "}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={12} className="mt-3 mb-3 px-0">
                              <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => goToHref(item.pathPayment)}
                                disabled={!completedPayments.length}
                              >
                                {t("reports:headerTable.invoice")}
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  ) : (
                    <Row>
                      <Col md={12} className=" align-middle">
                        <p className="text-center">
                          {t("dashboard:labels.noData")}
                        </p>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default ExpirationModule;

ExpirationModule.propTypes = {
  studentId: PropTypes.number,
  heightListContainer: PropTypes.number,
  modulesActive: PropTypes.object,
};
