import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

/* Services */
import { DownloadBulkExportCredentials } from "../../../api/StudentsReport";

/* Components */
import TextLoading from "../../../components/global/TextLoading";
import ContentButton from "../../../components/global/ContentButton";
export default function ModalDownloadCredentials(props) {
  const [t] = useTranslation(["global", "student"]);
  const downloadKey =
    props.count === 1 ? "downloadCredential" : "downloadCredentials";
  const downloadMessage = t(`student:ModalDownloadCredentials.${downloadKey}`, {
    count: props.count,
  });
  const [errors, setErrors] = useState(false);
  const [loadingCredentialsDownload, setLoadingCredentialsDownload] =
    useState(false);

  /* Initial Loading */
  useEffect(() => {
    Sentry.setTag("section", "Credential Download Modal");
  }, []);

  /**
   *Purpose: Download bulk export credentials
   */
  const downloadBulkExportCredentials = () => {
    setLoadingCredentialsDownload(true);
    const selectedRowIds = props.selectedRowIds;
    const schoolCycleId = props.schoolCycleId;
    const studentIds = [];
    selectedRowIds.forEach(({ original }) => {
      studentIds.push(original.index);
    });
    let data = {};
    if (studentIds.length) {
      data = {
        student_ids: studentIds,
      };
    } else {
      data = {
        school_cycle_id: schoolCycleId,
      };
    }
    DownloadBulkExportCredentials(data).then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setErrors(result);
        return;
      } else {
        // Show the processing modal
        props.toggleModalsCredentials(true);
      }
    });
  };

  return (
    <Modal
      show={props.showModalDownloadCredentials}
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("student:ModalDownloadCredentials.downloadCredentialsTitle")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="mb-2">
            {downloadMessage}
          </Col>
        </Row>
        {errors && (
          <div style={{ marginTop: "20px" }}>
            <Alert variant="warning">{errors.description}</Alert>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ContentButton
          className="content-button"
          disabled={loadingCredentialsDownload}
        >
          <Button
            id="cancelDownloadCredentialsBtn"
            variant="outline-secondary"
            onClick={() => props.setShowModalDownloadCredentials(false)}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button
            id="acceptDownloadCredentialsBtn"
            disabled={loadingCredentialsDownload}
            onClick={() => downloadBulkExportCredentials()}
          >
            {loadingCredentialsDownload ? (
              <TextLoading
                text={t("global:buttons.processing")}
                variant="light"
              />
            ) : (
              t("global:buttons.accept")
            )}
          </Button>
        </ContentButton>
      </Modal.Footer>
    </Modal>
  );
}

ModalDownloadCredentials.propTypes = {
  showModalDownloadCredentials: PropTypes.bool,
  setShowModalDownloadCredentials: PropTypes.func,
  toggleModalsCredentials: PropTypes.func,
  count: PropTypes.number,
  selectedRowIds: PropTypes.array,
  schoolCycleId: PropTypes.string,
};
