/**
 * Purpose: Warning mode to confirm deletion of records
 *
 * Props:
 *    showModalConfirmDeleted: boolean type value to show or hide the modal
 *    setShowModalConfirmDeleted: function linked to the modal's cancel button
 *    confirmAction: function to confirm action
 *    items: rray object that must contain the parameters: name
 *    title: Customized title to be displayed
 *    message: Alert message to be shown
 *    showItemList: Boolean to display the list of items to be deleted
 */
import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TextLoading from "../TextLoading";
export default function ModalConfirmDeletedRecords(props) {
  const [t] = useTranslation(["global"]);
  const [dummyLoad, setDummyLoad] = React.useState(false);

  return (
    <Modal
      show={props.showModalConfirmDeleted}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {props.title || t("global:modalConfirmDelete.deleteItems")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={12} className="text-black mb-2">
            {props.message ||
              t("global:modalConfirmDelete.message", {
                count: props.items.length,
              })}
          </Col>
        </Row>
        {props.showItemList && (
          <Row>
            {props.items.map((item) => {
              return (
                <Col md={12} key={item}>
                  <Form.Text className="text-black">
                    {"- " + item.name}
                  </Form.Text>
                </Col>
              );
            })}
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowModalConfirmDeleted(false)}
          disabled={dummyLoad}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button
          disabled={dummyLoad}
          onClick={() => {
            setDummyLoad(true)
            setTimeout(() => {
              setDummyLoad(false)
            }, 2000)
            props.confirmAction()
          }}
        >
          {props.confirmTextLoading && dummyLoad ?
            <TextLoading text={props.confirmTextLoading} />
            :
            props.confirmText ||
            t("global:buttons.accept")
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalConfirmDeletedRecords.propTypes = {
  showModalConfirmDeleted: PropTypes.bool, //Required
  setShowModalConfirmDeleted: PropTypes.func, //Required
  confirmAction: PropTypes.func, //Required
  items: PropTypes.array, //Required
  title: PropTypes.object,
  message: PropTypes.object,
  showItemList: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmTextLoading: PropTypes.string,
};
