import React, { useState, useContext } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { MainContext } from "../../App";

// Views
import ExtraLinkView from "../../components/extraLinks/ExtraLinkView";
import ToolsView from "../../components/extraLinks/ToolsView";

import { getUrlParamValue } from "../../components/global/GlobalTools";
const selectIdTab = getUrlParamValue("selecTab");
const tabSelect = selectIdTab || "extraLinks";
const ExtraLinkPage = () => {
  const [t] = useTranslation(["extraLink"]);
  const [defaultTabView, setDefaultTabView] = useState(tabSelect);
  const { permissions } = useContext(MainContext);

  return (
    <Container fluid>
      <Row>
        <Col md={12} lg={12}>
          {defaultTabView == "extraLinks" ? (
            <h2>{t("titleExtraLink.titleE")}</h2>
          ) : (
            <h2>{t("tools")}</h2>
          )}
        </Col>
      </Row>

      <Tab.Container id="app" defaultActiveKey={defaultTabView}>
        <Nav
          variant="pills"
          defaultActiveKey="extraLinks"
          style={{ width: "fit-content", margin: "0 auto" }}
        >
          <Nav.Item>
            {permissions.app_extra_links.access && (
              <a
                href="/app"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="extraLinks"
                  onClick={() => {
                    setDefaultTabView("extraLinks");
                  }}
                >
                  {t("titleExtraLink.titleE")}
                </Nav.Link>
              </a>
            )}
          </Nav.Item>
          <Nav.Item>
            {permissions.app_other_tools.access && (
              <a
                href="app?selecTab=tools"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="tools"
                  onClick={() => {
                    setDefaultTabView("tools");
                  }}
                >
                  {t("titleExtraLink.titleOtherTools")}
                </Nav.Link>
              </a>
            )}
          </Nav.Item>
        </Nav>
        <div>
          <Tab.Content>
            {defaultTabView == "extraLinks" && (
              <Tab.Pane eventKey="extraLinks">
                {/** Extra Link **/}
                <ExtraLinkView defaultTabView={defaultTabView} />
              </Tab.Pane>
            )}
            {defaultTabView == "tools" && (
              <Tab.Pane eventKey="tools">
                {/** Extra Link **/}
                <ToolsView />
              </Tab.Pane>
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    </Container>
  );
};

export default ExtraLinkPage;

ExtraLinkPage.propTypes = {
  match: PropTypes.object,
};
