import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";

export default function ModalConfirmationSuspendedStudent(props) {
  const [t] = useTranslation(["student", "global"]);

  return (
    <div>
      <Modal
        show={props.showModalConfirmationSuspeendedStudent}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Title className="modal-title ms-3 mt-3">
          {props.suspensionType.title}
        </Modal.Title>
        <Modal.Body>
          <div>
            <p> {props.suspensionType.description}</p>
            {/* Map names to be inactivated */}
            <Row>
              <Col md={12} style={{ maxHeight: "300px", overflow: "auto" }}>
                {props.selectedRowIds.map((item) => {
                  return <p key={item}>{"- " + item.values.name}</p>;
                })}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="confirmationStudentSuspensionBtn"
            variant="primary"
            onClick={() => {
              props.setShowModalConfirmationSuspeendedStudent(false);
              props.getStudentsList();
            }}
          >
            {t("global:buttons.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalConfirmationSuspendedStudent.propTypes = {
  setShowModalConfirmationSuspeendedStudent: PropTypes.func,
  showModalConfirmationSuspeendedStudent: PropTypes.bool,
  selectedRowIds: PropTypes.array,
  suspensionType: PropTypes.object,
  getStudentsList: PropTypes.func,
};
