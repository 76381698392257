/**
 *       **** Readme ****
 
 * Props:
 *   -cleanRequestFilters: {Object} Used to clean selected filters
 *   
 */

import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
/**
 * Purpose: Bloquea la pantalla
 * @param {*} props
 * @returns
 */

/**
 * Purpose: Show the table loader before showing the results
 * @returns
 */
export const LoadingTable = () => {
  return (
    <div>
      <Row
        className="table-lazyLoading"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
      </Row>
      <Row
        className="table-lazyLoading"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
      </Row>
    </div>
  );
};

/**
 * Purpose: Show the loader of the body lines before showing the results
 * @returns
 */
export const LoadingTableBody = () => {
  return (
    <div>
      <Row
        className="table-lazyLoading"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
      </Row>
    </div>
  );
};

/**
 * Displays a no match message when not found in the table and clears filters
 * @param {obj} props
 * @returns
 */
export const CleanFilters = (props) => {
  const [t] = useTranslation(["crm"]);

  /**
   * It is used to clean the filters of the table when it does not find any record
   */
  const cleanFilterData = () => {
    props.setAllFilters([]);
    //If the filters are connected to the api, the request filters are cleaned.
    if (props.cleanRequestFilters) {
      props.cleanRequestFilters();
    }
  };
  return (
    <div>
      <Row>
        <Col xs={12} style={{ marginTop: "70px" }}>
          <i
            className="bi bi-x-circle"
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={cleanFilterData}
          ></i>
          {t("crm:tableCrm.textNoMatching")}
        </Col>
        <Col xs={12} lg={12}>
          {t("crm:tableCrm.textResetSerach")}
        </Col>
      </Row>
    </div>
  );
};

export const LoadingTableModal = () => {
  return (
    <div>
      <Row className="table-lazyLoading mx-0">
        <Col xs={4}>
          <div></div>
        </Col>
      </Row>
    </div>
  );
};

export const LoadingBodyModal = () => {
  return (
    <div>
      <Row className="table-lazyLoading mx-0">
        <Col xs={3}>
          <div></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mx-0 mt-2">
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={3} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={3} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mx-0 ">
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={3} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={3} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
      </Row>
    </div>
  );
};

export const LoadingTools = () => {
  return (
    <div>
      <Row
        className="table-lazyLoading"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Col xs={2} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
      </Row>
      <Row
        className="table-lazyLoading"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
        <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <div></div>
        </Col>
      </Row>
    </div>
  );
};

export const LoadingExtraLink = () => {
  return (
    <div>
      <Row className="table-lazyLoading  mx-0">
        <Col md={3} className="my-4 ps-5">
          <div></div>
        </Col>
        <Col md={3} className="my-4">
          <div></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mx-5 my-3">
        {[1, 2, 3, 4].map((item) => {
          return (
            <Row key={item}>
              <Col xs={12} className="mb-3">
                <div></div>
              </Col>
              <Col xs={12} className="mb-3">
                <div></div>
              </Col>
              <Col xs={12} className="mb-3">
                <div></div>
              </Col>
            </Row>
          );
        })}
      </Row>
    </div>
  );
};

export const LoadingTaskDashboard = () => {
  return (
    <div>
      <Row className="table-lazyLoading mx-1 mb-2">
        <Col>
          <Row>
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
      </Row>
      {[1, 2, 3, 4, 5, 6, 7].map((item) => {
        return (
          <Row
            key={item}
            className="table-lazyLoading"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col
              className="mb-2"
              md={12}
              style={{ paddingLeft: "3px", paddingRight: "3px" }}
            >
              <div></div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export const LoadingOverdueTaskDashboard = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col className="mb-2 mt-2" md={12}>
          <div></div>
        </Col>
        <Col md={12}>
          <div></div>
        </Col>
        <Col className="mb-2 mt-2" md={12}>
          <div></div>
        </Col>
      </Row>
    </div>
  );
};

export const LoadingEventsDashboard = () => {
  return (
    <div>
      <Row>
        <Col className="mb-2 mt-2" md={12}>
          <Row className="table-lazyLoading">
            <Col md={7}>
              <div></div>
            </Col>
            <Col md={3} className="offset-md-2">
              <div></div>
            </Col>
          </Row>
        </Col>

        <Row>
          <Col>
            <Row className="table-lazyLoading">
              <Col md={6}>
                <div></div>
              </Col>
              <Col md={12} className="mb-2 mt-2">
                <div></div>
              </Col>
              <Col md={12} className="mb-2 mt-2">
                <div></div>
              </Col>
              <Col md={12} className="mb-2 mt-2">
                <div></div>
              </Col>
              <Col md={12} className="mb-2 mt-2">
                <div></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export const LoadingEnrolledDashboard = () => {
  return (
    <div>
      <Row>
        <Col className="mb-2 mt-2" md={12}>
          <Row className="table-lazyLoading">
            <Col md={7}>
              <div></div>
            </Col>
            <Col md={3} className="offset-md-2">
              <div></div>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col md={12}>
            <Row className="table-lazyLoading">
              <Col md={2}>
                <div></div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 mt-2" md={12}>
            <Row className="table-lazyLoading">
              <Col md={3}>
                <div></div>
              </Col>
              <Col md={3}>
                <div></div>
              </Col>
              <Col md={3}>
                <div></div>
              </Col>
              <Col md={3}>
                <div></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export const LoadingAccessdDashboard = () => {
  return (
    <div>
      <Row>
        <Col className="mb-2" md={12}>
          <Row className="table-lazyLoading">
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>

        <Col md={12}>
          <Row className="table-lazyLoading">
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row className="table-lazyLoading">
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row className="table-lazyLoading">
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row className="table-lazyLoading">
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const LoadingButtonDashboard = () => {
  return (
    <div>
      <Row>
        <Col md={12}>
          <Row className="table-lazyLoading">
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const LoadingDeadlinesDashboard = () => {
  return (
    <div>
      <Row>
        <Col className="mb-2 mt-2" md={12}>
          <Row className="table-lazyLoading">
            <Col md={7} className="mt-2">
              <div></div>
            </Col>
            <Col md={3} className="offset-md-2 mb-2 mt-2">
              <div></div>
            </Col>
            <Col md={12}>
              <div></div>
            </Col>
            <Col md={12}>
              <div></div>
            </Col>
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const LoadingCompletePaymetnsDashboard = () => {
  return (
    <div>
      <Row>
        <Col className="mb-2 mt-2" md={12}>
          <Row className="table-lazyLoading">
            <Col md={3} className="mt-2">
              <div></div>
            </Col>
            <Col md={8} className="mb-2 mt-2">
              <div></div>
            </Col>
            <Col md={12}>
              <div></div>
            </Col>
            <Col md={12}>
              <div></div>
            </Col>
            <Col md={12}>
              <div></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

CleanFilters.propTypes = {
  setAllFilters: PropTypes.func,
  cleanRequestFilters: PropTypes.func,
};
