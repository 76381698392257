import React from "react";
import PropTypes from "prop-types";
import { Button, Image, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import imgTutorialReviews from "../../img/tutorial-get-reviews.gif";

export default function ModalNonPremiumReviews({ showModal, closeModal }) {
  const [t] = useTranslation(["relatives", "global"]);

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("modalReviewNoPremium.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("modalReviewNoPremium.description")}&nbsp;
        <a
          className="btn-link"
          rel="noreferrer"
          target="_blank"
          href={"https://www.campusmovil.com.mx/gestion-reputacion/"}
        >
          {t("global:learnMore")}
        </a>
        <center className="mt-4">
          <Button
            size="lg"
            onClick={() =>
              window.open("https://www.campusmovil.com.mx/contacto/")
            }
          >
            {t("global:scheduleDemo")}
          </Button>
          <Image className="mt-4" fluid rounded src={imgTutorialReviews} />
        </center>
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="outline-secondary" onClick={closeModal}>
          {t("global:buttons.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalNonPremiumReviews.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
};
