import axios from "axios";
import { validateErrorResponse } from "./AxiosExtension";
let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Porpose: create a super admin
 * @param {obj} data Contains the user parameters
 * @returns json
 */
export const CreateSuperAdmin = async (data = {}) => {
  data.token = localStorage.getItem(`cmToken${prefix}`);

  let axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/users/create`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Porpose: update a super admin
 * @param {obj} data Contains the user parameters
 * @returns json
 */
export const UpdateSuperAdmin = async (data = {}) => {
  data.token = localStorage.getItem(`cmToken${prefix}`);

  let axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/users/update`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Get all the list of super admin users.
 * @param {obj} data
 * @returns
 */
export const GetSuperAdmin = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/users`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Delete by blocks of id's or by id
 * @param {int} request
 * @returns
 */
export const DeleteSuperAdmin = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = request;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/users/index`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
