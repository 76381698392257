import React, { } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";

export default function ModalConfirmNews(props) {
  const [t] = useTranslation(["global", "news", "modalCreateStudent"]);
  return (
    <Modal
      show={props.showModalConfirmNews}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {props.mensaggesConfirm.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.mensaggesConfirm.message}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => { props.setShowModalConfirmNews(false); }}
        >
          {t("global:buttons.accept")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalConfirmNews.propTypes = {
  showModalConfirmNews: PropTypes.bool, //Required
  setShowModalConfirmNews: PropTypes.func, //Required
  mensaggesConfirm: PropTypes.object, //Required
};
