import axios from "axios";

const prefix = process.env.REACT_APP_PREFIX;

/**
 *  Purpose: Get permissions
 * @returns
 */
export const GetPermissions = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.params = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/permissions`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 *  Purpose: Get permissions options
 * @returns
 */
export const GetOptions = async () => {
  let axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/permissions/options`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 *  Purpose: update permissiont
 */
export const UpdatePermitions = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/permissions`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 *  Purpose: Add new service user roles
 */
export const AddRoles = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/permissions/groups`;
  axiosOptions.data = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 *  Purpose: Add New Service Sections
 */
export const AddSections = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/permissions/sections`;
  axiosOptions.data = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error;
  }
};
