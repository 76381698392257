//Library
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
//Custom Hooks components
import ContentButton from "../../global/ContentButton";

export default function ModalActionStudentsSuccess(props) {
  const [t] = useTranslation(["student", "global"]);
  const {
    open,
    setShowActionStudents,
    title,
    message,
    studentsResult = [],
  } = props;

  return (
    <div>
      <Modal show={open} bsSize="md" aria-labelledby="contained-modal-title-lg">
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{message}</div>
          {studentsResult.map((student, index) => {
            return (
              <div className="mtop10" key={index}>
                {student.student_name}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          {/**Conten buttons*/}
          <ContentButton className="content-button">
            <Button
              variant="primary"
              onClick={() => setShowActionStudents(false)}
            >
              {t("global:buttons.accept")}
            </Button>
          </ContentButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalActionStudentsSuccess.propTypes = {
  open: PropTypes.bool,
  setShowActionStudents: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  studentsResult: PropTypes.array,
};
