import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, FormLabel, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SeeAllModule from "./admin/SeeAllModule";
import { getClassList } from "../../api/class";
import * as moment from "moment";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

const customStyles = {
  lazy: {
    height: "25px",
  },
  scrollClass: {
    height: "295px",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "13px",
  },
  rowClassContainer: {
    paddingTop: "10px",
    paddingBottom: "5px",
    border: "1px solid #ececee",
    borderRadius: "0px",
  },
};

const dummyClass = [
  {
    id: 1,
    name: "Español I",
    nameSubject: "Español",
    grade: "1",
    group: "A",
    totalStudents: "32",
    asignedSpace: "E1",
    classDays: [["L", "Mi", "V"]],
    startTime: [["09:00 AM", "09:00 AM", "09:00 AM"]],
    endTime: [["10:00 AM", "10:00 AM", "10:00 AM"]],
  },
  {
    id: 2,
    name: "Matematicas I",
    nameSubject: "Matematicas",
    grade: "2",
    group: "A",
    totalStudents: "28",
    asignedSpace: "E1",
    classDays: [["L", "Mi", "V"]],
    startTime: [["10:00 AM", "10:00 AM", "10:00 AM"]],
    endTime: [["10:00 AM", "10:00 AM", "10:00 AM"]],
  },
  {
    id: 3,
    name: "Biologia",
    nameSubject: "Biologia",
    grade: "2",
    group: "A",
    totalStudents: "25",
    asignedSpace: "E2",
    classDays: [["L", "Mi", "V"]],
    startTime: [["10:00 AM", "10:00 AM", "10:00 AM"]],
    endTime: [["10:00 AM", "10:00 AM", "10:00 AM"]],
  },
  {
    id: 4,
    name: "Matematicas II",
    nameSubject: "Matematicas",
    grade: "3",
    group: "A",
    totalStudents: "36",
    asignedSpace: "F1",
    classDays: [["L", "Mi", "V"]],
    startTime: [["10:00 AM", "10:00 AM", "10:00 AM"]],
    endTime: [["10:00 AM", "10:00 AM", "10:00 AM"]],
  },
];

const prefix = process.env.REACT_APP_PREFIX;

const lazyLoadingClass = () => {
  return (
    <div>
      <Row className="table-lazyLoading mt-3">
        <Col md={7}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={5}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-4">
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-4">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-4">
        <Col md={2} className="offset-md-10">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};

const ClassListView = (props) => {
  const [t] = useTranslation(["dashboardStudent", "dashboard"]);
  const [classModule, setClassModule] = useState([]);
  const [optionsCycles, setOptionsCycles] = useState([]);
  const [cycleSelected, setCycleSelected] = useState(null);
  const [loadingClass, setLoadingClass] = useState(true);

  /**
   *Gets the cycles
   */
  const getCycles = () => {
    const cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const hasCycles =
      cyclesLocal && Array.isArray(cyclesLocal) && cyclesLocal.length;
    if (hasCycles) {
      setOptionsCycles(cyclesLocal);
      setCycleSelected(cyclesLocal[0]);
      getClasses(cyclesLocal[0].id);
    } else {
      setTimeout(() => {
        getCycles();
      }, 1000);
    }
  };

  /**
   * Get the list of classes
   * @param {int} cycleId
   */
  const getClasses = (cycleId) => {
    const userID = Cookies.get(`cmUserID${prefix}`);
    setLoadingClass(true);
    let request = {
      school_cycle_id: cycleId,
      additional_fields: "class_room",
      teacher_id: userID,
    };
    getClassList(request)
      .then((result) => {
        let classes = result?.data?.data || [];
        classes.forEach((element) => {
          element.name = element.class_title;
          element.nameSubject = element.subject_name;
          element.grade = element.program_level || element.grade_level;
          element.group = element.grade_group;
          element.totalStudents = element.students_number;
          element.asignedSpace = element.class_room;
          let schedules = element.schedule;
          const daysAndTimes = formatDays(schedules);
          element.classDays = daysAndTimes.classDays;
          element.startTime = daysAndTimes.startTime;
          element.endTime = daysAndTimes.endTime;
          for (let param in element) {
            element[param] = element[param] || "N/A";
          }
        });
        setClassModule(classes);
      })
      .catch(() => {
        setClassModule(dummyClass);
      })
      .finally(() => {
        setLoadingClass(false);
      });
  };

  /**
   * Extracting and formatting class days and times
   * @param {obj} schedule
   * @param {string} field
   * @returns
   */
  const formatDays = (schedule) => {
    let daysSchedul = [];
    let totalDaysClass = 0;
    if (schedule) {
      for (let item of schedule) {
        let startTimeFormat = moment.unix(item.start).local().format("hh:mm A");
        let endTimeFormat = moment.unix(item.end).local().format("hh:mm A");
        const daysWeek = listDays(item);
        totalDaysClass = totalDaysClass + daysWeek.length;
        daysSchedul.push({
          start: startTimeFormat,
          end: endTimeFormat,
          days: daysWeek,
        });
      }
    }

    return {
      classDays: daysSchedul.map((item) => item.days),
      startTime: daysSchedul.map((item) => {
        let listStart = [];
        item.days.forEach(() => {
          listStart.push(item.start);
        });
        return listStart;
      }),
      endTime: daysSchedul.map((item) => {
        let listEnd = [];
        item.days.forEach(() => {
          listEnd.push(item.end);
        });
        return listEnd;
      }),
    };
  };

  /**
   * Generates a list of days on which the class is enabled
   * @param {obj} item
   * @returns
   */
  const listDays = (item) => {
    let days = [];
    item.monday ? days.push("L") : null;
    item.tuesday ? days.push("M") : null;
    item.wednesday ? days.push("Mi") : null;
    item.thursday ? days.push("J") : null;
    item.friday ? days.push("V") : null;
    item.saturday ? days.push("S") : null;
    item.sunday ? days.push("D") : null;
    return days;
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    if (props.active == false) {
      setClassModule(dummyClass);
      setLoadingClass(false);
      setCycleSelected({ label: t("dashboard:labels.schoolCycle"), value: 0 });
    } else {
      getCycles();
    }
  }, [props.active]);

  return (
    <Row>
      <Col>
        {loadingClass ? (
          lazyLoadingClass()
        ) : (
          <>
            {/** Class title and school cycle filter */}
            <Row className="mt-3">
              <Col xs={12} md={8} xl={8}>
                <i className="bi bi-journals me-2 fs-6"></i>
                <b className="fs-6">{t("textClassView.titleClasses")}</b>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="float-end">
                  <FormLabel className="mb-0">
                    {t("dashboard:labels.schoolCycle")}
                  </FormLabel>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="cycleSchool"
                      variant="outline-secondary"
                      className="text-truncate"
                    >
                      {cycleSelected?.label}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {optionsCycles.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item}
                            onClick={() => {
                              setCycleSelected(item);
                              getClasses(item.id);
                            }}
                            className="text-truncate"
                          >
                            {item.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            {/** Class listing header */}
            <div style={customStyles.scrollClass}>
              <Row className="mt-3 mb-2">
                <Col xs={12} md={12} xl={12} className="px-4">
                  <Row>
                    <Col xs={2} className="fw-bold text-truncate">
                      {t("textClassView.textClass")}
                    </Col>
                    <Col xs={1} className="fw-bold text-truncate">
                      {t("textClassView.subject")}
                    </Col>
                    <Col xs={1} className="fw-bold text-truncate">
                      {t("textClassView.grade")}
                    </Col>
                    <Col xs={1} className="fw-bold text-truncate">
                      {t("textClassView.group")}
                    </Col>
                    <Col xs={2} className="fw-bold text-truncate">
                      {t("textClassView.totalStudents")}
                    </Col>
                    <Col xs={1} className="fw-bold text-truncate">
                      {t("textClassView.classDays")}
                    </Col>
                    <Col xs={2} className="fw-bold text-truncate">
                      {t("textClassView.asignedSpace")}
                    </Col>
                    <Col xs={1} className="fw-bold text-truncate">
                      {t("textClassView.startTime")}
                    </Col>
                    <Col xs={1} className="fw-bold text-truncate">
                      {t("textClassView.endTime")}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/** List of classes */}
              {classModule.length ? (
                <>
                  {classModule.map((item) => {
                    return (
                      <Row key={item.id} className="mb-3">
                        <Col xs={12} md={12} xl={12} className="px-4">
                          <Row style={customStyles.rowClassContainer}>
                            <Col xs={2} className="text-truncate">
                              {item.name}
                            </Col>
                            <Col xs={1} className="text-truncate">
                              {item.nameSubject}
                            </Col>
                            <Col xs={1}>{item.grade}</Col>
                            <Col xs={1}>{item.group}</Col>
                            <Col xs={2}>{item.totalStudents}</Col>
                            <Col xs={1} className="text-truncate">
                              {item.classDays.map((schedule, i) => {
                                return (
                                  <div key={i}>
                                    {schedule.map((day, index) => {
                                      return <p key={index}>{day}</p>;
                                    })}
                                  </div>
                                );
                              })}
                            </Col>
                            <Col xs={2} className="text-truncate">
                              {item.asignedSpace}
                            </Col>
                            <Col xs={1} className="text-truncate">
                              {item.startTime.map((schedule, i) => {
                                return (
                                  <div key={i}>
                                    {schedule.map((start, index) => {
                                      return <p key={index}>{start}</p>;
                                    })}
                                  </div>
                                );
                              })}
                            </Col>
                            <Col xs={1} className="text-truncate">
                              {item.endTime.map((schedule, i) => {
                                return (
                                  <div key={i}>
                                    {schedule.map((end, index) => {
                                      return <p key={index}>{end}</p>;
                                    })}
                                  </div>
                                );
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <Row>
                  <Col md={12}>
                    <p className="text-center">
                      {t("dashboard:labels.noData")}
                    </p>
                  </Col>
                </Row>
              )}
            </div>
            {/** See more component */}
            <Row>
              <Col md={12} className="mt-3">
                {classModule.length > 0 && <SeeAllModule path={"/class"} />}
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default ClassListView;

ClassListView.propTypes = {
  active: PropTypes.bool,
};
