import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Row, Col, FormLabel } from "react-bootstrap";
import SeeAllModule from "./SeeAllModule";
import PropTypes from "prop-types";
import { LoadingEventsDashboard } from "../../lazyLoading/LazyLoading";
import { GetEventsDashboard } from "../../../api/Dashboard";
import * as moment from "moment";
const prefix = process.env.REACT_APP_PREFIX;

const customStyles = {
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "55px",
    marginBlock: "10px",
    marginLeft: "0px",
    marginRight: "0px",
  },
  scrollEvent: {
    maxHeight: "292px",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "13px",
  },
  styleText: {
    display: "block",
  },
};

const dummyEvents = [
  {
    dayFormat: "19 de junio del 2023",
    events: [
      {
        name: "CONSEJO TECNICO ESCOLAR",
        description:
          "Se suspenden las actividades escolares para todos los alumnos.",
        starDateFormat: "09:00 am",
        endDateFormat: "02:00 pm",
      },
      {
        name: "RECITAL PRIMARIA",
        description:
          "Presentación del trabajo de Actividades Artísticas.  Solo pueden ingresar dos familiares por alumno.",
        starDateFormat: "08:00 am",
        endDateFormat: "11:00 Am",
      },
    ],
  },
  {
    dayFormat: "09 de junio del 2023",
    events: [
      {
        name: "REUNION FAMILIAR",
        description:
          "Presentarse en el salon de usus multiples para llevar a cabo la reunion",
        starDateFormat: "04:00 pm",
        endDateFormat: "06:00 pm",
      },
      {
        name: "CIERRE DEL CICLO ESCOLAR",
        description: "",
        starDateFormat: "09:00 am",
        endDateFormat: "02:00 pm",
      },
    ],
  },
];
const EventsModule = (props) => {
  const [t] = useTranslation(["dashboard"]);
  const [eventsModule, setEvents] = useState([]);

  /**
   * Gets the events of the next 30 days
   */
  const getEvents = () => {
    let request = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetEventsDashboard(request).then((result) => {
      if (result.data) {
        let data = result.data;
        let events = data.map((item) => {
          return {
            name: item.title,
            description: item.description,
            startDate: item.start_date,
            endDate: item.end_date,
            starDateFormat: moment.unix(item.start_date).format("hh:mm a"),
            endDateFormat: moment.unix(item.end_date).format("hh:mm a"),
          };
        });

        //Order events by date
        events.sort((a, b) => {
          if (a.startDate > b.startDate) {
            return 1;
          }
          if (a.startDate < b.startDate) {
            return -1;
          }
          return 0;
        });

        //Group events by day
        let groupEvents = [];
        for (let event of events) {
          //Retrieve date
          let eventStart = moment.unix(event.startDate).format("DD-MM-YYYY");
          let exists = groupEvents.findIndex((e) => e.day == eventStart);
          if (exists != -1) {
            groupEvents[exists].events.push(event);
          } else {
            let day = moment.unix(event.startDate).format("DD");
            let month = moment.unix(event.startDate).format("MMMM").toLowerCase();
            let year = moment.unix(event.startDate).format("YYYY");
            groupEvents.push({
              day: eventStart,
              dayFormat: t("dashboard:textsEvents.textDateFrom", {
                day,
                month,
                year,
              }),
              events: [event],
            });
          }
        }
        setEvents(groupEvents);
        props.setWaitinData(false);
      }
    });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    let language =
      localStorage.getItem(`cmLanguage${prefix}`) == "spanish" ? "es" : "en";
    moment.locale(language);
    if (!props.active) return;
    if (props.readyFilters && props.waitingData) {
      getEvents();
    }
  }, [props.readyFilters]);

  useEffect(() => {
    if (props.active == false) {
      setEvents(dummyEvents);
      props.setWaitinData(false);
      return;
    }
  }, [props.active]);

  return (
    <Row>
      {props.waitingData ? (
        <LoadingEventsDashboard />
      ) : (
        <>
          <Col md={12}>
            <Row>
              <Col md={7}>
                <i className="bi bi-calendar3 me-2 fs-6"></i>
                <b className="fs-6">{t("dashboard:textsEvents.textEvent")}</b>
              </Col>
              <Col md={5}>
                <SeeAllModule isCMv1={true} path={"/eventos"}></SeeAllModule>
              </Col>
            </Row>
          </Col>

          {eventsModule.length > 0 ? (
            <div style={customStyles.scrollEvent}>
              {eventsModule.map((item) => {
                return (
                  <Row key={item}>
                    <Col md={12}>
                      <FormLabel className="mb-0">{item.dayFormat}</FormLabel>
                    </Col>
                    <Col md={12}>
                      {item.events.map((event) => {
                        return (
                          <Row key={event} style={customStyles.rowContainer}>
                            <Col
                              md={3}
                              className="opacity-50"
                              style={{
                                paddingRight: "0px",
                                paddingLeft: "15px",
                              }}
                            >
                              <Row
                                style={customStyles.styleText}
                                className="mt-1"
                              >
                                {event.starDateFormat}
                              </Row>
                              <Row style={customStyles.styleText}>
                                {event.endDateFormat}
                              </Row>
                            </Col>
                            <Col
                              md={8}
                              style={{
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              <Row
                                style={customStyles.styleText}
                                className="mt-1 text-truncate"
                              >
                                {event.name}
                              </Row>
                              <Row
                                className="opacity-50 text-truncate"
                                style={customStyles.styleText}
                              >
                                {event.description}
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <Col
              md={12}
              style={{ height: "220px", paddingTop: "100px" }}
              className=" align-middle"
            >
              <p className="text-center">{t("dashboard:labels.noData")}</p>
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default EventsModule;

EventsModule.propTypes = {
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  readyFilters: PropTypes.bool,
  filters: PropTypes.object,
  active: PropTypes.bool,
};
