/**
 *       **** Readme ****
 *
 * Component: <FormSchoolLevel />
 * Purpose: Returns the elements of School levels - Grades - Groups || School levels - Academic offer - Levels
 *
 * Props:
 *   -cycleId: {Integer} Id of the selected cycle
 *   -cycles: {Array} List of cycles
 *   -getSchoolCycle: Get the school cycles
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 03/Abril/2022
 * Last update: 05/April/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Components
import FormSelect from "./FormSelect";
import FormSelectGroup from "./FormSelectGroup";
import AddDegreeModal from "../../../pages/cycles/modals/AddDegreeModal";
import AcademicModalView from "../../../pages/crm/AcademicModalView";

//API
import { getGradeLevels, getGradeGroups } from "../../../api/StudentsReport";

export default function FormSchoolLevel({ ...props }) {
  const [t] = useTranslation(["cycles"]);

  //School levels
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [loadLevels, setLoadLevels] = useState(true);

  //Grades
  const [grades, setGrades] = useState([]); //List of degrees
  const [loadGrades, setLoadGrades] = useState(true);

  //Groups
  const [groups, setGroups] = useState([]); //List of groups
  const [loadGroups, setLoadGroups] = useState(true);

  //Academy Offer
  const [selectAcademicOffer, setSelectAcademicOffer] = useState(null); //Selected Academic Offer
  const [academicOffers, setAcademicOffers] = useState([]); //List of academic offers
  const [showAcademicModalView, setShowAcademicModalView] = useState(false); //Open the modal to add an academic offer

  //Levels of the academic offer
  const [selectLevel, setSelectLevel] = useState(null); //Level selec
  const [levels, setLevels] = useState([]); //List of levels for the academic offer

  //Modal Add Grade
  const [addDegreeModal, setAddDegreeModal] = useState(false); //Open the add degree modal

  /**
   * Gets the groups of the selected degree
   * @param {id} gradeId
   */
  const getGroups = (gradeId) => {
    setGroups([]);
    if (!gradeId) {
      return;
    }
    setLoadGroups(true);
    getGradeGroups({
      gradeId: gradeId,
      cycle_id: props.selectSchoolLevel.id,
    }).then((result) => {
      let data = result.data.data;
      if (data && data.length > 0) {
        let newGroups = [];
        for (let index = 0; index < data.length; index++) {
          newGroups.push({
            id: data[index].id,
            value: data[index].id,
            label: data[index].grade_group.name,
          });
        }
        setGroups(newGroups);
      } else {
        setGroups([]);
      }
      setLoadGroups(false);
    });
  };

  /**
   * Get the grades of the selected school level
   * @param {Id} schoolLeveId
   */
  const getGrades = (schoolLeveId) => {
    setGrades([]);
    if (!schoolLeveId) {
      return;
    }
    setLoadGrades(true);
    getGradeLevels(schoolLeveId).then((result) => {
      let data = result.data.data;
      if (data && data.length > 0) {
        let newGradres = [];
        for (let index = 0; index < data.length; index++) {
          newGradres.push({
            id: data[index].id,
            value: data[index].id,
            label: data[index].grade_level,
          });
        }
        setGrades(newGradres);
      } else {
        setGrades([]);
      }
      setLoadGrades(false);
    });
  };

  /**
   * Obtains the school levels (School levels) of the selected School cycle.
   * @param {Id} schoolCycleId
   */
  const getLevels = (schoolCycleId = false) => {
    setLoadLevels(true);
    setSchoolLevels([]);
    getGrades(schoolCycleId);
    getGroups();
    const schoolCycles = props.cycles; //Bring all the cycles
    const _schoolCycleId = schoolCycleId;
    //Filtering school cycles levels
    if (_schoolCycleId) {
      let schoolLevels = [];
      //Check if the school cycle has higher education
      let universityStudies = false;
      let listOffers = [];
      for (let schoolCycle of schoolCycles) {
        if (schoolCycleId == schoolCycle.id) {
          schoolCycle.colleges_and_schools.forEach((colleges_and_school) => {
            colleges_and_school.school_levels.forEach((school_level) => {
              school_level.programs.forEach((program) => {
                if (program.enabled == "1") {
                  universityStudies = true;
                  listOffers.push({
                    id: program.id,
                    value: program.id,
                    label: program.name,
                    data: program,
                  });
                }
              });
            });
          });
        }
        if (schoolCycle.id === _schoolCycleId) {
          //Get school cycle levels from school cycle
          const schoolCycleLevels = schoolCycle.school_levels;
          if (schoolCycleLevels && schoolCycleLevels.length != 0) {
            for (let level of schoolCycleLevels) {
              schoolLevels.push({
                id: level.id,
                value: level.organization_school_level_id,
                label: level.school_level_name,
              });
            }
          }
        } else {
          continue;
        }
      }
      //Add higher education option
      if (universityStudies) {
        schoolLevels.push({
          id: "higherLevel",
          value: "higherLevel",
          label: t("modalCreateStudent:labels.university"),
        });
      }
      setSchoolLevels(schoolLevels);
      setAcademicOffers(listOffers);
    }
    setLoadLevels(false);
  };

  /**
   * Get the levels of the selected academic offer
   * @param {Object} academicOffer
   */
  const getLevelsAcademicOffer = (academicOffer) => {
    setSelectLevel(null);
    setSelectAcademicOffer(academicOffer);
    setLevels([]);
    if (academicOffer != null) {
      let listLevels = [];
      academicOffer.data.program_levels.forEach((program_level) => {
        listLevels.push({
          id: program_level.id,
          value: program_level.id,
          label: program_level.level,
          data: program_level,
        });
      });
      setLevels(listLevels);
    }
  };

  /**
   * Purpose: Toggle to open and close "AddDegreeModal"
   */
  const openAddDegreeModal = () => {
    if (props.setSomeModalOpen) {
      //Save the value to know if any modal is open
      props.setSomeModalOpen(!addDegreeModal);
    }
    setAddDegreeModal(!addDegreeModal);
  };

  /**
   * Description: Toggle to open and close "AcademicModalView"
   */
  const openAcademicModalView = () => {
    if (props.setSomeModalOpen) {
      //Save the value to know if any modal is open
      props.setSomeModalOpen(!showAcademicModalView);
    }
    setShowAcademicModalView(!showAcademicModalView);
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getLevels(props.cycleId);
  }, [props.cycleId]);

  /**
   * Update the list of academic offer
   */
  useEffect(() => {
    if (props.cycleId != "") {
      getLevels(props.cycleId);
    }
  }, [props.cycles]);

  return (
    <div>
      {/**School levels */}
      <FormSelect
        label={t("schoolCycleGroup.schoolLevel")}
        name="schoolLevel"
        options={schoolLevels}
        onChange={(event) => {
          //Remove touched items from Formik object
          if (event == null) {
            delete props.values.touched.grade;
            delete props.values.touched.group;
            delete props.values.touched.level;
            delete props.values.touched.academicOffer;
          }
          let schoolLevelId = event && event.value ? event.value : event;
          let schoolLevelValue = event && event.value ? event.value : event;
          if (schoolLevelId != "higherLevel") {
            event && event.id ? getGrades(event.id) : setGroups([]);
            props.values.setFieldValue("academicOffer", "");
            props.values.setFieldValue("level", "");
          } else {
            props.values.setFieldValue("grade", "");
            props.values.setFieldValue("group", "");
          }
          props.setSelectGrade(null);
          props.setSelectGroup(null);
          setSelectAcademicOffer(null);
          setSelectLevel(null);
          props.setSelectSchoolLevel(event); //This is for StudentReportView
          props.setSelectGradeId(schoolLevelId); //This is for StudentReportView
          props.values.setFieldValue("schoolLevel", schoolLevelValue);
        }}
        value={props.selectSchoolLevel}
        isDisabled={
          props.cycleId === null || loadLevels || schoolLevels.length === 0
        }
        required={true}
        showRequired={props.showRequired}
        placeholder={t("select.placeholderSchoolLevels")}
        isClearable
      />

      {props.selectSchoolLevel === null ||
      (props.selectSchoolLevel &&
        props.selectSchoolLevel.value != "higherLevel") ? (
        <>
          {/** Grades */}
          <FormSelectGroup
            label={t("schoolCycleGroup.grade")}
            name="grade"
            options={grades}
            onChange={(event) => {
              //Remove touched items from Formik object
              if (event == null) {
                delete props.values.touched.group;
              }
              let gradeId = event && event.id ? event.id : event;
              getGroups(gradeId);
              props.setSelectGrade(event);
              props.setSelectGroup(null);
              props.values.setFieldValue("grade", gradeId);
            }}
            value={props.selectgrade}
            isDisabled={
              props.selectSchoolLevel === null ||
              loadGrades ||
              grades.length === 0
            }
            required={true}
            showRequired={props.showRequired}
            placeholder={t("select.placeholderGrade")}
            useIn="form"
            addButton={props.addButton}
            handleButton={openAddDegreeModal}
            textButton={t("select.buttonGrade")}
            isDisabledButton={
              props.cycleId === "" || props.selectGradeId === "" ? true : false
            }
            isClearable
          />

          {/** Groups */}
          <FormSelect
            label={t("schoolCycleGroup.group")}
            name="group"
            options={groups}
            onChange={(event) => {
              let groupId = event && event.value ? event.value : event;
              props.setSelectGroup(event);
              props.values.setFieldValue("group", groupId);
            }}
            value={props.selectGroup}
            isDisabled={
              props.selectgrade === null || loadGroups || groups.length === 0
            }
            required={true}
            showRequired={props.showRequired}
            placeholder={t("select.placeholderGroup")}
            isClearable
          />
        </>
      ) : (
        <>
          {/** Academic Offer */}
          <FormSelectGroup
            label={t("schoolCycleGroup.academicOffer")}
            name="academicOffer"
            options={academicOffers}
            value={selectAcademicOffer}
            onChange={(event) => {
              //Remove touched items from Formik object
              if (event == null) {
                delete props.values.touched.level;
              }
              let offerAcademicId = event && event.value ? event.value : event;
              getLevelsAcademicOffer(event);
              props.values.setFieldValue("academicOffer", offerAcademicId);
            }}
            required={true}
            showRequired={props.showRequired}
            placeholder={t("select.placeholderOfferAcademic")}
            useIn="form"
            addButton={props.addButton}
            handleButton={openAcademicModalView}
            textButton={t("select.buttonAcademicOffer")}
            isDisabledButton={
              props.cycleId === "" || props.selectGradeId === "" ? true : false
            }
            isClearable
          />

          {/** Academic Offer Level */}
          <FormSelect
            label={t("schoolCycleGroup.levelAcademicOffer")}
            name="level"
            options={levels}
            value={selectLevel}
            onChange={(event) => {
              let levelOfferAcademicId =
                event && event.value ? event.value : event;
              setSelectLevel(event);
              props.setSelectLevel(event);
              props.values.setFieldValue("level", levelOfferAcademicId);
            }}
            isDisabled={selectAcademicOffer == null || levels.length == 0}
            placeholder={t("select.placeholderLevel")}
            isClearable
          />
        </>
      )}

      {/** Modal to Add a Degree */}
      <AddDegreeModal
        open={addDegreeModal}
        handleClose={openAddDegreeModal}
        selectGradeId={props.selectGradeId}
        selectCycleId={props.cycleId}
        getGrades={getGrades}
        selectSchoolLevel={props.selectSchoolLevel}
      />

      {/** Modal to add an Academic Offer */}
      <AcademicModalView
        showAcademicModalView={showAcademicModalView}
        setShowAcademicModalView={openAcademicModalView}
        setShowModalAddProspect={() => props.getSchoolCycle()}
        setInitialValues={() => null}
        schoolCycleId={props.cycleId}
        setSomeModalOpen={props.setSomeModalOpen} //Update the value if any modal is open
      />
    </div>
  );
}

FormSchoolLevel.propTypes = {
  cycleId: PropTypes.string, //Selected school year
  cycles: PropTypes.array, //List of cycles with the complete info of each cycle
  getSchoolCycle: PropTypes.func, //Get the school cycles
  showRequired: PropTypes.bool,
  values: PropTypes.Object, //Valores de Formik
  selectSchoolLevel: PropTypes.object, //Selected School Level
  selectgrade: PropTypes.object, //Selected Grade
  selectGroup: PropTypes.object, //Selected Group
  selectGradeId: PropTypes.number, //Selected Grade Id
  setSelectSchoolLevel: PropTypes.func, //Update the selected School Level
  setSelectGrade: PropTypes.func, //Update the selected Grade
  setSelectGroup: PropTypes.func, //Update the selected Group
  setSelectGradeId: PropTypes.func, //Update the selected Grade Id
  setSelectLevel: PropTypes.func,
  setSomeModalOpen: PropTypes.func, //Update the value if any modal is open
  addButton: PropTypes.bool,
};
