/**
  *       **** Readme ****
  
  * Props:
  *   -column: {Object} Specifies the header titles of the columns
  *   -getByText: {Fuction} Function to make a request to the api with the selected filter. (default value: null)
  *   
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function InputFilter(props) {
  const [t] = useTranslation(["table"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const { filterValue, setFilter } = props.column;
  //Clears all filters in the table when there is no relationship between them.
  const { setAllFilters } = props;
  const getByText = props.getByText || null;
  //Sets the waiting time for a request to be mabe
  let [typingCallback, setTypingCallback] = useState(null);
  //Indicates if the filters in the table are not related to each other and work independently.
  const { unrelatedFilters } = props.state;
  const minFilterWidth = props.header
    ? props.header.length * 7.5 + "px"
    : "70px";

  /**Initial loading */
  useEffect(() => {}, []);
  const changeValue = (event) => {
    if (unrelatedFilters) {
      setAllFilters([]);
    }
    setFilter(event.target.value || undefined);
    //Function to make a call to the api when you finish writing in some input.
    if (getByText) {
      if (typingCallback) {
        clearTimeout(typingCallback);
      }
      typingCallback = setTimeout(() => {
        getByText(event, props.column);
      }, 1000);
    }
    setTypingCallback(typingCallback);
  };

  return (
    <div className="filter-input">
      <input
        value={filterValue || ""}
        onChange={changeValue}
        placeholder={t("columnFilter.search")}
        style={{ minWidth: minFilterWidth }}
      />
    </div>
  );
}

InputFilter.propTypes = {
  column: PropTypes.string,
  getByText: PropTypes.func,
  unrelatedFilters: PropTypes.bool,
  setAllFilters: PropTypes.func,
  state: PropTypes.object,
  header: PropTypes.string,
};
