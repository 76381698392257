import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { GetSchoolAverages } from "../../../api/Dashboard";

const dummyData = {
  average: 9.4,
  graph: [
    {
      name: "Primaria",
      value: 8.5,
    },
    {
      name: "Secundaria",
      value: 7.5,
    },
    {
      name: "Preparatoria",
      value: 4,
    },
    {
      name: "Superior",
      value: 9,
    },
    {
      name: "kinder",
      value: 8.5,
    },
    {
      name: "superior",
      value: 2,
    },
  ],
  ranges: [
    {
      label: "Bajo",
      min: 0,
      max: 3,
    },
    {
      label: "Regular",
      min: 4,
      max: 6,
    },
    {
      label: "Bueno",
      min: 7,
      max: 10,
    },
  ],
};

const colorGood = "#41d299";
const colorRegular = "#59bab7";
const colorUnder = "#ff6384";

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
  tooltips: {
    backgroundColor: "#ef90a4",
    xPadding: 10,
    callbacks: {
      title: function (context, data) {
        return data.fullNames[context[0].index]
      },
      label: function (tooltipItem, data) {
        return (
          data["datasets"][0]["data"][tooltipItem["index"]]
        );
      },
    },
  },
};

const lazyLoading = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col md={2}>
          <div className="mt-5"></div>
          <div style={{ height: "50px" }}></div>
          <div></div>
        </Col>
        <Col md={10}>
          <div style={{ height: "192px" }}></div>
        </Col>
        <Col md={4} className="offset-md-2">
          <div></div>
        </Col>
      </Row>
    </div>
  );
};

const AverageModule = (props) => {
  const [t] = useTranslation(["dashboard"]);
  const [data, setData] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [average, setAverage] = useState(0);

  /**
   * Load the test values in case the module is locked.
   */
  const loadDummyData = () => {
    let labels = [];
    let dataAverage = [];
    let colors = [];

    dummyData.ranges[2].color = colorGood;
    dummyData.ranges[1].color = colorRegular;
    dummyData.ranges[0].color = colorUnder;
    for (let item of dummyData.graph) {
      labels.push(item.name);
      dataAverage.push(item.value);
      for (let range of dummyData.ranges) {
        if (item.value >= range.min && item.value <= range.max) {
          colors.push(range.color);
        }
      }
    }
    setRanges(dummyData.ranges);
    dataAverage.push(0);
    dataAverage.push(10);
    let newData = {
      labels,
      datasets: [
        {
          data: dataAverage,
          backgroundColor: colors,
        },
      ],
    };
    setData(newData);
    setAverage(dummyData.average);
  };

  /**
   * obtains school averages
   */
  const getSchoolAverage = () => {
    const request = {
      school_cycle_id: props.filters.cycle?.id,
      school_level_id: props.filters.level?.id,
      grade_level_id: props.filters.grade?.id,
      program_id: props.filters.programs?.id,
      grade_group_id: props.filters.group?.id,
      program_level_id: props.filters.levelProgram?.id,
    };
    GetSchoolAverages(request).then((response) => {
      if (response.status) {
        let currentBase = props.cycles.find((cycle) => cycle.id == props.filters.cycle.id).school_levels;
        if (request.school_level_id) {
          let level = currentBase.find((level) => level.id == request.school_level_id);
          currentBase = level.type == "basic" ? level.grades : level.programs;
        }
        if (request.grade_level_id) {
          currentBase = currentBase.find((grade) => grade.id == request.grade_level_id).groups;
        }
        if (request.program_id) {
          currentBase = currentBase.find((program) => program.id == request.program_id).program_levels;
        }
        let labels = [];
        let fullNames = [];
        let dataAverage = [];
        let colors = [];
        let baseIds = []
        const average = response.data;
        //load local data as base
        if (request.grade_group_id == undefined && request.program_level_id == undefined) {
          for (let item of currentBase) {
            item.name = item.name.toString();
            labels.push(item.name.trim().substr(0, 1));
            fullNames.push(item.name);
            dataAverage.push(0);
            baseIds.push(item.id)
          }
          // load api data
          for (let item of average.graph) {
            let exist = baseIds.findIndex((id) => id == item.id);
            if (exist != -1) {
              labels[exist] = item.name.trim().substr(0, 1);
              fullNames[exist] = item.name;
              dataAverage[exist] = item.value;
              for (let range of average.ranges) {
                if (parseFloat(item.value) >= parseFloat(range.min) && parseFloat(item.value) <= parseFloat(range.max)) {
                  colors[exist] = range.color;
                }
              }
            }
          }
        } else {
          // load api data
          for (let item of average.graph) {
            labels.push(item.name.trim().substr(0, 2))
            fullNames.push(item.name);
            dataAverage.push(item.value);
            for (let range of average.ranges) {
              if (parseFloat(item.value) >= parseFloat(range.min) && parseFloat(item.value) <= parseFloat(range.max)) {
                colors.push(range.color);
              }
            }
          }
        }

        // Delimiting minimum and maximum ranges
        dataAverage.push(0);
        dataAverage.push(10);
        let newData = {
          labels,
          datasets: [
            {
              data: dataAverage,
              backgroundColor: colors,
            },
          ],
          fullNames,
        };
        setAverage(average.average);
        setRanges(average.ranges);
        setData(newData);
        props.setWaitinData(false);
      }
    });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.active == false) {
      loadDummyData();
      props.setWaitinData(false);
      return;
    }
  }, [props.active]);

  useEffect(() => {
    if (!props.active) return;
    if (props.readyFilters) {
      getSchoolAverage();
    }
  }, [props.readyFilters]);

  return props.waitingData ? (
    lazyLoading()
  ) : (
    <Row>
      <Col lg={3} xl={3} xxl={2} className="pe-0 align-items-center">
        <Row className="justify-content-center" style={{ marginTop: "50px" }}>
          <Col md={1} className="pt-3 fs-6">
            <i className="bi bi-journals"></i>
          </Col>
          <Col md={9} className="text-uppercase fs-6">
            <b>{t("dashboard:averageModule.schoolAverage")}</b>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            md={12}
            className=" text-center"
            style={{
              border: "solid 1px black",
              maxWidth: "fit-content",
            }}
          >
            <p className="fs-3 fw-bolder">{average}</p>
          </Col>
        </Row>
        <Row>
          <Col
            md={12}
            style={{ fontSize: "9px", color: "gray" }}
            className="text-center mt-3"
          >
            <p className="text-center text-uppercase">
              {t("dashboard:averageModule.academicPerformanceEvaluation")}
            </p>
          </Col>
        </Row>
      </Col>
      <Col lg={9} xl={9} xxl={10} className="pt-3">
        <Bar
          height="192px"
          options={options}
          data={data}
          legend={{
            display: false,
            onClick: null,
            position: "bottom",
            align: "center",
            labels: {
              usePointStyle: false,
            },
          }}
        />
      </Col>
      <Col
        lg={8}
        xl={8}
        xxl={9}
        className="offset-lg-3 offset-xl-3 offset-xxl-2"
      >
        {ranges.map((range) => {
          return (
            <>
              <span
                className="badge me-1"
                style={{
                  width: "25px",
                  height: "10px",
                  background: range.color,
                  borderRadius: "0px",
                }}
              >
                {" "}
              </span>
              <p className="d-inline-block me-3">{range.label}</p>
            </>
          );
        })}
      </Col>
    </Row>
  );
};

export default AverageModule;
AverageModule.propTypes = {
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  filters: PropTypes.object,
  readyFilters: PropTypes.func,
  active: PropTypes.bool,
  cycles: PropTypes.array,
};
