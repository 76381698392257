import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { createRef } from "react";
import * as Sentry from "@sentry/react";

/** Services */
import {
  DownloadReportMedicalRecord,
  DownloadTemplateMedicalRecord,
  importMedicalRecordCSV,
} from "../../api/StudentsReport";
import TextLoading from "../global/TextLoading";

export default function ModalImportMedicalRecord(props) {
  const [t] = useTranslation(["student"]);
  const dropzoneRef = createRef();
  const [file, setFile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [activeImport, setActiveImport] = useState(true);
  const FileDownload = require("js-file-download");
  const [errors, setErrors] = useState(false);

  /* Initial Loading */
  useEffect(() => {
    Sentry.setTag("section", "Medical Record Modal");
  }, []);

  /**
   * Enable/Disable the button to import Medical Record
   */
  useEffect(() => {
    const noFile = file == "";
    setActiveImport(noFile);
  }, [file]);

  /**
   * Download medical record report
   * Can only be downloaded when you select one or more students 
   * or when you do not select any but have a school year selected
   */
  const downloadReport = () => {
    let data = {};
    let selectedRowIds = props.selectedRowIds;
    let schoolCycleId = props.schoolCycleId;
    const studentIds = [];
    selectedRowIds.forEach(({original}) => {
      studentIds.push(original.index);
    });
    if (studentIds.length) {
      data = {
        student_ids: studentIds,
      };
    } else {
      data = {
        school_cycle_id: schoolCycleId,
      };
    }
    DownloadReportMedicalRecord(data).then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setErrors(result);
        return;
      }
      FileDownload(result.data, "report_medical_record.csv");
    });
  };

  /**
   * Download Template
   */
  const downloadTemplate = () => {
    DownloadTemplateMedicalRecord().then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setErrors(result);
        return;
      }
      FileDownload(result.data, "medical_info.csv");
    });
  };

  /**
   *Import Medical Record
   */
  const importMedicalRecord = () => {
    setIsSaving(true);
    setErrors(false);
    importMedicalRecordCSV(file[0])
      .then((result) => {
        if (result.data) {
          cancel();
          props.setShowImportResultMedicalRecord(true);
          props.modalSuccessImportMedicalRecord();
        } else {
          Sentry.captureException(Error(JSON.stringify(result)));
          setErrors(result);
        }
        setIsSaving(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Open File
   */
  const openFile = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  /**
   * save file csv
   * @param {File} file
   */
  const saveFile = (file) => {
    if (file.length > 0) {
      setFile(file);
    }
  };

  /**
   * Purpose: Close the "Import Medical Record" modal
   */
  const cancel = () => {
    props.setShowImportMedicalRecord(false);
    setFile("");
    setErrors(false);
  };
  return (
    <div>
      <Modal show={props.showImportMedicalRecord}>
        <Modal.Header>
          <Modal.Title>
            {t("modalImportMedicalRecord.titleMedicalRecord")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row>
              <Col style={{ fontSize: "12px" }}>
                {t("modalImportMedicalRecord.descriptionMedicalRecord")}
              </Col>
            </Row>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <Button
                id="downloadReportBtn"
                style={{ fontWeight: "bold" }}
                variant="outline-secondary"
                disabled={isSaving}
                onClick={downloadReport}
              >
                {t("modalImportMedicalRecord.btnDownloadReport")}
              </Button>

              <Button
                id="downloadTemplateBtn"
                onClick={downloadTemplate}
                style={{ marginLeft: "15px" }}
                variant="primary"
                disabled={isSaving}
              >
                {t("modalImportMedicalRecord.btnDownloadTemplate")}
              </Button>
            </span>
            <Row>
              <Col
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "12px",
                }}
              >
                {t("modalImportMedicalRecord.noteImportMedicalRecord")}
              </Col>
            </Row>
            <div
              style={{
                position: "relative",
                height: "auto",
                borderWidth: "2px",
                borderColor: "rgb(194, 194, 194)",
                borderStyle: "dashed",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              <Dropzone
                ref={dropzoneRef}
                accept=".csv"
                onDrop={saveFile}
                noClick
                noKeyboard
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <center>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#ea2c54",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        {file && file[0].name
                          ? file[0].name
                          : t("modalImportMedicalRecord.dropFile")}
                        <br />
                        {file ? "" : t("modalImportMedicalRecord.dropFileOr")}
                        <br />
                        <br />
                        <input {...getInputProps()} />
                        <Button
                          id="uploadFileBtn"
                          type="button"
                          className="btn btn-text btn-white"
                          disabled={isSaving}
                          onClick={openFile}
                        >
                          {t("modalImportMedicalRecord.uploadFile")}
                        </Button>
                      </p>
                    </center>
                  </div>
                )}
              </Dropzone>
            </div>
          </form>
          {errors && (
            <div style={{ marginTop: "20px" }}>
              <Alert variant="warning">{errors.description}</Alert>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="cancelImportMedicalRecordBtn"
            style={{ marginRight: "10px" }}
            variant="outline-secondary"
            disabled={isSaving}
            onClick={() => cancel()}
          >
            {t("modalImportMedicalRecord.btnCancel")}
          </Button>
          <Button
            id="importCsvMedicalRecordBtn"
            variant="primary"
            disabled={activeImport || isSaving}
            onClick={importMedicalRecord}
          >
            {isSaving ? (
              <TextLoading
                text={t("modalImportMedicalRecord.importing")}
                variant={"light"}
              />
            ) : (
              t("modalImportMedicalRecord.btnImportCSV")
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalImportMedicalRecord.propTypes = {
  showImportMedicalRecord: PropTypes.bool,
  setShowImportMedicalRecord: PropTypes.func,
  setShowImportResultMedicalRecord: PropTypes.func,
  modalSuccessImportMedicalRecord: PropTypes.func,
  schoolCycleId: PropTypes.string,
  selectedRowIds: PropTypes.array,
};
