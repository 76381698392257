import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";

import { GetPlayStore, CreateTool, GetAppStore } from "../../../api/ExtraLink";
export default function ModalAddTools(props) {
  const [t] = useTranslation(["extraLink", "global"]);
  const [playStoreApp, setPlayStoreApp] = useState({});
  const [appStore, setAppStore] = useState({});
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [typingCallback, setTypingCallback] = useState(null);
  const [mensageErros, setMensageErros] = useState({
    android: false,
    ios: false,
  });

  /**
   * Service to create play store and app store tools
   */
  const createTools = () => {
    let playStore = { ...playStoreApp };
    let appStoreIos = { ...appStore };
    const tools = [playStore, appStoreIos];
    let errors = {
      android: false,
      ios: false,
    };
    let cont = 0;
    for (let tool of tools) {
      if (tool.bundle_id) {
        const request = {
          name: tool.name,
          description: tool.description,
          image_url: tool.image_url,
          app_url: tool.app_url,
          bundle_id: tool.bundle_id,
          type: tool.type,
        };
        CreateTool(request).then((result) => {
          cont++;
          if (result && result.description) {
            errors[tool.type] = result.description;
          }
          setMensageErros(errors);
          if (cont == 2 && !errors.android && !errors.ios) {
            props.setShowModalAddEditTools(false);
          }
          if (result.data) {
            props.setShowModalAddEditTools(false);
            props.getTools();
          }
        });
      }
    }
  };

  /**
   * Searches for play store parameters
   * @param {obj} text
   * @param {func} callback
   */
  const searchPlayStore = (text, callback) => {
    if (!text || text == "") {
      callback([]);
    } else {
      if (typingCallback) {
        clearTimeout(typingCallback);
      }
      let typing = setTimeout(() => {
        const request = {
          search: text,
        };
        GetPlayStore(request).then((result) => {
          const searchs = result.data;
          let appList = [];
          if (searchs && searchs.length) {
            for (let search of searchs) {
              let obj = {
                label: search.name,
                name: search.name,
                image_url: search.image_url,
                app_url: search.store_url,
                bundle_id: search.bundleId,
                type: "android",
              };
              appList.push(obj);
            }
          }
          callback(appList);
        });
      }, 1000);
      setTypingCallback(typing);
    }
  };

  /**
   * Searches the app store parameters
   * @param {obj} text
   * @param {func} callback
   */
  const searchAppStore = (text, callback) => {
    if (!text || text == "") {
      callback([]);
    } else {
      if (typingCallback) {
        clearTimeout(typingCallback);
      }
      let typing = setTimeout(() => {
        const request = {
          search: text,
        };
        GetAppStore(request).then((result) => {
          const searchs = result.data;
          let appListIos = [];
          if (searchs && searchs.length) {
            for (let search of searchs) {
              let obj = {
                label: search.name,
                name: search.name,
                image_url: search.image_url,
                app_url: search.store_url,
                bundle_id: search.app_id,
                type: "ios",
              };
              appListIos.push(obj);
            }
          }
          callback(appListIos);
        });
      }, 1000);
      setTypingCallback(typing);
    }
  };

  /**
   * Saves the parameters to be output to the network
   * @param {obj} event
   */
  const changePlayStoreInfo = (event) => {
    let editApp = { ...playStoreApp };
    if (event.target) {
      editApp.description = event.target.value;
    } else {
      editApp.label = event.name;
      editApp.name = event.name;
      editApp.image_url = event.image_url;
      editApp.app_url = event.app_url;
      editApp.bundle_id = event.bundle_id;
      editApp.type = event.type;
      let error = { ...mensageErros };
      error.android = false;
      setMensageErros(error);
    }
    setDisabledBtn(false);
    setPlayStoreApp(editApp);
  };

  /**
   * Saves the parameters to be output to the network
   * @param {obj} e
   */
  const changeAppStoreInfo = (e) => {
    let editAppStore = { ...appStore };
    if (e.target) {
      editAppStore.description = e.target.value;
    } else {
      editAppStore.label = e.name;
      editAppStore.name = e.name;
      editAppStore.image_url = e.image_url;
      editAppStore.app_url = e.app_url;
      editAppStore.bundle_id = e.bundle_id;
      editAppStore.type = e.type;
      let error = { ...mensageErros };
      error.ios = false;
      setMensageErros(error);
    }
    setDisabledBtn(false);
    setAppStore(editAppStore);
  };

  return (
    <Modal
      show={props.showModalAddEditTools}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("addTool")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>{t("Play Store")}</Form.Label>
              <AsyncSelect
                id="selectPlayStore"
                matchPos="any"
                value={playStoreApp.name ? playStoreApp : ""}
                classNamePrefix={"select"}
                noOptionsMessage={() => t("global:select.noMatches")}
                loadOptions={searchPlayStore}
                onChange={changePlayStoreInfo}
                placeholder={t("modalTools.searchAplications")}
              />
            </Form.Group>
          </Col>
        </Row>
        {playStoreApp.type == "android" && (
          <Row>
            <Col md={12}>
              <FormGroup className="mt-2">
                <Form.Label>{t("desciptionOptional")}</Form.Label>
                <Form.Control
                  type="descriptionToolsPlayStore"
                  id="inputText"
                  placeholder={t("modalTools.placeholderText")}
                  value={playStoreApp.description || ""}
                  onChange={changePlayStoreInfo}
                  style={{ height: "60px" }}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {mensageErros.android && (
          <Row>
            <Col md={12} className="mt-3">
              <Alert variant="warning">{mensageErros.android}</Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>{t("App Store")}</Form.Label>
              <AsyncSelect
                id="selectAppStre"
                matchPos="any"
                classNamePrefix={"select"}
                noOptionsMessage={() => t("global:select.noMatches")}
                value={appStore.name ? appStore : ""}
                loadOptions={searchAppStore}
                onChange={changeAppStoreInfo}
                placeholder={t("modalTools.searchAplications")}
              />
            </Form.Group>
          </Col>
        </Row>
        {appStore.type == "ios" && (
          <Row>
            <Col md={12}>
              <FormGroup className="mt-2">
                <Form.Label>{t("desciptionOptional")}</Form.Label>
                <Form.Control
                  type="descriptionToolsAppStore"
                  id="inputText"
                  placeholder={t("modalTools.placeholderText")}
                  value={appStore.description || ""}
                  onChange={changeAppStoreInfo}
                  style={{ height: "60px" }}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {mensageErros.ios && (
          <Row>
            <Col md={12} className="mt-3">
              <Alert variant="warning">{mensageErros.ios}</Alert>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowModalAddEditTools(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={disabledBtn}
          onClick={() => createTools()}
        >
          {t("global:buttons.add")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalAddTools.propTypes = {
  showModalAddEditTools: PropTypes.bool,
  setShowModalAddEditTools: PropTypes.func,
  getTools: PropTypes.func,
};
