import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import SeeAllModule from "./SeeAllModule";
import PropTypes from "prop-types";
import { GetIncomeAndDebtsDashboard } from "../../../api/Dashboard";
import { thousandsFormat } from "../../global/GlobalTools";

const customStyles = {
  lazy: {
    height: "25px",
  },
  lazyGraft: {
    height: "160px",
    borderRadius: "90px",
    width: "160px",
  },
};

const lazyLoading = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col md={4}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={4}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={4} className="offset-md-3">
          <div style={customStyles.lazyGraft}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={4} className="offset-md-2">
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={4}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading">
        <Col md={4} className="offset-md-2">
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={4}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-3">
        <Col md={4} className="offset-md-8">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};

const baseColor = {
  last_30_days: { color: "rgba(255, 217, 224, 1)" },
  last_60_days: { color: "rgba(234, 44, 84, 1)" },
  last_90_days: { color: "rgba(167, 31, 60, 1)" },
  more_than_90_days: { color: "rgba(90, 17, 32, 1)" },
  no_due_date: { color: "rgba(235, 235, 235, 1)" },
  out_of_range: { color: "rgba(235, 235, 235, 1)" },
};

/**
 * Load the test values in case the module is locked.
 */
const DebtsModule = (props) => {
  const [t] = useTranslation(["dashboard"]);
  const [total, setTotal] = useState(0);
  const [debtsData, setDebtsData] = useState([]);
  const [formatDebtsData, setFormatDebtsData] = useState([]);
  const [debtsColors, setDebtsColors] = useState([]);

  const loadDummyData = () => {
    const debts = {
      debt: "216199",
      debt_intervals: [
        { name: "last_30_days", value: "34303" },
        { name: "last_60_days", value: "36305" },
        { name: "last_90_days", value: "26500" },
        { name: "more_than_90_days", value: "32500" },
        { name: "no_due_date", value: "86591" },
        { name: "out_of_range", value: 0 },
      ],
    };
    let colors = [];
    let debtsValues = [];
    for (let method of debts.debt_intervals) {
      colors.push(baseColor[method.name].color);
      debtsValues.push(method.value);
    }
    setTotal(debts.debt);
    setDebtsColors(colors);
    setDebtsData(debtsValues);
    props.setWaitinData(false);
  };

  /**
   * Obtains the debts
   */
  const getDebtsData = () => {
    let request = {
      data_from: "debts",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      school_cycle_id: props.filters.cycle?.id,
    };
    if (props.filters.level?.id) {
      request.school_level_id = props.filters.level.id;
    }
    // parameters for higher level
    if (props.filters.programs?.id) {
      request.program_id = props.filters.programs.id;
    }
    if (props.filters.levelProgram?.id) {
      request.program_level_id = props.filters.levelProgram.id;
    }
    // parameters for basic level
    if (props.filters.grade?.id) {
      request.grade_level_id = props.filters.grade.id;
    }
    if (props.filters.group?.id) {
      request.grade_group_id = props.filters.group.id;
    }
    GetIncomeAndDebtsDashboard(request).then((result) => {
      if (result.data) {
        const debts = result.data.debts;
        let colors = [];
        let debtsValues = [];
        let formatDebtsValues = [];
        for (let method of debts.debt_intervals) {
          colors.push(baseColor[method.name].color);
          debtsValues.push(method.value);
          formatDebtsValues.push(thousandsFormat(method.value))
        }
        setTotal(debts.debt);
        setDebtsColors(colors);
        setDebtsData(debtsValues);
        setFormatDebtsData(formatDebtsValues);
        props.setWaitinData(false);
      }
    });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.active == false) {
      loadDummyData();
      return;
    }
  }, [props.active]);

  useEffect(() => {
    if (!props.active) return;
    if (props.readyFilters) {
      getDebtsData();
    }
  }, [props.readyFilters]);

  return (
    <Row>
      {props.waitingData ? (
        lazyLoading()
      ) : (
        <Col>
          <Row>
            <Col md={6}>
              <i className="bi bi-credit-card me-2 fs-6"></i>
              <b className="fs-6">{t("dashboard:labels.debtTitle")}</b>
              <p className="fs-3">
                <b>${total}</b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto my-2 ustify-content-center">
              {parseFloat(total) > 0 ? (
                <Doughnut
                  data={{
                    labels: [
                      t("dashboard:labels.last30Days"),
                      t("dashboard:labels.last60Days"),
                      t("dashboard:labels.last90Days"),
                      t("dashboard:labels.moreThan90Days"),
                      t("dashboard:labels.noExpirationDate"),
                    ],
                    datasets: [
                      {
                        data: debtsData,
                        backgroundColor: debtsColors,
                        borderWidth: 1,
                      },
                    ],
                    formatDebtsData
                  }}
                  options={{
                    responsive: false,
                    tooltips: {
                      backgroundColor: "#ef90a4",
                      callbacks: {
                        label: function (tooltipItem, data) {
                          return (
                            "$" +
                            data["formatDebtsData"][tooltipItem["index"]]
                          );
                        },
                      },
                      displayColors: false,
                      xPadding: 20,
                    },
                  }}
                  legend={{
                    fullWidth: true,
                    labels: {
                      boxWidth: 25,
                    },
                    onClick: null,
                    position: "bottom",
                  }}
                  height="228px"
                />
              ) : (
                <Col
                  md={12}
                  style={{ height: "228px", paddingTop: "100px" }}
                  className=" align-middle"
                >
                  <p className="text-center">{t("dashboard:labels.noData")}</p>
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <SeeAllModule path={"/payments/debt"} />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default DebtsModule;
DebtsModule.propTypes = {
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  readyFilters: PropTypes.bool,
  filters: PropTypes.object,
  active: PropTypes.bool,
};
