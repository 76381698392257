import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";

/*Services */
import {
  GetCollagesSchool,
  GetSchoolLevels,
  CreateProgram,
  CollagesSchool,
  GetCodeProgram,
} from "../../api/Crm";

/** Elements */
import FieldInput from "../../components/global/form/FieldInput";
import FormSelect from "../../components/global/form/FormSelect";
import TextLoading from "../../components/global/TextLoading";
import ContentButton from "../../components/global/ContentButton";

export default function AcademicModalView(props) {
  const [t] = useTranslation(["crm", "global", "modalCreateStudent"]);
  const formikRef = useRef();
  const [collagesAndSchool, setCollagesAndSchool] = useState([]);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [loadCreateProgram, setLoadCreateProgram] = useState(false);
  const [error, setError] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [valueFaculty, setValueFaculty] = useState(null);
  const [valueSchooling, setValueSchooling] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [careerName, setCareerName] = useState("");
  const [code, setCode] = useState("");
  /**
   * initial values for Form
   */
  const [initialValues, setInitialValues] = useState({
    name: "",
    code: "",
    schoolLevel: "",
    college: "",
    nameFaculty: "",
  });
  /**
   * Get colleges and school
   */
  const getCollagesAndSchool = () => {
    GetCollagesSchool().then((result) => {
      let data = result.data;
      let higherLevel = [];
      for (let item of data) {
        if (item.id) {
          higherLevel.push({
            value: item.id,
            label: item.name,
          });
        }
      }
      setCollagesAndSchool(higherLevel);
    });
  };
  /**
   * Get school levels
   */
  const getSchoolLevels = () => {
    GetSchoolLevels().then((result) => {
      let data = result.data;
      let higherLevels = data.filter(
        (level) => level.id >= 5 && level.id != 6 && level.id < 13
      );
      for (let item of higherLevels) {
        item.value = item.id;
        item.label = item.name;
      }
      setSchoolLevels(higherLevels);
    });
  };
  /**
   * Used to select a faculty
   * @param {string} event
   */
  const onSelectFaculty = (event) => {
    setValueFaculty(event);
  };

  /**
   *Used to select a schooling
   * @param {string} event
   */
  const onSelectSchooling = (event) => {
    setValueSchooling(event);
  };

  /**
   * create a new university faculty
   * @param {string} nameFaculty
   * @returns {JSON object} JSON object
   */
  const postCollagesSchool = async (nameFaculty) => {
    let data = {};
    data.name = nameFaculty.toUpperCase();
    return await CollagesSchool(data).then((result) => {
      if (result && result.description) {
        setError(result.description);
      } else {
        let data = result.data;
        setShowAdd(false);
        getCollagesAndSchool();
        let obj = {
          label: data.name,
          value: data.id,
        };
        return obj;
      }
    });
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getCollagesAndSchool();
    getSchoolLevels();
  }, []);

  /**
   * Validate fields
   */
  const validateSchema = Yup.object().shape({
    name: Yup.string().required(
      t("modalAcademicView.textNameOfTheCareer") + " " + t("isRequired")
    ),
    code: Yup.string().required(
      t("modalAcademicView.textKey") + " " + t("isRequired")
    ),
    schoolLevel: Yup.string("schoolLevel").required(
      t("modalAcademicView.textAcademic") + " " + t("isRequired")
    ),
  });

  /**
   * Create program academic
   * @param {obj} values
   */
  const createProgram = (values) => {
    setIsProcessing(true);
    setLoadCreateProgram(true);
    let data = {};
    data.name = careerName.toUpperCase();
    data.code = values.code;
    data.school_level_id = values.schoolLevel;
    if (values.college || valueFaculty) {
      data.college_and_school_id = values.college
        ? values.college
        : valueFaculty.value;
    }
    if (props.schoolCycleId) {
      data.school_cycle_id = props.schoolCycleId;
    }
    CreateProgram(data).then((result) => {
      if (result && result.description) {
        setError(result.description);
      } else {
        setInitialValues({
          name: "",
          code: "",
          schoolLevel: "",
          college: "",
        });
        setCareerName("");
        setCode("");
        getLocalLeadInfo();
        props.setShowAcademicModalView();
        props.setShowModalAddProspect(true);
      }
      setLoadCreateProgram(false);
      setIsProcessing(false);
      setValueFaculty(null);
      setValueSchooling(null);
    });
  };
  /**
   * Method to show and hide the modal of adding a faculty
   */
  const showAddFactulty = (value) => {
    setShowAdd(value);
  };

  /**
   *Method to close the modal and clear the inputs
   */
  const closeModalAcademic = () => {
    setInitialValues({
      name: "",
      code: "",
      schoolLevel: "",
      college: "",
    });
    setCareerName("");
    setCode("");
    getLocalLeadInfo();
    setValueFaculty(null);
    setValueSchooling(null);
    showAddFactulty(false);
    setValueFaculty(null);
    props.setShowAcademicModalView();
    props.setShowModalAddProspect(true);
  };

  /**
   * retrieve new prospect data stored in localStorage
   */
  const getLocalLeadInfo = () => {
    let prospect = JSON.parse(localStorage.getItem("prospectInfo"));
    if (prospect) {
      props.setInitialValues(prospect);
      localStorage.removeItem("prospectInfo");
    }
  };

  //Waiting for changes in the name of the career name
  useEffect(() => {
    const delayFinisTypinCareerName = setTimeout(() => {
      if (careerName) {
        codeProgram(careerName);
      }
    }, 500);
    return () => clearTimeout(delayFinisTypinCareerName);
  }, [careerName]);

  /**
   *
   *Get career name code
   * @param {String} name
   */
  const codeProgram = (name) => {
    setCode("");
    formikRef.current.setFieldValue("code", "");
    let data = {
      name: name,
    };
    GetCodeProgram(data).then((result) => {
      if (result["X-Requested-With"] == careerName) {
        setCode(result.data.code);
        formikRef.current.setFieldValue("code", result.data.code);
        let values = initialValues;
        values.code = result.data.code;
        setInitialValues(values);
      }
    });
  };

  return (
    <Modal
      show={props.showAcademicModalView}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("modalAddProspect.addProgram")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validateSchema}
            innerRef={formikRef}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              !loadCreateProgram ? createProgram(values) : "";
            }}
          >
            {(values) => (
              <Form>
                <Row>
                  <Col lg={{ span: 12, offset: 0 }}>
                    {showAdd ? (
                      <div>
                        <FieldInput
                          name="nameFaculty"
                          type="text"
                          label={t("modalAcademicView.titleFaculty")}
                          capitalize={true}
                        />
                        <ContentButton className="content-button">
                          <Button
                            variant="outline-secondary"
                            onClick={() => showAddFactulty(false)}
                          >
                            {t("global:buttons.cancel")}
                          </Button>
                          <Button
                            onClick={async () => {
                              let collageObj = await postCollagesSchool(
                                values.values.nameFaculty
                              );
                              if (collageObj) {
                                setValueFaculty(collageObj);
                                values.setFieldValue("nameFaculty", "");
                                values.setFieldValue("college", collageObj.id);
                              }
                            }}
                            disabled={
                              values.values.nameFaculty === "" ||
                              !values.values.nameFaculty
                            }
                          >
                            {t("global:buttons.add")}
                          </Button>
                        </ContentButton>
                      </div>
                    ) : (
                      <div>
                        <FormSelect
                          label={t("modalAcademicView.titleFaculty")}
                          name="college"
                          options={collagesAndSchool}
                          onChange={(event) => {
                            onSelectFaculty(event);
                            values.setFieldValue("college", event.value);
                          }}
                          value={valueFaculty}
                          placeholder={t("modalAcademicView.selectFaculty")}
                        />
                        <Col md={12}>
                          <p
                            style={{
                              fontFamily: "'Open Sans', sans-serif",
                              fontSize: "13px",
                              marginBottom: "5px",
                              textAlign: "right",
                            }}
                          >
                            {t("modalAcademicView.addFaculty")}
                            <i
                              value="add"
                              className="bi bi-plus-circle-fill"
                              style={{
                                fontSize: "15px",
                                cursor: "pointer",
                                marginLeft: "4px",
                              }}
                              onClick={() => showAddFactulty(true)}
                            ></i>
                          </p>
                        </Col>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormSelect
                      label={t("modalAcademicView.textAcademicLevel")}
                      name="schoolLevel"
                      options={schoolLevels}
                      onChange={(event) => {
                        onSelectSchooling(event);
                        values.setFieldValue("schoolLevel", event.value);
                      }}
                      value={valueSchooling}
                      placeholder={t("modalAcademicView.selectAcademic")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FieldInput
                      name="name"
                      capitalize={true}
                      type="text"
                      label={t("modalAcademicView.nameCareer")}
                      value={careerName}
                      onChange={(event) => {
                        setCareerName(event.target.value);
                        values.setFieldValue("name", event.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FieldInput
                      name="code"
                      type="text"
                      label={t("modalAcademicView.titleKey")}
                      value={code}
                      onChange={(event) => {
                        setCode(event.target.value);
                        values.setFieldValue("code", event.target.value);
                      }}
                    />
                  </Col>
                </Row>
                {error !== "" ? <Alert variant="warning">{error}</Alert> : ""}
                <ContentButton className="content-button">
                  <Button
                    variant="outline-secondary"
                    onClick={closeModalAcademic}
                  >
                    {t("global:buttons.cancel")}
                  </Button>
                  <Button type="submit" disabled={isProcessing}>
                    {isProcessing ? (
                      <TextLoading
                        text={t("global:buttons.textAdding")}
                        variant="white"
                      />
                    ) : (
                      t("global:buttons.add")
                    )}
                  </Button>
                </ContentButton>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

AcademicModalView.propTypes = {
  showAcademicModalView: PropTypes.bool,
  setShowAcademicModalView: PropTypes.func,
  setShowModalAddProspect: PropTypes.func,
  setInitialValues: PropTypes.func,
  schoolCycleId: PropTypes.string,
  setSomeModalOpen: PropTypes.func,
};
