import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";

let axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Get the account status
 * @returns
 */
export const GetAccountStatus = async (studentId) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/account_status`;
  axiosOptions.params = { student_id: studentId };

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the pdf account status
 * @returns
 */
export const GetPdfAccountStatus = async (
  studentId,
  nameFile,
  justDownload
) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/print_account_status`;
  axiosOptions.params = { student_id: studentId };
  axiosOptions.responseType = "blob";

  try {
    const response = await axios(axiosOptions);
    var url = window.URL.createObjectURL(response.data); //Gets the url of the Blob returned by the API
    var is_firefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    if (!is_firefox || justDownload) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile);
      document.body.appendChild(link);
      link.click();
    }
    if (!justDownload) window.open(url);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};
