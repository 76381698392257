import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Dropdown } from "react-bootstrap";
import OverlayBlockModule from "../OverlayBlockModule";
import PropTypes from "prop-types";
import {
  GetAccessLogDashboard,
  GetDowloadAccess,
} from "../../../../api/Dashboard";
import { LoadingAccessdDashboard } from "../../../lazyLoading/LazyLoading";
import { LoadingButtonDashboard } from "../../../lazyLoading/LazyLoading";
import TextLoading from "../../../global/TextLoading";

const windowHeight = window.innerHeight;

const customStyles = {
  moduleContainerFixedHeight: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: windowHeight / 2,
    padding: "10px 13px",
  },
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    height: "45px",
  },
  textSize: {
    fontSize: "12px",
  },
  scrollEvent: {
    maxHeight: windowHeight / 2.5,
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "13px",
    minHeight: windowHeight / 3,
  },
};

const dumyStudentsAccess = {
  inside: [
    {
      name: "Primaria",
      total: 50,
      grades: [
        {
          name: "1",
          total: 20,
          groups: [
            {
              name: "A",
              total: 10,
            },
            {
              name: "B",
              total: 10,
            },
          ],
        },
        {
          name: "2",
          total: 30,
          groups: [
            {
              name: "A",
              total: 20,
            },
            {
              name: "B",
              total: 10,
            },
          ],
        },
      ],
    },
    {
      name: "Secundaria",
      total: 40,
      grades: [
        {
          name: "3",
          total: 20,
          groups: [
            {
              name: "A",
              total: 10,
            },
            {
              name: "B",
              total: 10,
            },
          ],
        },
        {
          name: "4",
          total: 30,
          groups: [
            {
              name: "A",
              total: 20,
            },
            {
              name: "B",
              total: 10,
            },
          ],
        },
      ],
    },
  ],
  outside: [
    {
      name: "Licenciatura (traducido)",
      total: 20,
      grades: [
        {
          name: "Ing. En Sistemas Computacionales",
          total: 20,
          groups: [
            {
              name: "1",
              total: 10,
            },
            {
              name: "2",
              total: 10,
            },
          ],
        },
      ],
    },
  ],
};

//Filter defaults with null value
const defaultFiltersDowload = {
  access: 1,
  level: null,
  grade: null,
  group: null,
  program: null,
  programLevel: null,
};

const AccessLogModule = (props) => {
  const [t] = useTranslation(["dashboard", "global"]);
  const [studentsAccess, setStudentsAccess] = useState(dumyStudentsAccess);
  const [access, setAccess] = useState("inside");
  const [selectLevel, setSelectedLevel] = useState(null);
  const [positionLevel, setPositionLevel] = useState(null);
  const [selectGrade, setSelectGrade] = useState(null);
  const [positionGrade, setPositionGrade] = useState(null);
  const [selectGroup, setSelectGroup] = useState(null);
  const [loading, setLoadign] = useState(props.accessLogLoadingData);
  const [isHigherLevels, setIsHigherLevels] = useState(false);
  const [filtersDowloads, setFiltersDowloads] = useState(defaultFiltersDowload);
  const [accessList, setAccessList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Function to obtain the data to be displayed
   */
  const getAccessDashboard = () => {
    setLoadign(true);
    const request = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      school_cycle_id: props.filters.cycle?.id,
    };
    GetAccessLogDashboard(request).then((result) => {
      if (result.data) {
        setStudentsAccess(result.data);
        setAccessList(result.data[access]);
      }
      setLoadign(false);
      props.setAccessLogLoadingData(false);
    });
  };

  /**
   *  Function to download in PDF or Excel the access log data
   * @param {string} print
   */
  const dowloadAccess = (print) => {
    setIsLoading(true);
    let request = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      school_cycle_id: props.filters.cycle?.id,
      print,
      in_institution: filtersDowloads.access,
    };
    if (filtersDowloads.level) {
      request.school_level_id = filtersDowloads.level;
    }
    if (filtersDowloads.grade) {
      request.grade_level_id = filtersDowloads.grade;
    }
    if (filtersDowloads.group) {
      request.grade_group_id = filtersDowloads.group;
    }
    if (filtersDowloads.program) {
      request.program_id = filtersDowloads.program;
    }
    if (filtersDowloads.programLevel) {
      request.program_level_id = filtersDowloads.programLevel;
    }
    GetDowloadAccess(request).then((result) => {
      if (result) {
        const nameFile = t("textAccess.titleAccessLog") + "." + print;
        let url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFile);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      }
    });
  };

  //Select filters from the different selectors
  const selectFilter = (selector, value) => {
    let filters = { ...filtersDowloads };
    if (value == "all") {
      switch (selector) {
        case "group":
          selector = "grade";
          value = positionGrade;
          break;
        case "grade":
          selector = "level";
          value = positionLevel;
          break;
        case "level":
          selector = "access";
          value = access;
          break;
        default:
          break;
      }
    }

    switch (selector) {
      case "access":
        setAccess(value);
        setAccessList(studentsAccess[value]);
        setSelectedLevel(null);
        setPositionLevel(null);
        setSelectGrade(null);
        setPositionGrade(null);
        setSelectGroup(null);
        filters.access = value == "inside" ? 1 : 0;
        filters.level = null;
        filters.grade = null;
        filters.group = null;
        filters.program = null;
        filters.programLevel = null;
        break;
      case "level":
        setSelectedLevel(studentsAccess[access][value].name);
        setAccessList(studentsAccess[access][value].grades);
        setPositionLevel(value);
        setSelectGrade(null);
        setPositionGrade(null);
        setSelectGroup(null);
        setIsHigherLevels(studentsAccess[access][value].is_higher_level);
        filters.level = studentsAccess[access][value].school_level_id;
        filters.grade = null;
        filters.group = null;
        filters.program = null;
        filters.programLevel = null;
        break;
      case "grade":
        setSelectGrade(
          studentsAccess[access][positionLevel].grades[value].name
        );
        setPositionGrade(value);
        setAccessList(
          studentsAccess[access][positionLevel].grades[value].groups
        );
        setSelectGroup(null);
        if (isHigherLevels) {
          filters.program =
            studentsAccess[access][positionLevel].grades[value].program_id;
          filters.grade = null;
          filters.group = null;
          filters.programLevel = null;
        } else {
          filters.grade =
            studentsAccess[access][positionLevel].grades[value].grade_level_id;
          filters.group = null;
          filters.program = null;
          filters.programLevel = null;
        }
        break;
      case "group":
        setSelectGroup(
          studentsAccess[access][positionLevel].grades[positionGrade].groups[
            value
          ].name
        );
        setAccessList([
          studentsAccess[access][positionLevel].grades[positionGrade].groups[
            value
          ],
        ]);
        if (isHigherLevels) {
          filters.programLevel =
            studentsAccess[access][positionLevel].grades[positionGrade].groups[
              value
            ].program_level_id;
          filters.group = null;
        } else {
          filters.group =
            studentsAccess[access][positionLevel].grades[positionGrade].groups[
              value
            ].grade_group_id;
          filters.programLevel = null;
        }
        break;
      default:
        break;
    }
    setFiltersDowloads(filters);
  };

  useEffect(() => {
    if (props.modulesActive.SCE == false && props.modulesActive.App == false) {
      setStudentsAccess(dumyStudentsAccess);
      setLoadign(false);
      return;
    }
  }, [props.modulesActive.SCE, props.modulesActive.App]);

  useEffect(() => {
    if (props.readyFilters && props.accessLogLoadingData) {
      if (props.modulesActive.SCE == false && props.modulesActive.App == false)
        return;
      setFiltersDowloads(defaultFiltersDowload);
      setAccess("inside");
      setSelectedLevel(null);
      setPositionLevel(null);
      setSelectGrade(null);
      setPositionGrade(null);
      setSelectGroup(null);
      setIsHigherLevels(false);
      setAccessList([]);
      getAccessDashboard();
    }
  }, [props.readyFilters]);

  return (
    <Row>
      <OverlayBlockModule
        active={props.modulesActive.SCE && props.modulesActive.App}
        today={true}
        customWidth="100%"
        module="accessLog"
        content={
          <Col
            className="mt-3"
            md={12}
            style={customStyles.moduleContainerFixedHeight}
          >
            {loading ? (
              <LoadingAccessdDashboard />
            ) : (
              <>
                <Row>
                  <Col md={3} className="text-center pe-0">
                    <i
                      className="bi bi-person-check me-2"
                      style={{ color: "#58D68D", fontSize: "24px" }}
                    ></i>
                  </Col>
                  <Col md={9} className="mt-1 fs-6 ps-0">
                    <b>{t("textAccess.titleAccessLog")}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2">
                    <Dropdown className="d-grid">
                      <Dropdown.Toggle
                        id="dropdown"
                        variant="outline-secondary"
                        className="text-truncate"
                      >
                        {access == "inside"
                          ? t("drowInstitution.titleInside")
                          : t("drowInstitution.titleOutside")}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "90%" }}>
                        <Dropdown.Item
                          eventKey={"inside"}
                          onClick={() => {
                            selectFilter("access", "inside");
                          }}
                        >
                          {t("drowInstitution.titleInside")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={"outside"}
                          onClick={() => {
                            selectFilter("access", "outside");
                          }}
                          className="text-truncate"
                        >
                          {t("drowInstitution.titleOutside")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Dropdown className="d-grid">
                      <Dropdown.Toggle
                        id="dropdown"
                        variant="outline-secondary"
                        className="text-truncate"
                      >
                        {selectLevel || t("placeholderText.textAllLevels")}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "90%" }}>
                        <Dropdown.Item
                          onClick={() => {
                            selectFilter("level", "all");
                          }}
                        >
                          {t("placeholderText.textAllLevels")}
                        </Dropdown.Item>
                        {studentsAccess[access].map((item, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              eventKey={item}
                              onClick={() => {
                                selectFilter("level", index);
                              }}
                              className="text-truncate"
                            >
                              {item.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Dropdown className="d-grid">
                      <Dropdown.Toggle
                        id="dropdown"
                        variant="outline-secondary"
                        className="text-truncate"
                        disabled={selectLevel == null}
                      >
                        {selectGrade
                          ? selectGrade
                          : isHigherLevels
                          ? t("placeholderText.textAllOffers")
                          : t("placeholderText.textAllGrades")}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "90%" }}>
                        <Dropdown.Item
                          onClick={() => {
                            selectFilter("grade", "all");
                          }}
                        >
                          {isHigherLevels
                            ? t("placeholderText.textAllOffers")
                            : t("placeholderText.textAllGrades")}
                        </Dropdown.Item>
                        {studentsAccess[access][positionLevel]?.grades.map(
                          (item, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                eventKey={item}
                                onClick={() => {
                                  selectFilter("grade", index);
                                }}
                                className="text-truncate"
                              >
                                {item.name}
                              </Dropdown.Item>
                            );
                          }
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Dropdown className="d-grid">
                      <Dropdown.Toggle
                        id="dropdown"
                        variant="outline-secondary"
                        className="text-truncate"
                        disabled={selectGrade == null}
                      >
                        {selectGroup
                          ? selectGroup
                          : isHigherLevels
                          ? t("placeholderText.textAllLevels")
                          : t("placeholderText.textAllGroup")}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "90%" }}>
                        <Dropdown.Item
                          onClick={() => {
                            selectFilter("group", "all");
                          }}
                        >
                          {isHigherLevels
                            ? t("placeholderText.textAllLevels")
                            : t("placeholderText.textAllGroup")}
                        </Dropdown.Item>
                        {studentsAccess[access][positionLevel] &&
                          studentsAccess[access][positionLevel].grades[
                            positionGrade
                          ]?.groups.map((item, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                eventKey={item}
                                onClick={() => {
                                  selectFilter("group", index);
                                }}
                                className="text-truncate"
                              >
                                {item.name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
            {!loading && (
              <Row>
                <Col style={customStyles.scrollEvent}>
                  {accessList.map((item) => {
                    return (
                      <Row key={item} style={{ padding: "5px 10px" }}>
                        <Col md={12} style={customStyles.rowContainer}>
                          <Row>
                            <p style={customStyles.textSize}>{item.total}</p>
                          </Row>
                          <Row>
                            <p
                              className="opacity-50 text-truncate"
                              style={customStyles.textSize}
                            >
                              {item.name}
                            </p>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            )}
            {loading ? (
              <LoadingButtonDashboard />
            ) : (
              <Row className="mt-2">
                <Col md={12}>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="optionsReceipts"
                      variant="primary"
                      style={{ width: "100%" }}
                      disabled={!accessList.length || isLoading}
                    >
                      {isLoading ? (
                        <TextLoading
                          text={t("global:buttons.downloading")}
                          variant="light"
                        />
                      ) : (
                        t("global:buttons.download")
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "90%" }}>
                      <Dropdown.Item
                        href="#"
                        onClick={() => dowloadAccess("pdf")}
                      >
                        {t("global:buttons.downloadPDF")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => dowloadAccess("csv")}
                      >
                        {t("global:buttons.downloadExcel")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            )}
          </Col>
        }
      />
    </Row>
  );
};

export default AccessLogModule;
AccessLogModule.propTypes = {
  modulesActive: PropTypes.object,
  filters: PropTypes.object,
  readyFilters: PropTypes.bool,
  accessLogLoadingData: PropTypes.bool,
  setAccessLogLoadingData: PropTypes.func,
};
