import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import SeeAllModule from "./SeeAllModule";
import PropTypes from "prop-types";
import { LoadingEnrolledDashboard } from "../../lazyLoading/LazyLoading";
import { GetStudentsCount } from "../../../api/Dashboard";

const customStyles = {
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "70px",
    marginBlock: "10px",
    width: "120px",
  },
  boxContainer: {
    border: "1px solid #ececee",
    height: "70px",
    width: "100%",
    paddingLeft: "10px",
  },
};

const dummyData = [
  {
    name: "Kinder",
    value: 140,
  },
  {
    name: "Preescolar",
    value: 120,
  },
  {
    name: "Primaria",
    value: 260,
  },
  {
    name: "Secundaria",
    value: 220,
  },
  {
    name: "Preparatoria",
    value: 120,
  },
  {
    name: "Nivel Superior",
    value: 120,
  },
];

const EnrolledStudentsModule = (props) => {
  const [t] = useTranslation(["dashboard"]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);


  const getStudentsCount = () => {
    let request = {
      school_cycle_id: props.filters.cycle?.id,
    };
    GetStudentsCount(request).then((result) => {
      if (result.status) {
        setData(result.data.school_levels);
        setTotal(result.data.total)
      }
      props.setWaitinData(false);
    });
  };
  /**
   * Initial Loading
   */
  useEffect(() => {
    if (!props.active) return;
    if (props.readyFilters && props.waitingData) {
      getStudentsCount();
    }
  }, [props.readyFilters]);
  useEffect(() => {
    if (props.active == false) {
      setData(dummyData);
      props.setWaitinData(false);
      return;
    }
  }, [props.active]);

  return (
    <Row>
      {props.waitingData ? (
        <LoadingEnrolledDashboard />
      ) : (
        <>
          <Col md={12}>
            <Row>
              <Col md={7}>
                <i className="bi bi-briefcase me-2 fs-6"></i>
                <b className="fs-6">
                  {t("dashboard:textEnrolledStudent.titleEnrolled")}
                </b>
              </Col>
              <Col md={5}>
                <SeeAllModule path={"/students"}></SeeAllModule>
              </Col>
              <Col md={12}>
                <p className="fs-3 fw-bolder">{total}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  {data.map((item) => {
                    return (
                      <Col sm={4} md={3} xxl={2} key={item} className="mb-3">
                        <div style={customStyles.boxContainer}>
                          <Row>
                            <Col md={12}>
                              <p className="fs-6 fw-bolder">{item.value}</p>
                            </Col>
                            <Col md={12} className="opacity-50">
                              <p className="text-truncate">{item.name}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};

export default EnrolledStudentsModule;

EnrolledStudentsModule.propTypes = {
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  readyFilters: PropTypes.bool,
  filters: PropTypes.object,
  active: PropTypes.bool,
};
