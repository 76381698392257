import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import ExpirationModule from "./ExpirationModule";
import { useTranslation } from "react-i18next";
import AccessModule from "./AccessModule";
import Cookies from "js-cookie";
import OverlayBlockModule from "../../admin/OverlayBlockModule";
import PropTypes from "prop-types";

// gets the width of the window and extracts a calculated portion from it
const heightListContainer = window.innerHeight - window.innerHeight / 4;

const customStyles = {
  chevrolColor: {
    background: "#e5e5e5",
    borderRadius: "4px",
    cursor: "pointer",
  },
  disabledChevrol: {
    opacity: "0.4",
  },
};

const TodayModule = (props) => {
  const [t] = useTranslation(["dashboard", "dashboardStudent"]);
  const [module, setModule] = useState("expirationPayments");
  const prefix = process.env.REACT_APP_PREFIX;
  const group = Cookies.get(`cmGroup${prefix}`);
  const studentId = localStorage.getItem(`cmStudentId${prefix}`);

  const changeModule = (direction) => {
    let newModule = module;
    switch (newModule) {
      case "expirationPayments":
        newModule = "accessLogPayments";
        break;
      case "accessLogPayments":
        newModule = direction == "left" ? "expirationPayments" : "task";
        break;
      default:
        break;
    }
    setModule(newModule);
  };

  return (
    <Row>
      <Col md={6}>
        <p className="fs-5">{t("reports:filterSelect.today")}</p>
      </Col>
      {group == "6" && (
        <Col md={6}>
          <Row className="mt-2 fs-6">
            <Col md={9} className="text-end pe-0">
              <i
                className="bi bi-chevron-left"
                style={
                  module == "expirationPayments"
                    ? customStyles.disabledChevrol
                    : customStyles.chevrolColor
                }
                onClick={
                  module == "expirationPayments"
                    ? null
                    : () => changeModule("left")
                }
              ></i>
            </Col>
            <Col md={2} className="text-end pe-0">
              <i
                className="bi bi-chevron-right"
                style={
                  module == "accessLogPayments"
                    ? customStyles.disabledChevrol
                    : customStyles.chevrolColor
                }
                onClick={
                  module == "accessLogPayments"
                    ? null
                    : () => changeModule("right")
                }
              ></i>
            </Col>
          </Row>
        </Col>
      )}

      <Col
        md={12}
        style={{
          minHeight: heightListContainer + "px",
          maxHeight: heightListContainer + "px",
          padding: "10px 20px 0px 20px",
        }}
      >
        {module === "expirationPayments" && (
          <ExpirationModule
            studentId={studentId}
            heightListContainer={heightListContainer}
            modulesActive={props.modulesActive}
          />
        )}
        {group == "6" && module === "accessLogPayments" && (
          <OverlayBlockModule
            active={props.modulesActive.SCE && props.modulesActive.App}
            textButton={t("dashboardStudent:textModals.textSeeMore")}
            module={"accessModule"}
            originRequest={"student_access_log"}
            nameService={"SCE"}
            customWidth="110%"
            content={
              <AccessModule
                studentId={studentId}
                heightListContainer={heightListContainer}
                active={props.modulesActive.SCE && props.modulesActive.App}
              />
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default TodayModule;

TodayModule.propTypes = {
  modulesActive: PropTypes.object,
};
