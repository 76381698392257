/**
 * Purpose: Action completed confirmation modal
 *
 * Props:
 *    showModalSuccess: boolean type value to show or hide the modal
 *    title: object type value (Translation)
 *    message: object type value (Translation)
 *    fnAcceptButton: function linked to the modal's accept button
 *    size: 'sm' | 'lg' | 'xl'
 *    txtBtnAccept: alternative text for the accept button (Translation)
 *    txtBtnCancel: alternative text for the cancel button (Translation)
 *    fnCancelButton: function linked to the modal's cancel button
 */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";

export default function ModalSuccess(props) {
  const [t] = useTranslation(["global"]);
  const txtBtnAccept = props.txtBtnAccept || t("global:buttons.accept");
  const txtBtnCancel = props.txtBtnCancel || t("global:buttons.cancel");
  const fnCancelButton = props.fnCancelButton || false;
  const acceptButtonVariant = props.acceptButtonVariant || "primary";
  const loading = props.loading || false;
  return (
    <Modal
      id="modalSuccess"
      show={props.showModalSuccess}
      size={props.size}
      aria-labelledby="contained-modal-title-md"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body mbottom10">
        <Row>
          <Col md={12} style={{textAlign: "justify"}}>{props.message}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {fnCancelButton && (
          <Button
            variant="outline-secondary"
            onClick={fnCancelButton}
            disabled={loading}
          >
            {txtBtnCancel}
          </Button>
        )}
        {props.fnAcceptButton && (
          <Button
            variant={acceptButtonVariant}
            onClick={props.fnAcceptButton}
            disabled={loading}
          >
            {txtBtnAccept}
            {loading && (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  variant="white"
                  style={{
                    width: "0.81rem",
                    height: "0.81rem",
                    marginLeft: "3px",
                    borderWidth: "0.17em",
                  }}
                />
              </>
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ModalSuccess.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  txtBtnAccept: PropTypes.string,
  fnAcceptButton: PropTypes.func,
  showModalSuccess: PropTypes.bool,
  txtBtnCancel: PropTypes.string,
  fnCancelButton: PropTypes.func,
  acceptButtonVariant: PropTypes.string,
  loading: PropTypes.bool,
};
