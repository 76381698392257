import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function PleaseWaitMessageModal(props) {
  const [t] = useTranslation(["reports", "global"]);
  return (
    <div>
      <Modal
        show={props.showPleaseWaitMessageModal}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Title className="modal-title ms-3 mt-3">
          {t("modals.titleModalInvoicingInProgress")}
        </Modal.Title>
        <Modal.Body>
          <div>
            <p
              className="mt-4"
              style={{ fontWeight: "lighter", fontSize: "14px" }}
            >
              {t("modals.textModalInvoicingProgress")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              props.setShowModalInvoicePrinting(true),
                props.setShowPleaseWaitMessageModal(false);
            }}
          >
            {t("global:buttons.continue")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

PleaseWaitMessageModal.propTypes = {
  showPleaseWaitMessageModal: PropTypes.bool,
  setShowModalInvoicePrinting: PropTypes.func,
  setShowPleaseWaitMessageModal: PropTypes.func,
};
