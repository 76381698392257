import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const prefix = process.env.REACT_APP_PREFIX;
import { VoteServices } from "../../api/DashboardRelativeStudent";
import { Spinner } from "react-bootstrap";
export default function ModalBlockedSection(props) {
  const [t] = useTranslation(["dashboardStudent", "class", "menu", "dashboard"]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [language] = useState(localStorage.getItem(`cmLanguage${prefix}`));
  const [likedService, setLikedService] = useState(false);
  const [loading, setLoading] = useState(false); //Loading button

  const messageAccessLog = () => {
    return language == "english" ? (
      <>
        Your institution does not have the {' '}<b>School Control System</b> tool
        that allows you to keep track of subjects, classes, grades, attendance
        and more. You can let your institution know that you would like to have
        this tool by clicking {'"'}I Like {'"'}.
      </>
    ) : (
      <>
        Tu institución no cuenta con la herramienta de{' '}
        <b>Sistema de Control Escolar</b> que te permite llevar un control de
        las materias, clases, calificaciones, asistencias y más. Puedes hacerle
        saber a tu institución que te gustaría contar con esta herramienta,
        dando click en {'"'}Me Gusta{'"'}.
      </>
    );
  };

  const expiration = () => {
    return language == "english" ? (
      <>
        Your institution does not have the <b>Payments and Invoicing</b> tool
        which allows you to make payments online in a secure and effective way.
        You can let your institution know that you would like to have this tool
        by clicking {'"'}I Like{'"'}.
      </>
    ) : (
      <>
        Tu institución no cuenta con la herramienta de{" "}
        <b>Pagos y facturación</b> la cual te permite realizar pagos en línea de
        manera segura y efectiva. Puedes hacer saber a tu institución que te
        gustaría contar con esta herramienta, dando click en {'"'}Me Gusta{'"'}.
      </>
    );
  };

  const event = () => {
    return language == "english" ? (
      <>
        Your institution does not have a <b>Mobile App</b> which allows
        you to keep you in immediate communication and informed of the most
        relevant events. You can let your institution know that you would like
        to have this tool by clicking {'"'}I Like{'"'}.
      </>
    ) : (
      <>
        Tu institución no cuenta con <b>Aplicación Móvil</b> la cual te permite
        mantenerte en comunicación inmediata e informado de los eventos más
        relevantes. Puedes hacerle saber a tu institución que te gustaría contar
        con esta herramienta, dando click en {'"'}Me Gusta{'"'}.
      </>
    );
  };

  const messageAttendance = () => {
    return language == "english" ? (
      <>Your institution does not have the <b>School Control System</b> tool that allows you to keep track of subjects, classes, grades, attendance and more. You can let your institution know
        that you would like to have this tool, by clicking {'"'}In Like{'"'}.</>
    ) : (
      <>
        Tu institución no cuenta con la herramienta de <b>Sistema de Control Escolar</b> que te permite llevar un
        control de las materias, clases, calificaciones, asistencias y más. Puedes hacerle saber a tu institución
        que te gustarlo contar con esta herramienta, dando click en {'"'}Me gusta{'"'}. </>
    );
  }

  /**
   * Obtains in the service and in the localstorage the following votes
   */
  const LikeService = () => {
    setLoading(true);
    let serviceName = props.originRequest;
    //exception for the assistance service
    if (props.originRequest == "attendance") {
      serviceName = "classes"
    }
    let request = {
      organization_service: serviceName,
    };
    props.studentId && (request.student_id = props.studentId);
    VoteServices(request).then((result) => {
      if (result?.status) {
        let servicesVotes = JSON.parse(
          localStorage.getItem(`cmUserVotes${prefix}`)
        ) || [];
        let orgServiceId = result.data.organization_service_id;
        servicesVotes.push({
          organization_service_id: orgServiceId,
        });
        localStorage.setItem(
          `cmUserVotes${prefix}`,
          JSON.stringify(servicesVotes)
        );
      }
      props.setShowModalSeeMore(false);
    })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Gets and updates the votes of the localStorage
   */
  useEffect(() => {
    const servicesVotes = JSON.parse(
      localStorage.getItem(`cmUserVotes${prefix}`)
    );
    let services = JSON.parse(localStorage.getItem(`${prefix}Services`));
    let orgService = services && services[props.nameService];
    if (servicesVotes && servicesVotes.length && orgService) {
      let exist = servicesVotes.find(
        (element) =>
          element.organization_service_id ==
          parseInt(orgService.organizationServiceId)
      );
      setLikedService(!!exist);
    }
  }, []);

  /**
   * Obtains the correct names of the service parameters
   */
  useEffect(() => {
    if (typeof t == "function") {
      switch (props.originRequest) {
        case "student_access_log":
          setTitle(t("textModals.titleAccessLog"));
          setMessage(messageAccessLog());
          break;
        case "payments_and_invoices":
          setTitle(t("textModals.textPayments"));
          setMessage(expiration());
          break;
        case "classes":
          setTitle(t("class:main.class"));
          setMessage(messageAccessLog());
          break;
        case "attendance":
          setTitle(t("dashboard:overlayBlock.attendances"));
          setMessage(messageAttendance());
          break;
        case "events_and_news":
          setTitle(props.new ? t("menu:news") : t("menu:events"));
          setMessage(event());
          break;
        default:
          break;
      }
    }
  }, [t]);

  return (
    <Modal
      show={props.showModalSeeMore}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={12} className="mb-2">
            {message}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer >
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowModalSeeMore(false)}
        >
          {t("global:buttons.close")}
        </Button>
        <Button
          variant="primary"
          disabled={likedService || loading}
          onClick={loading ? null : () => LikeService()}
        >
          <i className="bi bi-hand-thumbs-up"></i>
          {likedService
            ? t("textModals.textYouLiked")
            : loading ?
              <>
                {t("textModals.textYouLiked")}
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  variant="white"
                  style={{
                    width: "0.81rem",
                    height: "0.81rem",
                    marginLeft: "3px",
                    borderWidth: "0.17em",
                  }} />
              </>
              : t("textModals.textLike")}
        </Button>
      </Modal.Footer>
    </Modal >
  );
}

ModalBlockedSection.propTypes = {
  showModalSeeMore: PropTypes.bool, //Required
  setShowModalSeeMore: PropTypes.func,
  originRequest: PropTypes.string,
  studentId: PropTypes.number,
  nameService: PropTypes.string,
  new: PropTypes.string,
};
