import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UpdateSuperAdmin } from "../../api/SuperAdmins";
import TextLoading from "../global/TextLoading";
import ContentButton from "../global/ContentButton";

export default function ModalAddPhoneNumber(props) {
  const [t] = useTranslation(["global", "crm", "modalCreateRelative"]);
  const [phone, setPhone] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [validationPhone, setValidationPhone] = useState(true);
  const prefix = process.env.REACT_APP_PREFIX;
  const [showModalSucces, setShowModalSucces] = useState(false);
  const [showModalPhone, setShowModalPhone] = useState(props.showModalAddPhone);
  const [isProcessing, setIsProcessing] = useState(false);

  /**
   * Open or close manners
   * @param {Boolean} value
   */
  const toggleSuccesModal = (value) => {
    setShowModalSucces(value);
    setShowModalPhone(false);
  };

  /**
   * Update information by adding phone as required
   * @returns
   */
  const updateUserInfo = () => {
    setIsProcessing(true);
    let request = {
      token: localStorage.getItem(`cmToken${prefix}`),
      user_id: localStorage.getItem(`cmUserID${prefix}`),
      phone: phone,
    };
    if (request.phone == "") return;
    UpdateSuperAdmin(request)
      .then(() => {
        toggleSuccesModal(true);
        setIsProcessing(true);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => { });
  };

  /**
   * Regular expression for numbers only
   * @param {*} phone
   */
  const changeValue = (phone) => {
    const expOnlyNumbers = new RegExp(/^[0-9]+$/);
    const validationPhone = expOnlyNumbers.test(phone);
    const disabledButton = !validationPhone || phone.length != 10;

    setValidationPhone(validationPhone);
    setPhone(phone);
    setDisabledButton(disabledButton);
  };

  return (
    <>
      <Modal
        show={showModalPhone}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("global:textModalPhone.titlePhone")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={12}>{t("global:textModalPhone.textBodyPhone")}</Col>
          </Row>

          <Row className="mt-4">
            {/**Phone */}
            <Col md={12}>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>{t("crm:modalAddProspect.phoneNumer")}</Form.Label>
                <Form.Control
                  id="phone"
                  type="text"
                  placeholder={t("crm:cellPhonePlaceholder")}
                  value={phone}
                  onChange={(e) => changeValue(e.target.value)}
                  className={validationPhone ? "" : "errorValidation"}
                  maxlength="10"
                />
                {!validationPhone && (
                  <Form.Text className="text-muted error">
                    {t("global:validations.invalidPhoneFormat")}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ContentButton className="content-button">
            <Button
              variant="primary"
              disabled={disabledButton || isProcessing}
              onClick={() => updateUserInfo()}
            >
              {isProcessing ? (
                <TextLoading
                  text={t("global:buttons.textAdding")}
                  variant="white"
                />
              ) : (
                t("global:buttons.add")
              )}
            </Button>
          </ContentButton>
        </Modal.Footer>
      </Modal>

      {/* Confirmation modal   */}
      <Modal
        show={showModalSucces}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("global:textModalPhone.titleConfirmation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              {t("global:textModalPhone.textSuccessfullyAdded")}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => props.toogleShowModalAddPhone(false)}
          >
            {t("global:buttons.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ModalAddPhoneNumber.propTypes = {
  showModalAddPhone: PropTypes.bool,
  toogleShowModalAddPhone: PropTypes.bool,
};
