import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Alert, Row, Col, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import ContentButton from "../../../components/global/ContentButton";
import SuccesBatchBillingModal from "./SuccesBatchBillingModal";

/*Services */
import { multipleBilling } from "../../../api/PaymentsReport";
import ModalBulkInvoicingError from "./ModalBulkInvoicingError";
export default function MassBillingConfirmationModal(props) {
  const [t] = useTranslation(["global", "reports"]);
  const [showSuccesBatchBillingModal, setShowSuccesBatchBillingModal] =
    useState(false);
  const [confirmations, setConfirmations] = useState([]);
  const [invoicingErrors, setInvoicingErrors] = useState([]);
  const [invoicedPaymentIds, setInvoicedPaymentIds] = useState([]);
  const [missingInvoiced, setMissingInvoiced] = useState([]);
  useState(false);
  const [showModalBulkInvoicingError, setShowModalBulkInvoicingError] =
    useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  /**
   * Purpose: Function to generate invoices for selected payments.
   * @param {boolean} missing
   */
  const bill = (missing = false) => {
    setLoading(true);
    setShowSuccesBatchBillingModal(false);
    let paymentIds = missing
      ? JSON.stringify(missingInvoiced)
      : JSON.stringify(props.paymentIds);
    let obj = { payment_ids: paymentIds, ignore_not_billed: true };
    multipleBilling(obj).then((result) => {
      //Show error coming from the api
      if (result.description) {
        setError(result.description);
        setLoading(false);
        return;
      }
      if (result.data.status === true && result.data.data === true) {
        setShowModalBulkInvoicingError(true);
        props.setShowMassBillinConfirmationModal(false);
        setLoading(false);
        return;
      }
      let confirmations = [];
      let errors = [];
      let paymentsId = [];
      let missingBills = [];

      if (result.data.status) {
        let data = result.data.data;
        for (let item of data) {
          let invoices = item.invoices;
          for (let bill of invoices) {
            if (bill.error) {
              errors.push(bill.error);
              missingBills.push(bill.payment_id);
            } else {
              confirmations.push(bill.confirmation);
              paymentsId.push(bill.payment_id);
            }
          }
        }
        setShowSuccesBatchBillingModal(true);
      }
      setMissingInvoiced(missingBills);
      setInvoicingErrors(errors);
      setInvoicedPaymentIds(paymentsId);
      setConfirmations(confirmations);
      props.setShowMassBillinConfirmationModal(false);
      setLoading(false);
    });
  };

  return (
    <div>
      <Modal
        show={props.showMassBillinConfirmationModal}
        size="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("reports:modals.textInvoiced", {
              countPayments: props.paymentIds.length,
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("reports:modals.textPaymentInvoiced", {
            countPayments: props.paymentIds.length,
          })}

          <Row className="mtop20">
            <Col md={12}>
              {error && <Alert variant="warning">{error}</Alert>}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <ContentButton className="content-button">
            <Button
              variant="outline-secondary"
              disabled={loading}
              onClick={() => {
                setError(false);
                props.setShowMassBillinConfirmationModal(false);
              }}
            >
              {t("global:buttons.cancel")}
            </Button>
            <Button disabled={loading} onClick={() => bill()}>
              {loading ? (
                <>
                  {t("reports:headerTable.invoice")}
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    variant="white"
                    style={{
                      width: "0.81rem",
                      height: "0.81rem",
                      marginLeft: "3px",
                      borderWidth: "0.17em",
                    }}
                  />
                </>
              ) : (
                t("reports:headerTable.invoice")
              )}
            </Button>
          </ContentButton>
        </Modal.Footer>
      </Modal>
      <SuccesBatchBillingModal
        showSuccesBatchBillingModal={showSuccesBatchBillingModal}
        setShowSuccesBatchBillingModal={setShowSuccesBatchBillingModal}
        confirmations={confirmations}
        setShowMassBillinConfirmationModal={
          props.setShowMassBillinConfirmationModal
        }
        invoicingErrors={invoicingErrors}
        invoicedPaymentIds={invoicedPaymentIds}
        missingInvoiced={missingInvoiced}
        bill={bill}
      />
      <ModalBulkInvoicingError
        showModalBulkInvoicingError={showModalBulkInvoicingError}
        setShowModalBulkInvoicingError={setShowModalBulkInvoicingError}
      />
    </div>
  );
}

MassBillingConfirmationModal.propTypes = {
  showMassBillinConfirmationModal: PropTypes.bool,
  paymentIds: PropTypes.array,
  setShowMassBillinConfirmationModal: PropTypes.func,
};
