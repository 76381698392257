import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button, Dropdown, Alert } from "react-bootstrap";
import { getSchoolLevelPeriods } from "../../../api/StudentsReport";
import {
  sendReportCardsMail,
  downloadReportCardsMail,
} from "../../../api/StudentsReport";
import * as Sentry from "@sentry/react";

/** Elements */
import TextLoading from "../../../components/global/TextLoading";
import ContentButton from "../../../components/global/ContentButton";

export default function ModalSendReportCards(props) {
  const [t] = useTranslation(["student", "global"]);
  const [waiting, setWaiting] = useState(false);
  const [periodList, setPeriodList] = useState([]);
  const [periodsIds, setPeriodsIds] = useState([]);
  const [periodSelected, setPeriodSelected] = useState({
    periodName: t("modalSendReportCards.selectPeriods"),
  });
  const [errors, setErrors] = useState(false);
  //get language
  const prefix = process.env.REACT_APP_PREFIX;
  const language = localStorage.getItem(`cmLanguage${prefix}`);
  const [isSaving, setIsSaving] = useState(false);
  /**
   * Select the list of periods to be sent
   * @param {obj} period
   */
  const selectPeriod = (period) => {
    let periods = [...periodList];
    let periodsIds = [];
    let limit = period.position;
    let index = 0;
    do {
      periodsIds.push(periods[index].id);
      index++;
    } while (index <= limit);
    setPeriodSelected(period);
    setPeriodsIds(periodsIds);
  };
  /**
   * Method for obtaining the periods of the selected level or program
   */
  const getPeriods = () => {
    let filters = props.filterPeriods;
    let param = filters.programSchoolCycleId
      ? "program_school_cycle_id"
      : "organization_school_level_cycle_id";
    let value = filters.programSchoolCycleId || filters.schoolLevelCycleId;
    let data = { [param]: value };

    getSchoolLevelPeriods(data).then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        return;
      }
      let periods = result.data.data;
      let formattedPeriods = [];
      periods.forEach((element, index) => {
        formattedPeriods.push({
          id: element.id,
          periodName: index + 1,
          position: index,
        });
      });
      setPeriodList(formattedPeriods);
    });
  };
  /**
   * Send the ids of students and periods that will be sent to the following e-mail addresses
   * @param {obj} data - contains the parameters consumed by the service api
   */
  const sendReportCard = (data) => {
    setWaiting(true);
    sendReportCardsMail(data).then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      }
      setWaiting(false);
      props.setShowSendReportCards(false);
      props.reportCardsSuccessfullySent();
    });
  };
  /**
   * Send the zip file with the ballots of the selected students to the administrator's e-mail address.
   * @param {obj} data - contains the parameters consumed by the service api
   */
  const dowloadRepostCard = (data) => {
    setIsSaving(true);
    setWaiting(true);
    downloadReportCardsMail(data).then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      }
      setIsSaving(false);
      setWaiting(false);
      props.setShowSendReportCards(false);
      props.reportCardsSuccessfullySent();
    });
  };
  /**
   * Action of the main button, determines whether to send or download ballots
   */
  const actionBtnPrimary = () => {
    let studentIds = [];
    props.selectedRowIds.forEach((element) => {
      //filter only students actives
      if (
        element.original.status === "Active" ||
        element.original.status === "Activo"
      ) {
        studentIds.push(element.original.index);
      }
    });
    let data = {
      student_ids: studentIds,
      grading_period_ids: periodsIds,
    };
    // Validate that there are students
    if (!studentIds.length) {
      setErrors({
        description:
          language === "spanish"
            ? "Ha seleccionado solo estudiantes inactivos. Por favor verifique la selección"
            : "You have selected only inactive students. Please check the selection",
      });
      return;
    }
    props.actionReportCard == "send"
      ? sendReportCard(data)
      : dowloadRepostCard(data);
  };
  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Send Report Cards Modal");
    getPeriods();
  }, []);

  return (
    <div>
      <Modal
        show={props.showSendReportCards}
        size="md"
        aria-labelledby="contained-modal-title-md"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {props.actionReportCard == "send"
              ? t("modalSendReportCards.title")
              : t("modalSendReportCards.titleDownload")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body mbottom10">
          <Row>
            <Col md={12}>
              {props.actionReportCard == "send"
                ? t("modalSendReportCards.descripcionMessage", {
                    count: props.counterSelectedStudents,
                  })
                : t("modalSendReportCards.downloadMessage")}
            </Col>
            <Col md={12}>
              <Dropdown className="mt-3">
                <Dropdown.Toggle
                  id="dropdownPeriodss"
                  variant="outline-secondary"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "start",
                  }}
                  disabled={waiting}
                >
                  {periodSelected.id
                    ? t("modalSendReportCards.period") + " "
                    : null}
                  {periodSelected.periodName}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    width: "100%",
                  }}
                >
                  {periodList.map((period, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        eventKey={period}
                        onClick={() => selectPeriod(period)}
                      >
                        {t("modalSendReportCards.period")}
                        {" " + period.periodName}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          {/**Show errors*/}
          <div style={{ marginTop: "20px" }}>
            {errors ? (
              <Alert variant="warning">{errors.description}</Alert>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ContentButton className="content-button">
            <Button
              id="cancelSendReportCard"
              variant="light"
              onClick={() => props.setShowSendReportCards(false)}
              disabled={waiting}
            >
              {t("global:buttons.cancel")}
            </Button>
            <Button
              id="sendReportCardBtn"
              variant="primary"
              onClick={() => {
                periodsIds.length ? actionBtnPrimary() : null;
              }}
              disabled={!periodsIds.length || waiting}
            >
              {props.actionReportCard == "send" ? (
                t("global:buttons.send")
              ) : isSaving ? (
                <TextLoading
                  text={t("global:buttons.downloading")}
                  variant="light"
                />
              ) : (
                t("global:buttons.download")
              )}
            </Button>
          </ContentButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalSendReportCards.propTypes = {
  importResult: PropTypes.array,
  filterPeriods: PropTypes.object,
  selectedRowIds: PropTypes.array,
  actionReportCard: PropTypes.string,
  showSendReportCards: PropTypes.bool,
  setShowSendReportCards: PropTypes.func,
  counterSelectedStudents: PropTypes.number,
  reportCardsSuccessfullySent: PropTypes.func,
};
