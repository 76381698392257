import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import FormSelect from "../../global/form/FormSelect";
import FieldInput from "../../global/form/FieldInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { CreateCategory, UpdateCategory } from "../../../api/News";

export default function ModalCategory(props) {
  const [t] = useTranslation(["global", "news", "modalCreateStudent"]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const categoryInfo = props.categoryDetails || {
    name: "",
    parentCategory: "",
    description: "",
  };

  let categoriesList = props.categories ? props.categories.map((category) => {
    return { value: category.id, label: category.name }
  }) : [];

  if (categoriesList.length) {
    categoriesList.unshift({ value: "none", label: t("news:modalCategory.noParentCategory") });
  }

  const validateSchema = Yup.object().shape({
    name: Yup.string().required(
      t("news:modalCategory.name") +
      " " +
      t("modalCreateStudent:labels.isRequired")
    )
  });

  /**
   * Save the category
   * @param {*} values 
   */
  const saveCategory = (values) => {
    let payload = {
      name: values.name
    }
    if (values.parentCategory != "") payload.parent = values.parentCategory;
    if (values.parentCategory == "none") payload.parent = 0;
    payload.description = values.description;

    setSaving(true);
    if (categoryInfo.id) {
      payload.id = categoryInfo.id;
      updateCategory(payload);
    } else {
      createCategory(payload);
    }
  }

  /**
   * Create a new category
   * @param {object} values
   */
  const createCategory = (payload) => {
    CreateCategory(payload)
      .then((response) => {
        if (!response.status) {
          setError(response.error.description);
          setTimeout(() => setError(false), 3000);
          return;
        }
        props.getCategoriesPosts();
        props.setShowModalCategory(false);
      }
      )
      .finally(() => {
        setSaving(false);
      });
  }

  /**
   * Update a category
   * @param {object} payload 
   */
  const updateCategory = (payload) => {
    UpdateCategory(payload)
      .then((response) => {
        if (!response.status) {
          setError(response.error.description);
          setTimeout(() => setError(false), 3000);
          return;
        }
        props.getCategoriesPosts();
        props.setShowModalCategory(false);
      }
      )
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <Modal
      show={props.showModalCategory}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header>
        <Modal.Title className="modal-title">
          {categoryInfo.id ?
            t("news:modalCategory.editCategory")
            : t("news:categoriesPage.create")
          }
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={categoryInfo}
        validationSchema={validateSchema}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          !saving ? saveCategory(values) : null;
        }}
      >
        {(formik) => (
          <Form>
            <Modal.Body>
              {/* Name */}
              <Row>
                <Col md={12}>
                  <FieldInput
                    label={t("news:modalCategory.name")}
                    placeholder={t("news:modalCategory.namePlaceholder")}
                    name="name"
                    type="text"
                    required={true}
                    maxlength={200}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormSelect
                    label={t("news:modalCategory.parentCategory")}
                    placeholder={t("news:modalCategory.parentCategoryPlaceholer")}
                    name="parentCategory"
                    options={categoriesList.filter((category) => category.value != categoryInfo.id)}
                    onChange={(event) => {
                      formik.setFieldValue("parentCategory", event.value);
                    }}
                    value={categoriesList.find((category) => category.value == formik.values.parentCategory) || null}
                  />
                </Col>
              </Row>

              {/* Description */}
              <Row>
                <Col md={12}>
                  <FieldInput
                    label={t("news:modalCategory.description")}
                    placeholder={t("news:modalCategory.descriptionPlaceholder")}
                    name="description"
                    type="text"
                    required={false}
                  />
                </Col>
              </Row>
              {error && (
                <Row>
                  <Col md={12}>
                    <Alert variant="warning">{error}</Alert>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={() => props.setShowModalCategory(false)}
                disabled={saving}
              >
                {t("global:buttons.cancel")}
              </Button>
              <Button
                type="submit"
                disabled={saving}
              >
                {saving ?
                  t("global:buttons.saving")
                  :
                  categoryInfo.id ?
                    t("global:buttons.save")
                    :
                    t("news:categoriesPage.create")
                }
              </Button>
            </Modal.Footer>

          </Form>)}
      </Formik>
    </Modal>
  );
}

ModalCategory.propTypes = {
  showModalCategory: PropTypes.bool, //Required
  setShowModalCategory: PropTypes.func, //Required
  categories: PropTypes.array, //Required
  getCategoriesPosts: PropTypes.func, //Required
  categoryDetails: PropTypes.object,
};
