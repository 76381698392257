//Library
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button, Modal, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from 'react-select';
import { getAdditionalFieldsSections, getOrganizationAdditionalFields, UpdateAdditionalFields } from "../../api/StudentsReport";
import ModalConfirmDeletedRecords from "../global/modals/ModalConfirmDeletedRecords";
import TextLoading from "../global/TextLoading";

const stylesSelect = {
  dropdownIndicator: (provided) => ({
    ...provided,
    "svg": {
      fill: "#595e63"
    }
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isSelected ? '#ffd9e0' : 'white',
    '&:hover': {
      backgroundColor: '#fff0f3',
    },
  }),
  control: (baseStyles) =>
  ({
    ...baseStyles,
    borderColor: '#ced4da',
  }),
}

const structureAdditionalFields = {
  section: "",
  fields: []
}

const structureOption = {
  name: "",
  value: "",
  isInvalid: false,
  active: 1
}

const structureCheck = {
  name: "",
  value: 0,
  isInvalid: false,
  active: 1,
  checked: 0,
}

export default function AdditionalFieldsModal(props) {
  const [t] = useTranslation(["student", "global"]);
  const optionsFieldTypes = [
    { value: 'short_answer', label: t("student:ModalAdditionalFields.shortAnswer"), icon: "bi bi-filter-left" },
    { value: 'drop_down', label: t("student:ModalAdditionalFields.dropDownList"), icon: "bi bi-caret-down-square" },
    { value: 'multiple_option', label: t("student:ModalAdditionalFields.multipleChoice"), icon: "bi bi-ui-radios" },
    { value: 'check', label: t("student:ModalAdditionalFields.checkBoxes"), icon: "bi bi-ui-checks" },
  ];
  const structureFieldType = {
    name: "",
    type: { ...optionsFieldTypes[0] },
    required: 0,
    isInvalid: false,
    only_admins_can_fill: 1,
  }

  const [additionalFields, setAdditionalFields] = useState(structureAdditionalFields);
  const [listAdditionalFields, setlistAdditionalFields] = useState([{ ...structureFieldType }]);
  const [sections, setSections] = useState([]);
  const [existingAdditionalFields, setExistingAdditionalFields] = useState([]);
  const [errorAlert, setErrorAlert] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deletionAlert, setDeletionAlert] = useState(false);
  const [saving, setSaving] = useState(false);

  const TooltipWarningType = (
    <Tooltip id="button-tooltip" >
      {t("student:ModalAdditionalFields.typeChangeAlertMessage")}
    </Tooltip>
  );

  useEffect(() => {
    getSections();
    getAdditionalFields();
  }, []);

  /**
   * Get the sections of the additional fields
   */
  const getSections = () => {
    getAdditionalFieldsSections()
      .then((response) => {
        setSections(response.data)
      })
      .catch(() => {
      })
      .finally(() => {
      });
  }

  /**
   * Get the additional fields of the organization
   */
  const getAdditionalFields = () => {
    getOrganizationAdditionalFields()
      .then((response) => {
        setExistingAdditionalFields(response.data)
      })
      .catch(() => {
      })
      .finally(() => {
      });
  }

  /**
   * Add new field
   */
  const addNewField = () => {
    setlistAdditionalFields([{ ...structureFieldType }, ...listAdditionalFields])
  }

  /**
   * Remove a field
   * @param {int} index 
   */
  const removeField = (index) => {
    const isFieldLocal = listAdditionalFields[index].id ? false : true;
    let newList = listAdditionalFields.filter((field, i) => i !== index);
    setlistAdditionalFields(newList)
    if (!isFieldLocal) setDeletionAlert(true);
  }

  /**
   * Change option of the section
   * @param {object} e 
   */
  const changeSeccion = (e) => {
    const fields = { ...additionalFields };
    fields.section = e.target.value;
    setAdditionalFields(fields);
    // load the fields if form has section
    if (e.target.value != "") {
      let listFieldsSection = [];
      if (existingAdditionalFields.length > 0) {
        //Search the fields of the section
        const fieldsSection = existingAdditionalFields.find((field) => field.id == e.target.value);
        if (!fieldsSection) {
          setlistAdditionalFields([{ ...structureFieldType }]);
          return;
        }
        //format the values the fields
        listFieldsSection = fieldsSection.fields.map((field) => {
          return {
            id: field.additional_fields_id,
            name: field.name,
            type: optionsFieldTypes.find((type) => type.value === field.type),
            required: field.required,
            only_admins_can_fill: field.only_admins_can_fill,
            options: field.options ? field.options.map((option) => {
              return {
                name: option.name,
                value: option.value,
                active: option.active,
                checked: option.checked
              }
            }) : null
          }
        })
        setlistAdditionalFields(listFieldsSection)
      }
    }
  }

  /**
   * Change the title of the field
   * @param {*} e 
   * @param {*} index 
   */
  const changeTitleField = (e, index) => {
    if (e.target.value != "" && listAdditionalFields.map((item) => item.name.toLowerCase()).includes(e.target.value.toLowerCase())) {
      let newList = listAdditionalFields.map((item, i) => {
        if (i === index) {
          item.name = e.target.value;
          item.isInvalid = true;
        }
        return item;
      });
      setlistAdditionalFields(newList)
    } else {
      let newList = listAdditionalFields.map((item, i) => {
        if (i === index) {
          item.name = e.target.value;
          item.isInvalid = false;
        }
        return item;
      });
      setlistAdditionalFields(newList)
    }
  }

  /**
   *  Validate the fields of the form
   * @returns {boolean}
   */
  const validateFields = () => {
    if (additionalFields.section === "") return false;
    if (listAdditionalFields.length === 0) return false;
    if (listAdditionalFields.map((item) => item.name).includes("")) return false;
    const typesWithOptions = ["drop_down", "multiple_option", "check"]
    for (let field of listAdditionalFields) {
      if (typesWithOptions.includes(field.type.value)) {
        for (let option of field.options) {
          if (option.name === "" && option.active === 1) return false;
        }
      }
    }
    return true;
  }

  /**
   * Save the additional fields
   * @returns 
   */
  const saveAdditionalFields = (confirmSave = "") => {
    if (saving) return
    //launch alert if there are fields to delete
    if (deletionAlert && confirmSave != "confirm") {
      setShowConfirmDelete(true);
      return;
    }
    setDeletionAlert(false);
    setSaving(true);
    const fields = listAdditionalFields.map((item) => {
      let options = null;
      if (item.type.value != "short_answer") {
        options = item.options.map((option, index) => {
          if (item.type.value === "check") {
            return {
              name: option.name,
              value: item.id ? option.value : index + 1,
              active: option.active,
              checked: option.checked,
            }
          } else {
            return {
              name: option.name,
              value: item.id ? option.value : index + 1,
              active: option.active,
            }
          }
        })
      }
      return {
        additional_fields_id: item.id,
        name: item.name,
        type: item.type.value,
        required: item.required,
        only_admins_can_fill: item.only_admins_can_fill,
        //add options if type is drop_down, multiple_option or check
        options,
      }
    }
    );

    updateAdditionalFields(fields);

  }

  /**
   * Update the additional fields
   * @param {object} fields 
   */
  const updateAdditionalFields = (fields) => {
    const payload = {
      additional_fields_sections_id: additionalFields.section,
      fields: fields,
    }
    UpdateAdditionalFields(payload)
      .then((response) => {
        setSaving(false);
        if (response.description) {
          setErrorAlert(response.description)
          setTimeout(() => {
            setErrorAlert(null)
          }, 5000);
          return;
        }
        props.setAdditonalFieldsSuccess({
          showModal: true,
          data: {
            title: t("student:ModalAdditionalFields.title"),
            update: false,
            message: t("student:ModalAdditionalFields.additionalFieldsCorrectlyUpdated"),
          }
        });
        props.setShowAdditionalFieldsModal(false);
      })
      .catch()
      .finally(() => {
      });
  }

  /**
   * Change the type of the field
   * @param {object} e
   * @param {int} index
   */
  const changeFieldType = (e, index) => {
    let newList = [...listAdditionalFields].map((item, i) => {
      if (i === index) {
        item.type = e;
        switch (e.value) {
          case "short_answer":
            delete item.options
            break;
          case "drop_down":
            item.options = [{ ...structureOption }, { ...structureOption }];
            if (item.id) {
              item.options = item.options.map((option, index) => {
                return {
                  name: "",
                  value: index + 1,
                  isInvalid: false,
                  active: 1
                }
              })
            }
            break;
          case "multiple_option":
            item.options = [{ ...structureOption }, { ...structureOption }];
            if (item.id) {
              item.options = item.options.map((option, index) => {
                return {
                  name: "",
                  value: index + 1,
                  isInvalid: false,
                  active: 1
                }
              })
            }
            break;
          case "check":
            item.options = [{ ...structureCheck }];
            if (item.id) {
              item.options = item.options.map((option, index) => {
                return {
                  name: "",
                  value: index + 1,
                  isInvalid: false,
                  active: 1,
                  checked: 0
                }
              })
            }
            break;
          default:
            break;
        }
      }
      return item;
    });
    setlistAdditionalFields(newList)
  }

  /**
   * Add new option to the field
   * @param {int} fieldPosition
   * @returns
   */
  const addNewOption = (fieldPosition) => {
    return () => {
      let newList = listAdditionalFields.map((item, i) => {
        if (i === fieldPosition) {
          if (item.type.value === "check") {
            let checkOption = { ...structureCheck }
            item.id ? checkOption.value = item.options.length + 1 : null;
            item.options = [...item.options, checkOption]
          } else {
            let option = { ...structureOption }
            item.id ? option.value = item.options.length + 1 : null;
            item.options = [...item.options, option]
          }
        }
        return item;
      });
      setlistAdditionalFields(newList)
    }
  }

  /**
   * Remove an option of the field
   * @param {int} fieldPosition
   * @param {int} optionPosition
   */
  const removeOption = (fieldPosition, optionPosition) => {
    const listUpdated = [...listAdditionalFields].map((item, i) => {
      if (i === fieldPosition) {
        // change the  active the option selected
        for (let j = 0; j < item.options.length; j++) {
          if (j === optionPosition) {
            item.options[j].active = 0;
          }
        }
      }
      return item;
    })
    setlistAdditionalFields(listUpdated)
  }

  /**
   * Change the value of the option
   * @param {Event} e 
   * @param {int} fieldPosition 
   * @param {int} optionPosition 
   */
  const changeValueOption = (e, fieldPosition, optionPosition) => {
    const listUpdated = [...listAdditionalFields].map((item, i) => {
      if (i === fieldPosition) {
        item.options = item.options.map((option, j) => {
          if (j === optionPosition) {
            //filter for options with active = 1
            const optionsActive = item.options.filter((option) => option.active == 1);
            const isInvalid = e.target.value != "" && optionsActive.map((item) => item.name.toLowerCase()).includes(e.target.value.toLowerCase());
            option.name = e.target.value;
            option.isInvalid = isInvalid;
          }
          return option;
        });
      }
      return item;
    })
    setlistAdditionalFields(listUpdated)
  }

  /**
   * Create Element select for the types of the fields
   * @param {obj} field 
   * @param {int} index 
   * @returns JSX.Element
   */
  const selectTypes = (field, index) => {
    return <Form.Group className="mb-3 mt-3" controlId="fieldType">
      <Form.Label className="mb-0">
        {t("student:ModalAdditionalFields.fieldType")}
      </Form.Label>
      <Select
        options={optionsFieldTypes}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        defaultValue={optionsFieldTypes[0]}
        value={field.type}
        styles={stylesSelect}
        formatOptionLabel={
          function (data) {
            return (
              <>
                <i className={data.icon} /> { }
                <span style={{ fontSize: "1rem", fontWeight: "400" }}>
                  {data.label}
                </span>
              </>
            );
          }
        }
        onChange={(e) => changeFieldType(e, index)}
        isDisabled={additionalFields.section == ""}
      />
    </Form.Group>
  }

  /**
   * Evaluate whether to display the deletion option
   * @param {Array} listOptions 
   * @param {object} option 
   * @returns boolean
   */
  const evaluateShowRemoveOption = (listOptions, option) => {
    //count the active options
    let countActive = 0;
    listOptions.forEach((option) => {
      if (option.active == 1) {
        countActive++;
      }
    });
    return countActive <= 2 || (option.active != undefined && option.active == 0)
  }

  const optionsForDropDownAndMultipleOption = (field, index) => {
    let count = 0;
    return field.options.map((option, indexOption) => {
      if (option.active == 1) count++;
      return (
        <>
          <Col md={field.options.length <= 2 ? 12 : 11} key={indexOption} hidden={option.active != undefined && option.active == 0}>
            <Form.Group className="mb-3" controlId={"optionTitle" + indexOption}>
              <Form.Label className="mb-0">{t("student:ModalAdditionalFields.option") + " " + (count)}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("student:ModalAdditionalFields.option") + " " + (count)}
                value={option.name}
                onChange={(e) => changeValueOption(e, index, indexOption)}
                maxLength={30}
                isInvalid={option.isInvalid}
              />
              {option.isInvalid &&
                <Form.Text id="passwordHelpBlock" style={{ color: "#ea2c54" }}>
                  {t("student:ModalAdditionalFields.fieldNameAlreadyExists")}
                </Form.Text>
              }
            </Form.Group>
          </Col>
          {/* remove option */}
          <Col md={1} hidden={evaluateShowRemoveOption(field.options, option)} className="fs-6" style={{ paddingTop: "25px", color: "#5e5e5e" }}>
            <i className="bi bi-dash-circle-fill align-middle"
              role="button"
              style={{ marginLeft: "17px" }}
              onClick={() => removeOption(index, indexOption)}
              disabled={additionalFields.section == ""}
            />
          </Col>
        </>
      )
    })
  }

  const optionsForCheck = (field, index) => {
    let count = 0;
    return field.options.map((option, indexOption) => {
      if (option.active == 1) count++;
      return (
        <>
          <Col md={field.options.length <= 1 ? 12 : 11} key={indexOption} hidden={option.active != undefined && option.active == 0}>
            <Form.Group className="mb-3" controlId={"optionTitle" + indexOption}>
              <Form.Label className="mb-0">{t("student:ModalAdditionalFields.option") + " " + (count)}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("student:ModalAdditionalFields.option") + " " + (count)}
                value={option.name}
                onChange={(e) => changeValueOption(e, index, indexOption)}
                maxLength={30}
                isInvalid={option.isInvalid}
              />
              {option.isInvalid &&
                <Form.Text id="passwordHelpBlock" style={{ color: "#ea2c54" }}>
                  {t("student:ModalAdditionalFields.fieldNameAlreadyExists")}
                </Form.Text>
              }
            </Form.Group>
          </Col>
          {/* remove option */}
          <Col md={1} hidden={evaluateShowRemoveOption(field.options, option)} className="fs-6" style={{ paddingTop: "25px", color: "#5e5e5e" }}>
            <i className="bi bi-dash-circle-fill align-middle"
              role="button"
              style={{ marginLeft: "17px" }}
              onClick={() => removeOption(index, indexOption)}
              disabled={additionalFields.section == ""}
            />
          </Col>
        </>
      )
    })
  }

  return (
    <div>
      <Modal
        show={!showConfirmDelete}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("student:ModalAdditionalFields.title")}
          </Modal.Title>
          <Button variant="outline-secondary" className="end-0 fw-bold" onClick={() => addNewField()}>
            <i className="bi bi-plus-lg" />
            {t("student:ModalAdditionalFields.addField")}
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label            >
              {t("student:ModalAdditionalFields.selectSection")}
            </Form.Label>
            <Form.Select
              id="sectionStudent"
              value={additionalFields.section}
              onChange={changeSeccion}
            >
              <option hidden> {t("student:ModalAdditionalFields.selectSectionPlaceholder")}</option>
              {sections.map((section) => {
                return (
                  <option key={section.id} value={section.id}>{t("student:ModalAdditionalFields." + section.name)}</option>
                )
              })}
            </Form.Select>
          </Form.Group>
          <div className="mb-4">
            {additionalFields.section == 1 ? t("student:ModalAdditionalFields.addFieldMessage") : null}
            {additionalFields.section == 2 ? t("student:ModalAdditionalFields.addFieldMedicalRecordMessage") : null}
          </div>

          <Container style={{ minHeight: "255px", maxHeight: (window.innerHeight / 1.5) + "px", overflow: "auto" }}>
            {listAdditionalFields.map((field, index) => {
              return (
                <Row key={index} className="mb-3" style={{ border: "solid 1.5px #dbdddd", borderRadius: "8px" }}>
                  {/* basic fields */}
                  <Col md={6}>
                    <Form.Group className="mb-3 mt-3" controlId="fieldTitle">
                      <Form.Label className="mb-0">{t("student:ModalAdditionalFields.fieldTitle")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("student:ModalAdditionalFields.fieldTitle")}
                        value={field.name}
                        onChange={(e) => changeTitleField(e, index)}
                        maxLength={30}
                        isInvalid={field.isInvalid}
                        disabled={additionalFields.section == ""}
                      />
                      {field.isInvalid &&
                        <Form.Text id="passwordHelpBlock" style={{ color: "#ea2c54" }}>
                          {t("student:ModalAdditionalFields.fieldNameAlreadyExists")}
                        </Form.Text>
                      }
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    {field.id ? <OverlayTrigger trigger="focus" placement="right" overlay={TooltipWarningType}>
                      {selectTypes(field, index)}
                    </OverlayTrigger>
                      : selectTypes(field, index)
                    }
                  </Col>
                  <Col md={1} className="fs-6 float-end" style={{ paddingTop: "42px", color: "#5e5e5e" }}>
                    <i className="bi bi-dash-circle-fill align-middle" role={additionalFields.section == "" ? null : "button"} style={{ marginLeft: "17px" }}
                      onClick={additionalFields.section == "" ? null : () => removeField(index)}
                    />
                  </Col>
                  {/* Options drop_down type and multiple options*/}
                  {
                    field.type && (field.type.value === "drop_down" || field.type.value === "multiple_option") &&
                    optionsForDropDownAndMultipleOption(field, index)
                  }

                  {/* Options checks */}
                  {field.type && (field.type.value === "check") &&
                    optionsForCheck(field, index)
                  }

                  {field.type && (field.type.value === "drop_down" || field.type.value === "multiple_option" || field.type.value === "check") &&
                    <Col md={12} className="text-danger" >
                      <div onClick={addNewOption(index)} role="button" className="d-inline-block">{t("student:ModalAdditionalFields.addOption")}</div> { }
                      <i onClick={addNewOption(index)} role="button" className="d-inline-block bi bi-plus-circle-fill fs-6 align-middle"></i>
                    </Col>}

                  <Col md={12}>
                    {/* is required */}
                    <div className="float-end">
                      <div className="d-inline-block me-1 align-top" htmlFor="site_state">{t("student:ModalAdditionalFields.required")}</div>
                      <div className="form-check form-switch d-inline-block">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isRequired"
                          onChange={(e) => {
                            let newList = listAdditionalFields.map((item, i) => {
                              if (i === index) {
                                item.required = e.target.checked ? 1 : 0;
                              }
                              return item;
                            });
                            setlistAdditionalFields(newList)
                          }}
                          checked={field.required}
                          disabled={additionalFields.section == ""}
                        />
                      </div>
                    </div>
                    {/* is editing for no admins */}
                    <div className="float-end me-4">
                      <div className="d-inline-block me-1 align-top" htmlFor="site_state">{t("student:ModalAdditionalFields.enablingNoAdminEditing")}</div>
                      <div className="form-check form-switch d-inline-block">
                        {/* 0 is true, 1 is false, so we need to invert the value to show the correct value */}
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="only_admins_can_fill"
                          onChange={(e) => {
                            let newList = listAdditionalFields.map((item, i) => {
                              if (i === index) {
                                item.only_admins_can_fill = e.target.checked ? 0 : 1;
                              }
                              return item;
                            });
                            setlistAdditionalFields(newList)
                          }}
                          checked={field.only_admins_can_fill == 0}
                          disabled={additionalFields.section == ""}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )
            })
            }
          </Container>
          {errorAlert &&
            <div className="alert alert-danger mt-3" role="alert">
              {errorAlert}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => props.setShowAdditionalFieldsModal(false)} disabled={saving}>
            {t("global:buttons.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={saveAdditionalFields}
            disabled={!validateFields() || saving}
          >
            {saving ?
              <TextLoading
                text={t("global:buttons.saving")}
                variant="light"
              />
              : t("global:buttons.save")
            }
          </Button>
        </Modal.Footer>
      </Modal>
      {showConfirmDelete
        ? <ModalConfirmDeletedRecords
          showModalConfirmDeleted={showConfirmDelete}
          setShowModalConfirmDeleted={setShowConfirmDelete}
          showItemList={false}
          confirmAction={() => saveAdditionalFields("confirm")}
          items={[]}
          title={t("student:ModalAdditionalFields.deleteAdditionalFields")}
          message={t("student:ModalAdditionalFields.deleteFieldMessage")}
        />
        : null
      }
    </div >
  );
}

AdditionalFieldsModal.propTypes = {
  showAdditionalFieldsModal: PropTypes.bool,
  setShowAdditionalFieldsModal: PropTypes.func,
  data: PropTypes.object,
  children: PropTypes.node,
  setAdditonalFieldsSuccess: PropTypes.func,
};
