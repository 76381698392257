import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Table, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import * as Sentry from "@sentry/react";
import { multipleBilling } from "../../../api/PaymentsReport";
import PleaseWaitMessageModal from "./PleaseWaitMessageModal";
import ModalSuccessfulBilling from "./ModalSuccessfulBilling";

export default function ModalUnbilledPayments(props) {
  const [t] = useTranslation(["reports", "global"]);
  const [showPleaseWaitMessageModal, setShowPleaseWaitMessageModal] =
    useState(false);
  const [showModalSuccessfulBilling, setShowModalSuccessfulBilling] =
    useState(false);
  const [invoicingErrors, setInvoicingErrors] = useState([]);
  const [missingInvoiced, setMissingInvoiced] = useState([]);
  const [invoicedPaymentIds, setInvoicedPaymentIds] = useState([]);

  /**
   * Change the format type to what it comes with in the api
   * @param {String} date
   * @returns
   */
  const formatDate = (date) => {
    let dateMoment = moment(date, "YYYY-MM-DD");
    let formantDate = dateMoment.format("DD/MM/YYYY");
    return formantDate;
  };

  /**
   * Purpose: Function to generate invoices for selected payments.
   * @param {boolean} missing
   */
  const bill = (missing = false) => {
    props.setShowModalUnbilledPayments(false);
    setShowModalSuccessfulBilling(false);
    setShowPleaseWaitMessageModal(true);
    let ids = [];
    props.billablePayments.forEach((element) => {
      ids.push(element.id);
    });
    let paymentIds = missing ? missingInvoiced : ids;
    props.actionType === "dowload"
      ? props.downloadInvoices(props.idsPrint, true)
      : props.printInvoices(props.confirmations, true);

    let obj = {
      payment_ids: JSON.stringify(paymentIds),
      ignore_not_billed: true,
    };
    multipleBilling(obj).then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        return;
      }
      let data = result.data.data;
      let confirmations = [];
      let errors = [];
      let paymentsId = [];
      let missingBills = [];
      if (data.length) {
        for (let item of data) {
          let invoices = item.invoices;
          for (let bill of invoices) {
            if (bill.error) {
              errors.push(bill.error);
              missingBills.push(bill.payment_id);
            } else {
              confirmations.push(bill.confirmation);
              paymentsId.push(bill.payment_id);
            }
          }
        }
      } else {
        return;
      }

      setInvoicingErrors(errors);
      setMissingInvoiced(missingBills);
      setInvoicedPaymentIds(paymentsId);
      setShowModalSuccessfulBilling(true);
      props.setShowModalUnbilledPayments(false);
      if (paymentsId.length) {
        props.setIdsPrint(props.idsPrint.concat(paymentIds));
        props.setConfirmations(props.confirmations.concat(confirmations));
        props.getPayments();
      }
    });
  };

  /**
   * Purpose: It is used when you click on the button to download the invoice in .zip file asking for the ids.
   */
  const btnCurrentDownload = () => {
    let idsPrint = props.idsPrint;
    for (let payment of props.billablePayments) {
      let position = idsPrint.findIndex((id) => id === payment.id);
      if (position != -1) {
        idsPrint.splice(position, 1);
      }
    }
    props.setIdsPrint(idsPrint);
    props.downloadInvoices(idsPrint);
    props.setShowModalUnbilledPayments(false);
  };

  /**
   * Purpose: When you press the print button it sends you the PDF of the invoice asking for the confirmations.
   */
  const btnCurrentPrint = () => {
    let confirmations = props.confirmations;
    for (let payment of props.billablePayments) {
      let position = confirmations.findIndex(
        (confirmation) => confirmation === payment.confirmation
      );
      if (position != -1) {
        confirmations.splice(position, 1);
      }
    }
    props.setConfirmations(confirmations);
    props.printInvoices(confirmations);
    props.setShowModalUnbilledPayments(false);
  };

  useEffect(() => {
    Sentry.setTag("section", "Unbilled Payments Modal");
  }, []);

  return (
    <div>
      <Modal
        show={props.showModalUnbilledPayments}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("modals.titlePaymentsinvoicing")}
          </Modal.Title>
        </Modal.Header>
        <div className="mx-3 fw-light" style={{ fontSize: "14px" }}>
          {props.actionType === "dowload" ? (
            <p>{t("modals.textDowload")}</p>
          ) : (
            <p>{t("modals.textPaymentsInvoicing")}</p>
          )}
        </div>
        <div className="mx-3 fw-bolder mt-3" style={{ fontSize: "14px" }}>
          <p>{t("modals.BillingModalText")}</p>
        </div>
        <ModalBody className="modal-body mbottom10 pt-0 mt-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th>{t("headerTable.transaction")}</th>
                <th>{t("headerTable.date")}</th>
                <th>{t("headerTable.customer")}</th>
                <th>{t("headerTable.amount")}</th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {props.billablePayments.map((row, index) => (
                <tr key={index}>
                  <td>{row.confirmation}</td>
                  <td> {formatDate(row.data)} </td>
                  <td>{row.students}</td>
                  <td>$ {row.amount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={
              props.nonBillablePayments.length
                ? () => {
                    props.setShowModalPaymentsNonEligibleForBilling(true),
                      props.setShowModalUnbilledPayments(false);
                  }
                : () => {
                    props.setShowModalUnbilledPayments(false);
                    props.clearInvoiceDataModal();
                  }
            }
          >
            {t("global:buttons.backTo")}
          </Button>

          <Button
            variant="light"
            onClick={
              props.actionType === "dowload"
                ? () => btnCurrentDownload()
                : () => btnCurrentPrint()
            }
          >
            {props.actionType === "dowload"
              ? t("modals.btnDowloadInvoices")
              : t("modals.btnPrintInvoiced")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              bill();
            }}
          >
            {t("headerTable.invoice")}
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalSuccessfulBilling
        showModalSuccessfulBilling={showModalSuccessfulBilling}
        setShowModalSuccessfulBilling={setShowModalSuccessfulBilling}
        invoicingErrors={invoicingErrors}
        missingInvoiced={missingInvoiced}
        confirmations={props.confirmations}
        invoicedPaymentIds={invoicedPaymentIds}
        bill={bill}
        billablePayments={props.billablePayments}
        clearInvoiceDataModal={props.clearInvoiceDataModal}
        actionType={props.actionType}
        downloadInvoices={props.downloadInvoices}
        printInvoices={props.printInvoices}
        idsPrint={props.idsPrint}
      />
      <PleaseWaitMessageModal
        showPleaseWaitMessageModal={showPleaseWaitMessageModal}
        setShowPleaseWaitMessageModal={setShowPleaseWaitMessageModal}
        setShowModalInvoicePrinting={props.setShowModalInvoicePrinting}
      />
    </div>
  );
}

ModalUnbilledPayments.propTypes = {
  showModalUnbilledPayments: PropTypes.bool,
  billablePayments: PropTypes.array,
  setShowModalPaymentsNonEligibleForBilling: PropTypes.func,
  nonBillablePayments: PropTypes.array,
  setShowModalUnbilledPayments: PropTypes.func,
  confirmations: PropTypes.array,
  idsPrint: PropTypes.array,
  setIdsPrint: PropTypes.func,
  downloadInvoices: PropTypes.func,
  actionType: PropTypes.string,
  setConfirmations: PropTypes.func,
  printInvoices: PropTypes.func,
  clearInvoiceDataModal: PropTypes.func,
  getPayments: PropTypes.func,
  setShowMailingModal: PropTypes.func,
  setShowModalInvoicePrinting: PropTypes.func,
};
