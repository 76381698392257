import React from "react";
import PropTypes from "prop-types";

export default function GlobalSelectFilter(props) {
  return (
    <div
      style={{ width: "fit-content", margin: "0 auto", marginBottom: "20px" }}
    >
      <p style={{ textAlign: "center" }}>{props.t("globalFilter.date")}</p>
      <input
        placeholder={props.t("globalFilter.placeholderDate")}
        value={props.filter || ""}
        onChange={(e) => props.setFilter(e.target.value)}
      />
    </div>
  );
}

GlobalSelectFilter.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  t: PropTypes.func,
};
