// Import Libraries
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  Col,
  Container,
  Row,
  Alert,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form as FormFormik, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

// Import Custom Components
import { MainContext } from "../../App";
import { goToHref } from "../../components/global/GlobalTools";
import FieldInput from "../../components/global/form/FieldInput";
import FormSelect from "../../components/global/form/FormSelect";
import FormInputLeftDropdown from "../../components/global/form/FormInputLeftDropdown";
import ContentButton from "../../components/global/ContentButton";
import TextLoading from "../../components/global/TextLoading";
import FormBasicTable from "../../components/global/form/FormBasicTable";
import AddScholarshipStudents from "./modals/AddScholarshipStudents";
import FormSchoolCycleFilter from "../../components/global/form/FormSchoolCycleFilter";

// Import API Services
import {
  GetCategories,
  GetSubConcetpsByMultipleConceptIds,
} from "../../api/PaymentsReport";
import {
  GetProductConcepts,
  GetStudentsBySchoolLevelId,
} from "../../api/StudentsReport";
import {
  GetScholarshipById,
  createScholarship,
  updateScholarship,
} from "../../api/Scholarship";

// Set ustom Stylles
const customStyles = {
  container: {
    background: "#fff",
    minHeight: "750px",
    display: "inline-block",
    width: "100%",
    overflowX: "hidden",
  },
  Breadcrumb: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "#2d2d2d",
    userSelect: "none",
    cursor: "pointer",
  },
  basicTable: {
    marginTop: "10px",
    height: "620px",
  },
  circleButton: {
    cursor: "pointer",
    float: "right",
    fontSize: "17px",
    marginTop: "-4px",
  },
  circleDangerButton: {
    color: "var(--red-primary)",
  },
  circleGrayButton: {
    color: "var(--gray-secundary)",
  },
  skeletonText: {
    width: "60%",
    height: "14px",
    marginTop: "20px",
  },
};

const prefix = process.env.REACT_APP_PREFIX;
const services = JSON.parse(localStorage.getItem(`${prefix}Services`));

/**
 * Scholarship Details view
 * @param {*} props Scholarship params
 * @returns Scholarship view
 */
const ScholarshipDetails = (props) => {
  // Get user permissions
  const { permissions } = useContext(MainContext);
  // Get languaje
  const [t] = useTranslation(["global", "scholarship", "modalCreateStudent"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  // Get prop's params
  const { match } = props;
  //Get scholarship id
  const scholarshipId = match.params.id || null;
  // Initializing the discount type
  const scholarshipTypeValues = [
    {
      label: t("scholarship:scholarshipDetails.infoScholarship"),
      value: "scholarship",
    },
    {
      label: t("scholarship:scholarshipDetails.infoDiscount"),
      value: "discount",
    },
  ];
  const sapIsActive = (services.SAP && services.SAP.active) && permissions.sap_reports?.access;

  // Get Hooks
  const history = useHistory();
  const [scholarshipType, setScholarshipType] = useState(null);
  const [schoolCycle, setSchoolCycle] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [previousSchoolCycle, setPreviousSchoolCycle] = useState(null);
  const [concepts, setConcepts] = useState([]);
  const [subconcepts, setSubconcepts] = useState([]);
  const [error, setError] = useState({
    active: false,
    message: "",
    variant: "warning",
  });
  const [loadingConcept, setLoadingConcepts] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingSubconcept, setLoadingSubconcepts] = useState(false);
  const [isPercentage, setIspercentage] = useState(false);
  const [openAddStudents, setOpenAddStudents] = useState(false);
  const discountType = [
    { value: "amount", field: "$" },
    { value: "percentage", field: "%" },
  ];
  const [loadCreateScholarship, setLoadCreateScholarship] = useState(false);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [schoolLevel, setSchoolLevel] = useState(null);
  const [addingStudents, setAddingStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [gradeGroup, setGradeGroup] = useState(null);
  const [scholarshipStudents, setScholarshipStudents] = useState([]);
  const [scholarship, setScholarship] = useState(null);
  const [loadingScholarship, setLoadingScholarship] = useState(false);
  const [schoolCycles, setSchoolCycles] = useState([]);
  const [schoolCyclesBackup, setSchoolCycleBackup] = useState([]);
  const [programLevel, setProgramLevel] = useState(null);
  const [concept, setConcept] = useState(null);
  // Scholarship student head table
  let headTable = [
    {
      name: t("scholarship:scholarshipDetails.fullName"),
      col: 3,
    },
    {
      name: t("scholarship:scholarshipDetails.academicLevel"),
      col: 2,
    },
    {
      name: t("scholarship:scholarshipDetails.program"),
      col: 2,
    },
    {
      name: t("scholarship:scholarshipDetails.grade"),
      col: 2,
    },
    {
      name: t("scholarship:scholarshipDetails.group"),
      col: 2,
    },
    {
      name: "jsx",
      col: 1,
      jsx: (
        <i
          className="bi bi-plus-circle-fill"
          style={{
            ...customStyles.circleDangerButton,
            ...customStyles.circleButton,
          }}
          onClick={() => setOpenAddStudents(!openAddStudents)}
        ></i>
      ),
    },
  ];

  // Scholarship student no content body
  const noContent = (
    <div style={{ textAlign: "center" }}>
      <i className="i bi-people fa-8x"></i>
      <h6>
        <p style={{ fontSize: "14px" }}>
          <b>{t("scholarship:scholarshipDetails.noStudentsAssigned")}</b>
        </p>
      </h6>
    </div>
  );

  // Scholarship params
  const initialValues = {
    id: "",
    scholarshipName: "",
    scholarshipType: "",
    schoolCycleId: "",
    categoryId: "",
    conceptId: "",
    subconceptId: [],
    discount: "",
    discountType: "amount",
  };

  // Form Validations
  const validationSchema = Yup.object().shape({
    scholarshipName: Yup.string().required(
      t("scholarship:scholarshipValidations.scholarshipTitle")
    ),
    scholarshipType: Yup.string().required(
      t("scholarship:scholarshipValidations.benefitType")
    ),
    schoolCycleId: Yup.string().required(
      t("scholarship:scholarshipValidations.schoolCycle")
    ),
    categoryId: Yup.string().required(
      t("scholarship:scholarshipValidations.category")
    ),
    conceptId: Yup.string().required(
      t("scholarship:scholarshipValidations.concept")
    ),
    discount: Yup.string().required(
      t("scholarship:scholarshipValidations.percentageOrAmount")
    ),
  });

  /** Initial values */
  useEffect(() => {
    // Get Scholarship
    if (scholarshipId) {
      setLoadingScholarship(true);
      getScholarship();
    }
  }, []);

  // Functions

  /**
   * Get School cycle categories
   */
  useEffect(() => {
    // Get categories
    if (
      (!previousSchoolCycle && schoolCycle) ||
      (schoolCycle && previousSchoolCycle !== schoolCycle.value)
    ) {
      setPreviousSchoolCycle(schoolCycle.value || null);
      // By the time it is edit, no API requests will be made on the first load
      if (!scholarshipId) {
        setCategory(null);
        getCategories();
      }
    }
  }, [schoolCycle]);

  /**
   * Get categories
   * @param {Object} schoolCycle
   */
  const getCategories = (schoolCycleEvent) => {
    setLoadingCategories(true);
    // Processing parameters to send
    const cycle =
      schoolCycleEvent && schoolCycleEvent.value
        ? schoolCycleEvent
        : schoolCycle;
    let values = {};
    if (cycle && cycle.value) {
      values.school_cycle_id = cycle.value;
    }
    // Making API request
    GetCategories(values)
      .then((data) => {
        // Validating response
        if (data && data.data.data.length !== 0) {
          // Get categories
          const categories = data.data.data;
          // Processing categories
          let formatCategories = [];
          for (let category of categories) {
            const item = {
              label: category.name,
              value: category.id,
            };
            formatCategories.push(item);
          }
          // Saving the processed categories
          setCategories(formatCategories);
        } else {
          // Processing error
          setError({
            active: true,
            message: `${t("scholarship:noDataToDisplay.message")} ${t(
              "scholarship:scholarshipDetails.category"
            )} ${t("scholarship:noDataToDisplay.try")} ${t(
              "scholarship:scholarshipDetails.schoolCycle"
            )}`,
            variant: "warning",
          });
        }
      })
      .catch(() => { })
      .then(() => setLoadingCategories(false));
  };

  /**
   * GET Students by school level id
   * @param {Int} schoolLevelId
   */
  const getStudentsBySchoolLevel = (schoolLevelId) => {
    if (schoolLevelId && schoolLevel && schoolLevel.value) {
      setLoadingStudents(true);
      // Processing parameters to send
      let data = {};
      schoolLevel.value == "higherLevel"
        ? (data.program_level_cycle_id = schoolLevelId.value)
        : (data.grade_group_grade_level_id = schoolLevelId);
      // Making API request
      GetStudentsBySchoolLevelId(data)
        .then((result) => {
          // Validating response
          if (result && result.data && result.data.data) {
            // Get students
            const students = result.data.data;
            let studentList = [];
            // Processing students
            for (const student of students) {
              // Validating that they do not exist in the students already selected
              if (scholarshipStudents && scholarshipStudents.length !== 0) {
                const exists = scholarshipStudents.find(
                  (item) => item.value == student.id
                );
                if (exists) {
                  continue;
                }
              }
              // Get Student full name
              const fullName = `${student.first_name} ${student.middle_name.length !== 0
                ? `${student.middle_name} ${student.last_name}`
                : student.last_name
                }`;
              // Get value
              const value = student.id;
              // Get student school level
              const level = schoolLevel.label;
              // Get student grade
              const grade =
                schoolLevel.value == "higherLevel"
                  ? schoolLevelId.label
                  : student.grade_level;
              // Get student group
              const group = student.group_name;
              // Get program
              const program = student.program_name;
              // Get label
              const label = `${fullName} - ${level} ${schoolLevel.value == "higherLevel"
                ? `${program}`
                : `${grade}${group}`
                }`;
              let item = {
                fullName,
                level,
                program,
                grade,
                group,
                jsx: (
                  <i
                    className="bi bi-dash-circle-fill"
                    style={{
                      ...customStyles.circleGrayButton,
                      ...customStyles.circleButton,
                    }}
                  ></i>
                ),
                value,
                label,
              };
              studentList.push(item);
            }
            // Saving processed students
            setStudents(studentList);
          } else {
            setError({
              active: true,
              message: `${result && result.code
                ? result.description
                : t("scholarship:scholarshipDetails.error")
                }`,
              variant: "warning",
            });
          }
        })
        .catch(() => { })
        .then(() => setLoadingStudents(false));
    }
  };

  /**
   * Add Students to scholarship
   */
  const AddStudents = () => {
    // Validate that there are students to add
    if (addingStudents && addingStudents.length !== 0) {
      // Process students
      for (let item of addingStudents) {
        // Validate that the student to be added does not exist
        const exists = scholarshipStudents.find(
          (element) => element.value == item.value
        );
        // If it exists, it is ignored.
        if (exists) {
          continue;
        }
        // Adding 'ID' to identify that it is already added
        item.id = item.value;
        scholarshipStudents.push(item);
      }
      // Saving new students
      setScholarshipStudents(scholarshipStudents);
      clean();
    }
  };

  /**
   * Clean scholarschip view
   */
  const clean = () => {
    // Closing modal
    setGradeGroup(null);
    setStudents([]);
    setSchoolLevel(null);
    // Clearing the already added students
    setAddingStudents([]);
    setOpenAddStudents(false);
  };

  /**
   * Set formik values
   * @param {*} values
   */
  const setFormikValues = (values) => {
    //Set current school cycle
    if (schoolCycle && values.values.schoolCycleId == "" && !scholarshipId) {
      // Adding current school cycle
      values.setFieldValue("schoolCycleId", schoolCycle.value);
      // Validating category
      if (category) {
        // Adding Category
        values.setFieldValue("categoryId", category.value);
      }
    }

    // Validate the scholarship data, to fill out the form
    if (
      scholarshipId &&
      values.values.id == "" &&
      scholarship &&
      !loadingScholarship
    ) {
      // Processing data
      for (let [key, value] of Object.entries(scholarship)) {
        // Adding the information to the form
        switch (key) {
          case "id":
            values.setFieldValue("id", value);
            break;
          case "concepts":
            if (value && Array.isArray(value) && value.length !== 0) {
              for (let item of value) {
                setConcept({ value: `${item.id}`, label: item.name });
                values.setFieldValue("conceptId", `${item.id}`);
                break;
              }
            }
            break;
          case "discount":
            values.setFieldValue("discount", value);
            break;
          case "discount_type":
            if (value == "percentage") setIspercentage(true);
            values.setFieldValue("discountType", value);
            break;
          case "scholarship_name":
            values.setFieldValue("scholarshipName", value);
            break;
          case "scholarship_type":
            value = {
              label:
                value == "discount"
                  ? t("scholarship:scholarshipDetails.infoDiscount")
                  : t("scholarship:scholarshipDetails.infoScholarship"),
              value: value,
            };
            setScholarshipType(value);
            values.setFieldValue("scholarshipType", value.value);
            break;
          case "school_cycle_id":
            value = schoolCycles.find((item) => item.value == value);
            if (value) {
              setSchoolCycle(value);
              values.setFieldValue("schoolCycleId", value.value);
            }
            break;
          case "subconcepts":
            if (value && Array.isArray(value) && value.length !== 0) {
              values.setFieldValue(
                "subconceptId",
                value.map((subconcept) => {
                  return {
                    value: `${subconcept.id}`,
                    label: subconcept.name,
                    conceptId: subconcept.concept_id,
                    schoolCycleId: scholarship.school_cycle_id,
                  };
                })
              );
            }
            break;
          case "category":
            setCategory({ value: value.id, label: value.name });
            values.setFieldValue("categoryId", value.id);
            break;
          case "students":
            if (value) {
              value = value.map((student) => {
                // Get Student full name
                const fullName = student.name;
                // Get value
                const value = student.id;
                // Get student school level
                const level = student.school_level;
                // Get student grade
                const grade = student.grade || student.program_level;
                // Get student group
                const group = student.group;
                // Get program name
                const program = student.program_name;
                // Get label
                const label = `${fullName} - ${level} ${level == "Licenciatura"
                  ? `${grade} ${group}`
                  : `${grade}${group}`
                  }`;
                return {
                  id: value,
                  fullName,
                  level,
                  program,
                  grade,
                  group,
                  jsx: (
                    <i
                      className="bi bi-dash-circle-fill"
                      style={{
                        ...customStyles.circleGrayButton,
                        ...customStyles.circleButton,
                      }}
                    ></i>
                  ),
                  value,
                  label,
                };
              });
              setScholarshipStudents(value);
            }
            break;
          case "bank_account_id":
            values.setFieldValue("bankAccountId", value);
            break;
          default:
            break;
        }
      }
    }

    // Validating the discount type
    if (values.values.discountType == "percentage" && !isPercentage) {
      //isPercentage,
      setIspercentage(true);
    } else if (values.values.discountType == "amount" && isPercentage) {
      setIspercentage(false);
    }
  };

  /**
   * Get Concepts by Category id
   * @param {Object} categoryId
   */
  const getConcepts = (categoryId) => {
    setLoadingConcepts(true);
    // Processing parameters to send
    let values = {};
    if (categoryId) {
      values.product_category_id = categoryId;
    }
    // Making API request
    GetProductConcepts(values)
      .then((data) => {
        // Validating response
        if (data && data.data && data.data.data.length !== 0) {
          // Processing response
          const concepts = data.data.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          // Salving concepts
          setConcepts(concepts);
        }
        // Processing error
        else
          setError({
            active: true,
            message: `${t("scholarship:noDataToDisplay.message")} ${t(
              "scholarship:scholarshipDetails.concept"
            )} ${t("scholarship:noDataToDisplay.try")} ${t(
              "scholarship:scholarshipDetails.category"
            )}`,
            variant: "warning",
          });
      })
      .catch(() => { })
      .then(() => setLoadingConcepts(false));
  };

  /**
   * Get sub concepts
   * @param {Int} conceptId
   */
  const getSubconcepts = (conceptId) => {
    setLoadingSubconcepts(true);
    // Processing parameters to send
    if (conceptId) {
      const values = {
        concept_ids: [conceptId],
        school_cycle_id: schoolCycle.value,
      };
      // Making API request
      GetSubConcetpsByMultipleConceptIds(values)
        .then((data) => {
          // Validating response
          if (data && data.data.data.length !== 0) {
            // Processing response
            let subconcepts = data.data.data.map((item) => {
              return {
                id: item.id,
                label: item.name,
                value: item.id,
                conceptId: item.product_concept_id,
                schoolCycleId: item.school_cycle_id,
              };
            });
            // Salving subconcepts
            setSubconcepts(subconcepts);
          } else {
            // Processing error
            setError({
              active: true,
              message: `${t("scholarship:noDataToDisplay.message")} ${t(
                "scholarship:scholarshipDetails.subconcept"
              )} ${t("scholarship:noDataToDisplay.try")} ${t(
                "scholarship:scholarshipDetails.concept"
              )}`,
              variant: "info",
            });
          }
        })
        .catch(() => { })
        .then(() => setLoadingSubconcepts(false));
    }
  };

  /**
   * Remove the student from the modal or table
   * @param {Object} student params
   */
  const removeStudents = (student) => {
    // Validating the students to remove
    if (student && student.id) {
      // Verifying that it exists
      const index = scholarshipStudents.findIndex(
        (item) => item.value == student.value
      );
      // Removing from the table
      if (index !== -1) {
        scholarshipStudents.splice(index, 1);
        // saved rest
        setScholarshipStudents(scholarshipStudents);
        // Deleting id
        delete student.id;
      }
    } else {
      // Verifying that it exists
      const index = addingStudents.findIndex(
        (item) => item.value == student.value
      );
      if (index !== -1) {
        // Removing from the Modal
        addingStudents.splice(index, 1);
        // saved rest
        setAddingStudents(addingStudents);
      }
    }
    // Adding back to selector
    let newStudents = [...students];
    const exist = newStudents.find((item) => item.value == student.value);
    if (!exist) {
      newStudents.push(student);
      setStudents(newStudents);
    }
  };

  /**
   * Craete or Update a Scholarship
   * @param {Object} values  Scholarship info
   */
  const createUpdateScholarship = (values) => {
    setLoadCreateScholarship(true);
    // Get action
    let action = values && values.id ? "update" : "create";
    // Format params
    let data = {};
    for (const [key, value] of Object.entries(values)) {
      switch (key) {
        case "id":
          if (action == "update") {
            data.scholarship_id = value;
          }
          break;
        case "conceptId":
          data.concept_ids = [value];
          break;
        case "discount":
          data.discount = value;
          break;
        case "discountType":
          data.discount_type = value;
          break;
        case "scholarshipName":
          data.scholarship_name = value;
          break;
        case "scholarshipType":
          data.scholarship_type = value;
          break;
        case "schoolCycleId":
          data.school_cycle_id = value;
          break;
        case "subconceptId":
          if (
            Array.isArray(value) &&
            (value.length == 0 ||
              (value.length == 1 && value[0].value == "all"))
          ) {
            let ids = [];
            if (subconcepts.length !== 0) {
              subconcepts.forEach((item) => {
                if (item.id) ids.push(item.id);
              });
            }
            ids.length !== 0 ? (data.subconcept_ids = ids) : null;
          } else {
            data.subconcept_ids = value.map((subconcept) => {
              return subconcept.value;
            });
          }
          break;
        case "categoryId":
          data.category_id = value;
          break;
        default:
          break;
      }
    }
    // Format scholarship students
    if (scholarshipStudents.length != 0) {
      data.students_ids = scholarshipStudents.map((student) => {
        return student.value;
      });
    } else {
      data.students_ids = [];
    }
    // Add bank account id if SAP is active
    if (sapIsActive && values.bankAccountId != null) {
      data.bank_account_id = values.bankAccountId;
    }
    // Create or Update a Scholarship
    action = action == "create" ? createScholarship : updateScholarship;
    action(data)
      .then((result) => {
        //loadCreateScholarship
        setLoadCreateScholarship(true);
        if (result && result.data) {
          history.push("/payments/typeScholarships");
        } else {
          setError({
            active: true,
            message: `${result && result.code
              ? result.description
              : t("scholarship:scholarshipDetails.error")
              }`,
            variant: "warning",
          });
        }
      })
      .catch()
      .then(() => setLoadCreateScholarship(false));
  };

  /**
   * Clean error message
   */
  useEffect(() => {
    if (error.active) {
      setTimeout(() => {
        setError({ active: false, message: "", variant: "warning" });
      }, 3500);
    }
  }, [error]);

  /**
   * Get Scholarship details by ID
   */
  const getScholarship = () => {
    setLoadingScholarship(true);
    GetScholarshipById(scholarshipId)
      .then((result) => {
        if (result && result.data) {
          setScholarship(result.data);
        } else {
          history.push("/payments/typeScholarships");
        }
      })
      .catch(() => { })
      .finally(() => setLoadingScholarship(false));
  };

  /**
   * Scholarship details view
   */
  return permissions.payments_scholarships.access ||
    permissions.payments_scholarships.create ||
    permissions.payments_scholarships.edit ? (
    <>
      {/**Scholarship container */}
      <Container fluid>
        {/**Scholarship breadcrum */}
        <Breadcrumb>
          <Breadcrumb.Item href="#" active>
            <p
              onClick={() => history.push("/payments/typeScholarships")}
              style={customStyles.Breadcrumb}
            >
              {`<${t("breadcrumds.goBack")}`}
            </p>
          </Breadcrumb.Item>
        </Breadcrumb>
        {/**Scholarship details */}
        <div className="mx-3 mt-3">
          <Row style={customStyles.container}>
            <Col lg={12} md={12} sm={12} xs={12} style={{ padding: "20px" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={createUpdateScholarship}
              >
                {(values) => (
                  setFormikValues(values),
                  (
                    <FormFormik>
                      <Row>
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          style={{
                            borderRight: "1px solid #d6dadb",
                            height: "600px",
                          }}
                        >
                          <Row>
                            {/**Scholarship label */}
                            <Col lg={12} md={12} sm={12} xs={12}>
                              <div className="mt-0">
                                <h5>
                                  {t("scholarship:scholarshipDetails.title")}
                                </h5>
                              </div>
                            </Col>
                            {loadingScholarship ? (
                              <React.Fragment>
                                {/**Skeleton loading */}
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                  (index) => {
                                    const size =
                                      index == 1 || index == 8 ? 12 : 6;
                                    return (
                                      <React.Fragment key={index}>
                                        <Col lg={size} sm={size}>
                                          {index == 8 ? (
                                            <p
                                              className="skeleton"
                                              style={customStyles.skeletonText}
                                            ></p>
                                          ) : (
                                            <>
                                              <div className="mt-0">
                                                <p
                                                  className="skeleton"
                                                  style={{
                                                    width: "25%",
                                                    height: "14px",
                                                    marginTop: "20px",
                                                  }}
                                                ></p>
                                              </div>
                                              <div
                                                className="skeleton"
                                                style={{
                                                  width: "100%",
                                                  height: "35px",
                                                }}
                                              ></div>
                                            </>
                                          )}
                                        </Col>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {/**Scholarship title */}
                                <Col lg={12} sm={12} me={12} xs={12}>
                                  <FieldInput
                                    label={t(
                                      "scholarship:scholarshipDetails.scholarshipTitle"
                                    )}
                                    capitalize={false}
                                    name="scholarshipName"
                                    type="text"
                                    placeholder={t(
                                      "scholarship:scholarshipDetails.scholarshipTitle"
                                    )}
                                    required={true}
                                    showRequired={true}
                                  />
                                </Col>
                                {/**School benefit type */}
                                <Col lg={6} md={6} sm={12} xs={12}>
                                  <FormSelect
                                    label={t(
                                      "scholarship:scholarshipDetails.benefitType"
                                    )}
                                    placeholder={t(
                                      "scholarship:scholarshipDetails.benefitType"
                                    )}
                                    name="scholarshipType"
                                    options={scholarshipTypeValues}
                                    onChange={(event) => {
                                      setScholarshipType(event);
                                      values.setFieldValue(
                                        "scholarshipType",
                                        event.value
                                      );
                                    }}
                                    value={scholarshipType}
                                    showRequired={true}
                                    required={true}
                                  />
                                </Col>
                                {/**Scholarship school cycle */}
                                <Col lg={6} md={6} sm={12} xs={12}>
                                  <FormSchoolCycleFilter
                                    label={t(
                                      "scholarship:scholarshipDetails.schoolCycle"
                                    )}
                                    name="schoolCycleId"
                                    showRequired={true}
                                    required={true}
                                    setSchoolCycle={setSchoolCycle}
                                    value={schoolCycle}
                                    onChange={(event) => {
                                      setSchoolCycle(event);
                                      if (
                                        !schoolCycle ||
                                        (schoolCycle &&
                                          schoolCycle.value &&
                                          event.value !== schoolCycle.value)
                                      ) {
                                        setCategories([]);
                                        setConcepts([]);
                                        setSubconcepts([]);
                                        setCategory(null);
                                        setConcept(null);
                                        values.setFieldValue("categoryId", "");
                                        values.setFieldValue("conceptId", "");
                                        values.setFieldValue(
                                          "subconceptId",
                                          []
                                        );
                                        values.setFieldValue(
                                          "schoolCycleId",
                                          event.value
                                        );
                                        if (scholarshipId) {
                                          getCategories(event);
                                        }
                                      }
                                    }}
                                    currentCycle={!scholarshipId}
                                    setSchoolLevels={setSchoolLevels}
                                    schoolLevel={schoolLevel}
                                    isDisabled={
                                      scholarshipId || loadingCategories
                                    }
                                    schoolCycles={schoolCycles}
                                    setSchoolCycles={setSchoolCycles}
                                    schoolCyclesBackup={schoolCyclesBackup}
                                    setSchoolCycleBackup={setSchoolCycleBackup}
                                  />
                                </Col>
                                {/**Scholarship payment categories */}
                                <Col lg={6} md={6} sm={12} xs={12}>
                                  <div
                                    onClick={() => {
                                      // By the time it is edit, no API requests will be made on the first load
                                      if (
                                        scholarshipId &&
                                        categories.length == 0
                                      ) {
                                        getCategories();
                                      }
                                    }}
                                  >
                                    <FormSelect
                                      label={t(
                                        "scholarship:scholarshipDetails.category"
                                      )}
                                      placeholder={t(
                                        "scholarship:scholarshipDetails.category"
                                      )}
                                      name="categoryId"
                                      options={categories}
                                      onChange={(event) => {
                                        setCategory(event);
                                        getConcepts(event.value);
                                        values.setFieldValue(
                                          "categoryId",
                                          event.value
                                        );
                                        values.setFieldValue("conceptId", "");
                                        setConcept(null);
                                        values.setFieldValue(
                                          "subconceptId",
                                          []
                                        );
                                      }}
                                      value={category}
                                      showRequired={true}
                                      required={true}
                                      isLoading={loadingCategories}
                                      isDisabled={
                                        values.values.schoolCycleId == ""
                                      }
                                    />
                                  </div>
                                </Col>
                                {/**Schilarship payment concepts */}
                                <Col lg={6} md={6} sm={12} xs={12}>
                                  <div
                                    onClick={() => {
                                      // By the time it is edit, no API requests will be made on the first load
                                      if (
                                        scholarshipId &&
                                        concepts.length == 0
                                      ) {
                                        getConcepts(values.values.categoryId);
                                      }
                                    }}
                                  >
                                    <FormSelect
                                      label={t(
                                        "scholarship:scholarshipDetails.concept"
                                      )}
                                      placeholder={t(
                                        "scholarship:scholarshipDetails.concept"
                                      )}
                                      name="conceptId"
                                      options={concepts}
                                      onChange={(event) => {
                                        if (
                                          !concept ||
                                          (concept &&
                                            concept.value !== event.value)
                                        ) {
                                          getSubconcepts(event.value);
                                          values.setFieldValue("subconceptId", [
                                            {
                                              label: t(
                                                "scholarship:scholarshipDetails.all"
                                              ),
                                              value: "all",
                                            },
                                          ]);
                                          setConcept(event);
                                          values.setFieldValue(
                                            "conceptId",
                                            event.value
                                          );
                                        }
                                      }}
                                      value={concept}
                                      showRequired={true}
                                      required={true}
                                      isDisabled={
                                        values.values.categoryId == ""
                                      }
                                      isLoading={loadingConcept}
                                    />
                                  </div>
                                </Col>
                                {/**Scholarshipt payment subconcepts */}
                                <Col lg={6} md={6} sm={12} xs={12}>
                                  <div
                                    onClick={() => {
                                      // By the time it is edit, no API requests will be made on the first load
                                      if (
                                        scholarshipId &&
                                        subconcepts.length == 0
                                      ) {
                                        getSubconcepts(values.values.conceptId);
                                      }
                                    }}
                                  >
                                    <FormSelect
                                      label={t(
                                        "scholarship:scholarshipDetails.subconcept"
                                      )}
                                      placeholder={t(
                                        "scholarship:scholarshipDetails.subconcept"
                                      )}
                                      name="subconceptId"
                                      options={subconcepts}
                                      onChange={(event) => {
                                        // Removing default pill
                                        if (event && event.length >= 2) {
                                          const findAll = event.findIndex(
                                            (item) => item.value == "all"
                                          );
                                          if (findAll !== -1) {
                                            event.splice(findAll, 1);
                                          }
                                        }
                                        values.setFieldValue(
                                          "subconceptId",
                                          event
                                        );
                                      }}
                                      value={values.values.subconceptId}
                                      showRequired={false}
                                      required={false}
                                      isDisabled={values.values.conceptId == ""}
                                      isMulti
                                      className="scholarship-select"
                                      isLoading={loadingSubconcept}
                                    />
                                  </div>
                                </Col>
                                {/**Scholarship discount */}
                                <Col lg={6} md={6} sm={12} xs={12}>
                                  <FormInputLeftDropdown
                                    label={t(
                                      "scholarship:scholarshipDetails.percentageOrAmount"
                                    )}
                                    name="discount"
                                    placeholder={
                                      values.values.discountType == "amount"
                                        ? "$ 0.00"
                                        : "0.00 %"
                                    }
                                    required={true}
                                    showRequired={true}
                                    dropdownlabel={
                                      values.values.discountType == "amount"
                                        ? "$"
                                        : "%"
                                    }
                                    dropdownfield="discountType"
                                    dropdownmenu={discountType}
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      if (
                                        values.values.discountType == "amount"
                                      ) {
                                        const discount = value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                        values.setFieldValue(
                                          "discount",
                                          discount
                                        );
                                      } else if (
                                        values.values.discountType ==
                                        "percentage" &&
                                        value <= 100
                                      ) {
                                        const percentage = value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                        values.setFieldValue(
                                          "discount",
                                          percentage
                                        );
                                      } else {
                                        values.setFieldValue("discount", "");
                                      }
                                    }}
                                  />
                                </Col>
                                {sapIsActive && (
                                  <Col lg={6} md={6} sm={12} xs={12}>
                                    <FieldInput
                                      label={t(
                                        "scholarship:scholarshipDetails.accountingAccountID"
                                      )}
                                      capitalize={false}
                                      name="bankAccountId"
                                      type="text"
                                      placeholder={t(
                                        "scholarship:scholarshipDetails.accountingAccountID"
                                      )}
                                      maxLength={100}
                                      onChange={(event) => {
                                        let value = event.target.value;
                                        value = value.replace(/ /g, "");
                                        value = value.replace(/[^0-9a-zA-Z]/g, "");
                                        values.setFieldValue(
                                          "bankAccountId",
                                          value
                                        );
                                      }}
                                    />
                                  </Col>
                                )}
                              </React.Fragment>
                            )}
                            {/**Scholarshipt warning errors */}
                            {error && error.active && (
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <div style={{ marginTop: "20px" }}>
                                  <Alert variant={error.variant}>
                                    {error.message}
                                  </Alert>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <div
                            style={{ marginLeft: "15px", marginRight: "20px" }}
                          >
                            <Row>
                              {/**Scholarship label */}
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="mt-0">
                                  <h5>
                                    {t(
                                      "scholarship:scholarshipDetails.scholarshipStudents"
                                    )}
                                  </h5>
                                </div>
                              </Col>
                              {/**Scholarship students */}
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <div style={customStyles.basicTable}>
                                  <FormBasicTable
                                    head={headTable}
                                    noContent={noContent}
                                    body={scholarshipStudents}
                                    removeStudents={removeStudents}
                                    loading={loadingScholarship}
                                  />
                                </div>
                              </Col>
                              {/**Content acction's buttons */}
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <ContentButton
                                  className="content-button"
                                  disabled={loadCreateScholarship}
                                >
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                      setScholarshipType(null);
                                      setSchoolCycle(null);
                                      setCategory(null);
                                      values.handleReset();
                                      // Set to schoolarships type
                                      history.push(
                                        "/payments/typeScholarships"
                                      );
                                    }}
                                  >
                                    {t("global:buttons.cancel")}
                                  </Button>
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loadCreateScholarship}
                                  >
                                    {loadCreateScholarship ? (
                                      <TextLoading
                                        text={t("global:buttons.saving")}
                                        variant="light"
                                      />
                                    ) : (
                                      t("global:buttons.save")
                                    )}
                                  </Button>
                                </ContentButton>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <AddScholarshipStudents
                        open={openAddStudents}
                        schoolCycle={schoolCycle}
                        schoolLevels={schoolLevels}
                        setSchoolLevel={setSchoolLevel}
                        schoolLevel={schoolLevel}
                        addingStudents={addingStudents}
                        setAddingStudents={setAddingStudents}
                        students={students}
                        setStudents={setStudents}
                        loadingStudents={loadingStudents}
                        setLoadingStudents={setLoadingStudents}
                        getStudentsBySchoolLevel={getStudentsBySchoolLevel}
                        gradeGroup={gradeGroup}
                        setGradeGroup={setGradeGroup}
                        AddStudents={AddStudents}
                        clean={clean}
                        removeStudents={removeStudents}
                        programLevel={programLevel}
                        setProgramLevel={setProgramLevel}
                      />
                    </FormFormik>
                  )
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  ) : (
    goToHref("/landing_page")
  );
};

export default ScholarshipDetails;

ScholarshipDetails.propTypes = {
  match: PropTypes.object,
};
