import axios from "axios";
import { validateErrorResponse } from "./AxiosExtension";
const prefix = process.env.REACT_APP_PREFIX;
let axiosOptions = {};

// BlogPosts
/**
 *  Purpose: This function is used to get the blog posts
 * @returns
 */
export const getBlogPosts = async (data) => {
  axiosOptions = {}
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

export const CreateBlogPosts = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

export const DeleteBlogPosts = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

export const UpdateBlogPosts = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/update`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

// Authors
export const getAuthorBlogPosts = async (data) => {
  axiosOptions = {}
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/authors`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
}

export const CreateAuthor = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/authors`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

// Categories
export const GetCategoriesPosts = async (data) => {
  axiosOptions = {}
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/categories`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
}

export const CreateCategory = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/categories`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

export const UpdateCategory = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/categories`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

export const DeleteCategories = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/categories`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

// Tags
export const GetTagsPosts = async (data) => {
  axiosOptions = {}
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/tags`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
}

export const CreateTags = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/tags`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

export const UpdateTags = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/tags`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

export const DeleteTags = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_SITES}/v1/blog_posts/tags`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data;
  }
};

// Tickers
export const GetTickers = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/news_tickers`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

export const CreateTicker = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/news_tickers`;
  axiosOptions.data = data;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const UpdateTicker = async (data) => {
  let axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/news_tickers`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const DeleteTickers = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/news_tickers`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};