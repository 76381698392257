import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/** Elements */
import ContentButton from "../../components/global/ContentButton";
export default function ModalOperationNotAllowed(props) {
  const [t] = useTranslation(["crm", "global"]);

  /**
   * Purpose: Close the  modal
   */
  const cancel = () => {
    props.setShowModalOperationNotAllowed(false);
  };

  return (
    <Modal
      show={props.showModalOperationNotAllowed}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">
          {t("crm:modalOperationNotAllowed.operationNotAllowedTitle")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          props.statusToChange === "archived"
            ? "crm:modalOperationNotAllowed.operationNotAllowedForArchived"
            : "crm:modalOperationNotAllowed.operationNotAllowedForContact"
        )}
      </Modal.Body>
      <Modal.Footer>
        <ContentButton className="content-button">
          <Button onClick={cancel}>{t("modalAddProspect.btnClose")}</Button>
        </ContentButton>
      </Modal.Footer>
    </Modal>
  );
}

ModalOperationNotAllowed.propTypes = {
  showModalOperationNotAllowed: PropTypes.bool,
  setShowModalOperationNotAllowed: PropTypes.func,
  statusToChange: PropTypes.string,
};
