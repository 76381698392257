import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";

let axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "get",
};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Save the configured Reminder
 * @param {Object} data
 * @returns
 */
export const SaveReminder = async (data) => {
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/reminders`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Send payment reminders
 * @param {Object} data
 * @returns
 */
export const SendReminder = async (data) => {
  let sendData = {};
  sendData.student_ids = data.paymentIds;
  sendData.charges_without_expiration_date = data.chargesExpirationDate;
  if (data.reminder.title != null && data.reminder.title != "") {
    sendData.title = data.reminder.title;
  }
  if (data.reminder.description != null && data.reminder.description != "") {
    sendData.description = data.reminder.description;
  }
  axiosOptions.method = "post";
  axiosOptions.data = sendData;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/reminders/send`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the payment reminder
 * @returns
 */
export const GetReminder = async () => {
  let data = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/reminders`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};
