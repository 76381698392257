import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import { inactivateStaff } from "../../../api/Staff";

export default function ModalInactive(props) {
  const [t] = useTranslation(["staff", "global", "modalCreateStudent"]);
  const count = props.selectedRowIds.length;
  const [inactivating, setInactivating] = useState(false);

  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Inactive Staff Modal");
  }, []);

  /**
   * Service to inactivate staff
   */
  const inactiveStaff = () => {
    setInactivating(true);
    const usersToDelete = props.selectedRowIds.map((item) => item.original.id);
    let countInactive = 0;
    const total = usersToDelete.length - 1;
    for (let userId of usersToDelete) {
      let request = {
        user_id: userId,
      };
      inactivateStaff(request)
        .then(() => {
          if (countInactive >= total) {
            props.listStaff();
            setInactivating(false);
            props.setShowModalInactive(false);
          }
          countInactive++;
        })
        .catch((error) => {
          Sentry.captureException(Error(JSON.stringify(error)));
          throw new Error(error);
        });
    }
  };

  return (
    <div>
      <Modal
        show={props.showModalInactive}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Title className="modal-title ms-3 mt-3">
          {t("modalConfirmationInactive.titleInactive")}
        </Modal.Title>
        <Modal.Body>
          <div>
            <p
              className="mt-4"
              style={{ fontWeight: "lighter", fontSize: "14px" }}
            >
              {t("modalConfirmationInactive.textInactive", { count })}
            </p>
            {/* Map names to be inactivated */}
            <Row>
              <Col md={12} style={{ maxHeight: "300px", overflow: "auto" }}>
                {props.selectedRowIds.map((item) => {
                  return <p key={item}>{"- " + item.values.name}</p>;
                })}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => props.setShowModalInactive(false)}
            disabled={inactivating}
          >
            {t("global:buttons.close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => inactiveStaff()}
            disabled={inactivating}
          >
            {t("global:buttons.inactivate")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalInactive.propTypes = {
  setShowModalInactive: PropTypes.func,
  showModalInactive: PropTypes.bool,
  selectedRowIds: PropTypes.array,
  listStaff: PropTypes.func,
};
