import { React } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function ModalComplete(props) {
  const [t] = useTranslation(["scholarships", "global"]);
  return (
    <div>
      <Modal
        show={props.showModalComplete}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="pb-0">
          <Modal.Title className="modal-title">
            {t("textModal.titleCompleteAssignment")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p>
                {t("textModal.textscholarshipStudent", {
                  count: props.studentAssignetComplete.assigned,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>
                {t("textModal.textSelectedScholarship", {
                  count: props.studentAssignetComplete.already_assigned,
                })}
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => props.setShowModalComplete(false)}
          >
            {t("global:buttons.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalComplete.propTypes = {
  showModalComplete: PropTypes.bool,
  setShowModalComplete: PropTypes.func,
  studentAssignetComplete: PropTypes.object,
};
