/**
 *     ** Readme **
 *
 * Component: <ExportToExcel />
 * Purpose: Generate the file in excel and download it
 *
 * Props:
 *   -exportOptions: {Object} Contains configuration file
 *   -exportData: {Array} Contains the values that the excel file will contain
 *   -printExport: {Boolean} Disable the button
 *   -typeElement: {String} Type of element returned by the component, values: button | dropdownItem
 *
 *
 * Creation date: 04/January/2022
 * Last update: 24/February/2022
 */

/**
 *
 */

import React, { useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ExcellentExport from "excellentexport";

export default function ExportToExcel(props) {
  const [t] = useTranslation(["global"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module

  //Export to Excel
  const downloadFile = () => {
    ExcellentExport.convert(props.exportOptions, props.exportData);
  };

  /**Initial loading */
  useEffect(() => {}, []);

  return props.typeElement == "button" ||
    props.typeElement == "" ||
    props.typeElement == undefined ? (
    <Button
      onClick={downloadFile}
      variant="outline-secondary"
      disabled={props.printExport}
    >
      <i className="bi bi-file-earmark-spreadsheet"></i>
      {t("buttons.export")}
    </Button>
  ) : props.typeElement == "dropdownItem" ? (
    <Dropdown.Item onClick={downloadFile}>
      {t("buttons.downloadExcel")}
    </Dropdown.Item>
  ) : (
    ""
  );
}

ExportToExcel.propTypes = {
  exportOptions: PropTypes.object,
  exportData: PropTypes.array,
  printExport: PropTypes.bool,
  typeElement: PropTypes.string,
};
