import React from "react";
import PropTypes from "prop-types";

/**
 * Purpose: Elemento para mostrar los iconos del menu
 * @param {*} props
 * @returns
 */
export default function ServiceIcon(props) {
  return (
    <div className={props.validation}>
      <img
        src={props.image}
        id={props.id}
        className={props.className}
        style={props.style}
      />
      <br />
      <p style={{ fontSize: "1.2300123001230012vh" }}>{props.title}</p>
    </div>
  );
}

ServiceIcon.propTypes = {
  image: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  validation: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};
