import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "react-bootstrap";
export default function ModalEditColumns(props) {
  const [isSelecting, setIsSelecting] = useState(false);
  const [allColumns, setAllColumns] = useState(props.allColumns); //Contains all columns
  const [allColumnsUpdate, setAllColumnsUpdate] = useState(props.allColumns); //Contains the array with the change of the "isVisible" property of the hidden columns
  const [updateHiddenColumns, setUpdateHiddenColumns] = useState(
    props.hiddenColumns
  ); //Contains the array that is updated with the new columns to hide
  const [flagUpdateColumns, setFlagUpdateColumns] = useState(false); //Flag to update the columns in the .map

  /**
   * Purpose: Find the id of the column in the column array
   * @param {Object} columnId
   * @returns Returns the object of the found column
   */
  const findColumn = (columnId) => {
    return allColumns.findIndex(function (column) {
      return column.id === columnId;
    });
  };

  /**
   * Purpose: Select the columns to be displayed in the report
   * @param {Object} event
   * @param {Object} column
   */
  const toggleHiding = (event, column) => {
    if (isSelecting == false) {
      setIsSelecting(true);
    }
    let index = findColumn(column.id); //Gets the index in the array of "allColumns"
    let allColumnsUpdate = [...allColumns]; //Create a copy of "allColumns"
    allColumnsUpdate[index].isVisible = event.target.checked; //Update the checked property
    setFlagUpdateColumns(!flagUpdateColumns); //Change the flag to update the array "allColumns"
    setAllColumnsUpdate(allColumnsUpdate); //Contains the array with the change of hidden columns

    index = updateHiddenColumns.indexOf(column.id);
    let columns = [...updateHiddenColumns];
    if (event.target.checked) {
      if (index != -1) {
        //Delete the item
        columns.splice(index, 1);
      }
    } else {
      if (index == -1) {
        //Add an item
        columns.push(column.id);
      }
    }
    setUpdateHiddenColumns(columns); //Update the array with the new columns to hide
  };

  /**
   * Purpose: Update the report with the selected columns to display
   */
  const update = () => {
    let hiddenColumns = isSelecting ? updateHiddenColumns : props.hiddenColumns;
    let allColumns = isSelecting ? allColumnsUpdate : props.allColumns;
    let columns = isSelecting ? allColumnsUpdate : props.columns;

    if (isSelecting == false) {
      setUpdateHiddenColumns(props.hiddenColumns);
    }
    setAllColumns(allColumns);
    props.setColumns(columns); //Update the columns that were hidden
    props.setHiddenColumns(hiddenColumns); //Array with the id of the columns to hide
    props.setShowEditColumns(false); //Close the table headers modal
    setIsSelecting(false);
  };

  /**
   * Purpose: Close the "Edit Columns" modal
   */
  const cancel = () => {
    setAllColumns(props.allColumns);
    props.setColumns(props.columns); //Update the columns that were hidden
    props.setHiddenColumns(props.hiddenColumns); //Array with the id of the columns to hide
    setUpdateHiddenColumns(props.hiddenColumns);
    setIsSelecting(false);
    props.setShowEditColumns(false); //Close the table headers modal
  };

  /**
   * Purpose: Find the id of the column in the array
   * @param {Number} columnId
   * @returns Return the index in the array
   */
  const findAllColumns = (columnId) => {
    return allColumns.findIndex(function (column) {
      return column.id === columnId;
    });
  };

  useEffect(() => {
    //Remove the column from the checkbox when the columns are to be edited to prevent it from being duplicated
    let index = findAllColumns("selection");
    let allColumnsUpdate = [...allColumns]; //Create a copy of "allColumns"
    if (index != -1) {
      //Delete the item
      allColumnsUpdate.splice(index, 1);
      setAllColumns(allColumnsUpdate);
    }
  }, [allColumnsUpdate, props.showEditColumns]);

  return (
    <div>
      <Modal
        show={props.showEditColumns}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {props.t("editColumns.title")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body mbottom10">
          <div>
            <Row>
              {allColumns.map((column, index) =>
                column.Header != "" ? (
                  <Col xs={6} key={column.id} className="v2">
                    <input
                      type="checkbox"
                      id={`check${index}`}
                      onClick={(e) => toggleHiding(e, column)}
                      checked={column.isVisible}
                    />
                    <label htmlFor={`check${index}`}>{column.Header}</label>
                  </Col>
                ) : (
                  ""
                )
              )}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => cancel()}>
            {props.t("cancel")}
          </Button>
          <Button variant="primary" onClick={() => update()}>
            {props.t("save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalEditColumns.propTypes = {
  showEditColumns: PropTypes.bool,
  allColumns: PropTypes.array,
  hiddenColumns: PropTypes.array,
  setShowEditColumns: PropTypes.func,
  t: PropTypes.func,
  setHiddenColumns: PropTypes.func,
  IndeterminateCheckbox: PropTypes.element,
  setColumns: PropTypes.func,
  columns: PropTypes.array,
};
