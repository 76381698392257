import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

//Components
import selectStyles from "../GlobalTools";

export default function FormAsyncSelect({ label, required, ...props }) {
  const [t] = useTranslation(["global"]);
  const [field, meta, helpers] = useField(props);
  return (
    <Form.Group>
      <Form.Label
        htmlFor={props.id || props.name}
        className={props.required && props.showRequired ? "required" : ""}
      >
        {label}
        {!props.required && props.showRequired === false ? (
          <span className="optional">{t("optional")}</span>
        ) : (
          ""
        )}
      </Form.Label>
      {required ? <span className="pull-right text-danger">*</span> : ""}
      <AsyncSelect
        {...field}
        {...props}
        formatOptionLabel={function (data) {
          return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
        }}
        matchPos="any"
        loadingMessage={() => t("select.searching")}
        onBlur={() => helpers.setTouched(true)}
        classNamePrefix={"select"}
        className={
          meta.touched && meta.error ? `select errorValidation` : `select`
        }
        styles={selectStyles}
        noOptionsMessage={() => t("select.noMatches")}
      />
      {meta.touched && meta.error ? (
        <Form.Text className="text-muted error">{meta.error}</Form.Text>
      ) : null}
    </Form.Group>
  );
}

FormAsyncSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
};
