//Libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, Alert, Col, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { isRequiredFields } from "../../components/global/GlobalTools";

import moment from "moment";
//API
import { getCountries, getStates, getCities } from "../../api/Countries";
import { createStudent } from "../../api/StudentsReport";
//Custom Hooks components
import FormCheck from "../global/form/FormCheck";
import ContentButton from "../global/ContentButton";
import { DatePicker } from "../global/form/DatePicker";
import FormSelect from "../global/form/FormSelect";
import FormGender from "../global/form/FormGender";
import FieldInput from "../global/form/FieldInput";
import TextLoading from "../global/TextLoading";
import FormSchoolCycle from "../global/form/FormSchoolCycle";
import FormAsyncSelect from "../global/form/FormAsyncSelect";
import AdditionalFieldsBlock from "./AdditionalFieldsBlock";

export default function ModalCreateStudent(props) {
  const [t] = useTranslation(["crm", "global", "modalCreateStudent"]);
  const language = t("modalCreateStudent:language");
  const [loadCreateStudent, setLoadCreateStudent] = useState(false);
  const [errors, setErrors] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectCountry, setSelectCountry] = useState(null);
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState({});
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectSchoolCycle, setSelectSchoolCycle] = useState(null);
  const [showAlertCurpIdNo, setShowAlertCurpIdNo] = useState(true);
  const [selectgrade, setSelectGrade] = useState(null);
  const [selectGroup, setSelectGroup] = useState(null);
  const [isRequired, setIsRequired] = useState(false);
  const [showAlertPhoneEmail, setShowAlertPhoneEmail] = useState(true);
  const [changesInSchooling, setChangesInSchooling] = useState(true);


  const [typingCallback, setTypingCallback] = useState(null);
  const [selectLevel, setSelectLevel] = useState(null);
  const [someModalOpen, setSomeModalOpen] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  //Traslate calendar
  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }

  /**
   * initial values for Form
   */
  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    country: "",
    dateBirth: "",
    identificationNumber: "",
    email: "",
    curp: "",
    schoolCycle: "",
    schoolLevel: "",
    grade: "",
    group: "",
    academicOffer: "",
    level: "",
    studentTest: false,
    address: "",
    address2: "",
    state: null,
    city: null,
    zipcode: "",
    phone: "",
  };

  /**
   * Validate fields
   */
  const [validateSchema, setValidateSchema] = useState(
    Yup.object().shape({
      firstName: Yup.string().required(
        t("modalCreateStudent:labels.firstName") +
        " " +
        t("modalCreateStudent:labels.isRequired")
      ),
      lastName: Yup.string().required(
        t("modalCreateStudent:labels.lastName") +
        " " +
        t("modalCreateStudent:labels.isRequired")
      ),
      dateBirth: Yup.string()
        .required(
          t("modalCreateStudent:labels.dateBirth") +
          " " +
          t("modalCreateStudent:labels.isRequired")
        )
        .nullable(),
      schoolCycle: Yup.string().required(
        t("modalCreateStudent:labels.schoolCycle") +
        " " +
        t("modalCreateStudent:labels.isRequired")
      ),
      schoolLevel: Yup.string("")
        .required(
          t("modalCreateStudent:labels.schoolLevel") +
          " " +
          t("modalCreateStudent:labels.isRequired")
        )
        .nullable(),
      grade:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("grade")
          : Yup.string("grade")
            .required(
              t("modalCreateStudent:labels.grade") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable(),
      group:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("group")
          : Yup.string("group")
            .required(
              t("modalCreateStudent:labels.group") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable(),
      academicOffer:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("academicOffer")
            .required(
              t("modalCreateStudent:labels.offer") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable()
          : Yup.string("academicOffer").nullable(),
      level:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("level")
            .required(
              t("modalCreateStudent:labels.level") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable()
          : Yup.string("level").nullable(),
      identificationNumber: showAlertCurpIdNo
        ? Yup.string().required(t("modalCreateStudent:labels.dniCurpRequerid"))
        : Yup.string(),
      curp: showAlertCurpIdNo
        ? Yup.string("Curp").required(
          t("modalCreateStudent:labels.dniCurpRequerid")
        )
        : Yup.string("Curp").matches(
          /^([A-Za-z][AEIOUXaeioux][A-Za-z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HMhm](?:AS|as|B[CS]|b[cs]|C[CLMSH]|c[clmsh]|D[FG]|d[fg]|G[TR]|g[tr]|HG|hg|JC|jc|M[CNS]|m[cns]|N[ETL]|n[etl]|OC|oc|PL|pl|Q[TR]|q[tr]|S[PLR]|s[plr]|T[CSL]|t[csl]|VZ|vz|YN|yn|ZS|zs)[B-DF-HJ-NP-TV-Z-b-df-hj-np-tv-z]{3}[A-Za-z\d])(\d)$/,
          t("modalCreateStudent:labels.invalidCurpFormat")
        ),
      phone: showAlertPhoneEmail
        ? Yup.string().required(t("modalAddProspect.phoneEmailRequerid"))
        : Yup.string(),
      email: showAlertPhoneEmail
        ? Yup.string().required(t("modalAddProspect.phoneEmailRequerid"))
        : Yup.string().email(t("modalAddProspect.invalidEmailFormat")),
    })
  );

  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Create Student Modal");
    if (props.schoolCycle.length > 0) {
      props.schoolCycle[0].id = "";
    }
    //Format countries
    formaCountries();
  }, []);

  /**
   * Create student
   * @param {Object} values
   */
  const saveStudent = (values) => {
    setLoadCreateStudent(true);
    let data = {};
    let format = language == "english" ? "DD/MM/YYYY" : "MM/DD/YYYY";
    let dateObj = moment(values.dateBirth, format);

    data.first_name = values.firstName.toUpperCase();
    data.middle_name = values.middleName.toUpperCase();
    data.last_name = values.lastName.toUpperCase();
    if (values.homePhone != "") {
      data.home_phone = values.homePhone;
    }

    if (values.email) {
      data.email = values.email;
    }
    if (values.curp) {
      data.curp = values.curp.toUpperCase();
    }
    if (values.identificationNumber) {
      data.student_unique_id = values.identificationNumber.toUpperCase();
    }
    data.school_cycle_id = values.schoolCycle;
    if (values.country) {
      data.country = values.country;
    }
    if (values.gender) {
      data.gender = values.gender;
    }
    if (values.group) {
      data.organization_school_level = values.schoolLevel;
      data.organization_school_level_id = values.schoolLevel;
      data.grade_group_grade_level_id = values.group;
    } else {
      data.program_id = values.academicOffer;
    }
    if (values.level) {
      data.program_level_cycle_id = values.level;
      data.program_current_level = selectLevel.label;
    } else {
      data.program_current_level = null;
    }
    data.birth_date_extended = moment(dateObj).format("YYYY-MM-DD");
    data.test_account = values.studentTest;
    //Validate address
    if (values.address && values.address != "" && values.address != null) {
      data.address = values.address.toUpperCase();
    }
    //validate second address
    if (values.address2 && values.address2 != "" && values.address2 != null) {
      data.second_address = values.address2.toUpperCase();
    }
    //validate zip code
    if (values.zipcode && values.zipcode != "" && values.zipcode != null) {
      data.zip_code = values.zipcode;
    }
    //validate city
    if (values.city != null) {
      data.city = values.city;
    }
    //validate state
    if (values.state_id != null) {
      data.state_id = values.state_id;
    }
    //validate cell phone number
    if (values.phone != "") {
      data.phone = values.phone;
    }
    //validate Country of Residence
    values.country_of_residence_id
      ? (data.country_of_residence_id = values.country_of_residence_id)
      : null;
    // if there are additional fields get their values
    if (additionalFields.length > 0) {
      data.additional_fields = fomatAdditionalFields(values);
    }
    createStudent(data)
      .then((result) => {
        if (result.data) {
          props.setNewStudent(result.data);
          cancel();
          props.setOpenSuccessModal(true);
          props.reloadStudentList();
        } else {
          Sentry.captureException(Error(JSON.stringify(result)));
          setErrors(result);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      })
      .then(() => {
        setLoadCreateStudent(false);
      });
  };

  /**
   * Description: Format the additional fields to send them to the backend
   * @param {obj} values
   * @returns array of objects
   */
  const fomatAdditionalFields = (values) => {
    const structureAdditionalFields = [
      {
        section: 1,
        fields: additionalFields
          .map((field) => {
            const id = field.additional_fields_id;
            const value = values[id] || "";
            return {
              id,
              value,
            };
          })
          .filter((field) => field.value),
      },
    ];
    return structureAdditionalFields;
  };

  /**
   * cancel create student
   */
  const cancel = () => {
    props.setShowCreateStudent(false);
    props.setSelectGradeId("");
    props.setSelectCycleId("");
    setSelectCountry(null);
    setSelectSchoolCycle(null);
    setSelectGrade(null);
    props.setSelectSchoolLevel(null);
    setSelectGroup(null);
    setErrors("");
  };

  /**
   * Description: Format the countries to be able to show them in the selector
   */
  const formaCountries = () => {
    getCountries().then((data) => {
      if (data.description) {
        Sentry.captureException(Error(JSON.stringify(data)));
        return;
      }
      const countries = data.data;
      let formatCountries = [];
      if (countries && countries.length) {
        for (let country of countries) {
          let formatCountry = {
            label: country.name,
            value: country.id,
          };
          formatCountries.push(formatCountry);
        }
        setCountries(formatCountries);
      }
    });
  };

  /**
   * Description: Get states from country
   * @param Int id countryId
   */
  const getStatesByCountryId = (countryId) => {
    getStates({ countryId })
      .then((result) => {
        const states = result.data;
        let formatStates = [];
        if (states && states.length) {
          for (let state of states) {
            let formatState = {
              label: state.name,
              value: state.id,
            };
            formatStates.push(formatState);
          }
          setStates(formatStates);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  /**
   * Description: Get cities from state
   * @param Int stateId
   */
  const getCitiesByStateId = (stateId) => {
    getCities({ stateId })
      .then((result) => {
        const cities = result.data;
        let formatCities = [];
        if (cities && cities.length) {
          for (let city of cities) {
            let formatCity = {
              label: city.name,
              value: city.id,
            };
            formatCities.push(formatCity);
          }
          setCities(formatCities);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  /**
   * Description: Search cities from state
   * @param {String} text search term
   * @param {Function} callback callback
   */
  const searchCities = (text, callback) => {
    const data = {
      stateId: selectedState.value,
      search: text,
      limit: 20,
    };
    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    let typing = setTimeout(() => {
      getCities(data)
        .then((result) => {
          const cities = result.data;
          let formatCities = [];
          if (cities && cities.length) {
            for (let city of cities) {
              let formatCity = {
                label: city.name,
                value: city.id,
              };
              formatCities.push(formatCity);
            }
            callback(formatCities);
          } else {
            callback([]);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }, 1000);
    setTypingCallback(typing);
  };

  /**
   * Description: Overwrite validations
   * @param {object} newValidations
   */
  const overwriteValidations = (newValidations) => {
    setValidateSchema(validateSchema.concat(newValidations));
  };

  /**
   * Description: Update dependent validations
   */
  useEffect(() => {
    let updateSchema = validateSchema;
    updateSchema = updateSchema.shape({
      grade:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("grade")
          : Yup.string("grade")
            .required(
              t("modalCreateStudent:labels.grade") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable(),
      group:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("group")
          : Yup.string("group")
            .required(
              t("modalCreateStudent:labels.group") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable(),
      academicOffer:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("academicOffer")
            .required(
              t("modalCreateStudent:labels.offer") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable()
          : Yup.string("academicOffer").nullable(),
      level:
        props.selectSchoolLevel && props.selectSchoolLevel.id == "higherLevel"
          ? Yup.string("level")
            .required(
              t("modalCreateStudent:labels.level") +
              " " +
              t("modalCreateStudent:labels.isRequired")
            )
            .nullable()
          : Yup.string("level").nullable(),
      identificationNumber: showAlertCurpIdNo
        ? Yup.string().required(t("modalCreateStudent:labels.dniCurpRequerid"))
        : Yup.string(),
      curp: showAlertCurpIdNo
        ? Yup.string("Curp").required(
          t("modalCreateStudent:labels.dniCurpRequerid")
        )
        : Yup.string("Curp").matches(
          /^([A-Za-z][AEIOUXaeioux][A-Za-z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HMhm](?:AS|as|B[CS]|b[cs]|C[CLMSH]|c[clmsh]|D[FG]|d[fg]|G[TR]|g[tr]|HG|hg|JC|jc|M[CNS]|m[cns]|N[ETL]|n[etl]|OC|oc|PL|pl|Q[TR]|q[tr]|S[PLR]|s[plr]|T[CSL]|t[csl]|VZ|vz|YN|yn|ZS|zs)[B-DF-HJ-NP-TV-Z-b-df-hj-np-tv-z]{3}[A-Za-z\d])(\d)$/,
          t("modalCreateStudent:labels.invalidCurpFormat")
        ),
      phone: showAlertPhoneEmail
        ? Yup.string().required(t("modalAddProspect.phoneEmailRequerid"))
        : Yup.string(),
      email: showAlertPhoneEmail
        ? Yup.string().required(t("modalAddProspect.phoneEmailRequerid"))
        : Yup.string().email(t("modalAddProspect.invalidEmailFormat")),
    });
    setValidateSchema(updateSchema);
  }, [showAlertCurpIdNo, showAlertPhoneEmail, changesInSchooling]);

  /**
   * Purpose: Valid if the number of required fields is greater than the optional fields
   */
  useEffect(() => {
    setIsRequired(isRequiredFields(initialValues, validateSchema));
  }, [initialValues, validateSchema]);

  return (
    <div>
      <Modal
        show={props.showCreateStudent}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
        contentClassName={someModalOpen ? "hide" : ""} //Hide this modal so the open one doesn't overlap
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("global:buttons.createStudent")}
          </Modal.Title>
        </Modal.Header>
        <ModalBody className="modal-body mbottom10">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchema}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                !loadCreateStudent ? saveStudent(values) : "";
              }}
            >
              {(values) => (
                <Form>
                  <Row>
                    {/**First name */}
                    <Col md={6}>
                      <FieldInput
                        label={t("modalCreateStudent:labels.firstName")}
                        capitalize={true}
                        name="firstName"
                        type="text"
                        placeholder=""
                        required={false}
                        showRequired={true}
                      />
                    </Col>
                    {/**Second name */}
                    <Col md={6}>
                      <FieldInput
                        label={t("modalCreateStudent:labels.middleName")}
                        capitalize={true}
                        name="middleName"
                        type="text"
                        placeholder=""
                        showRequired={isRequired}
                      />
                    </Col>
                  </Row>
                  {/**Surnames (lastname)*/}
                  <FieldInput
                    label={t("modalCreateStudent:labels.lastName")}
                    capitalize={true}
                    name="lastName"
                    type="text"
                    placeholder=""
                    required={false}
                    showRequired={true}
                  />
                  <Row>
                    {/**Gender Male/Female*/}
                    <Col md={6}>
                      <FormGender
                        label={t("modalCreateStudent:labels.gender")}
                        name="gender"
                        gender={{
                          male: t("modalCreateStudent:labels.male"),
                          female: t("modalCreateStudent:labels.female"),
                        }}
                        //required={true}
                        showRequired={isRequired}
                      />
                    </Col>
                    {/**Select countries */}
                    <Col md={6}>
                      <FormSelect
                        label={t("modalCreateStudent:labels.countryOfOrigin")}
                        name="country"
                        options={countries}
                        onChange={(event) => {
                          setSelectCountry(event);
                          values.setFieldValue("country", event.value);
                        }}
                        value={selectCountry}
                        showRequired={isRequired}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/**Birth Day*/}
                    <Col md={5}>
                      <DatePicker
                        label={t("modalCreateStudent:labels.dateBirth")}
                        placeholderText="DD/MM/YYYY"
                        name="dateBirth"
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        {...Locallanguage}
                        required={false}
                        showRequired={true}
                      />
                    </Col>
                    {/**Id number*/}
                    <Col md={7}>
                      <FieldInput
                        id="identificationNumber"
                        label={t("modalCreateStudent:labels.idNumber")}
                        capitalize={true}
                        name="identificationNumber"
                        type="text"
                        placeholder=""
                        showRequired={isRequired}
                      />
                      {/**Validated optional values*/}
                      {values &&
                        values.values &&
                        values.values.identificationNumber === "" &&
                        values.values.curp === ""
                        ? setShowAlertCurpIdNo(true)
                        : setShowAlertCurpIdNo(false)}
                    </Col>
                  </Row>
                  {/**Curp */}
                  <Row>
                    <Col md={6}>
                      <FieldInput
                        id="curp"
                        name="curp"
                        label={t("modalCreateStudent:labels.curp")}
                        capitalize={true}
                        type="text"
                        placeholder={t("modalCreateStudent:labels.curp")}
                        showRequired={isRequired}
                      />
                    </Col>
                  </Row>
                  {/**Validated optional values*/}
                  {values &&
                    values.values &&
                    values.values.identificationNumber === "" &&
                    values.values.curp === ""
                    ? setShowAlertCurpIdNo(true)
                    : setShowAlertCurpIdNo(false)}
                  {/**Email */}
                  <Row>
                    <FieldInput
                      id="email"
                      name="email"
                      label={t("modalCreateStudent:labels.email")}
                      type="email"
                      placeholder={t(
                        "modalCreateStudent:labels.placeholderEmail"
                      )}
                      showRequired={isRequired}
                    />
                    {/**Validated optional values*/}
                    {values &&
                      values.values &&
                      values.values.phone === "" &&
                      values.values.email === ""
                      ? setShowAlertPhoneEmail(true)
                      : setShowAlertPhoneEmail(false)}
                  </Row>

                  {/**homePhone and cellPhoneNumber*/}
                  <Row className="mbottom10">
                    <Col md={6}>
                      <FieldInput
                        id="homePhone"
                        name="homePhone"
                        label={t("modalCreateStudent:labels.homePhone")}
                        placeholder={t("cellPhonePlaceholder")}
                        type="phone"
                        maxlength="10"
                        showRequired={isRequired}
                      />
                    </Col>
                    <Col md={6}>
                      <FieldInput
                        id="phone"
                        name="phone"
                        label={t("modalCreateStudent:labels.cellPhoneNumber")}
                        placeholder={t("cellPhonePlaceholder")}
                        type="phone"
                        maxlength="10"
                        showRequired={isRequired}
                      />

                      {/**Validated optional values*/}
                      {values &&
                        values.values &&
                        values.values.phone === "" &&
                        values.values.email === ""
                        ? setShowAlertPhoneEmail(true)
                        : setShowAlertPhoneEmail(false)}
                    </Col>
                  </Row>
                  <Row>
                    {/**Country Of Residence */}
                    <Col md={6}>
                      <FormSelect
                        label={t(
                          "modalCreateStudent:labels.countryOfResidence"
                        )}
                        name="country_of_residence_id"
                        options={countries}
                        onChange={(country) => {
                          setCountryOfResidence(country);
                          getStatesByCountryId(country.value);
                          values.setFieldValue(
                            "country_of_residence_id",
                            country.value
                          );
                          setSelectedState(null),
                            values.setFieldValue("state_id", null);
                          setSelectedCity(null),
                            values.setFieldValue("city", null);
                        }}
                        value={countryOfResidence}
                        placeholder={t(
                          "modalCreateStudent:labels.selectCountry"
                        )}
                        showRequired={false}
                      />
                    </Col>
                    {/**State */}
                    <Col md={6}>
                      <FormSelect
                        label={t("address:labels.state")}
                        name="state_id"
                        options={states}
                        onChange={(event) => {
                          setSelectedState(event);
                          values.setFieldValue("state_id", event.value);
                          event && event.value
                            ? getCitiesByStateId(event.value)
                            : setCities([]),
                            setSelectedCity(null),
                            values.setFieldValue("city", null);
                        }}
                        value={selectedState}
                        isDisabled={states.length == 0}
                        placeholder={t("address:select.placeholderState")}
                        showRequired={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/**City */}
                    <Col md={6}>
                      <FormAsyncSelect
                        label={t("address:labels.municipality")}
                        name="city"
                        defaultOptions={cities}
                        loadOptions={searchCities ? searchCities : null}
                        value={selectedCity}
                        onChange={(event) => {
                          setSelectedCity(event);
                          values.setFieldValue(
                            "city",
                            event && event.value ? event.value : event
                          );
                        }}
                        placeholder={t(
                          "address:select.placeholderCityOrMunicipality"
                        )}
                        isDisabled={selectedState == null ? true : false}
                        showRequired={false}
                      />
                    </Col>
                    {/**zip code*/}
                    <Col md={6}>
                      <FieldInput
                        id="zipcode"
                        name="zipcode"
                        label={t("address:labels.zipcode")}
                        type="text"
                        placeholder={t("address:labels.zipcode")}
                        onInput={(input) => {
                          // Condition to accept only numbers
                          let isNotNumber = isNaN(input.target.value);
                          // Evaluates if the incoming value of the target is not a number then assigns an empty value to the target
                          if (isNotNumber) {
                            input.target.value = "";
                          }
                          if (input.target.value.length > 5) {
                            input.target.value = input.target.value.slice(0, 5);
                          }
                        }}
                        showRequired={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/**Adreess */}
                    <Col md={12}>
                      <FieldInput
                        id="address"
                        name="address"
                        label={t("address:labels.address")}
                        type="text"
                        placeholder={t("address:labels.placeholderAddress")}
                        capitalize={true}
                        showRequired={false}
                      />
                    </Col>
                    {/**Adreess2 */}
                    <Col md={12}>
                      <FieldInput
                        id="address2"
                        name="address2"
                        label={t("address:labels.address2")}
                        type="text"
                        placeholder={t("address:labels.placeholderAddress2")}
                        capitalize={true}
                        showRequired={false}
                      />
                    </Col>
                  </Row>

                  <Row>
                    {/**School cycle */}
                    <Col md={12}>
                      <FormSchoolCycle
                        label={t("modalCreateStudent:labels.schoolCycle")}
                        name="schoolCycle"
                        onChange={(event) => {
                          //Remove touched items from Formik object
                          if (event == null) {
                            delete values.touched.schoolLevel;
                            delete values.touched.grade;
                            delete values.touched.group;
                            delete values.touched.level;
                            delete values.touched.academicOffer;
                          }
                          let cycleId = event && event.id ? event.id : "";
                          setSelectSchoolCycle(event);
                          values.setFieldValue("schoolCycle", cycleId);
                          values.setFieldValue("schoolLevel", "");
                          values.setFieldValue("grade", "");
                          values.setFieldValue("group", "");
                          values.setFieldValue("academicOffer", "");
                          values.setFieldValue("level", "");
                          props.setSelectSchoolLevel(null);
                          setSelectGrade(null);
                          setSelectGroup(null);
                          props.setSelectGradeId("");
                          props.setSelectCycleId(cycleId);
                          setChangesInSchooling(!changesInSchooling);
                        }}
                        value={selectSchoolCycle}
                        required={true}
                        showRequired={isRequired}
                        values={values}
                        useIn="form"
                        addButton={true}
                        //They are sent as props because when closing the create student modal the fields are cleaned
                        selectSchoolLevel={props.selectSchoolLevel}
                        selectgrade={selectgrade}
                        selectGroup={selectGroup}
                        selectGradeId={props.selectGradeId}
                        setSelectSchoolLevel={
                          (e) => {
                            props.setSelectSchoolLevel(e);
                            values.setFieldError("grade", undefined)
                            values.setFieldError("group", undefined)
                            values.setFieldError("academicOffer", undefined)
                            values.setFieldError("level", undefined)
                            setChangesInSchooling(!changesInSchooling);
                          }
                        }
                        setSelectGrade={setSelectGrade}
                        setSelectGroup={setSelectGroup}
                        setSelectGradeId={props.setSelectGradeId}
                        setSelectLevel={setSelectLevel}
                        setSomeModalOpen={setSomeModalOpen}
                      />
                    </Col>
                  </Row>
                  {props.permissions.admin_students.edit && (
                    <Row>
                      {/**CheckBox Student test*/}
                      <Col md={12}>
                        <FormCheck
                          name="studentTest"
                          label={t("modalCreateStudent:labels.testStudent")}
                          type="checkbox"
                          showRequired={isRequired}
                        />
                      </Col>
                    </Row>
                  )}
                  <AdditionalFieldsBlock
                    formik={values}
                    setAdditionalFields={setAdditionalFields}
                    overwriteValidations={overwriteValidations}
                  />

                  {/**Show errors when creating item*/}
                  <div style={{ marginTop: "20px" }}>
                    {errors ? (
                      <Alert variant="warning">{errors.description}</Alert>
                    ) : (
                      ""
                    )}
                  </div>
                  {/**Content acction's buttons */}
                  <ContentButton
                    className="content-button"
                    disabled={loadCreateStudent}
                  >
                    <Button
                      id="cancelCreateStudentBtn"
                      variant="outline-secondary"
                      onClick={() => cancel()}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      id="saveStudentBtn"
                      variant="primary"
                      type="submit"
                      disabled={loadCreateStudent}
                    >
                      {loadCreateStudent ? (
                        <TextLoading
                          text={t("global:buttons.saving")}
                          variant="light"
                        />
                      ) : (
                        t("global:buttons.save")
                      )}
                    </Button>
                  </ContentButton>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

ModalCreateStudent.propTypes = {
  showCreateStudent: PropTypes.bool,
  setShowCreateStudent: PropTypes.func,
  schoolCycle: PropTypes.array,
  handleClose: PropTypes.func,
  setSelectCycleId: PropTypes.func,
  selectCycleId: PropTypes.string,
  setSelectGradeId: PropTypes.func,
  selectGradeId: PropTypes.string,
  getGrades: PropTypes.func,
  loadGrades: PropTypes.bool,
  grades: PropTypes.array,
  setSelectSchoolLevel: PropTypes.func,
  selectSchoolLevel: PropTypes.object,
  setSchoolCycle: PropTypes.func,
  setOpenSuccessModal: PropTypes.func,
  setNewStudent: PropTypes.func,
  reloadStudentList: PropTypes.func,
  permissions: PropTypes.object,
};
