/**
 * Purpose: Change the password of any user
 *
 * Props:
 *    showModalChangePassword: boolean type value to show or hide the modal
 *    setShowModalChangePassword: function linked to the modal's cancel button
 *    user: Object that must contain the parameters "name" and "id".
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Alert,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UpdateSuperAdmin as UpdatePassword } from "../../../api/SuperAdmins";
//validation resources
import { Form as FormOfFormik, Formik } from "formik";
import * as Yup from "yup";

const stylesCustom = {
  biQuestion: {
    marginLeft: "5px",
    color: "#EA2C54",
  },
};

export default function ModalChangePassword(props) {
  const [t] = useTranslation(["global"]);
  const [serviceErrors, setServiceErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordInfo] = useState({
    password: "",
    confirmPassword: "",
  });

  /**
   * Method to update a user's password
   * @param {obj} values object that stores the values of the form
   */
  const updatePasswordUser = (values) => {
    const payload = {
      user_id: props.user.id,
      password: values.password,
    };
    UpdatePassword(payload).then((result) => {
      if (result.error) {
        setServiceErrors(result.error.message);
        return;
      }
      props.setShowModalChangePassword(false);
    });
  };

  const tooltipPassword = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("global:tooltipGlobal.textPassword")}
      </Tooltip>
    );
  };

  const validateSchema = Yup.object().shape({
    password: Yup.string()
      .required(
        t("global:modalChangePassword.password") +
          " " +
          t("superAdmin:view.isRequired")
      )
      .test(
        "formatPassword",
        t("global:tooltipGlobal.textPassword"),
        (password) => {
          if (password) {
            let passwordRegex = new RegExp(
              /(?=^(?:[^A-Z]*[A-Z]))(?=^(?:[^a-z]*[a-z]))(?=^(?:\D*\d))(?=^(?:\w*\W))^[A-Za-z\d\W]{8,}$/
            );
            return passwordRegex.test(password);
          }
        }
      ),
    confirmPassword: Yup.string()
      .required(
        t("global:modalChangePassword.confirmPassword") +
          " " +
          t("superAdmin:view.isRequired")
      )
      .test(
        "verificationPassword",
        t("global:modalChangePassword.verificationPasswordMessage"),
        (password, { parent }) => {
          return password === parent.password;
        }
      ),
  });

  return (
    <Modal
      show={props.showModalChangePassword}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("superAdmin:modalChangePassword.changePassword")}
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={passwordInfo}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          updatePasswordUser(values);
        }}
      >
        {({ values, errors, touched, ...formik }) => (
          <FormOfFormik>
            <Modal.Body>
              <Row>
                <Col md={12} className=" mb-2">
                  <Form.Text className="text-black">
                    {t("superAdmin:modalChangePassword.changePasswordFor", {
                      name: props.user.name,
                    })}
                  </Form.Text>
                </Col>
                <Col md={12}>
                  {/* Password field */}

                  <FormLabel>
                    {t("global:modalChangePassword.password")}{" "}
                    <OverlayTrigger placement="top" overlay={tooltipPassword}>
                      <span
                        className="bi bi-question-circle-fill"
                        aria-hidden="true"
                        style={stylesCustom.biQuestion}
                      ></span>
                    </OverlayTrigger>
                  </FormLabel>
                  <InputGroup className="mb-3" controlId="password">
                    <Form.Control
                      name="password"
                      placeholder="**************"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onBlur={() => formik.setFieldTouched("password", true)}
                      className={
                        errors.password && touched.password
                          ? "errorValidation"
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("password", e.target.value);
                      }}
                    />

                    <InputGroup.Text
                      role="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <img
                        id="type_password"
                        src={
                          showPassword ? "/img/eye.svg" : "/img/eye-slash.svg"
                        }
                      />
                    </InputGroup.Text>
                    {errors.password && touched.password && (
                      <Col md={12}>
                        <Form.Text className="text-muted error">
                          {errors.password}
                        </Form.Text>
                      </Col>
                    )}
                  </InputGroup>
                  {/* Confirm password field */}
                  <FormLabel htmlFor="confirmPassword">
                    {t("global:modalChangePassword.confirmPassword")}
                  </FormLabel>
                  <InputGroup
                    className="mb-3"
                    controlId="confirmPassword"
                    validationState="error"
                  >
                    <FormControl
                      name="confirmPassword"
                      placeholder="**************"
                      type={showConfirmPassword ? "text" : "password"}
                      value={values.confirmPassword}
                      onBlur={() =>
                        formik.setFieldTouched("confirmPassword", true)
                      }
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "errorValidation"
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("confirmPassword", e.target.value);
                      }}
                    />
                    <InputGroup.Text
                      role="button"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      <img
                        id="type_password"
                        src={
                          showConfirmPassword
                            ? "/img/eye.svg"
                            : "/img/eye-slash.svg"
                        }
                      />
                    </InputGroup.Text>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <Col md={12}>
                        <Form.Text className="text-muted error">
                          {errors.confirmPassword}
                        </Form.Text>
                      </Col>
                    )}
                  </InputGroup>
                </Col>
              </Row>
              {serviceErrors && (
                <Row>
                  <Col md={12}>
                    <Alert variant="warning">{serviceErrors}</Alert>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={() => props.setShowModalChangePassword(false)}
              >
                {t("global:buttons.cancel")}
              </Button>
              <Button type="submit">
                {t("superAdmin:modalChangePassword.changePassword")}
              </Button>
            </Modal.Footer>
          </FormOfFormik>
        )}
      </Formik>
    </Modal>
  );
}

ModalChangePassword.propTypes = {
  showModalChangePassword: PropTypes.bool,
  setShowModalChangePassword: PropTypes.func,
  user: PropTypes.object,
};
