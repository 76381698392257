/**
 *       **** Readme ****
 *
 * Component: <FieldDaysInput />
 * Purpose: Returns the Input element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React, { useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Custom Styles
const customStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignSelf: "stretch",
  },
  secondayContainer: {
    padding: "10px",
    borderRadius: "4px",
    border: "solid 1px var(--secondary-gray-main-500, #D6DADB)",
    background: "var(--secondary-gray-200, #F6F6F6)",
    height: "100px",
  },
  input: {
    width: "80px",
    display: "inline",
    borderTop: "-29px",
    borderLeft: "85px",
  },
  errorController: {
    marginTop: "0px",
  },
  text: {
    fontFamily: "Mulish",
    fontSize: "12px",
    lineHeight: "14px",
  },
};

export default function FieldDaysInput({ label, ...props }) {
  const [t] = useTranslation(["global"]);
  const [field, meta] = useField(props);

  /**
   * Initial loading
   */
  useEffect(() => {}, []);

  return (
    <div style={customStyles.container}>
      <Form.Group className={"mb-3"} style={customStyles.secondayContainer}>
        {label ? (
          <Form.Label
            data-text={t("optional")}
            htmlFor={props.id || props.name}
            className={
              props.required && props.showRequired
                ? "required"
                : !props.required && props.showRequired === false
                ? "optional"
                : ""
            }
          >
            {label}
          </Form.Label>
        ) : null}

        <div>
          <span style={customStyles.text}>
            {t("modalSetRestrictions.text1")}
          </span>
          <Form.Control
            className={meta.touched && meta.error ? `errorValidation` : `well`}
            style={customStyles.input}
            type="text"
            {...field}
            {...props}
          />
          <span style={customStyles.text}>
            {t("modalSetRestrictions.text2")}
          </span>
        </div>
        {/** Error Controller */}
        {meta.touched && meta.error ? (
          <Form.Text
            className="text-muted error"
            style={customStyles.errorController}
          >
            {meta.error}
          </Form.Text>
        ) : null}
      </Form.Group>
    </div>
  );
}

FieldDaysInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
};
