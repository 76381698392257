import React from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { DeleteLink } from "../../../api/ExtraLink";
export default function ModalDeleteExtraLink(props) {
  const [t] = useTranslation(["extraLink", "global"]);

  /**
   * Function to delete an extra link
   */
  const DeleteExtraLink = () => {
    const request = { hyperlink_id: props.itemIdToDelete };
    DeleteLink(request).then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      } else {
        props.getExtraLinks();
        props.setShowDeleteModal(false);
      }
    });
  };

  return (
    <Modal
      show={props.showDeleteModal}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("titleModalDelete")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body mbottom10">
        <Row>
          <Col lg={12}>{t("textModalDelete")}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowDeleteModal(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button type="submit" onClick={() => DeleteExtraLink()}>
          <>{t("global:buttons.accept")}</>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalDeleteExtraLink.propTypes = {
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
  getExtraLinks: PropTypes.func,
  itemIdToDelete: PropTypes.string,
};
