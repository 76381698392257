import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function ModalBulkInvoicingError(props) {
  const [t] = useTranslation(["reports", "global"]);

  return (
    <div>
      <Modal
        show={props.showModalBulkInvoicingError}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header>
          <Modal.Title className="modal-title ms-2">
            {t("modals.titleInvoicingError")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="mt-4 mx-3"
            style={{ fontWeight: "lighter", fontSize: "14px" }}
          >
            {t("modals.textModalInvoicingError")}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => props.setShowModalBulkInvoicingError(false)}
          >
            {t("global:buttons.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalBulkInvoicingError.propTypes = {
  showModalBulkInvoicingError: PropTypes.bool,
  setShowModalBulkInvoicingError: PropTypes.func,
};
