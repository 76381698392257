/**
 *       **** Readme ****
 
 * Props:
 *   -column: {Object} Specifies the header titles of the columns
 *   -defaultValues: {Array} Array of objects where the object will have: {value, label, id}. (default value: null)
 *   -getByOption: {Fuction}  Function to make a request to the api with the selected filter. (default value: null)
 *    
*/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Select from "react-select";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "'Mulish', sans-serif",
    padding: "5px 16px 3px 16px",
    color: state.isSelected ? "white" : state.isDisabled ? "#d4d4d4" : null,
    backgroundColor: state.isSelected
      ? "var(--base-color)"
      : state.isDisabled
        ? "white"
        : state.isFocused
          ? "var(--red-light)"
          : null,
    cursor: state.isDisabled ? "not-allowed" : null,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    zIndex: "9999",
    fontWeight: "400",
    fontFamily: "'Mulish', sans-serif",
    color: "#4d4d4d",
    textAlign: "left",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "'Mulish', sans-serif",
    minHeight: "29px",
    maxHeight: "29px",
    borderRadius: 0,
    borderColor: "#dddddd",
    outline: "none",
    textAlign: "left",
    border: "1px solid #dddddd",
    borderLeftColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #dddddd",
    },
  }),
  container: (provided) => ({
    ...provided,
    minHeight: "29px",
    maxHeight: "29px",
    outline: "none",
    width: "100%",
    maxWidth: "100%",
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "'Mulish', sans-serif",
    minHeight: "29px",
    maxHeight: "29px",
    margin: 0,
    fontWeight: "400",
    outline: "none",
    minWidth: "100px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#D6DADB",
      fontSize: "14px",
      fontFamily: "'Mulish', sans-serif",
      fontWeight: "600",
      textAlign: "left",
    };
  },
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: "29px",
    maxHeight: "29px",
    cursor: "pointer",
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "29px",
    maxHeight: "29px",
    padding: "0 4px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: "normal",
    fontFamily: "'Mulish', sans-serif",
  }),
};

export default function SelectFilter(props) {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t, i18n] = useTranslation(["table"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [optionSelected, setOptionSelected] = useState(null);
  const label = t("columnFilter.all");
  const { filterValue, setFilter } = props.column;
  const { preFilteredRows } = props.column;
  const { id } = props.column;
  const getByOption = props.getByOption || null;
  const defaultValues = props.defaultValues || null;
  //Indicates if the filters in the table are not related to each other and work independently.
  const { unrelatedFilters } = props.state;
  //Indicates if the filters in the table are not related to each other and work independently.
  const { setAllFilters } = props;
  /**
   * Purpose: Gets the selected item
   * @param {*} event
   */
  const changeOption = (event) => {
    if (unrelatedFilters) {
      setAllFilters([]);
    }
    setFilter(event.value || undefined);
    setOptionSelected(event);
    if (getByOption) {
      getByOption(event, props.column);
    }
  };

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    //Establish pre-established options in case they are sent to them
    if (defaultValues) {
      options.add({ label: label, value: null });
      defaultValues.forEach((option) => {
        options.add({
          label: option.label,
          value: option.id,
          id: option.id,
          valueOption: option.value,
        });
      });
    } else {
      //Get the unique options for the filter
      const uniqueOptions = new Set();
      preFilteredRows.forEach((row) => {
        // If it is an array it will flatten the values
        if (Array.isArray(row.values[id])) {
          row.values[id].flat(2).forEach((element) => {
            uniqueOptions.add(element);
          });
        } else {
          uniqueOptions.add(row.values[id]);
        }
      });

      //Create a copy of uniqueOptions and make them work in the Select of react-select
      options.add({ label: label, value: null });
      uniqueOptions.forEach((row, i) => {
        row && options.add({ label: row, value: row, id: i });
      });
    }
    return [...options.values()];
  }, [id, preFilteredRows, label]);

  /**Initial loading */
  useEffect(() => {
    //Preserves the value of the filter when the table data is updated
    const result = options.find(({ value }) => value === filterValue);
    setOptionSelected(result || null);
    //Change the language
    i18n.changeLanguage(localStorage.getItem(`cmLanguage${prefix}`));
  }, [filterValue]);

  return (
    <div>
      <Select
        options={options}
        placeholder={t("columnFilter.search")}
        styles={customStyles}
        value={optionSelected}
        onChange={changeOption}
        formatOptionLabel={function (data) {
          return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
        }}
      />
    </div>
  );
}

SelectFilter.propTypes = {
  column: PropTypes.object,
  preFilteredRows: PropTypes.array,
  getByOption: PropTypes.func,
  defaultValues: PropTypes.array,
  unrelatedFilters: PropTypes.bool,
  setAllFilters: PropTypes.func,
  state: PropTypes.object,
};
