// Libraries
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import Cookies from "js-cookie";
import moment from "moment";
import PropTypes from "prop-types";

// Custom Components
import ExportToExcel from "../../components/table/ExportToExcel";
import ContentTable from "../../components/table/ContentTable";
import { Table } from "../../components/table/Table";
import InputFilter from "../../components/table/InputFilter";
import SelectFilter from "../../components/table/SelectFilter";
import ModalAssignScholarship from "./modals/ModalAssignScholarship";
import { goToHref } from "../../components/global/GlobalTools";
import ModalComplete from "./modals/ModalComplete";
import ContentSolid from "../../components/global/ContentSolid";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import { dateCookie, domainCookie } from "../../components/global/GlobalTools";

// API Services
import { GetScholarshipStudents } from "../../api/Scholarship";

// Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const ScholarshipStudent = () => {
  const componentRef = useRef();
  const [t] = useTranslation([
    "reports",
    "scholarships",
    "global",
    "table",
    "cycles",
    "scholarship",
  ]); // In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [count, setCount] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [cycles, setCycles] = useState([]);
  const [cycleSelected, setCycleSelected] = useState({});
  const prefix = process.env.REACT_APP_PREFIX;
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [disabledBtnInactive, setDisabledBtnInactive] = useState(true);
  const [counterSelectedScholarships, setCounterSelectedScholarships] =
    useState(0);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [studentAssignetComplete, setStudentAssignetComplete] = useState({});

  const [loadingView, setLoadingView] = useState(true);
  const hiddenColumns = ["concept", "subconcept"];
  /**
   * Stores the table column data in memory, and is not updated unless useMemo identifies a change in the data.
   */
  const columns = React.useMemo(
    () => [
      {
        Header: t("headerTable.enrollment"),
        accessor: "registration",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 140,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.name"),
        accessor: "name",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 150,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("headerTable.schoolLevel"),
        accessor: "schooling",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 140,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.program"),
        accessor: "program_name",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 150,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("headerTable.grade"),
        accessor: "grade",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 100,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("headerTable.group"),
        accessor: "group",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 100,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.scholarship"),
        accessor: "scholarship",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 100,
        width: 115,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.benefit"),
        accessor: "benefit",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 140,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.concept"),
        accessor: "concept",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 140,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.subconcepts"),
        accessor: "subconcept",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 140,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.percentageOrAmount"),
        accessor: "percentage",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 150,
        width: 200,
        maxWidth: 250,
      },
    ],
    [loading]
  );

  // Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", // 'xlsx' or 'xls' or 'csv'
    filename: "becadosReport",
  };

  const inicialLoad = () => {
    // Get School Cycles
    let cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`)) || [];
    const hasCycles =
      cyclesLocal && Array.isArray(cyclesLocal) && cyclesLocal.length;
    if (hasCycles) {
      // Get current school cycle
      let currentCycle = cyclesLocal[0];
      // Get current date
      const today = moment().unix();
      for (let cycle of cyclesLocal) {
        if (
          today > cycle.start_time &&
          today < cycle.end_time &&
          cycle.current_cycle == 1
        ) {
          currentCycle = cycle;
        }
      }
      setCycles(cyclesLocal);
      if (currentCycle) {
        setCycleSelected(currentCycle);
        // Get students
        getScholarshipStudents(currentCycle.id);
      }
    } else {
      setTimeout(() => {
        inicialLoad();
      }, 1000);
    }
  };

  // Initial values
  useEffect(() => {
    //Update the cookie "cmSourceView"
    Cookies.set(`cmSourceView${prefix}`, "/payments/scholarships", {
      expires: dateCookie(),
      domain: domainCookie(), //Domain to Beta and Live, for local change this for localhost
    });
    inicialLoad();
  }, []);

  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  // Functions

  /**
   * Puporse: Get Scholarship Students
   * @param {Int} schoolCycleId ID - field param from school cycle table
   */
  const getScholarshipStudents = (schoolCycleId) => {
    if (schoolCycleId) {
      setLoading(true);
      const values = {
        school_cycle_id: schoolCycleId,
      };
      GetScholarshipStudents(values)
        .then((result) => {
          if (result && result.data) {
            const students = result.data.map((student) => {
              return {
                index: student.student_scholarship_id,
                linkToPage: `${process.env.REACT_APP_V1}/estudiante/${student.student_id}`,
                studentId: student.student_id,
                registration: student.student_unique_id || "N/A",
                name: student.name || "N/A",
                schooling: student.school_level || "N/A",
                grade: student.grade || "N/A",
                program_name: student.program_name || "N/A",
                group: student.group || "N/A",
                scholarship: student.scholarship_name || "N/A",
                benefit:
                  student.scholarship_type &&
                  student.scholarship_type == "scholarship"
                    ? t("scholarship:scholarshipDetails.infoScholarship")
                    : t("scholarship:scholarshipDetails.infoDiscount"),
                concept: student.concepts_names || "N/A",
                subconcept: student.subconcepts_names || "N/A",
                percentage: student.discount || "N/A",
              };
            });
            setCount(students.length);
            setDataTable(students);
            setDisabledBtnInactive(true);
          }
        })
        .catch()
        .finally(() => {
          setLoading(false);
          setLoadingView(false);
        });
    }
  };

  // Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  return (
    <div>
      <Row className="mb-3">
        <Col xs={12} md={6} style={{ position: "relative" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              let selectAll = {
                switch: !selectAllRows.switch,
                value: !selectAllRows.value,
              };
              setSelectAllRows(selectAll);
            }}
          >
            {!isAllRowsSelected
              ? t("global:buttons.selectAll")
              : t("global:buttons.unselectAll")}
          </Button>
          {/** Filter Select School Cycle */}
          <Dropdown className="ms-3" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="downloadOptionCycle"
              variant="outline-secondary"
            >
              {cycleSelected?.label || t("cycles:select.placeholder")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {cycles.map((cycle) => {
                return (
                  <Dropdown.Item
                    key={cycle.value}
                    onClick={() => {
                      setCycleSelected(cycle);
                      // Get Scholarship Students
                      getScholarshipStudents(cycle.id);
                    }} // here is the get with the cycle.id
                  >
                    {cycle.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {/* Total counter of records and selected records in Scholarships table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>
        <Col xs={12} md={6} style={{ position: "relative" }}>
          <div style={{ paddingTop: "5px", float: "right" }}>
            <Dropdown style={{ display: "inline-block", marginRight: "10px" }}>
              <Dropdown.Toggle
                id="downloadOptionPrint"
                variant="outline-secondary"
                disabled={printExport}
              >
                <i className="bi bi-download"></i>
                {t("global:buttons.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu disabled={printExport}>
                <ReactToPrint
                  trigger={() => (
                    <Dropdown.Item>
                      {t("global:buttons.downloadPDF")}
                    </Dropdown.Item>
                  )}
                  content={() => componentRef.current}
                />
                <ExportToExcel
                  exportData={exportData}
                  exportOptions={exportOptions}
                  printExport={printExport}
                  typeElement="dropdownItem"
                />
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="primary"
              onClick={() => setShowModalAssign(true)}
              disabled={
                counterSelectedScholarships > 0 ? false : disabledBtnInactive
              }
            >
              {t("scholarships:viewScholarships.textAssignScholarship")}
            </Button>
          </div>
        </Col>
      </Row>

      {/* TABLE */}
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          <ContentTable startColumFilter={1} lastColumFilter={8}>
            <Table
              data={data}
              rowSelect={true}
              loading={loading}
              columns={columns}
              ref={componentRef}
              selectAllRows={selectAllRows}
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              showEditColumns={showEditColumns}
              setShowEditColumns={setShowEditColumns}
              setSelectedRowIds={setSelectedRowIds}
              setIsAllRowsSelected={setIsAllRowsSelected}
              showColumnGear={true}
              rowOnclick={(student) =>
                goToHref(`/estudiante/${student.studentId}`)
              }
              setCounterSelectedStudents={setCounterSelectedScholarships}
              hiddenColumns={hiddenColumns}
              isLinked={true}
            />
          </ContentTable>
        </>
      )}

      {showModalAssign && (
        <ModalAssignScholarship
          showModalAssign={showModalAssign}
          setShowModalAssign={setShowModalAssign}
          selectedRowIds={selectedRowIds}
          setStudentAssignetComplete={setStudentAssignetComplete}
          setShowModalComplete={setShowModalComplete}
          getScholarshipStudents={() =>
            getScholarshipStudents(cycleSelected?.id)
          }
          cycleSelected={cycleSelected}
        />
      )}
      {showModalComplete && (
        <ModalComplete
          showModalComplete={showModalComplete}
          setShowModalComplete={setShowModalComplete}
          studentAssignetComplete={studentAssignetComplete}
        />
      )}
    </div>
  );
};

ScholarshipStudent.propTypes = {
  cell: PropTypes.object,
};

export default ScholarshipStudent;
