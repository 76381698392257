import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, Table, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import * as moment from "moment";

export default function ModalPaymentsNonEligibleForBilling(props) {
  const [t] = useTranslation(["reports", "global"]);

  /**
   * Change the format type to what it comes with in the api
   * @param {String} date
   * @returns
   */
  const formatDate = (date) => {
    let dateMoment = moment(date, "YYYY-MM-DD");
    let formantDate = dateMoment.format("DD/MM/YYYY");
    return formantDate;
  };

  /**
   * Purpose: Used to continue to the next modal verifying if it is for printing or downloading and performs the corresponding action.
   */
  const btnContinue = () => {
    if (props.actionType === "dowload") {
      let idsPrint = props.idsPrint;
      for (let payments of props.nonBillablePayments) {
        let position = idsPrint.findIndex((id) => id === payments.id);
        if (position != -1) {
          idsPrint.splice(position, 1);
        }
      }
      props.setIdsPrint(idsPrint);
    } else {
      let confirmations = props.confirmations;
      for (let payment of props.billablePayments) {
        let position = confirmations.findIndex(
          (confirmation) => confirmation === payment.confirmation
        );
        if (position != -1) {
          confirmations.splice(position, 1);
        }
      }
      props.setConfirmations(confirmations);
    }
    if (props.billablePayments.length) {
      props.setShowModalUnbilledPayments(true);
      props.setShowModalPaymentsNonEligibleForBilling(false);
    } else {
      props.actionType === "dowload"
        ? props.downloadInvoices(props.idsPrint)
        : props.printInvoices(props.confirmations);
      props.setShowModalPaymentsNonEligibleForBilling(false);
    }
  };

  return (
    <div>
      <Modal
        show={props.showModalPaymentsNonEligibleForBilling}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("modals.titleNotPayments")}
          </Modal.Title>
        </Modal.Header>
        <div className="mx-3 fw-light">
          {props.actionType === "dowload" ? (
            <p>{t("modals.textPaymentsDowload")}</p>
          ) : (
            <p>{t("modals.textPaymentsInvoices")}</p>
          )}
        </div>
        <ModalBody className="modal-body pt-0 mt-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th>{t("headerTable.transaction")}</th>
                <th>{t("headerTable.date")}</th>
                <th>{t("headerTable.customer")}</th>
                <th>{t("headerTable.amount")}</th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {props.nonBillablePayments.map((row, index) => (
                <tr key={index}>
                  <td>{row.confirmation}</td>
                  <td> {formatDate(row.data)} </td>
                  <td>{row.students}</td>
                  <td>$ {row.amount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              props.setShowModalPaymentsNonEligibleForBilling(false);
              props.clearInvoiceDataModal();
            }}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button variant="primary" onClick={btnContinue}>
            {t("global:buttons.continue")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalPaymentsNonEligibleForBilling.propTypes = {
  showModalPaymentsNonEligibleForBilling: PropTypes.bool,
  setShowModalPaymentsNonEligibleForBilling: PropTypes.func,
  nonBillablePayments: PropTypes.array,
  setShowModalUnbilledPayments: PropTypes.func,
  idsPrint: PropTypes.array,
  setIdsPrint: PropTypes.func,
  downloadInvoices: PropTypes.func,
  billablePayments: PropTypes.array,
  actionType: PropTypes.string,
  confirmations: PropTypes.array,
  setConfirmations: PropTypes.func,
  printInvoices: PropTypes.func,
  clearInvoiceDataModal: PropTypes.func,
};
