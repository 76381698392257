import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function MailingModal(props) {
  const [t] = useTranslation(["reports", "global"]);

  return (
    <div>
      <Modal
        show={props.showMailingModal}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header>
          <Modal.Title className="modal-title ms-2">
            {props.textMessageMailingModal}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="mx-3"
            style={{ fontWeight: "lighter", fontSize: "14px" }}
          >
            {props.messageMailingModal}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => props.setShowMailingModal(false)}
          >
            {t("global:buttons.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

MailingModal.propTypes = {
  showMailingModal: PropTypes.bool,
  setShowMailingModal: PropTypes.func,
  messageMailingModal: PropTypes.object,
  textMessageMailingModal: PropTypes.object,
};
