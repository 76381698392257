import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";

let axiosOptions = {};
//Get prefix token
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Get cycles for scores
 * @returns {Array}
 */
export const GetLevelsForScore = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/school_cycles/levels_for_scores`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response); // Call function to handle errors
    return ErrorResponse(error.response);
  }
};
/**
 * Get cycles for scores
 * @param {object} data
 * @returns {Array}
 */
export const GetScores = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/scores/report`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response); // Call function to handle errors
    return ErrorResponse(error.response);
  }
};
