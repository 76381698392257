import { React, useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form as FormikCont, Formik } from "formik";
import { DatePicker } from "../../global/form/DatePicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import * as moment from "moment";
const prefix = process.env.REACT_APP_PREFIX;
import {
  DownloadSapRepot,
  DownloadSapRepotMonth,
  GetSapReport,
  GetSapReportMonth
} from "../../../api/PaymentsReport";
import { GenerateSapReport, GeneratePolizaCorteMensual } from "../../../components/payments/reportSapTxt"


export default function ModalReportSAP(props) {
  const [t] = useTranslation(["reports", "global"]);
  const language = localStorage.getItem(`cmLanguage${prefix}`);
  const initialValues = {
    cycle: "",
    day: "",
    month: "",
  }
  const [optionsCycles, setOptionsCycles] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);


  //Traslate calendar
  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }

  const downloadReport = async (values) => {
    setLoading(true);
    let payload = {
      school_cycle_id: values.cycle,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    if (props.sapReportSAP.type == "csvDay") {
      payload.start_date = moment(values.day).startOf("day").unix();
      payload.end_date = moment(values.day).endOf("day").unix();
      DownloadSapRepot(payload, props.setSapReportSAP)
    }
    if (props.sapReportSAP.type == "txtDay") {
      payload.start_date = moment(values.day).startOf("day").unix();
      payload.end_date = moment(values.day).endOf("day").unix();
      getSapReport(payload)
    }
    if (props.sapReportSAP.type == "csvMonth") {
      payload.month = values.month.getMonth() + 1;
      payload.year = values.month.getFullYear();
      DownloadSapRepotMonth(payload, setShowModalConfirm);
    }
    if (props.sapReportSAP.type == "txtMonth") {
      payload.month = values.month.getMonth() + 1;
      payload.year = values.month.getFullYear();
      getSapReportMonth(payload)
    }
  }

  /**
  * Purpose: Run the creation of TXT report from obtained data
  */
  const getSapReport = (payload) => {
    GetSapReport(payload)
      .then((response) => {
        try {
          response.data.reportDate = moment.unix(payload.start_date).format("DDMM");
          if (response.status) GenerateSapReport(response.data, props.setSapReportSAP);
        } catch (error) {
          console.error(error);
        }
      })
      .finally(() => {
      });
  };

  /**
* Purpose: Run the creation of TXT report from obtained data
*/
  const getSapReportMonth = (payload) => {
    GetSapReportMonth(payload)
      .then((response) => {
        try {
          response.data.reportDate = moment(payload.month,"MM").format("MM");
          if (response.status) GeneratePolizaCorteMensual(response.data, props.setSapReportSAP);
        } catch (error) {
          console.error(error);
        }
      })
      .finally(() => {
      });
  };

  const validateParams = (values) => {
    let enabled = values.cycle != "" && (values.day != "" || values.month != "");
    setDisabledBtn(!enabled);
  }

  useEffect(() => {
    const cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const cycles = cyclesLocal.map((cycle) => {
      return {
        id: cycle.id,
        name: cycle.school_cycle,
      };
    });
    setOptionsCycles(cycles);
  }, []);

  return (
    <div>
      <Modal
        show={!showModalConfirm}
        size="lg"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="pb-0">
          <Modal.Title className="modal-title">
            {t("reports:optionsSap.sownloadReport")}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            downloadReport(values);
          }}
        >
          {({ setFieldValue, values }) => (
            <FormikCont>
              {validateParams(values)}
              <Modal.Body>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label            >
                        {t("reports:optionsSap.schoolCycle")}
                      </Form.Label>
                      <Form.Select
                        id="cycle"
                        value={values.cycle || null}
                        onChange={(e) => setFieldValue("cycle", e.target.value)}
                        style={values.cycle == "" ? { color: "#828282", fontWeight: "400" } : {}}
                      >
                        <option hidden> {t("reports:optionsSap.selectSchoolCycle")}</option>
                        {optionsCycles.map((cycle) => (
                          <option key={cycle.id} value={cycle.id} style={{ color: "#212529" }}>
                            {cycle.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {props.sapReportSAP.type === "csvMonth" || props.sapReportSAP.type === "txtMonth" ?
                    <Col md={6}>
                      <DatePicker
                        style={{ textTransform: "capitalize", fontSize: "1rem", fontWeight: "400" }}
                        label={t("reports:optionsSap.Month")}
                        placeholderText={t("reports:optionsSap.selectMonth")}
                        name="month"
                        dropdownMode="select"
                        monthView={true}
                        required
                        {...Locallanguage}
                        showMonthYearPicker
                        dateFormat="MMMM"
                      />
                    </Col>
                    :
                    <Col md={6}>
                      <DatePicker
                        label={t("reports:optionsSap.day")}
                        placeholderText={t("reports:optionsSap.selectay")}
                        dateFormat="dd/MM/yyyy"
                        name="day"
                        dropdownMode="select"
                        required
                        {...Locallanguage}
                        monthView={true}
                      />
                    </Col>
                  }
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-secondary"
                  onClick={() => props.setSapReportSAP({
                    ...props.sapReportSAP,
                    showModalReportSAP: false,
                  })}
                  disabled={loading}
                >
                  {t("global:buttons.cancel")}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={disabledBtn || loading}
                >
                  {loading ?
                    <>
                      {t("global:buttons.downloading")}
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="white"
                        style={{
                          width: "0.81rem",
                          height: "0.81rem",
                          marginLeft: "3px",
                          borderWidth: "0.17em",
                        }} />
                    </>
                    :
                    t("global:buttons.download")
                  }
                </Button>
              </Modal.Footer>

            </FormikCont>
          )}
        </Formik>
      </Modal>


      <Modal
        show={showModalConfirm}
        size="md"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="pb-0">
          <Modal.Title className="modal-title">
            {t("reports:optionsSap.sownloadReport")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("reports:optionsSap.reportGenerationMessage")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => props.setSapReportSAP({
              ...props.sapReportSAP,
              showModalReportSAP: false,
            })}
          >
            {t("global:buttons.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

ModalReportSAP.propTypes = {
  sapReportSAP: PropTypes.object,
  setSapReportSAP: PropTypes.func,
};
