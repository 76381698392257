import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

export default function ModalNotificationSend(props) {
  let CM_beta = window.location.href.includes("//beta.app.campusmovil.com");
  let CM_staging = window.location.href.includes(
    "//staging.app.campusmovil.com"
  );
  let CM_live = window.location.href.includes("//app.campusmovil.com");
  let campusMovil = CM_beta ? true : CM_staging ? true : CM_live ? true : false;

  return (
    <div>
      <Modal
        show={props.showNotificationSend}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {props.t("modalNotificationSent.notificationSent")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body mbottom10">
          <div className="text-center mtop20">
            <img
              src={
                campusMovil
                  ? "/img/communication.svg"
                  : "/img/communication-uplift.svg"
              }
            />
          </div>
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            {props.t("modalNotificationSent.notificationText")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => props.setShowNotificationSend(false)}
          >
            {props.t("modalNotificationSent.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalNotificationSend.propTypes = {
  showNotificationSend: PropTypes.bool,
  setShowNotificationSend: PropTypes.func,
  t: PropTypes.func,
};
