import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import SeeAllModule from "./SeeAllModule";
import { GetTaskDashboard } from "../../../api/Dashboard";
import PropTypes from "prop-types";
import * as moment from "moment";
import { goToHref } from "../../global/GlobalTools";
import { LoadingTaskDashboard } from "../../lazyLoading/LazyLoading";
import Cookies from "js-cookie";
import { dateCookie, domainCookie } from "../../global/GlobalTools";
const prefix = process.env.REACT_APP_PREFIX;

const customStyles = {
  rowContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "0px",
    height: "50px",
    marginBlock: "10px",
    marginLeft: "0px",
    marginRight: "0px",
  },
  scrollEvent: {
    maxHeight: "292px",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "13px",
  },
  textDate: {
    fontSize: "12px",
    marginTop: "6px",
    paddingLeft: "60px",
  },
};

const dummyData = [
  {
    id: "1",
    name: "Solicitud de de información",
    asigneed: "Juan Manuel",
    prospect: "Jose Luis",
    dateMoment: "08/08/2023",
  },
  {
    id: "2",
    name: "Llamar para solicitar información",
    asigneed: "Marta Estrada",
    prospect: "Jose Luis",
    dateMoment: "20/08/2023",
  },
  {
    id: "3",
    name: "Añadir materias",
    asigneed: "Lucas",
    prospect: "Mayte Solis",
    dateMoment: "10/08/2023",
  },
  {
    id: "4",
    name: "Ajustar información medica",
    asigneed: "Victor Gamboa",
    prospect: "Julia Hernandez",
    dateMoment: "02/08/2023",
  },
  {
    id: "5",
    name: "Revisar tareas",
    asigneed: "Marcos Torres",
    prospect: "Juan Jose",
    dateMoment: "20/02/2023",
  },
];

const TasksModule = (props) => {
  const [t] = useTranslation(["dashboard"]);
  const [taskList, setTaskList] = useState([]);

  /**
   * Service to obtain the data of the tasks of the last 30 days
   */
  const getTasksDashboard = () => {
    const request = {
      school_cycle_id: props.filters.cycle?.id,
      school_level_id: props.filters.level?.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetTaskDashboard(request)
      .then((result) => {
        let data = result.data;
        let task = [];
        task = data.map((item) => {
          let today = moment().format("DD/MM/YYYY");
          let entry = moment.unix(item.expire_date).format("DD/MM/YYYY");
          return {
            id: item.id,
            name: item.title,
            asigneed: item.asignee,
            prospect: item.prospect_name,
            dateMoment: moment.unix(item.expire_date).format("DD/MM/YYYY"),
            expiresToday: today == entry,
            prospectId: item.prospect_id,
          };
        });
        setTaskList(task);
        props.setWaitinData(false);
      })
      .catch(() => {
        setTaskList(dummyData);
      });
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    if (props.active == false) {
      setTaskList(dummyData);
      props.setWaitinData(false);
      return;
    }
  }, [props.active]);
  useEffect(() => {
    if (props.readyFilters) {
      if (props.active == false) return;
      getTasksDashboard();
    }
  }, [props.readyFilters]);

  return (
    <Row>
      {props.waitingData ? (
        <LoadingTaskDashboard />
      ) : (
        <>
          <Col md={12}>
            <Row>
              <Col md={7}>
                <i className="bi bi-people me-2 fs-6"></i>
                <b className="fs-6">{t("textTask.titleTask")}</b>
              </Col>
              <Col md={5}>
                <SeeAllModule isCMv1={true} path={"/crm/tasks"}></SeeAllModule>
              </Col>
            </Row>
          </Col>
          {taskList.length || !props.active ? (
            <div style={customStyles.scrollEvent}>
              {taskList?.map((item) => {
                return (
                  <Row key={item} style={customStyles.rowContainer} role="button" onClick={() => {
                    //Update the cookie "cmSourceView"
                    Cookies.set(`cmSourceView${prefix}`, "/dashboard", {
                      expires: dateCookie(),
                      domain: domainCookie(), //Domain to Beta and Live, for local change this for localhost
                    });
                    goToHref("/crm/lead_profile/" + item.prospectId)
                  }}>
                    <Col md={12}>
                      <Row>
                        <Col md={8}>
                          {item.name}
                        </Col>
                        <Col md={3} className="opacity-50 text-end pe-0">
                          <p >{t("textTask.expirationDate")}
                            {
                              item.expiresToday ? (
                                <p className="text-danger d-inline">{t("textTask.expirationToday")}</p>
                              ) : item.dateMoment
                            }
                          </p>
                        </Col>
                        <Col md={1} className="opacity-50 text-end">
                          <i
                            className="bi bi-chevron-right"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={5} className="opacity-50 text-truncate">
                              {t("textTask.titleAssignedTo")} {item.asigneed}
                            </Col>
                            <Col md={5} className="opacity-50 text-truncate">
                              {t("textTask.titleProspect")} {item.prospect}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <Row>
              <Col md={12}>
                <p className="text-center">{t("textTask.titleNoTask")}</p>
              </Col>
            </Row>
          )}
        </>
      )}
    </Row>
  );
};

export default TasksModule;

TasksModule.propTypes = {
  waitingData: PropTypes.bool,
  setWaitinData: PropTypes.func,
  filters: PropTypes.object,
  readyFilters: PropTypes.bool,
  active: PropTypes.bool,
};
