import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button, Alert, Col, Row, Form } from "react-bootstrap";

/** Services */
import { SendReminder } from "../../../api/Reminder";

/**Elements */
import TextLoading from "../../../components/global/TextLoading";

export default function ModalSendPaymentReminder(props) {
  const [t] = useTranslation(["reminder", "global, reports"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [numberStudents, setNumberStudents] = useState(0);
  const [paymentIds, setPaymentIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(false);
  const [chargesWithoutExpirationDate, setChargesWithoutExpirationDate] =
    useState(false);

  /**
   * Purpose: Send the payment reminder
   */
  const sendReminder = () => {
    setIsSaving(true);
    let request = {
      paymentIds: paymentIds,
      reminder: props.reminder,
      chargesExpirationDate: chargesWithoutExpirationDate,
    };
    if (chargesWithoutExpirationDate) {
      request.charges_without_expiration_date = true;
    } else {
      request.charges_without_expiration_date = false;
    }
    setChargesWithoutExpirationDate(false);
    SendReminder(request).then((result) => {
      if (result.data) {
        props.setShowSendReminder(false);
      } else {
        setErrors(result);
      }
      props.setReopenModalSendReminder(false); //Indicates if the Send Reminder modal will be reopened when pressing save/close in the Configure Reminder modal
      setIsSaving(false);
    });
  };

  /**
   * Open the Configure Reminder modal
   */
  const openConfigureReminder = () => {
    props.setShowSendReminder(false);
    props.setShowConfigureReminder(true);
    props.setReopenModalSendReminder(true); //Indicates if the Send Reminder modal will be reopened when pressing save/close in the Configure Reminder modal
  };

  const closeModal = () => {
    props.setShowSendReminder(false);
    props.setReopenModalSendReminder(false); //Indicates if the Send Reminder modal will be reopened when pressing save/close in the Configure Reminder modal
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    props.showSendReminder == false ? setErrors(false) : "";
  }, [props.showSendReminder]);

  /**
   * Purpose: Gets the id's of payments to which the reminder will be sent
   */
  useEffect(() => {
    props.setSelectedRowIds(props.selectedRowIds);
    let paymentIds = [];
    if (props.selectedRowIds.length && props.selectedRowIds[0].original) {
      //If at least one checkbox from the list is selected
      for (let i = 0; i < props.selectedRowIds.length; i++) {
        paymentIds.push(props.selectedRowIds[i].original.student_id);
      }
    } else {
      //Get all the ids in the list
      for (let i = 0; i < props.values.length; i++) {
        paymentIds.push(props.values[i].original.student_id);
      }
    }
    setPaymentIds(paymentIds);
    props.setNumberStudents(paymentIds.length);
  }, [props.values, props.selectedRowIds]);

  /** Initializes the number of students to whom the reminder is sent */
  useEffect(() => {
    setNumberStudents(props.numberStudents);
  }, [props.numberStudents]);

  return (
    <div>
      <Modal
        show={props.showSendReminder}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">
            {numberStudents == 1
              ? t("SendReminder.titleSingular", { numberStudents })
              : t("SendReminder.titlePlural", { numberStudents })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body mbottom10">
          <Row>
            <Col md={12}>
              <p>
                {numberStudents == 1
                  ? t("SendReminder.subTitleSingular", { numberStudents })
                  : t("SendReminder.subTitlePlural", { numberStudents })}
              </p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Form.Check
                id="checkExpirationDate"
                label={t("reports:expirateDate")}
                className="for-element"
                onClick={() =>
                  setChargesWithoutExpirationDate(!chargesWithoutExpirationDate)
                }
              />
            </Col>
          </Row>

          <div style={{ marginTop: "20px" }}>
            {errors ? (
              <Alert variant="warning">{errors.description}</Alert>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <div>
            <Button
              variant="link"
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => openConfigureReminder(true)}
            >
              {t("configureReminder.linkConfigure")}
            </Button>
          </div>
          <div className="pull-left">
            <Button
              variant="outline-secondary"
              onClick={() => closeModal()}
              disabled={isSaving}
            >
              {t("global:buttons.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => sendReminder()}
              disabled={isSaving}
            >
              {isSaving ? (
                <TextLoading
                  text={t("global:buttons.sending")}
                  variant="light"
                />
              ) : (
                t("global:buttons.send")
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalSendPaymentReminder.propTypes = {
  showSendReminder: PropTypes.bool,
  setShowSendReminder: PropTypes.func,
  selectedRowIds: PropTypes.array,
  reminder: PropTypes.object,
  setShowConfigureReminder: PropTypes.func,
  openConfigureReminder: PropTypes.func,
  setReopenModalSendReminder: PropTypes.func,
  reopenModalSendReminder: PropTypes.bool,
  setSelectedRowIds: PropTypes.func,
  values: PropTypes.array,
  numberStudents: PropTypes.number,
  setNumberStudents: PropTypes.func,
};
