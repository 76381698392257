/**
 *       **** Readme ****
 *
 * Component: <FormSelectGroup />
 * Purpose: Returns a group of elements (Select and button)
 *
 * Props:
 *   -handleButton: {Function} Función que se ejecuta al presionar el boton
 *   -addButton: {Boolean} Indica si se muestra el boton
 *   -textButton: {String} Texto que se muestra en el botón
 *   -isDisabledButton: {Boolean} Deshabilita el boton
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 04/March/2022
 * Last update: 05/April/2022
 */

import React, { useEffect, useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import Select from "react-select";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//Components
import selectStyles from "../GlobalTools";

export default function FormSelectGroup({ label, ...props }) {
  const [t] = useTranslation(["cycles"]);
  const [field, meta, helpers] = useField(props);
  const [addButton] = useState(props.addButton || false);

  /**
   * Initial loading
   */
  useEffect(() => {}, []);

  return (
    <>
      {addButton ? (
        <Form.Group className="mb-3">
          <Form.Label
            htmlFor={props.id || props.name}
            className={props.required && props.showRequired ? "required" : ""}
          >
            {label}
            {!props.required && props.showRequired === false ? (
              <span className="optional">{t("optional")}</span>
            ) : (
              ""
            )}
          </Form.Label>
          <InputGroup className="mb-3">
            <Select
              styles={selectStyles}
              formatOptionLabel={function (data) {
                return (
                  <span dangerouslySetInnerHTML={{ __html: data.label }} />
                );
              }}
              classNamePrefix={"select-group"}
              className={
                meta.touched && meta.error
                  ? `select-group errorValidation`
                  : `select-group`
              }
              //className="select-group"
              {...field}
              {...props}
              onBlur={() => helpers.setTouched(true)}
            />
            <Button
              variant="primary"
              id="button-addon2"
              onClick={() => props.handleButton(true)}
              disabled={props.isDisabledButton}
            >
              {props.textButton}
            </Button>
            {meta.touched && meta.error ? (
              <Form.Text
                className="text-muted error"
                style={{ bottom: "-17px" }}
              >
                {meta.error}
              </Form.Text>
            ) : null}
          </InputGroup>
        </Form.Group>
      ) : (
        <Form.Group className="mb-3">
          <Form.Label
            htmlFor={props.id || props.name}
            className={props.required && props.showRequired ? "required" : ""}
          >
            {label}
            {!props.required && props.showRequired === false ? (
              <span className="optional">{t("optional")}</span>
            ) : (
              ""
            )}
          </Form.Label>
          <Select
            classNamePrefix={"select"}
            className={
              meta.touched && meta.error ? `select errorValidation` : `select`
            }
            placeholder={t("select.placeholder")}
            styles={selectStyles}
            {...field}
            {...props}
            onBlur={() => helpers.setTouched(true)}
          />
          {meta.touched && meta.error ? (
            <Form.Text className="text-muted error">{meta.error}</Form.Text>
          ) : null}
        </Form.Group>
      )}
    </>
  );
}

FormSelectGroup.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  handleButton: PropTypes.func,
  addButton: PropTypes.bool,
  textButton: PropTypes.string,
  isDisabledButton: PropTypes.bool,
};
