import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button } from "react-bootstrap";

export default function ModalImportResult(props) {
  const [t] = useTranslation(["student", "global"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const isByCronjob = props.isByCronjob || false;
  const typeUsers = props.typeUsers || "";

  return (
    <div>
      <Modal
        show={props.showImportResult}
        size="md"
        aria-labelledby="contained-modal-title-md"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {isByCronjob
              ? t("modalImportResult.importOf", { typeUsers })
              : t("modalImportResult.title")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body mbottom10">
          {isByCronjob ? (
            <Row>
              <Col xs={12}>
                {t("modalImportResult.importOfDescription", { typeUsers })}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <center>
                  <i
                    className="far fa-5x fa-check-circle"
                    style={{ color: "#07C25F", marginBottom: "20px" }}
                  ></i>
                </center>

                {props.importResult.created ? (
                  <p style={{ fontWeight: "bold" }}>
                    {t("modalImportResult.successfullyCreated")}{" "}
                    {props.importResult.created.length}
                  </p>
                ) : (
                  ""
                )}

                {props.importResult.updated ? (
                  <p style={{ fontWeight: "bold" }}>
                    {t("modalImportResult.successfullyUpdated")}{" "}
                    {props.importResult.updated.length}
                  </p>
                ) : (
                  ""
                )}

                {props.importResult.errors ? (
                  <p style={{ fontWeight: "bold" }}>
                    {t("modalImportResult.errors")}{" "}
                    {props.importResult.errors.length}
                  </p>
                ) : (
                  ""
                )}

                <p style={{ textAlign: "right" }}>
                  <a
                    style={{
                      color: "var(--base-color)",
                      textDecoration: "none",
                    }}
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    {t("modalImportResult.seeDetails")}
                  </a>
                </p>
                <div className="collapse" id="collapseExample">
                  <div
                    className="card card-body"
                    style={{ background: "var(--white-hover)" }}
                  >
                    {props.importResult.created ? (
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          {props.importResult.created.length}{" "}
                          {t("modalImportResult.successfullyCreated")}
                        </p>
                        {props.importResult.created.map((student, index) => {
                          return (
                            <p style={{ fontSize: "12px" }} key={index}>
                              {student.last_name}, {student.first_name}{" "}
                              {student.middle_name} - {student.curp}
                            </p>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}

                    {props.importResult.updated ? (
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          {props.importResult.updated.length}{" "}
                          {t("modalImportResult.successfullyUpdated")}
                        </p>
                        {props.importResult.updated.map((student, index) => {
                          return (
                            <p
                              style={{ fontSize: "12px", paddingLeft: "15px" }}
                              key={index}
                            >
                              {student.last_name}, {student.first_name}{" "}
                              {student.middle_name} - {student.curp}
                            </p>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}

                    {props.importResult.errors ? (
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          {props.importResult.errors.length}{" "}
                          {t("modalImportResult.errors")}
                        </p>
                        {props.importResult.errors.map((error, index) => {
                          return (
                            <p style={{ fontSize: "12px" }} key={index}>
                              {error}
                            </p>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            id="acceptImportResultBtn"
            style={{ marginRight: "10px" }}
            variant="primary"
            onClick={() => props.setShowImportResult(false)}
          >
            {t(isByCronjob ? "global:buttons.close" : "global:buttons.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalImportResult.propTypes = {
  showImportResult: PropTypes.bool,
  importResult: PropTypes.object,
  setShowImportResult: PropTypes.func,
  isByCronjob: PropTypes.bool,
  typeUsers: PropTypes.string,
};
