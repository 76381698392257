import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Alert, Col } from "react-bootstrap";
//import Select from "react-select";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import * as Sentry from "@sentry/react";
import { DownloadTemplate, GetSchoolCycle } from "../../api/StudentsReport";

/** Services */
import { importStudentsCSV } from "../../api/StudentsReport";

/**Elements */
import TextLoading from "../global/TextLoading";

/**Components */
import SchoolCycle from "../global/form/SchoolCycle";
import CreateCycleModal from "../../pages/cycles/modals/CreateCycleModal";

export default function ModalImportStudent(props) {
  const dropzoneRef = createRef();
  const [file, setFile] = useState("");
  const [schoolcycleId, setSchoolCycleId] = useState("");
  const [activeImport, setActiveImportButton] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(false);
  const [t] = useTranslation(["modalImportStudent", "student", "global"]);
  const FileDownload = require("js-file-download");
  //Create Cycle Modal
  const [openCreateCycleModal, setCreateCycleModal] = useState(false);
  const [organizationSchoolLevels, setOrganizationSchoolLevels] = useState([]);
  const [programsIds, setProgramsIds] = useState([]);
  const [reloadCycles, setReloadCycles] = useState(false);

  useEffect(() => {
    if (props.schoolCycle.length > 0) {
      props.schoolCycle[0].label = t("labels.selectSchoolCycle");
    }
    activeImportButton();
  });

  /**
   * Download Templeate
   */
  const downloadTemplate = () => {
    DownloadTemplate().then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        return;
      }
      FileDownload(result.data, "template.csv");
    });
  };

  /**
   * save file csv
   * @param {File} file
   */
  const saveFile = (file) => {
    if (file.length > 0) {
      setFile(file);
    }
  };

  /**
   * import students
   */
  const importStudents = () => {
    setActiveImportButton(true);
    setIsSaving(true);
    setErrors(false);
    importStudentsCSV(file[0], schoolcycleId)
      .then((result) => {
        if (result.data) {
          cancel();
          props.setShowImportResult(true);
          props.setImportResult(result.data);
        } else {
          Sentry.captureException(Error(JSON.stringify(result)));
          setErrors(result);
        }
        setIsSaving(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Purpose: Close the "Import Students" modal
   */
  const cancel = () => {
    props.setShowImportStudent(false);
    setFile("");
    setSchoolCycleId("");
    setActiveImportButton(true);
    setErrors(false);
  };

  /**
   * Purpose: Select school cycle
   */
  const selectSchoolCycle = (event) => {
    let cycleId = "";
    if (event && event.id) {
      cycleId = event.id;
    }
    setSchoolCycleId(cycleId);
  };

  /**
   * Validate buttton "import csv"
   */
  const activeImportButton = () => {
    if (schoolcycleId && file) {
      setActiveImportButton(false);
    } else {
      setActiveImportButton(true);
    }
  };

  /**
   * Open File
   */
  const openFile = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  /**
   * Description: Toggle to open and close "CreateCycleModal"
   */
  const handleClose = () => {
    setCreateCycleModal(!openCreateCycleModal);
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Import Student Modal");
    getSchoolCycle();
  }, []);

  /**
   * Purpose: Gets the school cycle. //Este es para ambos
   */
  const getSchoolCycle = () => {
    GetSchoolCycle().then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        return;
      }
      let data = result.data.data;
      for (let index = 0; index < data.length; index++) {
        data[index].label = data[index].school_cycle;
        data[index].value = data[index].id;
      }

      //Get the school cycles
      let currentSchoolCycle = {};
      //eval if data is  an array
      currentSchoolCycle = !Array.isArray(data) ? data : data.find((cycle) => cycle.current_cycle === "1");
      currentSchoolCycle = currentSchoolCycle
        ? currentSchoolCycle
        : data.length
          ? data[0]
          : false;

      if (currentSchoolCycle) {
        getCurrentLevels(currentSchoolCycle);
      }
    });
  };

  /**
   * Get current leves. //Este es para formuluarios
   * @param {Object} currentSchoolCycle
   */
  const getCurrentLevels = (currentSchoolCycle) => {
    //Extract the levels of the school cycle in use that belong to the organization.
    let organizationSchoolLevels = [];
    for (let singleLevel of currentSchoolCycle.school_levels) {
      let schoolLevel = {};
      schoolLevel.organization_school_level_id =
        singleLevel.organization_school_level_id;
      organizationSchoolLevels.push(schoolLevel);
    }
    //Extract the higher levels of the school cycle in use that belong to the organization.
    let programsIds = [];
    for (let singleColleges of currentSchoolCycle.colleges_and_schools) {
      let collegesLevels = singleColleges.school_levels;
      for (let singleLevel of collegesLevels) {
        let levelPrograms = singleLevel.programs;
        for (let singleProgram of levelPrograms) {
          if (singleProgram.enabled === "1") {
            programsIds.push(singleProgram.id);
          }
        }
      }
    }
    setOrganizationSchoolLevels(organizationSchoolLevels);
    setProgramsIds(programsIds);
  };

  return (
    <Modal
      show={props.showImportStudent}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("labels.importStudents")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <div className="col-md-7 textImportStudent">
              {t("labels.formImportStudents")}
              <br />
              <p
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: "12px",
                  marginTop: "15px",
                  marginBottom: "10px",
                }}
              >
                {t("labels.note")}
              </p>
            </div>
            <div className="col-md-5">
              <Button
                id="downloadTempleateBtn"
                type="button"
                onClick={downloadTemplate}
                style={{ marginLeft: "40px" }}
                disabled={isSaving}
              >
                {t("buttons.downloadTempleate")}
              </Button>
            </div>
          </div>
          <p
            style={{
              fontFamily: "'Open Sans', sans-serif",
              fontSize: "13px",
              marginBottom: "10px",
            }}
          >
            {t("labels.schoolCycle")}
          </p>
          <Row style={{ marginBottom: "20px" }}>
            <Col md={8}>
              <SchoolCycle
                useIn="filters"
                handleOnchange={selectSchoolCycle}
                reloadCycles={reloadCycles}
              />
            </Col>
            <Button
              id="addCycleBtn"
              variant="primary"
              onClick={() => handleClose()}
              style={{ width: "25%", marginLeft: "20px" }}
              disabled={isSaving}
            >
              {t("buttons.addCycle")}
            </Button>
          </Row>
          <div
            style={{
              position: "relative",
              height: "auto",
              borderWidth: "2px",
              borderColor: "rgb(194, 194, 194)",
              borderStyle: "dashed",
              borderRadius: "5px",
            }}
          >
            <Dropzone
              ref={dropzoneRef}
              accept=".csv"
              onDrop={saveFile}
              noClick
              noKeyboard
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <center>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "'Open Sans', sans-serif",
                        fontSize: "14px",
                        color: "#ea2c54",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {file && file[0].name
                        ? file[0].name
                        : t("labels.dropFile")}
                      <br />
                      {file ? "" : t("labels.dropFileOr")}
                      <br />
                      <br />
                      <input {...getInputProps()} />
                      <Button
                        id="uploadStudentInformationFileBtn"
                        type="button"
                        className="btn btn-text btn-white"
                        disabled={isSaving}
                        onClick={openFile}
                      >
                        {t("labels.uploadFile")}
                      </Button>
                    </p>
                  </center>
                </div>
              )}
            </Dropzone>
          </div>
          <div>
            <p
              style={{
                color: "var(--base-color)",
                textDecoration: "none",
                marginTop: "15px",
              }}
            >
              <a
                style={{
                  color: "var(--base-color)",
                  textDecoration: "none",
                  fontSize: "13px",
                }}
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                target="_self"
                href={`${process.env.REACT_APP_V1}/configuraciones/ciclo#higherlevel-section`}
              >
                {t("student:modalImport.linkAcademicOffers")}
              </a>
            </p>
          </div>
        </form>
        {errors ? <Alert variant="warning">{errors.description}</Alert> : ""}
      </Modal.Body>

      <Modal.Footer>
        <Button
          id="cancelImportCsvStudentBtn"
          style={{ marginRight: "10px" }}
          variant="outline-secondary"
          disabled={isSaving}
          onClick={() => cancel()}
        >
          {t("buttons.cancel")}
        </Button>
        <Button
          id="importCsvStudentBtn"
          variant="primary"
          disabled={activeImport || isSaving}
          onClick={importStudents}
        >
          {isSaving ? (
            <TextLoading text={t("buttons.importingCSV")} variant="light" />
          ) : (
            t("buttons.importCSV")
          )}
        </Button>
      </Modal.Footer>
      {/**Create Cycle Modal */}
      <CreateCycleModal
        open={openCreateCycleModal}
        importView={"cycle"}
        handleClose={handleClose}
        getSchoolCycleFromComponent={() => {
          setReloadCycles(!reloadCycles);
        }}
        organizationSchoolLevels={organizationSchoolLevels}
        setOrganizationSchoolLevels={setOrganizationSchoolLevels}
        programsIds={programsIds}
      />
    </Modal>
  );
}

ModalImportStudent.propTypes = {
  showImportStudent: PropTypes.bool,
  setShowImportStudent: PropTypes.func,
  schoolCycle: PropTypes.array,
  setShowImportResult: PropTypes.func,
  setImportResult: PropTypes.func,
};
