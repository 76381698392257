import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import * as Sentry from "@sentry/react";

import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { DownloadTemplate } from "../../api/Staff";

/** Services */
import { importStaffCSV } from "../../api/Staff";

/**Elements */
import TextLoading from "../global/TextLoading";

export default function ModalImportStaff(props) {
  const [t] = useTranslation(["staff"]);
  const FileDownload = require("js-file-download");
  const dropzoneRef = createRef();
  const [file, setFile] = useState("");
  const [errors, setErrors] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [activeImport, setActiveImportButton] = useState(true);

  /* Initial Loading */
  useEffect(() => {
    Sentry.setTag("section", "Import Staff Modal");
  }, []);

  /**
   * Enable/Disable the button to import staff
   */
  useEffect(() => {
    const noFile = file == "";
    setActiveImportButton(noFile);
  }, [file]);

  /**
   * Download Templeate
   */
  const downloadTemplate = () => {
    DownloadTemplate().then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setErrors(result);
        return;
      }
      FileDownload(
        result.data,
        `${t("modalImportStaff.nameFileDownload")}.csv`
      );
    });
  };

  /**
   * Open File
   */
  const openFile = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  /**
   * save file csv
   * @param {File} file
   */
  const saveFile = (file) => {
    if (file.length > 0) {
      setFile(file);
    }
  };

  /**
   * import Staff
   */
  const importStaff = () => {
    setIsSaving(true);
    setErrors(false);
    importStaffCSV(file[0])
      .then((result) => {
        if (result.data) {
          cancel();
          props.setShowImportResult(true);
          props.setImportResult(result.data);
          props.listStaff();
        } else {
          Sentry.captureException(Error(JSON.stringify(result)));
          setErrors(result);
        }
        setIsSaving(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Purpose: Close the "Import Staff" modal
   */
  const cancel = () => {
    props.setShowImportStaff(false);
    setFile("");
    setErrors(false);
  };

  return (
    <div>
      <Modal
        show={props.showImportStaff}
        bsSize="large"
        arial-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("modalImportStaff.importStaff")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body mbottom10">
          <form>
            <Row>
              <Col md={7} style={{ fontSize: "12px" }}>
                {t("modalImportStaff.formImportStaff")}
              </Col>
              <Col md={5}>
                <Button
                  type="button"
                  onClick={downloadTemplate}
                  style={{ marginLeft: "40px" }}
                  disabled={isSaving}
                >
                  {t("modalImportStaff.btnDownloadTempleate")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2" md={9}>
                <b style={{ fontSize: "12px" }}>{t("modalImportStaff.note")}</b>
              </Col>
            </Row>
            <div
              style={{
                position: "relative",
                height: "auto",
                borderWidth: "2px",
                borderColor: "rgb(194, 194, 194)",
                borderStyle: "dashed",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              <Dropzone
                ref={dropzoneRef}
                accept=".csv"
                onDrop={saveFile}
                noClick
                noKeyboard
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <center>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#ea2c54",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        {file && file[0].name
                          ? file[0].name
                          : t("modalImportStaff.dropFile")}
                        <br />
                        {file ? "" : t("modalImportStaff.dropFileOr")}
                        <br />
                        <br />
                        <input {...getInputProps()} />
                        <Button
                          type="button"
                          className="btn btn-text btn-white"
                          disabled={isSaving}
                          onClick={openFile}
                        >
                          {t("modalImportStaff.uploadFile")}
                        </Button>
                      </p>
                    </center>
                  </div>
                )}
              </Dropzone>
            </div>
          </form>
          <div style={{ marginTop: "20px" }}>
            {errors ? (
              <Alert variant="warning">{errors.description}</Alert>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{ marginRight: "10px" }}
            variant="outline-secondary"
            disabled={isSaving}
            onClick={() => cancel()}
          >
            {t("modalImportStaff.btnCancel")}
          </Button>
          <Button
            variant="primary"
            disabled={activeImport || isSaving}
            onClick={importStaff}
          >
            {isSaving ? (
              <TextLoading
                text={t("modalImportStaff.btnImportingCSV")}
                variant="light"
              />
            ) : (
              t("modalImportStaff.btnImportCSV")
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalImportStaff.propTypes = {
  showImportStaff: PropTypes.bool,
  setShowImportStaff: PropTypes.func,
  setShowImportResult: PropTypes.func,
  setImportResult: PropTypes.func,
  listStaff: PropTypes.func,
};
