import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import TodayModule from "../../components/dashboard/relative/Today/TodayModule";
import { useTranslation } from "react-i18next";
import UserInfo from "../../components/dashboard/UserInfo";
import ClassView from "../../components/dashboard/ClassView";
import EventsView from "../../components/dashboard/EventsView";
import NewsView from "../../components/dashboard/NewsView";
import { GetServices } from "../../api/Organizations";
import Cookies from "js-cookie";
import OverlayBlockModule from "../../components/dashboard/admin/OverlayBlockModule";
import {
  GetUserRestriction,
  domainCookie,
  getFullName,
  getUrlParamValue,
  itsMyBirthday,
} from "../../components/global/GlobalTools";
import BirthdayModal from "../../components/global/modals/BirthdayModal";

const customStyles = {
  baseContainer: {
    padding: "10px 20px 0px 20px",
  },
  moduleContainerUserProfile: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
  },
  moduleContainer: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
  },
  moduleContainerEvents: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    height: "300px",
    marginBottom: "20px",
  },
  moduleContainerNews: {
    background: "#ffffff",
    border: "1px solid #ececee",
    borderRadius: "8px",
    height: "248px",
  },
};

const DashboardStudent = () => {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["dashboardStudent"]);
  const [studentId, setStudentId] = useState(null);
  const userId = localStorage.getItem(`cmUserID${prefix}`);
  const [isHigherLevel, setIsHigherLevel] = useState(false);
  const [modulesActive, setModulesActive] = useState({});
  const [modulesRealy, setModulesRealy] = useState(false);
  const [hasDebtRestriction, setHasDebtRestriction] = useState(false);
  const [loadClassModule, setLoadClassModule] = useState(false);
  const [showBirthdayModal, setShowBirthdayModal] = useState(false);
  const [isBirthday, setIsBirthday] = useState(false);

  const loadOrganizationServices = () => {
    let orgServices = {};
    GetServices(Cookies.get(`cmOrganizationID${prefix}`)).then((response) => {
      if (response.status) {
        localStorage.removeItem(`${prefix}Services`);
        let servicesUpdated = {};
        for (let service of response.data) {
          switch (service.service_id) {
            case "1": {
              //SIE
              servicesUpdated.SIE = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "2": {
              //Website
              servicesUpdated.Website = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "3": {
              //Payments
              servicesUpdated.Payments = {
                id: service.service_id,
                active: service.premium == "0" ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "4": {
              //App
              servicesUpdated.App = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "5": {
              //Reviews
              servicesUpdated.Reviews = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "6": {
              //Chat
              servicesUpdated.Chat = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "7": {
              //DocuSing
              servicesUpdated.DocuSing = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "8": {
              //SCE
              servicesUpdated.SCE = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "9": {
              //CRM
              servicesUpdated.CRM = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "10": {
              //DigitalContent
              servicesUpdated.DigitalContent = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
            case "11": {
              //GoogleForEducations
              servicesUpdated.GoogleForEducations = {
                id: service.service_id,
                active: service.premium == 0 ? false : true,
                organizationServiceId: service.organization_service_id,
              };
              break;
            }
          }
        }
        localStorage.setItem(
          `${prefix}Services`,
          JSON.stringify(servicesUpdated)
        );
        for (let item in servicesUpdated) {
          orgServices[item] = servicesUpdated[item].active;
        }
      } else {
        let services = JSON.parse(localStorage.getItem(`${prefix}Services`));
        for (let item in services) {
          orgServices[item] = services[item].active;
        }
      }
    });
    setModulesActive(orgServices);
    setTimeout(() => {
      setModulesRealy(true);
    }, 500);
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    getUserData();
    loadOrganizationServices();
    // Get Restraction value from user session
    let hasDebtRestriction = GetUserRestriction();
    // Validate restriction
    hasDebtRestriction =
      hasDebtRestriction &&
      typeof hasDebtRestriction == "string" &&
      hasDebtRestriction !== "false"
        ? true
        : false;
    if (hasDebtRestriction) {
      setHasDebtRestriction(hasDebtRestriction);
    }
  }, []);

  useEffect(() => {
    if (studentId != null) {
      setLoadClassModule(true);
    }
  }, [studentId]);

  const getUserData = () => {
    let user = null;
    user = JSON.parse(localStorage.getItem(`cmUserProfile${prefix}`));
    if (!user) {
      setTimeout(() => getUserData(), 200);
    } else {
      validateBirthday(user);
    }
  };

  /**
   * Evaluate if a user has a birthday
   */
  const validateBirthday = (userInfo) => {
    // comprobate if the user has a birthday
    if (itsMyBirthday(userInfo.birth_date)) {
      setIsBirthday({
        id: userInfo.id,
        name: getFullName(userInfo),
        firstName: userInfo.first_name.split(" ")[0],
      });
      // Show modal when you come from login
      if (
        Cookies.get(`cmSourceView${prefix}`) === "login" ||
        getUrlParamValue("show") === "birthday"
      ) {
        toggleBirthdayModal();
        // Clean cookie
        Cookies.remove(`cmSourceView${prefix}`, {
          domain: domainCookie(),
          path: "/",
        });
        // Clean url param
        history.replaceState(
          null,
          document.title,
          location.origin + location.pathname
        );
      }
    }
  };

  /**
   * Function to show or hide BirtdayModal
   */
  const toggleBirthdayModal = () => {
    setShowBirthdayModal((status) => !status);
  };

  return (
    <Container fluid>
      {modulesRealy && (
        <Row>
          <Col
            md={isHigherLevel ? 10 : 12}
            className={isHigherLevel ? "border-end" : ""}
          >
            <Row>
              <Col md={5}>
                <p className="fs-5">{t("titleWelcome.welcome")}</p>
              </Col>
              <Col md={7}>
                {isBirthday && (
                  <Button
                    onClick={toggleBirthdayModal}
                    size="lg"
                    style={{
                      backgroundColor: "#10B981",
                      color: "#fff",
                    }}
                    variant="outline-light"
                  >
                    <i className="fal fa-birthday-cake fa-lg" />
                    {t("birthdayModal.todayIsYouBirthday", {
                      name: isBirthday.firstName,
                    })}
                  </Button>
                )}
              </Col>
            </Row>
            {/*Student Profile Section*/}
            <Row>
              <Col sm={12} md={12} xl={12} style={customStyles.baseContainer}>
                <Row style={customStyles.moduleContainerUserProfile}>
                  <Col sm={12} md={12} xl={12}>
                    <UserInfo
                      userId={userId}
                      setIsHigherLevel={setIsHigherLevel}
                      setHasDebtRestriction={setHasDebtRestriction}
                      setStudentId={setStudentId}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Class section  */}
            <Row>
              <Col sm={12} md={7} xl={7} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.SCE}
                  textButton={t("dashboardStudent:textModals.textSeeMore")}
                  originRequest="classes"
                  nameService="SCE"
                  customWidth="103%"
                  content={
                    <Row style={customStyles.moduleContainer}>
                      <Col sm={12} md={12} xl={12}>
                        <ClassView
                          studentId={studentId}
                          active={modulesActive.SCE}
                          hasDebtRestriction={hasDebtRestriction}
                          loadClassModule={loadClassModule}
                        />
                      </Col>
                    </Row>
                  }
                />
              </Col>

              {/* Event section */}
              <Col sm={12} md={5} xl={5} style={customStyles.baseContainer}>
                <OverlayBlockModule
                  active={modulesActive.App}
                  textButton={t("dashboardStudent:textModals.textSeeMore")}
                  originRequest="events_and_news"
                  nameService="App"
                  customWidth={isHigherLevel ? "105%" : "104%"}
                  content={
                    <Row style={customStyles.moduleContainerEvents}>
                      <Col sm={12} md={12} xl={12}>
                        <EventsView active={modulesActive.App} />
                      </Col>
                    </Row>
                  }
                />

                <Col sm={12} md={12} xl={12}>
                  <OverlayBlockModule
                    active={modulesActive.App}
                    textButton={t("dashboardStudent:textModals.textSeeMore")}
                    originRequest="events_and_news"
                    nameService="App"
                    new={"new"}
                    customWidth={isHigherLevel ? "105%" : "104%"}
                    content={
                      <Row style={customStyles.moduleContainerNews}>
                        <Col sm={12} md={12} xl={12}>
                          <NewsView active={modulesActive.App} />
                        </Col>
                      </Row>
                    }
                  />
                </Col>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={2} xl={2}>
            {isHigherLevel && (
              <TodayModule
                modulesActive={modulesActive}
                active={modulesActive.App && modulesActive.SCE}
              />
            )}
          </Col>
        </Row>
      )}
      {showBirthdayModal && (
        <BirthdayModal
          showBirthdayModal={showBirthdayModal}
          toggleBirthdayModal={toggleBirthdayModal}
          birthdayBoys={[isBirthday]}
        />
      )}
    </Container>
  );
};

export default DashboardStudent;
