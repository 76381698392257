//Library
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
//Components
import ContentButton from "../global/ContentButton";
import { goToHref } from "../global/GlobalTools";

export default function SuccessModal(props) {
  const [t] = useTranslation(["modalCreateStudent", "global"]);
  const {
    open,
    handleClose = () => {},
    setShowCreateStudent = () => {},
    newStudentData = () => {},
  } = props;

  //Functions

  /**
   * Description: Create new student
   */
  const newStudent = () => {
    handleClose();
    setShowCreateStudent();
  };

  /**
   * Description: Redirects to APP v1
   */
  const studentProfile = () => {
    handleClose();
    goToHref(`/estudiante/${newStudentData.id}`);
  };

  return (
    <div>
      <Modal
        show={open}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("successModal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("successModal.body")}</Modal.Body>
        <Modal.Footer>
          {/**Conten buttons*/}
          <ContentButton className="content-button">
            <Button variant="outline-secondary" onClick={() => handleClose()}>
              {t("global:buttons.close")}
            </Button>
            <Button variant="primary" onClick={() => studentProfile()}>
              {t("successModal.viewProfile")}
            </Button>
            <Button variant="primary" onClick={() => newStudent()}>
              {t("successModal.addNew")}
            </Button>
          </ContentButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

SuccessModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setShowCreateStudent: PropTypes.func,
  newStudentData: PropTypes.object,
};
