import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ModalConfirmRequestReview({
  showModal,
  closeModal,
  count,
  confirmRequestReviews,
}) {
  const [t] = useTranslation(["relatives", "global"]);

  return (
    <div>
      <Modal show={showModal} size="lg">
        <Modal.Header>
          <Modal.Title className="modal-title text-center">
            {t("modalReviewPremium.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("modalReviewPremium.description", { count })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={closeModal}>
            {t("global:buttons.cancel")}
          </Button>
          <Button variant="primary" onClick={confirmRequestReviews}>
            {t("global:buttons.send")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ModalConfirmRequestReview.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  count: PropTypes.number,
  confirmRequestReviews: PropTypes.func,
};
